import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { combineReducers, createStore, applyMiddleware } from 'redux';
import * as serviceWorker from './serviceWorker';
import App from './App';
import 'bootstrap';

import { createEpicMiddleware } from 'redux-observable';
import { rootEpic } from './redux/epics';
import productReducer from './redux/reducers/productReducer';

const reducers = combineReducers({
  productReducer
});

const epicMiddleware = createEpicMiddleware();

const store = createStore(reducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(), applyMiddleware(thunk, epicMiddleware));


epicMiddleware.run(rootEpic);

ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root'),
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
