import React from "react";
import FileConstants from "../../../FileConstants";
import TextConstants from "../../../TextConstants";
import { Button } from "antd";

function Description() {
  return (
    <>
      <div className="description-container">
        <div className="description">
          <div
            className="row justify-content-md-center"
            style={{ padding: 26 }}
          >
            <WebVersion />
            <MobileVersion />
          </div>
        </div>
      </div>
      <Consultation />
    </>
  );
}

const { text_1, text_2, consultation_btn_text } = TextConstants.DESCRIPTION;
const WebVersion = () => {
  return (
    <div className="d-none d-lg-block">
      <div className="">
        <span>
          <p style={{ marginBottom: 20, fontSize: 24 }}>
            {text_1.p1}{" "}
            <span style={{ fontWeight: "bold", fontSize: 24 }}>
              {text_1.p2}
            </span>
          </p>
        </span>

        <span>
          <p style={{ fontWeight: 700, fontSize: 18 }}>
            {text_2.name}{" "}
            <span style={{ fontWeight: "normal", fontSize: 18 }}>
              {text_2.designation}
            </span>
          </p>
        </span>
      </div>
    </div>
  );
};

const MobileVersion = () => {
  return (
    <div className="d-lg-none">
      <div className="col-lg-10 col-12 font lora" id="textSection">
        <span>
          <p style={{ marginBottom: 20, fontSize: 18 }}>
            {text_1.p1}{" "}
            <span style={{ fontWeight: "bold", fontSize: 18 }}>
              {text_1.p2}
            </span>
          </p>
        </span>
        <span>
          <p style={{ fontWeight: 700, fontSize: 16 }}>
            {text_2.name}{" "}
            <span style={{ fontWeight: "normal", fontSize: 16 }}>
              {text_2.designation}
            </span>
          </p>
        </span>
      </div>
    </div>
  );
};

const Consultation = () => {
  return (
    <>
      <div className="d-none d-lg-block">
        <div
          className="row justify-space-between immersive-storefronts"
          style={{ marginTop: 0 }}
        >
          <div
            className="col-lg-6 col-12"
            style={{
              paddingLeft: 0,
              paddingBottom: 26,
            }}
          >
            <h4 className="font lora f-32 w-700 black-2b">
              Ready to supercharge <br />
              your brand?
            </h4>
            <Button
              onClick={() => {
                window.scrollTo({ top: 750, behavior: "smooth" });
              }}
              style={{
                backgroundColor: "rgba(194,163,116,1)",
                border: "none",
                height: "50px",
                width: "calc(92% - 32px)",
                borderRadius: 4,
                marginRight: 16,
              }}
            >
              <span
                style={{
                  color: "white",
                  fontSize: "medium",
                  fontWeight: "500",
                }}
              >
                {consultation_btn_text}
              </span>
            </Button>
          </div>
          <div className="col-lg-6 col-12 bg-img">
            <img
              className="section-img load-lazy"
              style={{ position: "relative", top: -100, paddingLeft: 50 }}
              src={FileConstants.HIGH_POINT_LANDING_PAGE.chair}
              alt="index"
            />
          </div>
        </div>
      </div>

      <div className="d-lg-none">
        <div
          className="row justify-content-md-center justify-in-center"
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          <div
            className="col-lg-6 col-12"
            style={{
              paddingLeft: 0,
            }}
          >
            <h4 className="font lora f-18 ws-2 w-300 black-2b justify-in-center">
              Ready to supercharge your brand?
            </h4>
            <div className="justify-in-center">
              <button
                className="btn font lora f-16 ws-2 w-300 grey-light "
                onClick={() =>
                  window.scrollTo({ top: 680, behavior: "smooth" })
                }
                type="button"
              >
                {consultation_btn_text}
              </button>
            </div>
          </div>
          <div className="col-lg-6 col-12 bg-img">
            <img
              className="section-img load-lazy"
              src={FileConstants.HIGH_POINT_LANDING_PAGE.chair}
              alt="index"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Description;
