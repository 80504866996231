import React from "react";
import LazyLoad from "react-lazyload";
import FileConstants from "../../../FileConstants";
import TextConstants from "../../../TextConstants";
import './index.scss'
const AISection = () => {
  return (
    <>
      <WebVersion />
      <MobileVersion />
    </>
  );
};
const { title, text_1, text_2 } = TextConstants.AI_CONTENT;
const WebVersion = () => {
  return (
    <div className="d-none d-lg-block">
      <div
        className="row justify-space-between our-product-offering"
      >
        <div className="col-lg-6 col-12 bg-img">
          <img
            className="section-img load-lazy"
            src={FileConstants.AI_CONTENT}
            alt="Image"
          />
        </div>
        <div className="col-lg-6 col-12" style={{ paddingLeft: 50 }}>
          <h4 className="font graphik-medium f-18 w-500 black-2b">{title}</h4>
          <p className="font graphik-regular f-16 w-400 grey-light">{text_1}</p>
          <p className="font graphik-regular f-16 w-400 grey-light">{text_2}</p>

        </div>
      </div>
    </div>
  );
};

const MobileVersion = () => {
  return (
    <div className="d-lg-none">
      <div className="row justify-space-between our-product-offering ai-content-section">
        <div className="col-12 justify-in-center direction-flex col">
          <h4 className="font graphik-medium f-16 w-500 black-2b">{title}</h4>
          <p
            className="font graphik-regular f-14 w-400 grey-light"
            style={{ textAlign: "center" }}
          >
            {text_1}
          </p>
          <p
            className="font graphik-regular f-14 w-400 grey-light"
            style={{ textAlign: "center" }}
          >
            {text_2}
          </p>
        </div>
        <div className="col-12 bg-img  justify-in-center">
          <img
            className="section-img load-lazy"
            src={FileConstants.AI_CONTENT}
            alt="Social Media User"
          />
        </div>
      </div>
    </div>
  );
};
export default AISection;
