import React from "react";
import CookieConsent, { Cookies } from "react-cookie-consent";
import TextConstants from "../../TextConstants";

function CookieConsentBanner() {
  const { text, link } = TextConstants.COOKIE_CONSENT;
  return (
    <CookieConsent
      location="bottom"
      //debug={true}
      buttonText="Accept"
      sameSite="strict"
      data-nosnippet
      cookieSecurity={true}
      extraCookieOptions={{ domain: "all3d.ai" }}
      cookieName="ALL3DCookieConsent"
      onAccept={() => {
        var d = new Date();
        d.setTime(d.getTime() + 150 * 24 * 60 * 60 * 1000);
        var expires = "expires=" + d.toUTCString();
        var domain = "all3d.ai";
        document.cookie =
          "ALL3DCookieConsent" + "=" + "true" + ";" + expires + ";path=/";
      }}
      buttonStyle={{
        color: "#ffffff",
        fontSize: "18px",
        background: "#276DD7",
        borderRadius: "4px",
        fontWeight: "normal",
        padding: "8px 30px",
      }}
      expires={150}
    >
      <p style={{ marginBottom: "0" }}>
        <span data-nosnippet>
          {text}{" "}
          <a
            href="/privacy"
            target="_blank"
            style={{ textDecoration: "underline" }}
          >
            {link}
          </a>
          .{" "}
        </span>
      </p>
    </CookieConsent>
  );
}

export default CookieConsentBanner;
