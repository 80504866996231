import React from "react";
import { useEffect } from "react";
import $ from "jquery";
import "./index.scss";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import FileConstants from "../../../FileConstants";
import TextConstants from "../../../TextConstants";
import LazyLoad from "react-lazyload";

const CreateLifestyleImagery = () => {
  return (
    <>
      <WebVersion />
      <MobileVersion />
    </>
  );
};
const { title, text } = TextConstants.CREATE_LIFESTYLE_IMAGERY;
const WebVersion = () => {
  useEffect(() => {
    $("#lifestyleCarousel").carousel({
      interval: 10000,
    });

    $("#lifestyleCarousel.carousel .carousel-item").each(function () {
      var minPerSlide = 3;
      var next = $(this).next();
      if (!next.length) {
        next = $(this).siblings(":first");
      }
      next.children(":first-child").clone().appendTo($(this));

      for (var i = 0; i < minPerSlide; i++) {
        next = next.next();
        if (!next.length) {
          next = $(this).siblings(":first");
        }

        next.children(":first-child").clone().appendTo($(this));
      }
    });
  });

  return (
    <div className="d-none d-lg-block">
      <div style={{ margin: "120px 0% 0px 10%" }}>
        <div className="col-lg-12 col-12 justify-space-between">
          <div className="col-lg-10 col-10">
            <h4
              className="font graphik-medium f-18 w-500 black-2b"
              style={{ textAlign: "left" }}
            >
              {title}
            </h4>
            <p className="font graphik-regular f-16 w-400 grey-light justify">
              {text}
            </p>
          </div>
          <div className="col-lg-2 col-2 justify-in-end">
            <a
              className="lifestyle carousel-control-prev w-auto"
              href="#lifestyleCarousel"
              role="button"
              data-slide="prev"
              style={{ marginRight: 20 }}
            >
              <span
                className="carousel-control-prev-icon carousel-btns"
                aria-hidden="true"
              >
                <ArrowLeftOutlined
                  style={{ fontSize: 20, lineHeight: "16px" }}
                />
              </span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="lifestyle carousel-control-next w-auto"
              href="#lifestyleCarousel"
              role="button"
              data-slide="next"
            >
              <span
                className="carousel-control-next-icon carousel-btns brown"
                aria-hidden="true"
              >
                <ArrowRightOutlined
                  style={{ fontSize: 20, lineHeight: "16px" }}
                />
              </span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>

        <div className="col-lg-12 col-12" style={{ paddingRight: 0 }}>
          <div
            id="lifestyleCarousel"
            className="carousel slide w-100"
            data-ride="carousel"
          >
            <div
              id="lifestyleContainer"
              className="carousel-inner"
              role="listbox"
            >
              {FileConstants.LIFESTYLE_IMAGES.map((img_src, index) => (
                <div className={`carousel-item ${index == 0 ? `active` : ``}`}>
                  <div className="col-5">
                    <img
                      className="lifestyle-img load-lazy"
                      src={img_src}
                      alt={index}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const MobileVersion = () => {
  useEffect(() => {
    $("#lifestyleCarouselMobile").carousel({
      interval: 10000,
    });

    $("#lifestyleCarouselMobile.carousel .carousel-item").each(function () {
      var minPerSlide = 3;
      var next = $(this).next();
      if (!next.length) {
        next = $(this).siblings(":first");
      }
      next.children(":first-child").clone().appendTo($(this));

      for (var i = 0; i < minPerSlide; i++) {
        next = next.next();
        if (!next.length) {
          next = $(this).siblings(":first");
        }

        next.children(":first-child").clone().appendTo($(this));
      }
    });
  });

  return (
    <div className="d-lg-none">
      <div className="our-product-offering" style={{ marginTop: 48 }}>
        <div
          className="col-12 justify-in-center direction-flex col"
          style={{ textAlign: "center", marginBottom: 6 }}
        >
          <h4 className="font graphik-medium f-16 w-500 black-2b">{title}</h4>
          <p className="font graphik-regular f-14 w-400 grey-light">{text}</p>
        </div>
      </div>

      <div
        className="col-lg-12 col-12"
        style={{ paddingRight: 0, marginBottom: 8 }}
      >
        <div
          id="lifestyleCarouselMobile"
          className="carousel slide w-100"
          data-ride="carousel"
        >
          <div
            id="lifestyleContainerMobile"
            className="carousel-inner"
            role="listbox"
          >
            {FileConstants.LIFESTYLE_IMAGES.map((img_src, index) => (
              <div className={`carousel-item ${index == 0 ? `active` : ``}`}>
                <div className="col-8">
                  <img
                    className="lifestyle-img load-lazy"
                    src={img_src}
                    alt={index}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="our-product-offering justify-space-between w-100">
        <div className="col-6 justify-in-start" style={{ padding: 0 }}>
          <a
            className="lifestyle carousel-control-prev w-auto"
            href="#lifestyleCarouselMobile"
            role="button"
            data-slide="prev"
          >
            <span
              className="carousel-control-prev-icon carousel-btns"
              aria-hidden="true"
            >
              <ArrowLeftOutlined style={{ fontSize: 16, lineHeight: "16px" }} />
            </span>
            <span className="sr-only">Previous</span>
          </a>
        </div>
        <div className="col-6 justify-in-end" style={{ padding: 0 }}>
          <a
            className="lifestyle carousel-control-next w-auto"
            href="#lifestyleCarouselMobile"
            role="button"
            data-slide="next"
          >
            <span
              className="carousel-control-next-icon carousel-btns brown"
              aria-hidden="true"
            >
              <ArrowRightOutlined
                style={{ fontSize: 16, lineHeight: "16px" }}
              />
            </span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>
  );
};
export default CreateLifestyleImagery;
