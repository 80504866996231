export const PRODUCT_SUCCESS = 'PRODUCT_SUCCESS';
export const PRODUCT_FAILURE = 'PRODUCT_FAILURE';
export const LIST_STORE_PRODUCT = 'LIST_STORE_PRODUCT';
export const RENDER_PRODUCT = 'RENDER_PRODUCT';

export const listStoreProducts = (product) => ({
  type: LIST_STORE_PRODUCT,
  payload: product,
});

export const productSuccessAction = (product) => ({
    type: PRODUCT_SUCCESS,
    payload: product,
  });
  
export const productFailureAction = (error) => ({
    type: PRODUCT_FAILURE,
    payload: error,
});

export const getProductRender = (product) => ({
  type: RENDER_PRODUCT,
  payload: product,
});