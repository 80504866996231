import React from "react";
import { useEffect, useState } from "react";
import $ from "jquery";
import FileConstants from "../../../FileConstants";
import TextConstants from "../../../TextConstants";

const FeaturedIn = () => {
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);

  return (
    <div className="container-fluid featured-in-container">
      <div className="featured-in">
        <div className="row justify-content-md-center">
          <WebVersion />
          <MobileVersion />
        </div>
      </div>
    </div>
  );
};

const { title } = TextConstants.FEATURED_IN;
const WebVersion = () => {
  return (
    <div className="d-none d-lg-block">
      <div className="row justify-content-md-center justify-in-center">
        <div
          className="col"
          style={{ textAlign: "center", maxWidth: "max-content", padding: 0 }}
          id="textSection"
        >
          <h2
            className="font graphik-regular f-24 w-400 grey-light justify-in-center"
            style={{ marginBottom: 0 }}
          >
            {title}
          </h2>
        </div>
        {FileConstants.featured_in.map((press) => (
          <div
            className="col"
            style={{
              textAlign: "center",
              maxWidth: "max-content",
              marginLeft: "6%",
              padding: 0,
            }}
            id="textSection"
          >
            <a href={press.press_link} target="_blank">
              <img
                src={press.logo}
                alt={press.name}
                className="featured-in-img load-lazy"
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

const MobileVersion = () => {
  return (
    <div className="d-lg-none">
      <div className="col-lg-10 col-12" id="textSection">
        <h2
          className="font lora f-20 ws-2 w-400 black-2b justify-in-center"
          style={{ marginBottom: 0 }}
        >
          {title}
        </h2>
      </div>
      <div className="row justify-content-md-center">
        {FileConstants.featured_in.map((press) => (
          <div
            className="col-6"
            style={{ textAlign: "center", padding: "20px 20px 0px" }}
            id="textSection"
          >
            <a href={press.press_link} target="_blank">
              <img
                src={press.logo}
                className="load-lazy"
                alt={press.name}
                style={{ objectFit: "contain", width: "100%", height: 34 }}
              />
            </a>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FeaturedIn;
