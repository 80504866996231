import React from "react";
import { Link, NavLink } from "react-router-dom";
import "./header.scss";
import $ from "jquery";
import TextConstants from "../../../TextConstants";
class NavBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navBarClass: "",
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", () => {
      let elmnt = document.getElementsByClassName("threeDImage");
      if (elmnt && elmnt[0] && elmnt[0].offsetHeight) {
        let updatedElem =
          window.document.getElementsByClassName("navbar-light");
        if (updatedElem && updatedElem[0]) {
          if (window.scrollY > elmnt[0].offsetHeight) {
            updatedElem[0].classList.add("full-opacity");
          } else {
            updatedElem[0].classList.remove("full-opacity");
          }
        }
      }
    });

    $(function () {
      //Commenting this code bit for now
      $(".navbar-toggler").on("click", function () {
        // $(".rel").toggleClass("menu-open");
        // $(".ant-layout").toggleClass("menu-open");
      });
    });
  }

  render() {
    const { items } = TextConstants.NAVBAR;
    return (
      <div className="custom-nav" id="topheader">
        <nav className="navbar navbar-expand-lg navbar-light pt-2 justify-space-between navbar-border-bottom">
          <Link className="navbar-brand" to={"/"}>
            <img src="/all3d-new-logo-red.svg" width="105px" height="35px" alt="ALL3D" />
          </Link>
          <button
            className="navbar-toggler collapsed"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="my-1 mx-2 close">
              <img src="/cross.svg" alt="cross icon" />
            </span>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav mr-auto"></ul>
            <ul className="navbar-nav">
              <li className={`links nav-item m-auto text-settings`}>
                <NavLink
                  className="nav-link header-text font graphik-medium f-16 w-400 black-2b"
                  style={{ lineHeight: "0" }}
                  onClick={() => (window.location.href = "/store")}
                  activeClassName="active"
                  to="/store"
                >
                  {items[0]}
                </NavLink>
              </li>
              {/* <li className={`links nav-item m-auto`}>
                <NavLink className="nav-link header-text font graphik-medium f-16 w-400 black-2b" activeClassName="active" to="/about-us">
                  About Us
                </NavLink>
              </li> */}
              <li className={`links nav-item m-auto text-settings`}>
                <NavLink
                  className="nav-link header-text font graphik-medium f-16 w-400 black-2b"
                  style={{ lineHeight: "0" }}
                  activeClassName="active"
                  to="/pricing"
                >
                  {items[1]}
                </NavLink>
              </li>
              <li
                className="nav-item m-auto  display-button-large"
                style={{ cursor: "pointer" }}
              >
                <NavLink
                  className="nav-link"
                  to={{ pathname: "https://app.all3d.ai" }}
                  target="_blank"
                >
                  <button
                    id="getStart"
                    className={`btn login-btn font graphik-medium f-16 w-400`}
                    disabled={false}
                  >
                    {items[2]}
                  </button>
                </NavLink>
              </li>
              <li
                className={`links nav-item m-auto text-settings  display-button-small`}
                style={{ borderBottom: "none" }}
              >
                <NavLink
                  className="nav-link header-text font graphik-medium f-16 w-400 black-2b"
                  style={{ padding: 0 }}
                  to={{ pathname: "https://app.all3d.ai" }}
                  target="_blank"
                >
                  {items[2]}
                </NavLink>
              </li>
            </ul>
          </div>
          {/* <NavLink className="display-button-large" to={{ pathname: "https://app.all3d.ai" }} target="_blank" >
            <button id="getStart" className={`btn login-btn font graphik-medium f-16 w-400 `} disabled={false}>Login</button>
          </NavLink> */}
        </nav>
      </div>
    );
  }
}

//const ShowTheLocationWithRouter = withRouter(ShowTheLocation)

export default NavBar;
