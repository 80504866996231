import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Tabs, Card, Layout, Tooltip, Image } from "antd";
import { getProductRender } from "../../redux/actions";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReactPlayer from "react-player";
import CookieConsentBanner from "../cookieConsentBanner";
import NavBar from "../landingPage/header/navbar";
import Footer from "../landingPage/footer";
import "./index.scss";
import ProductDetailComponent from "./ProductDetailComponent";
import * as ENVIRONMENT from "../../env";
import axios from "axios";
import FileConstants from "../../FileConstants";
import MetaTagContents from "../../MetaTagContents";
import ResponsiveProductPage from "./ResponsiveProductPage";
import { Helmet } from "react-helmet";

const { Content } = Layout;
const { TabPane } = Tabs;
const ProductDetails = (props) => {
  const [viewType, setViewType] = useState("2D");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [image, setImage] = useState(null);
  const [selectedPermission, setSelectedPermission] =
    useState("download_and_embed");
  const [productDetails, setProductDetails] = useState(null);
  const [productMaterials, setProductMaterials] = useState(null);
  const [loader, setLoader] = useState(true);
  const [aspectRatio, setAspectRatio] = useState(1);
  const [imageWidth, setImageWidth] = useState(0);
  const [imageHeight, setImageHeight] = useState(0);
  const [price, setPrice] = useState(null);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [schemaMarkup, setSchemaMarkup] = useState({})

  const {
    match: { params },
  } = props;

  /**
   * 
   * @param {*} product 
   * Schema Markup for SEO
   */
   const createSchemaMarkup = (product) => {
    const ldJson = {
      "@context": "https://schema.org/", 
      "@type": "Product", 
      "name": product.product_name,
      "description": product.category,
      "image": product.thumbnail,
      "offers": {
        "@type": "Offer",
        "url": `https://all3d.ai/product-assets/${params.id}`,
        "priceCurrency": "USD",
        "price": product.price,
        "availability": "https://schema.org/OnlineOnly"
      }
    }
    setSchemaMarkup(ldJson)
  }
 
  useEffect(() => {
    props.getProduct({ product_id: params.id });
    fetchProduct();
  }, [params]);

  const fetchProduct = () => {
    let payload = {
      product_id: params.id,
    };
    axios.post(ENVIRONMENT.FETCH_PRODUCT, payload).then((res) => {
      setPrice(res.data.price);
      setProductDetails(res.data);
      setLoader(false);
      createSchemaMarkup(res.data); 
      axios
        .post(ENVIRONMENT.PRODUCT_MATERIAL_GET_BATCH, payload)
        .then((res) => {
          let response = res.data;
          if (response) {
            response = response.body;
            setProductMaterials(response);
            console.log("Materials", response);
          }
        });
    });
  };

  useEffect(() => {
    console.log(props.productReducer);
  }, [props.productReducer]);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let elmnt = document.getElementsByClassName("product-row");
      if (elmnt && elmnt[0] && elmnt[0].offsetHeight) {
        let updatedElem =
          window.document.getElementsByClassName("navbar-light");
        if (updatedElem && updatedElem[0]) {
          if (window.scrollY > elmnt[0].offsetHeight) {
            updatedElem[0].classList.add("full-opacity");
          } else {
            updatedElem[0].classList.remove("full-opacity");
          }
        }
      }
    });
  }, []);

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);
  const { name, content } = MetaTagContents.PRODUCT_DETAILS;
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{MetaTagContents.PRODUCT_DETAILS.title}</title>
        <link rel="canonical" href="https://all3d.ai/product-assets/:id" />
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
        <meta name={name} content={content} />
      </Helmet>
      {loader ? (
        <div className="loader-rol"></div>
      ) : (
        <React.Fragment>
          <NavBar />
          <Layout>
            <Content className="pdp-visible">
              <CookieConsentBanner />
              <Row
                type="flex"
                justify="space-around"
                style={{ marginTop: "100px" }}
              >
                <Col span={20}>
                  {props.productReducer.product.model_status == 5 ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      className="product-row"
                    >
                      <h2
                        className="manrope f-14 w-600 upper-case"
                        style={{ marginBottom: 10 }}
                      >
                        <Link id="store-bd" to={"/store"}>
                          Store
                        </Link>{" "}
                        /{" "}
                        <span style={{ color: "#D93025" }}>
                          {props.productReducer.product.category}
                        </span>
                      </h2>
                    </div>
                  ) : (
                    ""
                  )}

                  <ProductDetailComponent {...props} />

                  <Row
                    type="flex"
                    style={{
                      justifyContent: "space-between",
                      marginTop: "10px",
                      marginBottom: "12px",
                    }}
                  >
                    <h3 className="manrope f-20 black-33 clamp-text w-60 capitalize">
                      {props.productReducer.product.product_name}
                    </h3>
                    {price == 0 ? (
                      <h3 className="manrope f-20 black-33">Free</h3>
                    ) : productDetails.company_id && FileConstants.ALL3D_COMPANY_IDS().includes(productDetails.company_id.toString()) && price && price != 0 ? (
                      <h3 className="manrope f-20 black-33">
                        Included in platform fee
                      </h3>
                    ) : price ? (
                      <h3 className="manrope f-20 black-33">
                        {"Price: $" + price.toString()}
                      </h3>
                    ):''}
                  </Row>

                  <Row
                    type="flex"
                    style={{
                      justifyContent: "space-between",
                      marginTop: "0px",
                      alignItems: "center",
                    }}
                  >
                    <Col span={14}>
                      <div
                        className="justify-in-start"
                        style={{ flexWrap: "wrap", alignItems: "flex-start" }}
                      >
                        {productDetails && productDetails.category ? (
                          <Col span={6} style={{ marginBottom: "10px" }}>
                            <div
                              className="manrope f-12 grey-99"
                              style={{ marginBottom: "4px", textAlign: "left" }}
                            >
                              Category
                            </div>
                            <div className="manrope f-12 black-33 w-600">
                              {productDetails && productDetails.category}
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                        {productDetails && productDetails.style_category ? (
                          <Col span={6} style={{ marginBottom: "10px" }}>
                            <div
                              className="manrope f-12 grey-99"
                              style={{ marginBottom: "4px", textAlign: "left" }}
                            >
                              {" "}
                              Style Category
                            </div>
                            <div className="manrope f-12 black-33 w-600">
                              {productDetails && productDetails.style_category}
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                        {productDetails && productDetails.similar_color ? (
                          <Col span={6} style={{ marginBottom: "10px" }}>
                            <div
                              className="manrope f-12 grey-99"
                              style={{ marginBottom: "4px", textAlign: "left" }}
                            >
                              Color
                            </div>
                            <div className="manrope f-12 black-33 w-600">
                              {productDetails && productDetails.similar_color}
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                        {productDetails &&
                        productDetails.licensing_options &&
                        productDetails.licensing_options.license ? (
                          <Col span={6} style={{ marginBottom: "10px" }}>
                            <div
                              className="manrope f-12 grey-99"
                              style={{ marginBottom: "4px", textAlign: "left" }}
                            >
                              License
                            </div>
                            <div className="manrope f-12 black-33 w-600 capitalize">
                              {productDetails.licensing_options.license ==
                              "private"
                                ? productDetails.licensing_options.license
                                : ""}
                              {productDetails.licensing_options.license ==
                              "public" ? (
                                productDetails.licensing_options
                                  .public_option == "forCreativeCommon" ? (
                                  <Tooltip
                                    title={
                                      <span className="manrope f-12 white">
                                        {
                                          productDetails.licensing_options
                                            .creative_common
                                        }
                                      </span>
                                    }
                                  >
                                    <img
                                      style={{ marginLeft: 5 }}
                                      alt="License icon"
                                      src={FileConstants.getLicensingIcon(
                                        productDetails.licensing_options
                                          .creative_common
                                      )}
                                    />
                                  </Tooltip>
                                ) : (
                                  <span>
                                    <Tooltip
                                      title={
                                        <span className="manrope f-12 white">
                                          Only For ALL3D Usage
                                        </span>
                                      }
                                    >
                                      <img
                                        alt="License icon"
                                        src={FileConstants.getLicensingIcon(
                                          productDetails.licensing_options
                                            .public_option
                                        )}
                                      />
                                    </Tooltip>
                                    &nbsp;
                                    <Tooltip
                                      title={
                                        <span className="manrope f-12 white">
                                          Not Downloadable
                                        </span>
                                      }
                                    >
                                      <img
                                        alt="Can't download"
                                        src="/not_downloadable.png"
                                      />
                                    </Tooltip>
                                  </span>
                                )
                              ) : (
                                ""
                              )}
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}

                        <Col span={6} style={{ marginBottom: "10px" }}>
                          <div
                            className="manrope f-12 grey-99"
                            style={{ marginBottom: "4px", textAlign: "left" }}
                          >
                            Dimensions
                          </div>
                          <div className="manrope f-12 black-33 w-600">
                            {productDetails.model_info != undefined
                              ? Math.round(productDetails.model_info.width)
                              : Math.round(productDetails.width)}
                            "W x{" "}
                            {productDetails.model_info != undefined
                              ? Math.round(productDetails.model_info.height)
                              : Math.round(productDetails.height)}
                            "H x{" "}
                            {productDetails.model_info != undefined
                              ? Math.round(productDetails.model_info.depth)
                              : Math.round(productDetails.length)}
                            "D
                          </div>
                        </Col>
                        {productDetails &&
                        productDetails.materials &&
                        productDetails.materials.length > 0 ? (
                          <Col span={6} style={{ marginBottom: "10px" }}>
                            <div
                              className="manrope f-12 grey-99"
                              style={{ textAlign: "left", marginBottom: 4 }}
                            >
                              Materials
                            </div>
                            <div
                              className="justify-in-start"
                              style={{ flexWrap: "wrap" }}
                            >
                              {productDetails.materials.map(
                                (material, index) => (
                                  <span className="manrope f-12 black-33 w-600">
                                    {index > 0
                                      ? `, ${material}`
                                      : `${material}`}
                                  </span>
                                )
                              )}
                            </div>
                          </Col>
                        ) : (
                          ""
                        )}
                      </div>
                    </Col>
                  </Row>

                  <hr />
                </Col>
              </Row>
            </Content>
          </Layout>

          <ResponsiveProductPage
            productMaterials={productMaterials}
            productDetails={productDetails}
            product_id={params.id}
            productReducer={props.productReducer}
          />

          <div className="abs-btm">
            <Footer />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

const mapStateToProps = (state) => state;
const mapDispatchToProps = (dispatch) => ({
  getProduct: (product) => {
    dispatch(getProductRender(product));
  },
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(ProductDetails)
);
