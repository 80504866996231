import React from "react";
import { Link } from "react-router-dom";
import { Layout, Radio, Collapse, Row, Col, Form, Input, Button  } from "antd";
import FileConstants from "./../../FileConstants";
import TextConstants from "../../TextConstants";
import MetaTagContents from "../../MetaTagContents";
import NavBar from "../landingPage/header/navbar";
import { LeftOutlined, LockOutlined, LoadingOutlined } from "@ant-design/icons";
import Footer from "../landingPage/footer";
import { useEffect, useState } from "react";
import { GET_CATEGORY_AND_PRICE, GET_LIFESTYLES_FEED } from "../../env";
import axios from "axios";
import "./index.scss";
import DottedLoader from "../DottedLoader/DottedLoader.jsx";
import { Helmet } from "react-helmet";

const { Panel } = Collapse;

function UnitPrice() {
  const [selectedService, setSelectedService] = useState("virtual_products");
  const [unitPrices, setUnitPrices] = useState(null);
  const [loader, setLoader] = useState(true);
  const [isVerified, setIsVerified] = useState(null);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isVerifyingSession, setIsVerifyingSession] = useState(false);


  useEffect(() => {
    let payload = {
      type: "invoice",
    };
    axios.post(GET_CATEGORY_AND_PRICE, payload).then((res) => {
      setLoader(false);
      setUnitPrices(res.data);
    });

    verifySession();
  }, []);

  // ==> Authentication Logic

  const checkPasswordAndGetSecurityToken = (values) => {
    const admin_password = values.password;
    const payload = {
      action: "get_security_token",
      password: admin_password,
    };
    setErrorMessage(null);
    setIsButtonLoading(true);
    axios.post(GET_LIFESTYLES_FEED, payload).then((res) => {
      console.log(res.data);
      if (res.data.security_token) {
        localStorage.setItem("security_token", res.data.security_token);
        setIsVerified(true);
        setErrorMessage(null);
        setIsButtonLoading(false);
      } else {
        setIsButtonLoading(false);
        setErrorMessage("Wrong password Please try again!");
      }
    });
  };

  const verifySession = () => {
    const key = localStorage.getItem("security_token");
    const payload = {
      action: "check_session",
      security_token: key,
    };
    setIsVerified(false);
    setIsVerifyingSession(true);
    axios.post(GET_LIFESTYLES_FEED, payload).then((res) => {
      console.log(res.data);
      setIsVerifyingSession(false);
      if (res.data.session_verified == "true") {
        setIsVerified(true);
      } else {
        setIsVerified(false);
      }
    });
  };

  // Authentication Logic <==

  const changeServiceType = (e) => {
    setSelectedService(e.target.value);
  };

  const fetchPrice = (request_name) => {
    if (unitPrices) {
      let prices = unitPrices.filter((ele) => ele.request_type == request_name);
      return prices.length > 0 ? prices[0].price : 0;
    } else {
      return 0;
    }
  };

  return (
    <React.Fragment>
      <Layout className="layout-container">
      {
        isVerifyingSession ? 
        <DottedLoader />
        :
        isVerified ? (
          loader ? (
              <DottedLoader />
          ) : (
            <>
              <NavBar />
              <WebVersion
                selectedService={selectedService}
                fetchPrice={fetchPrice}
                changeServiceType={changeServiceType}
                unitPrices={unitPrices}
              />
              <MobileVersion
                selectedService={selectedService}
                fetchPrice={fetchPrice}
                changeServiceType={changeServiceType}
                unitPrices={unitPrices}
              />
              <Footer />
            </>
          )
        ) :
          <>
            <Row>
              <Col span={12}>
                <img
                  className="branding-image"
                  src={"/login-branding.jpg"}
                  alt="ALL3D Brand"
                />
              </Col>
              <Col span={12}>
                <div className="form-container">
                  <div className="input-form custom-frm">
                    <Form
                      onFinish={checkPasswordAndGetSecurityToken}
                      className="login-form"
                    >
                      <Row className="justify-in-center mb-20">
                        <Link className="navbar-brand" to={"/"}>
                          <img
                            src="/logo-2.svg"
                            width="120px"
                            height="40px"
                            alt="ALL3D"
                          />
                        </Link>
                      </Row>
                      <Row>
                        <p
                          className="manrope f-14 black-33"
                          style={{ textAlign: "center" }}
                        >
                          Enter password to view Unit Pricing
                        </p>
                      </Row>
                      <Row>
                        <Form.Item
                          name="password"
                          className="justify-in-center"
                          rules={[
                            {
                              required: true,
                              message: "Please enter the password.",
                            },
                          ]}
                        >
                          <Input
                            prefix={
                              <LockOutlined
                                style={{ color: "rgba(0,0,0,.25)" }}
                              />
                            }
                            className="password-field"
                            placeholder="Password"
                            type="password"
                          />
                        </Form.Item>
                      </Row>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="submit-btn mb-20"
                        >
                          Confirm &nbsp;
                          {isButtonLoading ? <LoadingOutlined spin /> : ""}
                        </Button>
                        {errorMessage ? (
                          <div className="text-danger text-center">
                            {errorMessage}
                          </div>
                        ) : (
                          ""
                        )}
                      </Form.Item>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </>
      }
      </Layout>

    </React.Fragment>
  );
}

const { title } = TextConstants.UNIT_PRICE;
const { name, content } = MetaTagContents.UNIT_PRICE;
const WebVersion = (props) => {
  return (
    <div className="d-none d-xl-block">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{MetaTagContents.UNIT_PRICE.title}</title>
        <link rel="canonical" href="https://all3d.ai/unit-price" />
        <meta name={name} content={content} />
      </Helmet>
      <div
        className="unit-pricing-tables"
        style={{ marginBottom: 40, marginTop: 100 }}
      >
        <div style={{ marginTop: "70px" }} className="justify-in-center">
          <a
            href="/pricing"
            style={{
              marginRight: "15px",
              backgroundColor: "rgba(0, 0, 0, 0.08)",
              padding: "2px 5px",
            }}
          >
            <LeftOutlined style={{ color: "#555555" }} />
          </a>
          <span className="font lora f-32 w-400 ws-6 black-2b">{title}</span>
        </div>
        <Radio.Group
          style={{ marginTop: 40 }}
          className="radio-btn-brown"
          onChange={props.changeServiceType}
          defaultValue={props.selectedService}
          value={props.selectedService}
          buttonStyle="solid"
        >
          {FileConstants.UNIT_PRICING_CATEGORIES.map((category) => (
            <Radio.Button className="radio-btn-header-brown" value={category}>
              {FileConstants.UNIT_PRICING_CATEGORY_NAMES[category]}
            </Radio.Button>
          ))}
        </Radio.Group>
        <Row style={{ marginTop: 40 }}>
          <Col span={12}>
            {FileConstants.UNIT_PRICE_LIST[props.selectedService].map(
              (unit_attr, index) =>
                unit_attr in FileConstants.UNIT_PRICING_SUB_CATEGORIES ? (
                  <Collapse
                    className="unit-price-collapse"
                    accordion
                    ghost={true}
                    expandIconPosition={"right"}
                    style={{ marginBottom: 20 }}
                  >
                    <Panel
                      header={
                        <p
                          className="font graphik-regular f-16 black-14"
                          style={{ marginBottom: 0 }}
                        >
                          {FileConstants.setRequestTypeText(unit_attr)}
                        </p>
                      }
                      key={index}
                    >
                      {FileConstants.UNIT_PRICING_SUB_CATEGORIES[unit_attr].map(
                        (type, index) => (
                          <div
                            className={`unit-price-container-collapse ${FileConstants.UNIT_PRICING_SUB_CATEGORIES[
                                unit_attr
                              ].length -
                                1 ==
                                index
                                ? `w-o-border`
                                : ``
                              }`}
                          >
                            <p
                              className="font graphik-regular f-16 black-55 justify-space-between"
                              style={{ marginBottom: 0 }}
                            >
                              <span>
                                {FileConstants.setRequestTypeText(type)}
                              </span>
                              <span>${props.fetchPrice(type)}</span>
                            </p>
                          </div>
                        )
                      )}
                    </Panel>
                  </Collapse>
                ) : (
                  <div
                    className="unit-price-container-div"
                    style={{ marginBottom: 20 }}
                  >
                    <p
                      className="font graphik-regular f-16 black-55 justify-space-between"
                      style={{ marginBottom: 0 }}
                    >
                      <span>{FileConstants.setRequestTypeText(unit_attr)}</span>
                      <span>
                        {unit_attr != "sla"
                          ? `$${props.fetchPrice(unit_attr)}`
                          : "24-48 Hours"}
                      </span>
                    </p>
                  </div>
                )
            )}
          </Col>
        </Row>
      </div>
    </div>
  );
};

const MobileVersion = (props) => {
  return (
    <div className="d-xl-none" style={{ padding: "0 20px" }}>
      <div className="unit-pricing-tables" style={{ marginBottom: 40 }}>
        <div style={{ marginTop: "80px" }} className="justify-in-center">
          <a
            href="/pricing"
            style={{
              marginRight: "15px",
              backgroundColor: "rgba(0, 0, 0, 0.08)",
              padding: "2px 5px",
            }}
          >
            <LeftOutlined style={{ color: "#555555" }} />
          </a>
          <span className="font lora f-20 w-400 ws-2 black-2b">{title}</span>
        </div>
        <div className="radio-btn-scroll radio-scrollbar">
          <Radio.Group
            style={{ marginTop: 20 }}
            className="radio-btn-brown"
            onChange={props.changeServiceType}
            defaultValue={props.selectedService}
            value={props.selectedService}
            buttonStyle="solid"
          >
            {FileConstants.UNIT_PRICING_CATEGORIES.map((category) => (
              <Radio.Button className="radio-btn-header-brown" value={category}>
                {FileConstants.UNIT_PRICING_CATEGORY_NAMES[category]}
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>
        <Row style={{ marginTop: 20 }}>
          <Col span={24}>
            {FileConstants.UNIT_PRICE_LIST[props.selectedService].map(
              (unit_attr, index) =>
                unit_attr in FileConstants.UNIT_PRICING_SUB_CATEGORIES ? (
                  <Collapse
                    className="unit-price-collapse"
                    accordion
                    ghost={true}
                    expandIconPosition={"right"}
                    style={{ marginBottom: 20 }}
                  >
                    <Panel
                      header={
                        <p
                          className="font graphik-regular f-14 black-14"
                          style={{ marginBottom: 0 }}
                        >
                          {FileConstants.setRequestTypeText(unit_attr)}
                        </p>
                      }
                      key={index}
                    >
                      {FileConstants.UNIT_PRICING_SUB_CATEGORIES[unit_attr].map(
                        (type, index) => (
                          <div
                            className={`unit-price-container-collapse ${FileConstants.UNIT_PRICING_SUB_CATEGORIES[
                                unit_attr
                              ].length -
                                1 ==
                                index
                                ? `w-o-border`
                                : ``
                              }`}
                          >
                            <p
                              className="font graphik-regular f-14 black-55 justify-space-between"
                              style={{ marginBottom: 0 }}
                            >
                              <span>
                                {FileConstants.setRequestTypeText(type)}
                              </span>
                              <span>${props.fetchPrice(type)}</span>
                            </p>
                          </div>
                        )
                      )}
                    </Panel>
                  </Collapse>
                ) : (
                  <div
                    className="unit-price-container-div"
                    style={{ marginBottom: 20 }}
                  >
                    <p
                      className="font graphik-regular f-14 black-55 justify-space-between"
                      style={{ marginBottom: 0 }}
                    >
                      <span>{FileConstants.setRequestTypeText(unit_attr)}</span>
                      <span>
                        {unit_attr != "sla"
                          ? `$${props.fetchPrice(unit_attr)}`
                          : "24-48 Hours"}
                      </span>
                    </p>
                  </div>
                )
            )}
          </Col>
        </Row>
        {/* <div className='justify-in-center unit-price-table' style={{padding:14}}>
                <Table dataSource={FileConstants.SubscriptionPricingData} getColumName columns={FileConstants.SubscriptionPricingColumns} pagination={false}/>
            </div>
            <div className='justify-in-center unit-price-table' style={{padding:14}}>
                <Table dataSource={FileConstants.SpaceData} columns={FileConstants.AppColumns} pagination={false}/>
            </div>
            <div className='justify-in-center unit-price-table' style={{padding:14}}>
                <Table dataSource={FileConstants.TourData} columns={FileConstants.SupportPriceColumn} pagination={false}/>
            </div>  */}
      </div>
    </div>
  );
};

export default UnitPrice;
