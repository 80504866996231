import React, { useEffect } from "react";
import "./privacyTerms.scss";
import { Helmet } from "react-helmet";
import MetaTagContents from "../../MetaTagContents";

function Terms() {
  useEffect(() => {
    window.addEventListener("scroll", () => {
      let elmnt = document.getElementsByClassName("heading-alignment");
      if (elmnt && elmnt[0] && elmnt[0].offsetHeight) {
        let updatedElem =
          window.document.getElementsByClassName("navbar-light");
        if (updatedElem && updatedElem[0]) {
          if (window.scrollY > elmnt[0].offsetHeight) {
            updatedElem[0].classList.add("full-opacity");
          } else {
            updatedElem[0].classList.remove("full-opacity");
          }
        }
      }
    });
  }, []);
  const { name, content } = MetaTagContents.TERMS;
  return (
    <body class="c15" style={{ marginTop: "60px" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{MetaTagContents.TERMS.title}</title>
        <link rel="canonical" href="https://all3d.ai/terms" />
        <meta name={name} content={content} />
      </Helmet>
      <div class="heading-alignment">
        <h1 class="welcome-h1">Welcome to ALL3D!</h1>
        <p class="c0 date-text">Last updated on July 29, 2020</p>
      </div>
      <p class="c0 content-text">
        <span class="c3">
          ALL3D, Inc. (&quot;ALL3D,&quot; &quot;we&quot; or &quot;us&quot;)
          builds technologies and services that enable our users
          (&quot;User&quot; or &quot;you&quot;) to create, scan, upload, and/or
          view 3D models of products and spaces. In addition we provide software
          services that let our users use the models to create product and
          lifestyle photography, 360 spins, 3D tours, configuration of models
          and spaces, and/or Augmented Reality (AR) and Virtual Reality (VR)
          immersive experiences. ALL3D provides services and solutions for our
          users to create, approve, customize, collaborate, manage, distribute,
          and/or monetize your 2D and 3D assets for valuable experiences that
          span from e-commerce, personalized services and advertising across any
          device. Our users and customers have a choice to keep all their 2D and
          3D assets &ldquo;PRIVATE&rdquo; for their use only or make any of
          their 2D and 3D assets &ldquo;PUBLIC&rdquo; for sale or for free (with
          &nbsp;
        </span>
        <span class="c9">
          <a
            class="c4"
            href="https://creativecommons.org/licenses/."
            target="_blank"
          >
            Creative Commons Licensing
          </a>
        </span>
        <span class="c3">
          ) on the ALL3D store for use by other users of ALL3D. Users can also
          share their assets with other users in their company, group and
          partners for embedding and/or downloading purposes.&nbsp;
        </span>
      </p>
      <p class="c0 content-text">
        <span class="c3">
          These terms govern your use of all3d.ai website and dashboard, the
          ALL3D scanning iOS application and other software we offer, and our
          other products or services, which are referred to herein as the
          &ldquo;Products&rdquo; or &quot;Services,&quot; and may be modified
          from time to time at our sole discretion.
        </span>
      </p>
      <p class="c0 content-text">
        <span class="c1">
          By registering with us, or by using the Products and Services, you
          agree to be bound by these Terms of Use (the &quot;Agreement,&quot;)
          which we may update from time to time. Your continued use of the
          Services constitutes your consent to such changes. PLEASE READ THIS
          AGREEMENT CAREFULLY AND CHECK THESE TERMS OF USE PERIODICALLY FOR
          CHANGES. IF YOU DO NOT AGREE TO THESE TERMS OF USE, YOU MAY NOT USE
          THE SERVICES.
        </span>
      </p>
      <p class="c0 content-text" style={{ color: "#333333" }}>
        <span class="c8 c11">
          Any dispute arising out of or relating to your use of the Services or
          the Site will be determined by final and binding arbitration on an
          individual basis, and the parties irrevocably waive any and all rights
          to class actions, other representative actions, and trial by jury or
          by any court, as set forth in detail in these Terms.
        </span>
      </p>
      <p class="c0">
        <span class="c3">Our </span>
        <span class="c9">
          <a class="c4" href="/privacy" target="_blank">
            Privacy Policy
          </a>
        </span>
        <span class="c3">
          , which explains how we use your personal data, is located at{" "}
        </span>
        <span class="c9">
          <a class="c4" href="/privacy" target="_blank">
            https://all3d.ai/privacy
          </a>
        </span>
        <span class="c3">
          &nbsp;and is considered part of this Agreement. By using the Services,
          you agree that your information may be collected and used in
          accordance with our{" "}
        </span>
        <span class="c9">
          <a class="c4" href="/privacy" target="_blank">
            Privacy Policy
          </a>
        </span>
        <span class="c3">&nbsp;and this Agreement.</span>
      </p>
      <div class="h2-height"></div>
      <h2 class="c0">
        <span class="c8 h2-text">1. General Conditions</span>
      </h2>
      <p class="c0">
        <span class="c8 h3-text">1.1 Description of Services</span>
        <span class="c3">
          <br />
          ALL3D is pleased to offer free products and services (&quot;Free
          Services&quot;) with limited functionality, as well as various tiers
          of paid products and services (&quot;Paid Services&quot;) plans with
          enhanced functionality. Detailed descriptions of the Free Services and
          Paid Services options, including pricing, duration, and features, are
          available at &nbsp;
        </span>
        <span class="c9">
          <a class="c4" href="/pricing" target="_blank">
            https://all3d.ai/pricing
          </a>
        </span>
        <span class="c3">.</span>
      </p>
      <p class="c0">
        <span class="c8 h3-text">1.2 Account</span>
        <span class="c3">
          <br />
          An ALL3D account is required in order to take full advantage of the
          Services. The account can be created directly by filling out the
          appropriate form, or via third-party website accounts such as Twitter,
          Google or Facebook. You agree to provide only accurate and true
          information in connection with the creation of your account, and to
          update your information as necessary so it remains accurate and
          current. You may not transfer your account to any third party. You are
          responsible for keeping your account login information confidential
          and for any and all activities that occur under your account. You
          agree to notify us immediately if you suspect or become aware of any
          unauthorized use of your account or other breach of security on the
          Services. ALL3D will not be responsible for any liabilities, losses,
          or damages arising out of the unauthorized use of your member name,
          password and/or account. You will have the ability to pick an
          available username to link to your account. Please note that ALL3D
          retains the right at any time to deactivate, reallocate or rename your
          username at its sole discretion. In the event of the deactivation,
          reallocation or renaming of your username all your User Content and
          Services shall continue to remain associated with your account,
          subject to the terms of this Agreement.
        </span>
      </p>
      <p class="c0">
        <span class="c8 h3-text">1.3 Access</span>
        <span class="c3">
          <br />
          We may, in our sole discretion, refuse to offer the Services to any
          person or entity. We may, in accordance with this Agreement and
          Section 8 hereof, terminate your right to use the Services, or any
          portion of thereof, and block or prevent your future access to and use
          of the Services or any portion thereof.
        </span>
      </p>
      <p class="c0">
        <span class="c8 h3-text">1.4 Minors</span>
        <span class="c3">
          <br />
          THE SERVICES ARE NOT INTENDED FOR CHILDREN UNDER 13, and persons under
          the age of 13 are prohibited from registering with or using the
          Services. You represent and warrant that you are more than 18 years of
          age or, if you are above 13 and below 18 years of age, you are an
          emancipated minor, or possess legal parental or guardian consent, and
          are fully able and competent to abide by and comply with the terms,
          conditions, obligations, affirmations, representations, and warranties
          set forth in these Terms. This provision is void where prohibited by
          law and the right to access the Services is revoked in such
          jurisdictions.
        </span>
      </p>
      <div class="h2-height"></div>
      <h2 class="c0">
        <span class="c8 h2-text">
          2. Service Plans, Payment Terms, Fees and Cancellation
        </span>
      </h2>
      <p class="c0">
        <span class="c8 h3-text">2.1 Service Plans and Subscription Plans</span>
        <span class="c1">
          <br />
          ALL3D may offer free Service plans as well as Paid Subscription Plans.
          &nbsp;Subscriptions may be available on a monthly or other basis
          &nbsp;Each Subscription is valid for only one user account may not be
          used with any other user accounts.
        </span>
      </p>
      <p class="c0">
        <span class="c8 h3-text">2.2 Paid Subscription Plans</span>
        <span class="c1">
          <br />
          You agree that ALL3D will automatically renew your Paid Subscription
          on a monthly basis at the conclusion of each month and that ALL3D will
          charge the payment method on file for the level of subscription you
          have selected for the next one-month term. Billing occurs on the date
          you begin your subscription and on the same date in each month
          thereafter or on the last day of the month for subscriptions started
          on the 28th through 31st of a month. THIS BILLING CYCLE WILL CONTINUE
          IN PERPETUITY UNTIL YOU OR ALL3D TERMINATE YOUR MEMBERSHIP.
          &nbsp;ALL3D will notify you by email three (3) days prior to charging
          the payment method.
        </span>
      </p>
      <p class="c0">
        <span class="c8 h3-text">2.3 Pricing and Payment</span>
        <span class="c3">
          <br />
          The pricing of our various products and services, whether a la carte
          or via a subscription, is available at{" "}
        </span>
        <span class="c9">
          <a class="c4" href="/pricing" target="_blank">
            https://all3d.ai/pricing
          </a>
        </span>
        <span class="c1">
          . All Paid Subscription fees are payable in advance and are COMPLETELY
          NON-REFUNDABLE. Upon signup for one of the pricing plans or when the
          products and services are delivered a la carte, the credit/debit card
          number you provide will be automatically charged the relevant Service
          fee every billing cycle. You agree to pay the Subscription fees and
          any other charges incurred in connection with your account (including
          any applicable taxes) at the rates in effect when the charges were
          incurred. ALL3D reserves the right to charge for use of additional
          Services or content that are currently free of charge. &nbsp;YOUR
          SUBSCRIPTION WILL AUTOMATICALLY RENEW EVERY MONTH AND THE SUBSCRIPTION
          FEE WILL BE AUTOMATICALLY CHARGED AT THE TIME OF RENEWAL TO AN ACTIVE
          CREDIT CARD ON FILE IN YOUR ACCOUNT. &nbsp;If you cancel a payment or
          initiate an improper chargeback your Account may be suspended. In the
          event of a pricing change to your Paid Services options, we will
          inform you within 2 (two) months before the new prices take effect. If
          you do not wish to continue your current Paid Services plan with the
          new prices, you may cancel your Paid Services plan.&nbsp;You have the
          right to receive, and we will provide, email notice of the amount to
          be charged and the date of the charge before the scheduled date of the
          transaction, unless applicable law requires you to expressly consent
          to the change in price. Any agreement you have with your payment
          provider governs your use of your specified payment method.
        </span>
      </p>
      <p class="c0">
        <span class="c8 h3-text">2.4 Billing Authorization </span>
        <span class="c3">
          <br />
          Your authorizations in this section also apply to our payment
          processors and any other companies who act as billing agents for us.
          You hereby authorize us to charge your specified payment method on a
          monthly basis, in advance, for your Subscription and/or to place a
          hold on your payment method with respect to any unpaid charges for
          your Subscription. THIS AUTHORIZATION SHALL CONTINUE IN EFFECT UNTIL
          YOU TERMINATE YOUR SUBSCRIPTION. &nbsp;You authorize the issuer of
          your selected payment method to pay any amounts described herein
          without requiring a signed receipt, and you agree that these Terms
          shall be accepted as authorization to the issuer of the payment method
          to pay any amounts described herein, without requiring a signed
          receipt from you. You authorize us to continue to attempt to charge
          and/or place holds with respect to all sums described herein, or any
          portion thereof, to your payment method until such amounts are paid in
          full. You agree to provide updated payment information upon request
          and any time the information you previously provided is no longer
          valid. You acknowledge and agree that neither ALL3D, nor any ALL3D
          agent, will have any liability whatsoever for any insufficient funds
          or other charges incurred by you as a result of attempts to charge,
          and/or place holds on, your specified payment method as contemplated
          by these Terms. If you provide a debit card number instead of a credit
          card number, you authorize all charges described herein to be applied
          to such debit card unless and until you provide a credit card number.
        </span>
      </p>
      <p class="c0">
        <span class="c8 h3-text">
          2.5 Upgrading or Downgrading a Service Plan
        </span>
        <span class="c3">
          <br />
          You may upgrade or downgrade your Service Plan at any time by choosing
          upgrade or downgrade option under &ldquo;Subscription&rdquo; tab in
          your Account. Should you downgrade your Service plan, your new Service
          fee will take immediate effect and no pro-rated refund will be issued.
          Should you upgrade your Service plan, your new Service fee will take
          immediate effect and be charged in full.
        </span>
      </p>
      <p class="c0">
        <span class="c8 h3-text">2.6 Cancellation of a Service Plan</span>
        <span class="c1">
          <br />
          You may cancel your Service plan via your Account at any time and for
          any reason. However, ALL FEES PAID BY YOU HEREUNDER ARE
          NON-REFUNDABLE. If you cancel your Service plan prior to the end of a
          billing cycle cancellation will be effective at the end of the billing
          cycle. &nbsp;You will be able to continue enjoying the Service until
          the end of such billing cycle.
        </span>
      </p>
      <p class="c0">
        <span class="c18 h3-text h3-text">
          2.7 Termination of Your Subscription by ALL3D
        </span>
        <span class="c1">
          <br /> ALL3D may immediately terminate or suspend your account, and
          all or a portion of your Subscription, without notice, if:{" "}
        </span>
      </p>
      <ul class="list-indent">
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Your payment is overdue (provided that we will use reasonable
            efforts to notify you of the overdue payment before we terminate or
            suspend);{" "}
          </span>
        </li>
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            You provide false or inaccurate information;{" "}
          </span>
        </li>
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            You violate these Terms or any other ALL3D rules or agreements then
            in effect;{" "}
          </span>
        </li>
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            You engage in conduct that is a violation of any applicable law or
            tariff (including, without limitation, copyright and intellectual
            property laws); or{" "}
          </span>
        </li>
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            You engage in conduct that is threatening, abusive, or harassing to
            ALL3D employees, agents, or other users, including, for example,
            making threats to physically harm or damage property.
          </span>
        </li>
      </ul>
      <p class="c0">
        <span class="c1">
          If we terminate or suspend your Subscription, your license to use any
          software or content provided in connection with the Subscription is
          also terminated or suspended (as applicable). If your Subscription is
          terminated, you must pay all charges up to and including the date of
          termination. Should you wish to resume your Subscription after any
          suspension, you can sign up at any time.
        </span>
      </p>
      <p class="c0">
        <span class="c8 h3-text">2.8. Representations</span>
        <span class="c3">
          <br />
          YOU REPRESENT AND WARRANT THAT YOU HAVE THE LEGAL RIGHT TO USE THE
          BANK ACCOUNTS OR CREDIT CARD(S) THAT YOU USE TO PAY FOR THE SERVICES.
          You agree to pay all charges incurred by you or any users of your
          account at the prices in effect when such charges are incurred. You
          will also be responsible for paying any applicable taxes relating to
          your purchases. Verification of information applicable to a purchase
          may be required prior to our acceptance of any order.
        </span>
      </p>
      <div class="h2-height"></div>
      <h2 class="c0">
        <span class="c8 h2-text">3. Ownership of ALL3D Content</span>
      </h2>
      <p class="c0">
        <span class="c8 h3-text">3.1 Generally</span>
        <span class="c3">
          <br />
          By using the Services, you will encounter &quot;ALL3D Content,&quot;
          which includes all of the images, text, information, data, audio,
          video, graphics, templates, 3D product and space models, scenes and
          other material included on or otherwise made available through the
          Services, excluding User Content. &quot;User Content&quot; includes
          all the 3D models (products and spaces), images, audio, and related
          content, as well as User comments, uploaded by Users to ALL3D, and all
          3D models (product and spaces), product and lifestyle images, scenes,
          360 spins and 3D tours that are paid for or created by Users on ALL3D
        </span>
        <span class="c10">.</span>
        <span class="c3">
          &nbsp;Except as otherwise set forth in this Agreement, we do not claim
          ownership over any User Content.&nbsp;
        </span>
      </p>
      <p class="c0">
        <span class="c8 h3-text">3.2 Ownership</span>
        <span class="c3">
          <br />
          All ALL3D Content is owned by ALL3D, Inc. or its licensors, and is
          protected by U.S. and international copyright laws, trademark laws
          and/or other proprietary rights and laws. As between you and ALL3D,
          Inc. and its licensors, ALL3D, Inc. or its licensors own and retain,
          solely and exclusively, all rights, title and interest in and to the
          Services, and all ALL3D Content that we or our licensors create and
          that we make available to you through the Services, including but not
          limited to any and all copyrights, trademark rights, trade secret
          rights, patent rights, database rights and other intellectual property
          and proprietary rights therein. The trademark ALL3D and all associated
          logos and the other trademarks, service marks, logos and trade names
          displayed on or in connection with the Services are the registered and
          unregistered trademarks and service marks of ALL3D, Inc. or third
          parties in the United States and/or other countries. Your use of the
          Services does not grant you any ownership over any ALL3D Content, and
          except for the limited license we grant you under this Agreement, your
          use of the Services does not grant you any license or permission under
          any copyright, trademark or other intellectual property rights of
          ALL3D, Inc. or any third party. We reserve all rights not expressly
          granted to you in this Agreement.
        </span>
      </p>
      <p class="c0">
        <span class="c8 h3-text">3.3. Service and Content License</span>
        <span class="c3">
          <br />
          We grant you a limited, non-exclusive, non-sublicensable and
          non-transferable license to use the Services and ALL3D Content as they
          are provided to you by us, only as set forth in this Agreement. Except
          as expressly permitted in this Agreement, you may not reproduce,
          distribute, adapt, modify, translate, create derivative works from,
          publish or otherwise use any portion of the Services or ALL3D Content
          for any purpose without express prior written permission from us or
          the applicable rights holder. Use of ALL3D free product and spaces 3d
          models, and scenes can be used and their derivative works are
          not&nbsp; are not required
        </span>
        <span class="c10 c16 c15">&nbsp;</span>
        <span class="c10 c15">
          to be licensed from ALL3D. The derivative works created on ALL3D is
          &ldquo;User Content&rdquo;
        </span>
        <span class="c10 c15 c16">. </span>
        <span class="c3">
          Any commercial exploitation of the Services or ALL3D Content without
          express prior written permission from us or the applicable rights
          holder is strictly prohibited.
        </span>
      </p>
      <p class="c0">
        <span class="c8 h3-text">3.4 Software</span>
        <span class="c3">
          <br />
          All software and software-as-a-service (SAAS) used in connection with
          the Services (&quot;Software&quot;) is proprietary to us or to third
          parties, and except as may be required to exercise the foregoing
          license grant, any use, redistribution, sale, decompilation, reverse
          engineering, disassembly, translation or reduction of such software to
          human-readable form is prohibited. You agree that we may update the
          Software without notice, at any time and in our sole discretion, and
          that this Agreement will apply to any updated versions.
        </span>
      </p>
      <div class="h2-height"></div>
      <h2 class="c0">
        <span class="c8 h2-text">4. User Content</span>
      </h2>
      <p class="c0">
        <span class="c3">
          During the operation of the Services, Users may scan, create, pay for,
          modify, or upload certain User Content including 3D models (products
          and spaces), product and lifestyle images, scenes, 360 spins and 3D
          tours. Derivative works, such as product and lifestyle images, scenes,
          360 spins, and 3D tours, created based on 3D models on ALL3D are
          considered &ldquo;User Content,&rdquo; as permitted and governed under
          the Creative Commons Licensing if applicable. You remain the owner of
          your User Content at all times, and ALL3D does not claim any ownership
          rights in your User Content. User Content is otherwise subject to the
          following provisions. This Agreement applies to all the User Content
          that you contribute onto our Website during the term of this
          Agreement.
        </span>
      </p>
      <p class="c0">
        <span class="c8 h3-text">4.1 Generally</span>
        <span class="c3">
          <br />
          You are solely responsible for ensuring that any User Content you
          submit to the Services complies with any applicable laws and third
          party rights, including but not limited to any intellectual property
          rights, privacy rights and publicity rights. You agree that any
          information included in your User Content may be used in accordance
          with our &nbsp;
        </span>
        <span class="c9">
          <a class="c4" href="/privacy" target="_blank">
            Privacy Policy
          </a>
        </span>
        <span class="c3">
          . ALL3D always has the right, in its sole discretion, to accept or
          reject any given User Content.
        </span>
      </p>
      <p class="c0">
        <span class="c8 h3-text">
          4.2 User Content License
          <br />
        </span>
        <span class="c8 h4-text">4.2.1 Use by us</span>
        <span class="c3">
          <br />
          By using the Services, you grant ALL3D a worldwide, non-exclusive,
          royalty-free, transferable, perpetual, irrevocable, sub-licensable
          (through multiple tiers) right and license to use, reproduce, encode,
          store, copy, transmit, post, broadcast, modify, publish, publicly
          perform, publicly display, list information regarding, edit,
          distribute, translate, adapt, and make derivative works of any User
          Content for the purposes of developing, distributing, providing,
          improving, and promoting the Services, our activities and any User
          Content. You further grant ALL3D the right to use your name and
          trademarks, if any, in connection with our use of your publicly shared
          User Content.&nbsp;
        </span>
      </p>
      <p class="c0">
        <span class="c8 h4-text">4.2.2 Use by others for free</span>
        <span class="c3">
          <br />
          Users may make its User Content available to other users for download
          for free via one of several Creative Commons licenses. For more
          information, see{" "}
        </span>
        <span class="c9">
          <a
            class="c4"
            href="https://creativecommons.org/licenses/"
            target="_blank"
          >
            https://creativecommons.org/licenses/
          </a>
        </span>
        <span class="c3">
          . The User is solely responsible for choosing the appropriate license,
          if any, under which the Content is available for download. By
          downloading User Content made available by other Users, you agree to
          adhere to the terms of the Creative Commons license that applies at
          the time of download.
        </span>
      </p>
      <p class="c0">
        <span class="c8 h4-text">4.2.3 Use by others for a price</span>
        <span class="c8"></span>
        <span class="c3">
          <br />
          Subject always to our prior consent, you may also elect to make your
          User Content available to other users (your customers) to use in the
          ALL3D store for a price. In such event, you grant us the right to
          grant other users (your customers) a non-exclusive, non-sublicensable,
          and non-transferable, perpetual, worldwide right to use your User
          Content for the purposes and under the conditions below
        </span>
      </p>
      <ul class="list-indent">
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Pay for your 3D models (products and spaces) via the ALL3D store, to
            be used only on ALL3D for the creation of the customer&rsquo;s
            lifestyle photography and scenes. The customers can use the
            photography and scenes for any commercial purposes on or off the
            ALL3D Platform&nbsp;
          </span>
        </li>
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Pay for your scenes (3D representation of designed spaces with
            products, as well as 2D representation as lifestyle photography), to
            be used only on ALL3D for the creation of customers&#39; own scenes
            and lifestyles by modifying your scenes. The customers can use these
            derivative works for any commercial purposes on ALL3D or off the
            ALL3D platform.&nbsp;
          </span>
        </li>
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            ALL3D is entitled to a commission from the seller on each sale of 9%
            excluding payment processing charges between 3%-5%, which we may
            update from time to time at our sole discretion.&nbsp;
          </span>
        </li>
      </ul>
      <div style={{ marginBottom: "20px" }}></div>
      <p class="c0">
        <span class="c8 h4-text">4.2.4 Sharing</span>
        <span class="c3">
          <br />
          Users can share their 3D and 2D content on ALL3D, including 3D models
          of products and spaces, product and lifestyle images, 360 spins, 3D
          tours, and scenes, with other users, which makes their content
          downloadable and editable by other users on ALL3D. If the other user
          with whom a user shares its content does not yet have an ALL3D
          Account, the other user is invited to create an ALL3D Account.
        </span>
        <span class="c8">
          &nbsp; These shared items will appear in the other user&rsquo;s
          Account as the other user&rsquo;s User Content, to be used for all the
          other services offered by ALL3D.
        </span>
        <span class="c3">
          &nbsp;Users are solely responsible for whom they share their content
          with, including any ownership and intellectual property rights with
          regards to the content shared.&nbsp;
        </span>
      </p>
      <p class="c0">
        <span class="c8 h4-text">4.2.5 Download API</span>
        <span class="c3">
          <br />
          Users who make their User Content available to other Users under
          Article 4.2.2 of these Terms acknowledge and accept that their Content
          (i) may be made available and (ii) can be downloaded from a third
          party application or website, through the download API operated by
          ALL3D. For the avoidance of doubt, said Users specifically grant to
          ALL3D a license over their User Content covering all use related to
          the development, distribution, provision, improvement and promotion of
          the download API operated by ALL3D.
        </span>
      </p>
      <p class="c0">
        <span class="c3">
          Users accessing and downloading User Content through the download API
          operated by ALL3D shall be authenticated and it is expressly agreed
          that: (i) the use of the download API is governed by these Terms and
          each User&#39;s plan; and (ii) the authenticated User accessing and
          downloading the User Content shall be responsible for complying with
          the terms of the license governing said User Content.
        </span>
      </p>
      <p class="c0">
        <span class="c8 h3-text">4.3 User Representations</span>
        <span class="c3">
          <br />
          By submitting User Content, you represent and warrant that (i) you own
          or otherwise control all of the rights to your User Content, (ii) the
          use of your User Content does not violate this Agreement or the law,
          and will not violate any rights of or cause injury to any person or
          entity, (iii) your User Content will not violate the additional
          content restrictions set forth in Section 5 of this Agreement, and
          that (iv) to the greatest extent permitted by law, You hereby
          irrevocably and unconditionally waive any and all moral rights
          whatsoever, or any rights of a similar nature, in your User Content,
          for the benefit of or in favor of ALL3D, including any right to be
          associated with your User Content (right of paternity) or any right to
          restrict or prevent the modification or use of your User Content in
          any manner whatsoever (right of integrity). To the extent You retain
          any such moral rights under applicable law, You hereby agree not to
          assert them against ALL3D or other ALL3D users in any manner
          whatsoever.
        </span>
      </p>
      <p class="c0">
        <span class="c8 h3-text">4.4 User Feedback</span>
        <span class="c3">
          <br />
          We appreciate your feedback and suggestions about our Services, but
          you agree that any feedback or suggestions submitted to us about the
          Services are entirely voluntary and that we will be free to use any
          such feedback or suggestions as we see fit and without any obligation
          to you.
        </span>
      </p>
      <p class="c0">
        <span class="c8 h3-text">4.5 Right to Monitor, Moderate or Remove</span>
        <span class="c3">
          <br />
          You agree that you bear all risks associated with your User Content.
          You are solely responsible for safeguarding your User Content, and
          ALL3D has no duty to store copies of User Content for future
          availability to you or any user except as otherwise provided under
          this Agreement. ALL3D does not permit the infringement of intellectual
          property rights on the Services, and will remove User Content from the
          Services if properly notified that such User Content infringes on
          another&#39;s intellectual property rights. We reserve the right to
          remove User Content from the Services, in whole or in part, without
          prior notice, for any reason or for no reason at all. Without limiting
          our right to terminate a User pursuant to Section 8 of this Agreement,
          we reserve the right to terminate the account of any User of the
          Services who has been notified of infringing activity more than twice
          and/or has had User Content removed from the Service more than twice.
          We also reserve the right to decide whether User Content is
          appropriate and complies with this Agreement for violations other than
          violations of intellectual property law. This shall extend to the
          right of ALL3D to edit, modify, moderate, re-format, change or
          otherwise remove all or part of the descriptions, comments and/or
          annotations that You and/or third parties add and/or make in relation
          to your User Content, in any manner that We may determine, whenever We
          deem it appropriate.
        </span>
      </p>
      <div class="h2-height"></div>
      <h2 class="c0">
        <span class="c8 h2-text">5. User Restrictions and Obligations</span>
      </h2>
      <p class="c0">
        <span class="c3">
          It is important to us that the Services be used safely, and in
          accordance with the law, for the enjoyment of all Users. You agree
          that you will not use the Services to:
        </span>
      </p>
      <ol class="lst-kix_list_38-0 start" start="1">
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            {" "}
            Decompile, disassemble, reverse engineer, copy, transfer, or
            otherwise use the Services, ALL3D Content, and User Content except
            as permitted by this Agreement.
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            {" "}
            Promote any illegal activity, or advocate, promote or assist any
            unlawful act.
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Violate the legal rights (including the rights of publicity and
            privacy) of others or contain any material that could give rise to
            any civil or criminal liability under applicable laws or regulations
            or that otherwise may be in conflict with this Agreement and our
            &nbsp;
          </span>
          <span class="c9">
            <a class="c4" href="/privacy" target="_blank">
              Privacy Policy
            </a>
          </span>
          <span class="c1">.</span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Transmit any material or content that is abusive, pornographic,
            dehumanizing, threatening, harassing, libelous, hate-oriented,
            harmful, defamatory, racist, xenophobic, or illegal.
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Transmit any material or content that is inappropriate for families
            or otherwise suitable only for audiences over the age of 13.
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Transmit any material or content that attempts to falsely state or
            otherwise misrepresent your identity or affiliation with a person or
            entity.
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Transmit material or content that promotes, provides, or relates to
            instructional information about illegal activities or promotes
            physical harm or injury against any individual or group.
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Transmit or otherwise make available any unsolicited or unauthorized
            advertising, promotional materials, &quot;junk mail,&quot;
            &quot;spam,&quot; &quot;chain letters,&quot; &quot;pyramid
            schemes,&quot; or any other form of solicitation.
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Transmit or encourage the transmission of any material that may
            infringe the &nbsp;trade secrets or intellectual property rights or
            other rights of third parties, including trademark, copyright,
            patent, or right of publicity, or which otherwise constitutes or
            promotes counterfeit materials or goods.
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Transmit or transfer (by any means) information or software derived
            from the site to foreign countries or certain foreign nations in
            violation of US export control laws.
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Use the Services to, directly or indirectly, create or improve
            (including by training) any similar or competing product or service.
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Use the Services in a manner that (a) is likely to interrupt,
            suspend, slow down or hinder the continuity of the Services, (b)
            constitutes an intrusion or attempt to break into or interfere with
            the Services or ALL3D&#39;s computer or networking systems, (c) will
            divert of the Services&#39; system resources, (d) may place a
            disproportionate load on the infrastructure of the Services, and (e)
            constitutes an attack on security and authentication measures of the
            Services or ALL3D&#39;s computer or networking systems.
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Intrude into a third party&#39;s computer system, engage in any
            activity that may damage, control, interfere with or intercept all
            or part of a third party&#39;s computer system and violate its
            integrity or security, or otherwise transmit any materials or
            content that is harmful for third party information systems
            (including but not limited to viruses, worms, Trojans).
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Access data not intended for you or logging into a server or account
            which you are not authorized to access
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Otherwise use the Services for purposes other than those for which
            they were designed.
          </span>
        </li>
      </ol>
      <p class="c0">
        <span class="c3">
          You understand and agree that any liability, loss or damage that
          occurs as a result of the use of any User Content that you make
          available or access through your use of the Service is solely your
          responsibility. ALL3D is not responsible for any public display or
          misuse of your User Content. ALL3D does not, and cannot, pre-screen or
          monitor all User Content. However, at our discretion, we, or
          technology we employ, may monitor and/or record your interactions with
          the Service.
        </span>
      </p>
      <p class="c0">
        <span class="c3">
          Use of ALL3D within advertisements or advertiser-supported content
          requires the license of an Enterprise account and express consent by
          ALL3D for which you may{" "}
        </span>
        <span class="c9">
          <a class="c4" href="/contact-us" target="_blank">
            contact us here
          </a>
        </span>
        <span class="c3">.</span>
      </p>
      <p class="c0">
        <span class="c3">
          You agree to inform ALL3D promptly about any legal complaint, claim or
          action related to the User Content you have uploaded using the
          Services.
        </span>
      </p>

      <div class="h2-height"></div>
      <h2 class="c0">
        <span class="c8 h2-text">
          6. Warranty Disclaimers, Limitations of Liability, Indemnity
        </span>
      </h2>
      <p class="c0">
        <span class="c8 h3-text">Disclaimer of Warranties</span>

        <span class="c3">
          <br />
          You
        </span>
        <span class="c3" style={{ textTransform: "lowercase" }}>
          &nbsp;UNDERSTAND AND AGREE THAT TO THE FULLEST EXTENT PERMITTED BY
          APPLICABLE LAW: YOUR USE OF THE SERVICES IS AT YOUR SOLE RISK. THE
          SITE AND THE SERVICES, INCLUDING WITHOUT LIMITATION, ALL CONTENT,
          FUNCTION, MATERIALS AND SERVICES ARE PROVIDED "AS IS" AND "AS
          AVAILABLE" FOR YOUR USE, WITHOUT WARRANTIES OF ANY KIND, EITHER
          EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, ANY WARRANTY (i)
          RELATING TO INFORMATION, DATA, DATA PROCESSING SERVICES OR
          UNINTERRUPTED ACCESS, (ii) OF MERCHANTABILITY, FITNESS FOR A
          PARTICULAR PURPOSE, TITLE, NONINFRINGEMENT, AND ANY WARRANTIES ARISING
          FROM COURSE OF DEALING AND USAGE OF TRADE; (ii) THAT THE SERVICES,
          WEBSITE AND THE{" "}
          <span style={{ textTransform: "uppercase" }}>ALL3D</span> CONTENT WILL
          BE SECURE OR OPERATE WITHOUT ERROR OR THAT DEFECTS WILL BE CORRECTED;
          (iv) AS TO THE RESULTS THAT MAY BE OBTAINED FROM THE OPERATION, USE OR
          OTHER EXPLOITATION OF THE SERVICES, WEBSITE OR THE ALL3D CONTENT; AND
          (v) AS TO THE ACCURACY, AVAILABILITY, COMPLETENESS, USEFULNESS OR
          RELIABILITY OF ANY CONTENT OR INFORMATION OBTAINED FROM THE SERVICES,
          WEBSITE OR THE{" "}
          <span style={{ textTransform: "uppercase" }}>ALL3D</span> CONTENT.
        </span>
      </p>
      <p class="c0">
        <span class="c3">You</span>
        <span class="c3" style={{ textTransform: "lowercase" }}>
          &nbsp;UNDERSTAND AND AGREE THAT YOUR USE, ACCESS OR DOWNLOAD OF THE
          APPLICATION, MATERIALS OR ANY OTHER SERVICES OR PRODUCTS OFFERED BY{" "}
          <span style={{ textTransform: "uppercase" }}>ALL3D,I</span>nc. TO YOU
          THROUGH ANY OF THE FOREGOING IS DONE AT YOUR OWN DISCRETION AND RISK
          AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
          PROPERTY (INCLUDING YOUR COMPUTER SYSTEM OR MOBILE DEVICE) OR LOSS OF
          DATA THAT RESULTS FROM THE DOWNLOAD OR USE OF SUCH MATERIAL,
          APPLICATION OR DATA.
        </span>
      </p>
      <p class="c0">
        <span class="c3">ALL3D, Inc</span>
        <span class="c3" style={{ textTransform: "lowercase" }}>
          . DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS REGARDING THE QUALITY
          OF SCANS, MODELS, OR ANY OTHER CONTENT OBTAINED THROUGH THE USE OF THE
          SERVICES OFFERED BY THE COMPANY. NO ADVICE, RESULTS OR INFORMATION, OR
          MATERIALS WHETHER ORAL OR WRITTEN, OBTAINED BY YOU THROUGH THE SITE
          SHALL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN. IF YOU ARE
          DISSATISFIED WITH THE SITE OR THE SERVICES, YOUR SOLE REMEDY IS TO
          DISCONTINUE USING THE SITE OR THE SERVICES.
        </span>
      </p>
      <p class="c0">
        <span class="c3">ALL3D, Inc</span>
        <span class="c3" style={{ textTransform: "lowercase" }}>
          . DOES NOT ENDORSE, WARRANT OR GUARANTEE ANY PRODUCTS OR SERVICES
          OFFERED OR PROVIDED BY OR ON BEHALF OF THIRD PARTIES ON OR THROUGH THE
          SITE. &nbsp;
          <span style={{ textTransform: "uppercase" }}>ALL3D, I</span>nc. IS NOT
          A PARTY TO, AND DOES NOT MONITOR, ANY TRANSACTION BETWEEN USERS AND
          THIRD PARTIES WITHOUT THE DIRECT INVOLVEMENT OF{" "}
          <span style={{ textTransform: "uppercase" }}>ALL3D, I</span>nc. &nbsp;
        </span>
      </p>
      <p class="c0">
        <span class="c8 h3-text">Limitation of Liability </span>
        <span class="c3">
          <br />
          To
        </span>
        <span class="c3" style={{ textTransform: "lowercase" }}>
          &nbsp;THE EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL{" "}
          <span style={{ textTransform: "uppercase" }}>ALL3D</span>, ITS
          AFFILIATES, OFFICERS, DIRECTORS, OR EMPLOYEES, OR ITS LICENSORS,
          SERVICE PROVIDERS OR PARTNERS, BE LIABLE TO YOU FOR (A) ANY LOSS OF
          PROFITS, USE, OR DATA, OR FOR ANY INCIDENTAL, INDIRECT, SPECIAL,
          CONSEQUENTIAL OR EXEMPLARY OR PUNITIVE DAMAGES, ARISING FROM OR
          DIRECTLY OR INDIRECTLY RELATED TO (A) THE USE, DISCLOSURE, OR DISPLAY
          OF YOUR USER CONTENT; (B) YOUR USE OR INABILITY TO USE THE SERVICES,
          THE SITE OR THE CONTENT, MATERIALS AND FUNCTION RELATED THERETO; (C)
          THE SERVICE GENERALLY OR THE SOFTWARE OR SYSTEMS THAT MAKE THE SERVICE
          AVAILABLE; OR (D) ANY OTHER INTERACTIONS WITH ALL3D OR ANY OTHER USER
          OF THE SERVICE; OR (II) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF
          $100, IN EACH CASE WHETHER BASED ON WARRANTY, CONTRACT, TORT
          (INCLUDING NEGLIGENCE) OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT
          ALL3D HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, AND EVEN IF
          A REMEDY SET FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL
          PURPOSE. SOME JURISDICTIONS LIMIT OR DO NOT PERMIT DISCLAIMERS OF
          LIABILITY, SO THIS PROVISION MAY NOT APPLY TO YOU.
        </span>
      </p>
      <p class="c0">
        <span class="c8 h3-text">Indemnity</span>
        <span class="c3">
          <br />
          You agree to indemnify and hold ALL3D and its affiliates,
          subsidiaries, owners, directors, officers, employees and agents
          harmless from and against any and all claims, demands, suits,
          proceedings, liabilities, judgments, losses, damages, expenses and
          costs (including without limitation reasonable attorneys&#39; fees)
          assessed or incurred by ALL3D in any way arising from, related to or
          in connection with use of the Site, your violation of the Terms or the
          posting or transmission of any materials on or through the Site by
          you, including, but not limited to, any third party claim that any
          information or materials you provide infringes any third party
          proprietary right.
        </span>
      </p>
      <div class="h2-height"></div>
      <h2 class="c0">
        <span class="c8 h2-text">7. Intellectual Property Claims</span>
      </h2>
      <p class="c0">
        <span class="c3">
          ALL3D respects the intellectual property rights of others, and
          complies with the provisions of the Digital Millennium Copyright Act
          (DMCA) applicable to Internet service providers (17 U.S.C. &sect; 512,
          as amended). We respond to clear notices of alleged copyright
          infringement , and infringing materials posted by Users can be
          identified and removed pursuant to this complaint procedure.
        </span>
      </p>
      <p class="c0">
        <span class="c3">
          In the event you believe that any User Content on the Services
          infringes your copyright, please file a notice of infringement using
          this form:
        </span>
      </p>
      <p class="c0">
        <span class="c18 h3-text">
          <a class="c4" href="/contact-us" target="_blank">
            FILE A DMCA TAKEDOWN NOTICE
          </a>
        </span>
      </p>
      <p class="c0">
        <span class="c3">
          Electronic notification is preferred. You can also file a notice with
          our designated agent, but processing may be slower than using the link
          above.
        </span>
      </p>
      <p class="c13">
        <span class="c3">ALL3D, Inc.&nbsp;</span>
      </p>
      <p class="c13">
        <span class="c1">220 Halleck Street, Suite 125</span>
      </p>
      <p class="c13">
        <span class="c1">San Francisco, CA 94129</span>
      </p>
      <p class="c0">
        <span class="c3">DMCA@all3d.ai</span>
      </p>
      <p class="c0">
        <span class="c3">
          To be effective, any notice submitted to our Copyright Agent must
          comply with the requirements set forth at 17 U.S.C. &sect; 512(c)(3),
          and must include substantially the following:
        </span>
      </p>
      <ol class="lst-kix_list_38-0 start" start="1">
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            The date of your notification;
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            A physical or electronic signature of a person authorized to act on
            behalf of the owner of an exclusive right that is allegedly
            infringed;
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Identification of the copyrighted work claimed to have been
            infringed, or, if multiple copyrighted works at a single online site
            are covered by a single notification, a representative list of such
            works at the Site;
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Identification of the material that is claimed to be infringing or
            to be the subject of infringing activity and that is to be removed
            or access to which is to be disabled, and information reasonably
            sufficient to permit us to locate the material;
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Information reasonably sufficient to permit ALL3D to contact you,
            such as an address, telephone number, and/or email address;
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            A statement that you have a good faith belief that use of the
            material in the manner complained of is not authorized by the
            copyright owner, its agent, or the law; and
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            A statement that the information in the notification is accurate,
            and under penalty of perjury, that you are authorized to act on
            behalf of the owner of an exclusive right that is allegedly
            infringed.
          </span>
        </li>
      </ol>
      <p class="c0">
        <span class="c3">
          ALL3D will, upon receiving your compliant notice of copyright
          infringement, contact the User who posted the allegedly infringing
          User Content concerning the notice of infringement, and expeditiously
          remove the allegedly infringing User Content. It is our policy to
          document all notifications of alleged infringement on which we act. A
          copy of the notification may be sent to one or more third parties who
          may make it available to the public. Please be advised that it is our
          policy to terminate the accounts of users that repeatedly violate this
          Agreement and/or the DMCA Policy.
        </span>
      </p>
      <p class="c0">
        <span class="c3">
          If you are a User and material that you have uploaded to the Services
          has been removed or disabled, you may file a counter-notification
          pursuant to 17 U.S.C. &sect; 512(g). To be effective, the
          counter-notification must be a written communication sent to the
          Designated Agent address listed above that includes the following:
        </span>
      </p>
      <ol class="lst-kix_list_38-0 start" start="1">
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Your physical or electronic signature;
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Identification of the material that has been removed or to which
            access has been disabled, and the location at which the material
            appeared before it was removed or access to it was disabled;
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            A statement under penalty of perjury that you have a good faith
            belief that the material was removed or disabled as a result of
            mistake or misidentification of the material to be removed or
            disabled; and
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Your name, address, and telephone number, and a statement that you
            consent to the jurisdiction of Federal District Court for the
            judicial district in which the address is located or, if your
            address is outside of the United States, the Northern District of
            California, and that you will accept service of process from the
            person who provided notification under subsection 17 U.S.C. &sect;
            512(c)(1)(C) or an agent of such person.
          </span>
        </li>
      </ol>
      <p class="c0">
        <span class="c1">
          It is often difficult to determine if your intellectual property
          rights have been violated or if the Digital Millennium Copyright Act
          (DMCA) requirements have been met. We may request additional
          information before we remove any infringing material. If a dispute
          develops as to the correct owner of the rights in question, we reserve
          the right to remove or disable access to the allegedly infringing
          material pending resolution of the matter. We will terminate the
          accounts of users that we determine are repeat infringers.
        </span>
      </p>
      <p class="c0">
        <span class="c1">
          Please note that under Section 512(f) of the Copyright Act any person
          who makes false claims that material or an activity is infringing may
          be subject to liability for damages.
        </span>
      </p>
      <p class="c0">
        <span class="c3">
          If you have a claim related to trademarks, patents, intellectual
          property, or any other matter other than copyright, please{" "}
        </span>
        <span class="c9">
          <a class="c4" href="/contact-us" target="_blank">
            contact us
          </a>
        </span>
        <span class="c3">
          . Please provide detailed information regarding the nature of your
          complaint, including but not limited to your name and contact
          information, the subject matter of the complaint, and any additional
          information that we may require in order to review the matter and take
          action (or no action) as may be appropriate. Please be advised that we
          may share the details of your complaint with the User that owns the
          User Content that is the subject of your complaint, or with
          appropriate authorities (including law enforcement authorities) if
          ALL3D in its sole discretion believes that such action is necessary,
          or if we are obligated to do so by law.
        </span>
      </p>
      <div class="h2-height"></div>
      <h2 class="c0">
        <span class="c8 h2-text">
          8. Modifying and Terminating the Services
        </span>
      </h2>
      <p class="c0">
        <span class="c8 h3-text">8.1 At any time</span>
        <span class="c3">
          <br />
          ALL3D reserves the right to modify or stop offering all or part of the
          Services at any time, at its own discretion, in which case we will
          provide you with one (1) month&#39;s notice.
        </span>
      </p>
      <p class="c0">
        <span class="c8 h3-text">8.2 For cause</span>
        <span class="c3">
          <br />
          We may terminate your user account or right to access all or portions
          of the Services at any time, without notice, for conduct that we
          believe violates these Terms and/or is harmful to other users, to
          ALL3D, to other service or information providers, or to any third
          parties.
        </span>
      </p>
      <p class="c0">
        <span class="c8 h3-text">8.3 Survival</span>
        <span class="c3">
          <br />
          The provisions herein dealing directly or indirectly with intellectual
          property, disclaimers of warranties, limitation of liability,
          compliance with laws and regulations, security, dispute resolution and
          use restrictions and prohibitions continue to apply and shall survive
          the termination or expiration of any relationship between you and
          ALL3D, Inc.
        </span>
      </p>
      <div class="h2-height"></div>
      <h2 class="c0">
        <span class="c8 h2-text">9. Other Websites and Services</span>
      </h2>
      <p class="c0">
        <span class="c3">
          The Services may contain links and features that enable you to access
          other third-party websites or services (&quot;Third-Party
          Services&quot;) that are not owned or controlled by us. Such
          Third-Party Services are governed by their own terms of use. We do not
          control Third-Party Services, and we are not responsible for the
          contents of any linked site. A link does not imply endorsement of,
          sponsorship of, or affiliation with the Third-Party Services by ALL3D,
          Inc. Please exercise caution before proceeding to any Third-Party
          Services or entering into any transaction with third parties linked to
          from the Services. ALL3D may in no circumstances be held liable for
          the technical availability of Third-Party Services, the content,
          advertising, products and/or services available on Third-Party
          Services, or any transactions that take place between a User and
          Third-Party Services whom the User has been directed via the Services.
          ALL3D may in no circumstances be a party to any disputes whatsoever
          between you and third parties concerning Third-Party Services.
        </span>
      </p>
      <div class="h2-height"></div>

      <h2 class="c0">
        <span class="c8 h2-text">
          10. Applicable Law and Jurisdiction; Arbitration
        </span>
      </h2>
      <p class="c0">
        <span class="c8 h3-text">10.1 Applicable law and jurisdiction</span>
        <span class="c1">
          <br />
          Except to the extent applicable law provides otherwise, the Terms and
          any access to or use of the Site or the Services shall be governed by
          the laws of the State of California, without regard to the conflict of
          laws rules thereof.
        </span>
      </p>
      <p class="c0">
        <span class="c8 h3-text">10.2 Arbitration</span>
        <span class="c1">
          <br />
          All disputes of any nature related to the Terms shall be determined by
          final and binding arbitration in San Francisco County, California
          before a single arbitrator. The parties shall agree on the rules and
          procedures to be followed in the arbitration. If the parties are
          unable to agree on the rules and procedures to be followed within 15
          days, the arbitration shall be administered by JAMS pursuant to its
          Streamlined Arbitration Rules and Procedures, and the arbitrator shall
          apply the laws applicable in the State of Delaware (without regard to
          the conflict of laws rules thereof). Judgment on the arbitral award
          may be entered in any court having jurisdiction thereof. The foregoing
          arbitration provisions shall not preclude either party from seeking an
          injunction or other provisional remedies in aid of arbitration from a
          court of appropriate jurisdiction. The arbitrator shall, in the
          arbitral award, allocate all or part of the costs of the arbitration,
          including the fees of the arbitrator and reasonable attorneys&rsquo;
          fees of the prevailing party, for payment by the non-prevailing party,
          and shall determine the prevailing party for this purpose.
        </span>
      </p>
      <p class="c0">
        <span class="c8 h3-text">10.3 Class Action and Jury Trial Waiver</span>
        <span class="c1">
          <br />
          The parties further agree that (i) any claims brought by either party
          must be brought in such party&rsquo;s individual capacity and not as a
          plaintiff or class member in any purported class or representative
          proceeding, and (ii) the arbitrator may not (x) consolidate more than
          one person&rsquo;s claims, (y) otherwise preside over any form of a
          representative or class proceeding or (z) award class-wide relief. ANY
          RIGHT TO A TRIAL BY JURY IS HEREBY WAIVED.
        </span>
      </p>
      <p class="c0">
        <span class="c8 h3-text">10.4. Time To Bring Action</span>
        <span class="c1">
          <br />
          Any claim or cause of action you may have with respect to ALL3D, Inc.,
          the Site or the Service must be commenced within one (1) year after
          the claim or cause of action arose.
        </span>
      </p>
      <p class="c0">
        <span class="c8 h3-text">10.5 Export Regulation</span>
        <span class="c1">
          <br /> The Application may be subject to US export control laws,
          including the US Export Administration Act and its associated
          regulations. You shall not, directly or indirectly, export, re-export,
          or release the Application to, or make the Application accessible
          from, any jurisdiction or country to which export, re-export, or
          release is prohibited by law, rule, or regulation. You shall comply
          with all applicable federal laws, regulations, and rules, and complete
          all required undertakings (including obtaining any necessary export
          license or other governmental approval), prior to exporting,
          re-exporting, releasing, or otherwise making the Application available
          outside the US.
        </span>
      </p>
      <p class="c0">
        <span class="c8 h3-text">10.6 US Government Rights</span>
        <span class="c1">
          <br />
          The Application is commercial computer software, as such term is
          defined in 48 C.F.R. &sect;2.101. Accordingly, if you are an agency of
          the US Government or any contractor therefore, you receive only those
          rights with respect to the Application as are granted to all other end
          users under license, in accordance with (a) 48 C.F.R. &sect;227.7201
          through 48 C.F.R. &sect;227.7204, with respect to the Department of
          Defense and their contractors, or (b) 48 C.F.R. &sect;12.212, with
          respect to all other US Government licensees and their contractors.
        </span>
      </p>
      <div class="h2-height"></div>

      <h2 class="c0">
        <span class="c8 h2-text">11. Apple App Terms</span>
      </h2>
      <p class="c0">
        <span class="c3">
          These Terms apply to your use of all the Services, including the
          iPhone, iPad Touch, and iPad applications available via the Apple,
          Inc. (&ldquo;Apple&rdquo;) App Store (the &ldquo;Application&rdquo;),
          but the following additional terms also apply to the Application:
        </span>
      </p>
      <ol class="lst-kix_list_38-0 start" start="1">
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Both you and ALL3D acknowledge that the Terms are concluded between
            you and ALL3D only, and not with Apple, and that Apple is not
            responsible for the Application or any content you view or access
            through the Application;
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            The Application is licensed to you on a limited, non-exclusive,
            non-transferable, non-sublicensable basis, solely to be used in
            connection with the Services for your private, personal,
            non-commercial use, subject to all the terms and conditions of these
            Terms as they are applicable to the Services;
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            You will only use the Application in connection with an Apple device
            that you own or control;
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            You acknowledge and agree that Apple has no obligation whatsoever to
            furnish any maintenance and support services with respect to the
            Application;
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            In the event of any failure of the Application to conform to any
            applicable warranty, including those implied by law, you may notify
            Apple of such failure; upon notification, Apple&rsquo;s sole
            warranty obligation to you will be to refund to you the purchase
            price, if any, of the Application;
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            You acknowledge and agree that ALL3D, and not Apple, is responsible
            for addressing any claims you or any third party may have in
            relation to the Application;
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            You acknowledge and agree that, in the event of any third party
            claim that the Application or your possession and use of the
            Application infringes that third party&rsquo;s intellectual property
            rights, ALL3D, and not Apple, will be responsible for the
            investigation, defense, settlement and discharge of any such
            infringement claim;
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            You represent and warrant that you are not located in a country
            subject to a U.S. Government embargo, or that has been designated by
            the U.S. Government as a &ldquo;terrorist supporting&rdquo; country,
            and that you are not listed on any U.S. Government list of
            prohibited or restricted parties;
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Both you and ALL3D acknowledge and agree that, in your use of the
            Application, you will comply with any applicable third party terms
            of agreement which may affect or be affected by such use; and
          </span>
        </li>
        <li class="c0 c6" style={{ fontWeight: "bold", color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Both you and ALL3D acknowledge and agree that Apple and
            Apple&rsquo;s subsidiaries are third party beneficiaries of these
            Terms, and that upon your acceptance of these Terms, Apple will have
            the right (and will be deemed to have accepted the right) to enforce
            these Terms against you as the third party beneficiary hereof.
          </span>
        </li>
      </ol>
      <div class="h2-height"></div>

      <h2 class="c0">
        <span class="c8 h2-text">12. Amendment</span>
      </h2>
      <p class="c0">
        <span class="c3">
          ALL3D reserves the right to amend these Terms at any time by posting a
          notice on this page. &nbsp;When we make material changes to the Terms,
          we will endeavor to provide you with notice as appropriate under the
          circumstances, e.g., by displaying a prominent notice within the Site
          or Service or by sending an email. In some cases, we will notify you
          in advance, and your continued use of the Service after the changes
          have been made will constitute your acceptance of the changes.
          &nbsp;If you do not agree with the changes to the Terms your sole
          remedy is to terminate your account and to cease use of the Services
          or access to the Site.
        </span>
      </p>
      <div class="h2-height"></div>

      <h2 class="c0">
        <span class="c8 h2-text">13. Miscellaneous</span>
      </h2>
      <p class="c0">
        <span class="c3">These Terms, along with the </span>
        <span class="c9">
          <a class="c4" href="/privacy" target="_blank">
            Privacy Policy
          </a>
        </span>
        <span class="c3">
          , represents the entire agreement between ALL3D and the User with
          respect to the provision of the Services and access to the Site.
        </span>
        <span class="c10">&nbsp;</span>
        <span class="c1">
          You also may be subject to additional terms and conditions that are
          applicable to certain parts of the Site. &nbsp;The current Terms
          supersede all prior or contemporaneous understandings, agreements,
          representations, and warranties, both written and oral, with respect
          to the subject matter hereof. &nbsp;
        </span>
      </p>
      <p class="c0">
        <span class="c1">
          No delay or omission by ALL3D in exercising any of its rights
          occurring upon any noncompliance or default by any User with respect
          to any of the terms and conditions of these Terms will impair any such
          right or be construed to be a waiver thereof, and a waiver by ALL3D of
          any of the covenants, conditions or terms to be performed by a User
          will not be construed to be a waiver of any succeeding breach thereof
          or of any other covenant, condition or terms hereof contained.
        </span>
      </p>
      <p class="c0">
        <span class="c1">
          As used in these Terms, &quot;including&quot; means &quot;including
          but not limited to.&quot;
        </span>
      </p>
      <p class="c0">
        <span class="c1">
          If any provision of these Terms is found by a court of competent
          jurisdiction to be invalid or unenforceable, then the Agreement will
          remain in full force and effect and will be reformed to be valid and
          enforceable while reflecting the intent of the parties to the greatest
          extent permitted by law..
        </span>
      </p>
      <p class="c0">
        <span class="c1">
          ALL3D may assign or delegate these Terms and/or ALL3D&rsquo;s Privacy
          Policy, in whole or in part, to any person or entity at any time with
          or without your consent. You shall not assign or transfer these Terms
          or any right or obligation hereunder to any third party.
        </span>
      </p>
      <p class="c0">
        <span class="c1">
          You agree that no joint venture, partnership, employment, or agency
          relationship exists between ALL3D, Inc. and you as a result of these
          Terms or your use of the Site or Services.
        </span>
      </p>
      <p class="c0">
        <span class="c3">You consent to receive communications </span>
        <span class="c1">
          from us electronically, and you agree that we may communicate with you
          by email or by posting notices on the Services. You agree that all
          agreements, notices, disclosures and other communications that we
          provide to you, or that you provide to us, electronically satisfy any
          legal requirement that such communications be in writing. We are not
          responsible for any automatic filtering that may be applied to any
          email notices that we send to the email address you have provided.
        </span>
      </p>
      <p class="c0">
        <span class="c3">
          The Terms inure to the benefit of ALL3D, Inc.&rsquo;s successors,
          assigns and licensees. &nbsp;The section titles in the Terms are for
          convenience only and have no legal or contractual effect.{" "}
        </span>
        <span class="c3">
          The Services are operated by ALL3D, Inc., a Delaware corporation.{" "}
        </span>
        <span class="c3">General inquiries may be directed to </span>
        <span class="c9">
          <a class="c4" href="/contact-us" target="_blank">
            contact us
          </a>
        </span>
        <span class="c3">.</span>
      </p>
      <p class="c0">
        <span class="c3" style={{ color: "#333333" }}>
          The effective date of these Terms of Use is July 29th, 2020.
        </span>
      </p>
      {/* <p class="c0 c14 c21"><span class="c1"></span></p> */}
      {/* <div>
        <p class="c14 c23"><span class="c11 c24"></span></p>
        </div> */}
    </body>
  );
}

export default Terms;
