import React, { useState, useEffect, useCallback } from 'react';
import {
    Card,
    Tooltip,
} from 'antd';
import ReactPlayer from 'react-player';
import ModelViewer from '../landingPage/sectionTwo/modelViewer';
import './index.scss'
import { RightOutlined } from '@ant-design/icons';


const ResponsiveProductCard = (props) => {

    const [viewType, setViewType] = useState('2D');
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [image, setImage] = useState(null);
    const [aspectRatio, setAspectRatio] = useState(1);
    const [imageWidth, setImageWidth] = useState(0);
    const [imageHeight, setImageHeight] = useState(0);
    const [, updateState] = React.useState();
    const forceUpdate = React.useCallback(() => updateState({}), []);
    
    const viewImage = (image) => {
        setImage(image);
    }

    const handleViewChange = (e) => {
        setTimeout(() => {
            window.CI360.destroy();
            window.CI360.init();
        }, 1)
        setViewType(e);
    }

    const resize360Spin = () => {
        let element = document.getElementById('container-360');
        if (imageWidth == 0 && element) {
            const img = new Image();
            img.onload = function() {
                let parent = this;
                let aspect_ratio = parent.height/parent.width;
                setAspectRatio(aspect_ratio);
                setImageWidth(parent.width);
                setImageHeight(parent.height);
                set360WidthStyle(element,aspect_ratio);
            }
            img.src = (props.productReducer.product['360'] && props.productReducer.product['360']["data-folder"]) + props.productReducer.product.product_id + '_Frame1.jpg';
        }
        if (imageWidth != 0) {
            set360WidthStyle(element);
        }
    }

    const set360WidthStyle = (element, aspect_ratio = null) => {
        let aspect = aspectRatio;
        if (aspect_ratio != null) {
            aspect = aspect_ratio;
        }
        if (element != null) {
            element.style.visibility = "hidden";
            if (isFullScreen) {
                element.style.width = ((document.documentElement.clientHeight - 10) / aspect) + "px";
            }
            else {
                element.style.width = ((document.documentElement.clientHeight - 310) / aspect) + "px";
            }
            element.style.visibility = "visible";
            handleViewChange('360D');
            forceUpdate();
        }
    }

    useEffect(() => {
        resize360Spin();
    },[viewType,document.getElementById('container-360'),props.productReducer.product['360']]);
    

    const view360Player = () => {
        resize360Spin();
        handleViewChange('360D');
        resize360Spin();
    }
     
    const getVideoUrl = () => {
        let link = (props.productReducer.product.download_links || []).find((ele) => ele.display_name == 'VIDEO');
        if(link ) {
            return link.model_url
        } else {
            return ''
        }
    }
    

    const getGLBUrl = () => {
        let link = (props.productReducer.product.download_links || []).find((ele) => ele.display_name == 'GLB');
        if (link) {
            return link.model_url
        } else {
            return ''
        }
    }

    const getUSDZUrl = () => {
        let link = (props.productReducer.product.download_links || []).find((ele) => ele.display_name == 'USDZ');
        if(link ) {
            return link.model_url
        } else {
            return ''
        }
    }

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            setIsFullScreen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, []);

    useEffect(() => {
        window.CI360.destroy();
        window.CI360.init();
    }, [isFullScreen]);

    const leftScroll = () => {
        var elem = document.getElementById('pdp-scrollable');
        if (elem != null) {
            elem.style.scrollBehavior = "smooth";
            elem.scrollLeft -= 273;
            var left = document.getElementById('left-button-scr');
            var right = document.getElementById('right-button-scr');
            if (left != null && elem.scrollLeft == 0) {
                left.style.display = "none";
            }
            
            if (right!=null) {
                right.style.visibility = 'visible';
                elem.style.left = "20px";
                forceUpdate();
            }
        }
    }

    const rightScroll = () => {
        var elem = document.getElementById('pdp-scrollable');
        if (elem != null) {
            elem.style.scrollBehavior = "smooth";
            elem.scrollLeft += 273;
            var left = document.getElementById('left-button-scr');
            var right = document.getElementById('right-button-scr');
            elem.style.left = "94px";
            if (right != null) {
                if (left != null) {
                    left.style.display = "inline-block";
                }
                
                var newScrollLeft = elem.scrollLeft;
                var divWidth = elem.offsetWidth;
                var scrollwidth = elem.scrollWidth;
                if(newScrollLeft === scrollwidth - divWidth){
                    right.style.visibility = 'hidden';
                }
            }
            
        }
    }

    useEffect(()=> { 
        checkScroll();
    },[]);

    React.useEffect(() => {
        checkScroll();
        window.addEventListener('resize', checkScroll);
        return () => {
          window.removeEventListener('resize', checkScroll);
        }
    });

    const checkScroll = () => {
        var elem = document.getElementById('pdp-scrollable'); 
        var carousel_elem = document.getElementById('pdp-carousel');
        if (elem != null) {
            elem.style.scrollBehavior = "unset";

            var y1 = elem.scrollTop;  
            elem.scrollTop += 1; 
            var y2 = elem.scrollTop ; 
            elem.scrollTop -= 1; 
            var y3 = elem.scrollTop; 
            elem.scrollTop = y1; 
        
            var x1 = elem.scrollLeft;  
            elem.scrollLeft += 1; 
            var x2 = elem.scrollLeft; 
            elem.scrollLeft -= 1; 
            var x3 = elem.scrollLeft; 
            elem.scrollLeft = x1; 
            var right = document.getElementById('right-button-scr');
            var left = document.getElementById('left-button-scr');
            let nextValue = elem.scrollLeft - 273;

            if (elem.style.left == "94px") {
                elem.style.left = "94px";
            }
            else {
                if ( elem.scrollLeft == 0) {
                    elem.style.left = "20px";
                }
            }
            if (right != null && (x1 !== x2 || x2 !== x3)) {
                right.style.display = "inline-block";
                if (left != null) {
                    if (elem.style.left == "20px" &&  elem.scrollLeft == 0) {
                        left.style.display = "none"; 
                    }
                }
                if (elem.style.left == "20px"  &&  nextValue <= 0) {// for right scroll
                    if (window.innerWidth <= 1500 && props.compareView) {
                        elem.style.width = "100%";
                    }
                    if (window.innerWidth <= 1320 && props.compareView) {
                        elem.style.width = "100%";
                    }
                    if (window.innerWidth <= 1200 && props.compareView) {
                        elem.style.width = "100%";
                    }

                    if (props.compareView) {
                        carousel_elem.style.width = "80%"
                    }
                }
                else { // for left scroll
                    if (window.innerWidth <= 1500 && props.compareView) {
                        elem.style.width = "100%";
                    }
                    if (window.innerWidth <= 1320 && props.compareView) {
                        elem.style.width = "100%";
                    }
                    if (window.innerWidth <= 1200 && props.compareView) {
                        elem.style.width = "100%";
                    }
                    if (props.compareView) {
                        carousel_elem.style.width = "80%"
                    }
                }
            }

            if (!props.compareView && props.adminScrollCheck) {
                elem.style.width = "100%";
                elem.style.left = "20px";
                if (window.innerWidth <= 1300) {
                    elem.style.width = "100%";
                }
            }

            if (right != null && left != null && !(x1 !== x2 || x2 !== x3)) {
                right.style.display = "none";
                // left.style.display = "none";
            }
            if (right != null  && !(x1 !== x2 || x2 !== x3)) {
                right.style.display = "none";
                // left.style.display = "none";
            }
            // ---- NEED FOR LATER USE ----
            // console.log("H",x1 !== x2 || x2 !== x3); 
            // console.log("V",y1 !== y2 || y2 !== y3);
        }
    }

    return (
        <React.Fragment>
            <Card bordered={false} className={"product-viewer-normal"} bodyStyle={{padding: 0}}>
                {viewType == '3D' && (props.productReducer.product.download_links != undefined) ?
                    <ModelViewer src={getGLBUrl()} ios_src={getUSDZUrl()} />
                    :
                    ""
                }
                {viewType == '2D' && <div style={{ display: 'flex', justifyContent: "space-around" }}>
                    <img alt="2D product" src={(image || props.productReducer.product['2d'] && props.productReducer.product['2d'][0])} className="product-images"/>
                </div>}
                {viewType == '360D' ?
                
                    <div id="container-360" className="container-360-spin" style={{ height: (isFullScreen) ? "100vh" : "calc(100vh - 387px)" }}>
                        <div
                            className="cloudimage-360"
                            id="image-360"
                            data-folder={props.productReducer.product['360'] && props.productReducer.product['360']["data-folder"]}
                            data-filename={props.productReducer.product['360'] && props.productReducer.product['360']["data-filename"]}
                            data-amount={props.productReducer.product['360'] && props.productReducer.product['360']["data-amount"]}>

                        </div>
                    </div>
                : ''}

                {viewType == 'video' && <div>
                    <ReactPlayer width="100%" height={isFullScreen?"calc(100vh - 84px)":"calc(100vh - 361px)"} url={getVideoUrl()} controls={true} loop={true}/>
                </div>
                }
            </Card>

            <div className='pdp-carousel-scroll' id="pdp-carousel">

            <div onClick={leftScroll}  className="icon-con-left" id="left-button-scr">
                <RightOutlined  className="left-button-scr" />
                
            </div>
            <div id="pdp-scrollable" className='pdp-scrollable'>
            
                <Tooltip title={"3D"} placement="top">
                    <Card bodyStyle={{ padding: "0px"}} style={{marginRight:"10px"}}>
                        <div className="product-view-icon" onClick={() => setViewType('3D')}>
                            <span style={{background: "url(/3d.jpg)",objectFit:"contain",height:"40px",width:"40px"}}><b style={{color: "white"}}></b></span>
                        </div>
                    </Card>
                </Tooltip>
                {props.productReducer.product['360'] &&
                <Tooltip title={"360"} placement="top">
                    <Card bodyStyle={{ padding: "0px"}} style={{marginRight:"10px"}}>
                        <div className="product-view-icon" onClick={() => view360Player()}>
                            <span style={{background: "url(/360-tn.jpg)",objectFit:"contain",height:"40px",width:"40px"}}><b style={{color: "white"}}></b></span>
                        </div>
                    </Card>
                </Tooltip>
                }
                {(getUSDZUrl() != '') && 
                <Tooltip title={"AR"} placement="top">
                    <Card bodyStyle={{ padding: "0px"}} style={{marginRight:"10px"}}>
                        <div className="product-view-icon" onClick={() => setViewType('view_in_room')}>
                            <span style={{background: "url(/ar.jpg)",objectFit:"contain",height:"40px",width:"40px"}}><b style={{color: "white"}}></b></span>
                        </div>
                    </Card>
                </Tooltip>}
                {(getVideoUrl() != '') &&
                <Card bodyStyle={{ padding: "0px"}} style={{marginRight:"10px"}}>
                    <div className="product-view-icon" onClick={() => setViewType('video')}>
                        <span style={{background: "url(/mp4.svg)",objectFit:"contain",height:"40px",width:"40px"}}><b style={{color: "white"}}></b></span>
                    </div>
                </Card>}
                {(props.productReducer.product['2d'] || []).map((u, index) => {
                    return <Card key={index} bodyStyle={{ padding: "2px", minWidth:40, textAlign: "-webkit-center"}} style={{marginRight:"10px",width:"64px",height:"64px",padding:"10px"}}> 
                        <img onClick={() => viewImage(u)} style={{ cursor: "pointer", height: "40px", objectFit: "contain", display: "block",width:"40px"}} alt="Clickable Product Image" src={u} /> 
                    </Card>
                })}
            </div>    
                <div style={{display:"inline-block"}} onClick={rightScroll} className="icon-con" id="right-button-scr">
                    <RightOutlined  className="right-button-scr"/>
                </div>    
            </div>   
        </React.Fragment>
    );


}

export default ResponsiveProductCard;