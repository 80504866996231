import React, { createRef } from "react";
import NavBar from "../landingPage/header/navbar";
import Footer from "../landingPage/footer";
import "./pricing.scss";
import CookieConsentBanner from "../cookieConsentBanner";
import { Row, Col, Layout, Collapse } from "antd";
import { Link } from "react-router-dom";
import DATA from "./data";
import $ from "jquery";
import { Helmet } from "react-helmet";
import MetaTagContents from "../../MetaTagContents";

const { Content } = Layout;

const { Panel } = Collapse;

function PricingBoxItem(props) {
  return (
    <div>
      <div className="price-model scroll-price" style={{ paddingTop: "0px" }}>
        {props.title}
      </div>
      <div
        className="price-description"
        style={{ paddingTop: "15px", textTransform: "capitalize" }}
      >
        {props.description}{" "}
        <span
          style={{
            color: "#333333",
            display: props.state === "products" ? "inline" : "none",
          }}
        >
          {" "}
          Click on the categories below to see the item types present in them.{" "}
        </span>
      </div>
      <hr
        style={{
          width: "34vw",
          marginLeft: "20px",
          background: "#D93025",
          display: props.state === "products" ? "none" : "blocl",
        }}
      />
    </div>
  );
}

function SelectBox(props) {
  return (
    <div
      id={props.id}
      className={`price-container-left ${
        props.state === props.id || props.state === props.id2
          ? "price-container-active"
          : ""
      }`}
      onClick={() => {
        //props.scrollTo.current.scrollIntoView({behavior: 'smooth'})
        // props.this.setState({priceBlock: props.id})
        props.this.setState({ priceBlock: props.id }, () => {
          window.dispatchEvent(new CustomEvent("scroll"));
        });
      }}
    >
      <div className="price-model">{props.title}</div>
      <div className="price-start">Starting At</div>
      <div className="price">{props.price}</div>
    </div>
  );
}

function Prices(props) {
  return (
    <div
      className="space-header"
      style={{ borderBottom: props.last ? "none" : "1px solid #C4C4C4" }}
    >
      <div className="space-title">{props.title}</div>
      <div className="space-price">{props.price}</div>
    </div>
  );
}

function PricesMobile(props) {
  return (
    <div
      className="space-mobile"
      style={{ borderBottom: props.last ? "none" : "1px solid #c4c4c4" }}
    >
      <div className="space-title-mobile">{props.title}</div>
      <div className="space-price-mobile">{props.price}</div>
    </div>
  );
}

function SelectBoxMobile(props) {
  return (
    <div
      id={props.id}
      className={`price-container-center ${
        props.state === props.id ? "price-container-center-active" : ""
      } scroll-price`}
    >
      <div
        className="price-title"
        style={{ color: props.state === props.id ? "#333333" : "#737373" }}
      >
        {props.title}
      </div>

      <div
        className="circle"
        style={{
          display: props.state === props.id ? "none" : "block",
          marginTop: "0px",
        }}
        onClick={() => props.this.setState({ priceBlock: props.id })}
      >
        <img
          src="/caret-below.svg"
          style={{ padding: "16px 0px" }}
          alt="Caret Down"
        />
      </div>
      <div
        className="circle-red"
        onClick={() => props.this.setState({ priceBlock: "products" })}
        style={{
          display: props.state === props.id ? "block" : "none",
          marginTop: "0px",
        }}
      >
        <img
          src="/caret-up.svg"
          style={{ padding: "16px 0px" }}
          alt="Caret Up"
        />
      </div>
      <div
        className="price-start"
        style={{ paddingTop: props.state === props.id ? "28px" : "28px" }}
      >
        Starting At <span className="price">{props.price}</span>
      </div>
      <div
        className="price-description"
        style={{ display: props.state === props.id ? "block" : "none" }}
      >
        {props.description}{" "}
        <span
          style={{
            color: "#333333",
            display: props.state === "product" ? "inline" : "none",
          }}
        >
          {" "}
          Click on the categories below to see the item types present in them.{" "}
        </span>
      </div>

      <div
        className={props.id === "lifestyle-360-3d" ? " " : `bottom-border`}
        style={{
          borderBottom:
            props.state === props.id || props.id === "lifestyle-360-3d"
              ? ""
              : "1px solid #c4c4c4",
        }}
      ></div>
    </div>
  );
}

class PricingPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      treeData: [],
      priceBlock: "",
      isLoading: true,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", () => {
      let elmnt = document.getElementsByClassName("scroll-price");
      if (elmnt && elmnt[0] && elmnt[0].offsetHeight) {
        let updatedElem =
          window.document.getElementsByClassName("navbar-light");
        if (updatedElem && updatedElem[0]) {
          if (window.scrollY > elmnt[0].offsetHeight) {
            updatedElem[0].classList.add("full-opacity");
          } else {
            updatedElem[0].classList.remove("full-opacity");
          }
        }
      }
    });

    fetch(
      "https://xnhluveva9.execute-api.us-west-2.amazonaws.com/staging/all3d-category-get-batch"
    )
      .then((response) => response.json())
      .then((data) => {
        let tree_data = [];
        let check = false;
        for (let i = 0; i < data.length; i++) {
          let children = [];
          if (data[i].parent_id === "") {
            for (let j = 0; j < data.length; j++) {
              if (data[i].category_id === data[j].parent_id) {
                check = true;
                tree_data[i] = {
                  title: data[i].name,
                  key: data[i].category_id,
                  price: data[i].price,
                };
                children.push({
                  title: data[j].name,
                  key: data[j].category_id,
                  price: data[j].price,
                });
              }
              if (check) {
                tree_data[i].children = children;
                check = false;
              }
            }
          }
        }

        this.setState({
          treeData: tree_data,
          priceBlock: "products",
          isLoading: false,
        });
      });

    window.addEventListener("scroll", () => {
      let scrollTop = $(window).scrollTop();

      let elementThree = $("#three");
      if (elementThree !== undefined) {
        let parent = elementThree.parent().offset();
        if (parent !== undefined) {
          let parentOffset = parent.top;
          elementThree.css("position", "relative");
          var finalDestination = scrollTop + 60 - parentOffset;
          var realDestination = 0;
          // var stopper = ($('.footer-container').offset().top);
          // var stopHere = stopper - elementThree.outerHeight() - parentOffset - 60;

          if (scrollTop + 60 > parentOffset) {
            if (finalDestination >= 586) {
              realDestination = 586;
            } else {
              realDestination = finalDestination;
            }
          }
          elementThree.stop().animate(
            {
              top: realDestination,
            },
            "fast"
          );
        }
      }

      let elementFour = $("#four");
      if (elementFour !== undefined) {
        let parent = elementFour.parent().offset();
        if (parent !== undefined) {
          let parentOffset4 = parent.top;
          elementFour.css("position", "relative");
          var finalDestination4 = scrollTop + 60 - parentOffset4;
          var realDestination4 = 0;
          if (scrollTop + 60 > parentOffset4) {
            if (finalDestination4 >= 727) {
              realDestination4 = 727;
            } else {
              realDestination4 = finalDestination4;
            }
          }
          elementFour.stop().animate(
            {
              top: realDestination4,
            },
            "fast"
          );
        }
      }
      let elementTwo = $("#two");
      if (elementTwo !== undefined) {
        let parent = elementTwo.parent().offset();
        if (parent !== undefined) {
          let parentOffset2 = parent.top;
          elementTwo.css("position", "relative");
          var finalDestination2 = scrollTop + 60 - parentOffset2;
          var realDestination2 = 0;
          if (scrollTop + 60 > parentOffset2) {
            if (finalDestination2 >= 832) {
              realDestination2 = 832;
            } else {
              realDestination2 = finalDestination2;
            }
          }
          elementTwo.stop().animate(
            {
              top: realDestination2,
            },
            "fast"
          );
        }
      }

      let elementOne = $("#one");
      if (elementOne !== undefined) {
        let parent = elementOne.parent().offset();
        if (parent !== undefined) {
          let parentOffset1 = parent.top;
          elementOne.css("position", "relative");
          var finalDestination1 = scrollTop + 60 - parentOffset1;
          var realDestination1 = 0;
          if (scrollTop + 60 > parentOffset1) {
            if (finalDestination1 >= 586) {
              realDestination1 = 586;
            } else {
              realDestination1 = finalDestination1;
            }
          }
          elementOne.stop().animate(
            {
              top: realDestination1,
            },
            "fast"
          );
        }
      }
    });
  }

  onChange = (e) => {
    this.setState({ size: e.target.value });
  };

  onSelect = (selectedKeys, info) => {
    console.log("selected", selectedKeys, info);
  };

  render() {
    const data = this.state.treeData.map((parent, index, arr) => {
      return (
        <Collapse
          style={{ fontFamily: "Avenir" }}
          className="collapse-product"
          expandIconPosition="right"
          ghost={true}
          bordered={false}
        >
          <Panel header={parent.title} key={parent.key} className="bg-collapse">
            <div
              style={{
                marginBottom: "20px",
                fontSize: "18px",
                color: "#737373",
              }}
            >
              {parent.children.map((child) => {
                return (
                  <div
                    className="category-wrapper"
                    key={child.key}
                    style={{
                      marginBottom: "20px",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div className="category-name" key={child.key}>
                      {" "}
                      {child.title}{" "}
                    </div>
                    <div className="category-price" key={child.key}>
                      {child.price != "" ? "$" + child.price : ""}
                    </div>
                  </div>
                );
              })}
            </div>
          </Panel>
          <div
            className="collapse-border"
            style={{
              borderBottom:
                arr.length - 1 !== index ? "1px solid #c4c4c4" : "none",
            }}
          ></div>

          {/* <hr style={{width: "34vw", background:"#c4c4c4",marginLeft:"10px"}}/> */}
        </Collapse>
      );
    });
    const { name, content } = MetaTagContents.PRICING_PAGE;
    return (
      <React.Fragment>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{MetaTagContents.PRICING_PAGE.title}</title>
          <link rel="canonical" href="https://all3d.ai/pricing" />
          <meta name={name} content={content} />
        </Helmet>

        {this.state.isLoading ? (
          <div className="loader-rol"></div>
        ) : (
          <div className="complete-page-wrapper">
            <NavBar />
            <Layout>
              <Content>
                <CookieConsentBanner />
                <Row style={{ marginBottom: "70px" }}>
                  {/* <div className='text-center pricing-header' style={{border: "1px solid #D93025",boxSizing:"border-box", borderRadius:"4px"}}> */}
                  <div
                    className="text-center pricing-header"
                    style={{ boxSizing: "border-box", borderRadius: "4px" }}
                  >
                    <div className="price-head-container">
                      <div className="desc-container">
                        <div className="pricing-text">Pricing</div>
                        <div className="pricing-desc">
                          We customise our prices according to our customers'
                          needs. Please{" "}
                          <Link
                            to={"/contact-us"}
                            target="_blank"
                            style={{
                              textDecoration: "underline",
                              color: "#D93025",
                            }}
                          >
                            Contact Us
                          </Link>{" "}
                          for volume and package pricing. We would love to work
                          with you!
                        </div>
                        <a href="/contact-us" target="_blank">
                          <button id="contact-us-button">
                            <span className="button-text">Contact Us</span>
                          </button>
                        </a>
                      </div>
                      <div className="contact-btn-container">
                        <img
                          src="/pricing.jpg"
                          alt="Puzzle Blocks"
                          style={{ width: "800px", height: "420px" }}
                        />
                      </div>
                    </div>

                    <div className="price-head-container-sm">
                      <img
                        src="/pricing-mobile.jpg"
                        alt="Puzzle Blocks Mobile"
                        style={{ width: "320px", height: "220px" }}
                      />
                      <div className="pricing-text">Pricing</div>
                      <div className="desc-container">
                        <div className="pricing-desc">
                          We customise our prices according to our customers'
                          needs. Please{" "}
                          <Link
                            to={"/contact-us"}
                            target="_blank"
                            style={{
                              textDecoration: "underline",
                              color: "#D93025",
                            }}
                          >
                            Contact Us
                          </Link>{" "}
                          for volume and package pricing. We would love to work
                          with you!
                        </div>
                        <a href="/contact-us" target="_blank">
                          <button id="contact-us-button">
                            <span className="button-text">Contact Us</span>
                          </button>
                        </a>
                      </div>
                    </div>
                  </div>
                </Row>

                {/* <Row style={{ margin:"0"}} className='row-small-screen'>
                <Col span={24}>
                  <SelectBoxMobile
                      this={this}
                      state={this.state.priceBlock}
                      id={"product"}
                      description={"Model your products with All3D at unbeatable prices with an unmatched quality."}
                      title={"Create Product Models"}
                      price={"$10"}/>

                    <div className={`detail ${this.state.priceBlock === "product" ? "visibility-block" : "visibility-none"}`}>
                      {data}
                      <div style={{borderBottom:"1px solid #c4c4c4",width:"100%",marginLeft:"auto",marginRight:"auto",marginTop:"45px",marginBottom:"30px"}}></div>
                    </div>

                  <SelectBoxMobile
                    this={this}
                    state={this.state.priceBlock}
                    id={"space"}
                    description={"Model your spaces with All3D at unbeatable prices with an unmatched quality."}
                    title={"Create Space Models"}
                    price={"$80"}/>
                    <div className={`${this.state.priceBlock === "space" ? "visibility-block" : "visibility-none"}`}>
                      {DATA.PRICE_DATA.SPACE.map((item) => {
                        return (
                          <PricesMobile title={item.name}
                          last={item.last}
                          price={item.price}/>
                        )
                      })}
                      <div style={{borderBottom:"1px solid #c4c4c4",width:"70%",marginLeft:"auto",marginRight:"auto",marginTop:"45px",marginBottom:"30px"}}></div>
                    </div>   

                  <SelectBoxMobile
                    this={this}
                    state={this.state.priceBlock}
                    id={"product-photography"}
                    description={"Model your product photography with All3D at unbeatable prices with an unmatched quality."}
                    title={"Create Product Photography"}
                    price={"$4"}/>
                   <div className={`${this.state.priceBlock === "product-photography" ? "visibility-block" : "visibility-none"}`}>
                      {DATA.PRICE_DATA.PRODUCT_PHOTOGRAPHY.map((item) => {
                        return (
                          <PricesMobile title={item.name}
                          last={item.last}
                          price={item.price}/>
                        )
                      })}
                      
                      <div style={{borderBottom:"1px solid #c4c4c4",width:"70%",marginLeft:"auto",marginRight:"auto",marginTop:"45px",marginBottom:"30px"}}>
                      </div>
                    </div>

                  <SelectBoxMobile
                    this={this}
                    state={this.state.priceBlock}
                    id={"product360"}
                    description={"Model your product 360 with All3D at unbeatable prices with an unmatched quality."}
                    title={"Create Product 360"}
                    price={"$20"}/>
                    <div className={`${this.state.priceBlock === "product360" ? "visibility-block" : "visibility-none"}`}>
                    {DATA.PRICE_DATA.PRODUCT_360.map((item) => {
                        return (
                          <PricesMobile title={item.name}
                          last={item.last}
                          price={item.price}/>
                        )
                      })}
                     
                      <div style={{borderBottom:"1px solid #c4c4c4",width:"70%",marginLeft:"auto",marginRight:"auto",marginTop:"45px",marginBottom:"30px"}}></div>
                    </div>

                  <SelectBoxMobile
                    this={this}
                    state={this.state.priceBlock}
                    id={"lifestyle-photography"}
                    description={"Model your lifestyle images with All3D at unbeatable prices with an unmatched quality."}
                    title={"Create Lifestyle Images"}
                    price={"$30"}/>
                    <div className={`${this.state.priceBlock === "lifestyle-photography" ? "visibility-block" : "visibility-none"}`}>
                    {DATA.PRICE_DATA.LIFESTYLE_PHOTOGRAPHY.map((item) => {
                        return (
                          <PricesMobile title={item.name}
                          last={item.last}
                          price={item.price}/>
                        )
                      })} 
                      
                      <div style={{borderBottom:"1px solid #c4c4c4",width:"70%",marginLeft:"auto",marginRight:"auto",marginTop:"45px",marginBottom:"30px"}}></div>
                    </div>       

                  <SelectBoxMobile
                    this={this}
                    state={this.state.priceBlock}
                    id={"lifestyle-360-3d"}
                    description={"Model your lifestyle 360 images and 3D tours with All3D at unbeatable prices with an unmatched quality."}
                    title={"Create Lifestyle 360 and 3D Tours"}
                    price={"$30"}/>
                    <div className={`${this.state.priceBlock === "lifestyle-360-3d" ? "visibility-block" : "visibility-none"}`}>
                    {DATA.PRICE_DATA.LIFESTYLE_360.map((item) => {
                        return (
                          <PricesMobile title={item.name}
                          last={item.last}
                          price={item.price}/>
                        )
                      })} 
                    </div>    
                  </Col>
              </Row>

              <Row gutter={[48, 8]} style={{flexWrap:"unset", margin:"0"}} className='row-large-screen'>
                <Col xxl={11} lg={10} className='price-box sticky-column' offset={0}>
                  <div>
                  <SelectBox
                  this={this}
                  state={this.state.priceBlock}
                  id={"products"}
                  id2={"product"}
                  title={"Create Product Models"}
                  price={"$10"}/>

                  <div className={`${this.state.priceBlock === "products" || this.state.priceBlock === "space" || this.state.priceBlock === "product" ? "" : 'border-class' }`}
                  style={{width:"34vw"}}></div>

                  <SelectBox
                  this={this}
                  state={this.state.priceBlock}
                  id={"space"}
                  title={"Create Space Models"}
                  price={"$80"}/>

                  <div className={`${this.state.priceBlock === "space" || this.state.priceBlock === "product-photography" ? "" : 'border-class' }`}
                  style={{width:"34vw"}}></div>

                  <SelectBox
                  this={this}
                  state={this.state.priceBlock}
                  id={"product-photography"}
                  title={"Create Product Photography"}
                  price={"$4"}/>

                  <div className={`${this.state.priceBlock === "product-photography" || this.state.priceBlock === "product360" ? "" : 'border-class' }`}
                  style={{width:"34vw"}}></div>

                  <SelectBox
                  this={this}
                  state={this.state.priceBlock}
                  id={"product360"}
                  title={"Create Product 360"}
                  price={"$20"}/>

                  <div className={`${this.state.priceBlock === "product360" || this.state.priceBlock === "lifestyle-photography" ? "" : 'border-class' }`}
                  style={{width:"34vw"}}></div>

                  <SelectBox
                  this={this}
                  state={this.state.priceBlock}
                  id={"lifestyle-photography"}
                  title={"Create Lifestyle Images"}
                  price={"$30"}/>

                  <div className={`${this.state.priceBlock === "lifestyle-360-3d" || this.state.priceBlock === "lifestyle-photography" ? "" : 'border-class' }`}
                  style={{width:"34vw"}}></div>

                  <SelectBox
                  this={this}
                  state={this.state.priceBlock}
                  id={"lifestyle-360-3d"}
                  title={"Create Lifestyle 360 and  3D Tours"}
                  price={"$30"}/>    
                  </div>
                  
                </Col>

                <Col xxl={11} lg={10} className="details" style={{display: (this.state.priceBlock === "products"  || this.state.priceBlock === "product" ? "block" : "none")}}>
                  <div><PricingBoxItem title={"Create Product Models"}
                  description={"Model your products with All3D at unbeatable prices with an unmatched quality."}
                  state={this.state.priceBlock}
                  />
                  {data}
                  </div>
                </Col>

                <Col xxl={11} lg={10} className="details" style={{display: (this.state.priceBlock === "space" ? "block" : "none")}}>
                  <div><PricingBoxItem title={"Create Space Models"}
                  description={"Model your spaces with All3D at unbeatable prices with an unmatched quality."}
                  />
                  {DATA.PRICE_DATA.SPACE.map((item) => {
                        return (
                          <Prices title={item.name}
                          last={item.last}
                          price={item.price}/>
                        )
                    })} 
                   </div>
                </Col>

                <Col xxl={11} lg={10} className="details" style={{display: (this.state.priceBlock === "product-photography" ? "block" : "none")}} >
                  <div id="one">
                    <PricingBoxItem title={"Create Product Photography"}
                  description={"Model your product's photos with All3D at unbeatable prices with an unmatched quality."}
                  />
                 {DATA.PRICE_DATA.PRODUCT_PHOTOGRAPHY.map((item) => {
                        return (
                          <Prices title={item.name}
                          last={item.last}
                          price={item.price}/>
                        )
                      })} 
                  </div>
                </Col>  

                <Col xxl={11} lg={10} className="details" style={{display: (this.state.priceBlock === "product360" ? "block" : "none")}} >
                  <div id="two">
                    <PricingBoxItem title={"Create Product 360"}
                  description={"Model your product 360 with All3D at unbeatable prices with an unmatched quality."}
                  />
                  {DATA.PRICE_DATA.PRODUCT_360.map((item) => {
                        return (
                          <Prices title={item.name}
                          last={item.last}
                          price={item.price}/>
                        )
                      })} 
                  </div>
                </Col>  

                <Col xxl={11} lg={10} className="details" style={{display: (this.state.priceBlock === "lifestyle-photography" ? "block" : "none")}} >
                <div id="three" >
                  <PricingBoxItem title={"Create Lifestyle Images"}
                  description={"Model your lifestyle images with All3D at unbeatable prices with an unmatched quality."}
                  />
                  {DATA.PRICE_DATA.LIFESTYLE_PHOTOGRAPHY.map((item) => {
                        return (
                          <Prices title={item.name}
                          last={item.last}
                          price={item.price}/>
                        )
                      })} 
                </div>
                </Col>  

                <Col xxl={11} lg={10} className="details" style={{display: (this.state.priceBlock === "lifestyle-360-3d" ? "block" : "none")}} >
                  <div id="four"><PricingBoxItem title={"Create Lifestyle 360 and 3D Tours"}
                  description={"Model your lifestyle 360 and 3D Tours with All3D at unbeatable prices with an unmatched quality."}
                  />
                  {DATA.PRICE_DATA.LIFESTYLE_360.map((item) => {
                        return (
                          <Prices title={item.name}
                          last={item.last}
                          price={item.price}/>
                        )
                      })} 
                  </div>
                </Col>  
              </Row> */}
              </Content>
            </Layout>
            <div className="abs-btm">
              <Footer />
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default PricingPage;
