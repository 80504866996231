import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import "./LifestylesFeed.scss";
import {
  Card,
  Layout,
  DatePicker,
  Select,
  Divider,
  Row,
  Col,
  Modal,
  Empty,
  Button,
  Input,
  Form,
  Spin,
  Image,
} from "antd";
import {
  ExpandAltOutlined,
  LoadingOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { Helmet } from "react-helmet";
import MetaTagContents from "../../MetaTagContents";
import axios from "axios";
import moment from "moment";
import {
  GET_CATEGORY_AND_PRICE,
  COMPANY_GET_BATCH,
  CUSTOMER_GET_BATCH,
  GET_LIFESTYLES_FEED,
} from "../../env";
import DottedLoader from "../DottedLoader/DottedLoader.jsx";

const DATE_FORMAT = "MM/DD/YYYY";
const { Option } = Select;
const { Content } = Layout;
const { RangePicker } = DatePicker;

const resolution_category_options = [
  "1K",
  "2K",
  "3K",
  "4K",
  "5K",
  "6K",
  "7K",
  "8K",
];

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
      color: "black",
    }}
    spin
  />
);

const pageSize = 100;

const LifestylesFeed = () => {
  const { Meta } = Card;
  const { name, content } = MetaTagContents.LIFESTYLES_FEED;
  const [spaceCategories, setSpaceCategories] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [users, setUsers] = useState([]);
  const [companiesData, setCompaniesData] = useState([]);
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format(DATE_FORMAT)
  );
  const [endDate, setEndDate] = useState(
    moment().endOf("month").format(DATE_FORMAT)
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isVerified, setIsVerified] = useState(null);
  const [isButtonLoading, setIsButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isVerifyingSession, setIsVerifyingSession] = useState(false);

  // ==> Authentication Logic

  const checkPasswordAndGetSecurityToken = (values) => {
    const admin_password = values.password;
    const payload = {
      action: "get_security_token",
      password: admin_password,
    };
    setErrorMessage(null);
    setIsButtonLoading(true);
    axios.post(GET_LIFESTYLES_FEED, payload).then((res) => {
      console.log(res.data);
      if (res.data.security_token) {
        localStorage.setItem("security_token", res.data.security_token);
        setIsVerified(true);
        setErrorMessage(null);
        setIsButtonLoading(false);
      } else {
        setIsButtonLoading(false);
        setErrorMessage("Wrong password Please try again!");
      }
    });
  };

  const verifySession = () => {
    const key = localStorage.getItem("security_token");
    const payload = {
      action: "check_session",
      security_token: key,
    };
    setIsVerified(false);
    setIsVerifyingSession(true);
    axios.post(GET_LIFESTYLES_FEED, payload).then((res) => {
      console.log(res.data);
      setIsVerifyingSession(false);
      if (res.data.session_verified == "true") {
        setIsVerified(true);
      } else {
        setIsVerified(false);
      }
    });
  };

  useEffect(() => {

    verifySession();
  }, []);

  // Authentication Logic <==

  const getSpaceCategoriesData = () => {
    let payload = {
      request_type: "room_model",
    };
    setIsLoading(true);
    axios.post(GET_CATEGORY_AND_PRICE, payload).then((res) => {
      let categories = [];
      if (res.data) {
        res.data.map((request) => {
          if (!categories.includes(request.category)) {
            categories.push(request.category);
          }
        });
        // remove the first empty element from Space Categories
        categories.shift();
        setSpaceCategories(categories);
        console.log("Space Categories", categories);
        setIsLoading(false);
      }
    });
  };

  const getCompaniesList = () => {
    let payload = {};
    setIsLoading(true);
    axios.post(COMPANY_GET_BATCH, payload).then((res) => {
      console.log("Companies Data", res.data);
      setCompaniesData(res.data);
      let companies = [];
      let uniqueCompanies = [];
      if (res.data) {
        res.data.map((request) => {
          if (!companies.includes(request.company_display_name)) {
            companies.push(request.company_display_name);
            uniqueCompanies = [...new Set(companies.map((item) => item))];
          }
        });

        // remove the company that has no company_display_name
        let emptyIndex = uniqueCompanies.indexOf("");
        if (emptyIndex != -1) {
          uniqueCompanies.splice(emptyIndex, 1);
        }

        setCompanies(uniqueCompanies);
        console.log("Companies List", uniqueCompanies);
        setIsLoading(false);
      }
    });
  };

  const getUsersList = () => {
    let payload = {};
    setIsLoading(true);
    axios.post(CUSTOMER_GET_BATCH, payload).then((res) => {
      let users = [];
      if (res.data) {
        res.data.map((request) => {
          if (!users.includes(request.customer_username)) {
            users.push(request.customer_username);
          }
        });
        setUsers(users);
        console.log("Users List", users);
        setIsLoading(false);
      }
    });
  };

  useEffect(() => {
    getSpaceCategoriesData();
    getCompaniesList();
    getUsersList();
  }, []);

  const onCalChange = (date_values) => {
    if (date_values && date_values[0] && date_values[1]) {
      setStartDate(date_values[0].format(DATE_FORMAT));
      setEndDate(date_values[1].format(DATE_FORMAT));
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{MetaTagContents.LIFESTYLES_FEED.title}</title>
        <link rel="canonical" href="https://all3d.ai/feed" />
        <meta name={name} content={content} />
      </Helmet>

      <Layout className="layout-container">
        {isVerifyingSession ? (
          <DottedLoader />
        ) : (
          <>
            {isVerified ? (
              <>
                {isLoading ? (
                  <DottedLoader />
                ) : (
                  <>
                    <WebVersion
                      startDate={startDate}
                      endDate={endDate}
                      onCalChange={onCalChange}
                      companiesData={companiesData}
                      companies={companies}
                      users={users}
                      spaceCategories={spaceCategories}
                    />
                  </>
                )}
              </>
            ) : (
              <>
                <Row>
                  <Col span={12}>
                    <img
                      className="branding-image"
                      src={"/login-branding.jpg"}
                      alt="ALL3D Brand"
                    />
                  </Col>
                  <Col span={12}>
                    <div className="form-container">
                      <div className="input-form custom-frm">
                        <Form
                          onFinish={checkPasswordAndGetSecurityToken}
                          className="login-form"
                        >
                          <Row className="justify-in-center mb-20">
                            <Link className="navbar-brand" to={"/"}>
                              <img
                                src="/logo-2.svg"
                                width="120px"
                                height="40px"
                                alt="ALL3D"
                              />
                            </Link>
                          </Row>
                          <Row>
                            <p
                              className="manrope f-14 black-33"
                              style={{ textAlign: "center" }}
                            >
                              Enter password to view ALL3D's
                              <br />
                              Lifestyles Feed.
                            </p>
                          </Row>
                          <Row>
                          <Form.Item
                            name="password"
                            className="justify-in-center"
                            rules={[
                              {
                                required: true,
                                message: "Please enter the password.",
                              },
                            ]}
                          >
                            <Input
                              prefix={
                                <LockOutlined
                                  style={{ color: "rgba(0,0,0,.25)" }}
                                />
                              }
                              className="password-field"
                              placeholder="Password"
                              type="password"
                            />
                          </Form.Item>
                          </Row>
                          <Form.Item>
                            <Button
                              type="primary"
                              htmlType="submit"
                              className="submit-btn mb-20"
                            >
                              Confirm &nbsp;
                              {isButtonLoading ? <LoadingOutlined spin /> : ""}
                            </Button>
                            {errorMessage ? (
                              <div className="text-danger text-center">
                                {errorMessage}
                              </div>
                            ) : (
                              ""
                            )}
                          </Form.Item>
                        </Form>
                      </div>
                    </div>
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      </Layout>
    </React.Fragment>
  );
};

const WebVersion = ({
  startDate,
  endDate,
  onCalChange,
  companiesData,
  companies,
  users,
  spaceCategories,
}) => {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedCompanyIDs, setSelectedCompanyIDs] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedSpaceCategory, setSelectedSpaceCategory] = useState(null);
  const [lifestyles, setLifestyles] = useState([]);
  const [isLoadingLifestyles, setIsLoadingLifestyles] = useState(false);
  const [selectedLifestyleRender, setSelectedLifestyleRender] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [companyName, setCompanyName] = useState(null);
  const [selectedResolutionCategory, setSelectedResolutionCategory] =
    useState("4K");
  const [isObservable, setIsObservable] = useState(false);
  const containerRef = useRef(null);
  const [offset, setOffset] = useState(0);
  const [isLoadingMoreData, setIsLoadingMoreData] = useState(false);
  const [allDataLoaded, setAllDataLoaded] = useState(false);
  const controller = new AbortController();

  const callBackFunction = (entries) => {
    const [entry] = entries;
    setIsObservable(entry.isIntersecting);
  };

  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0,
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callBackFunction, options);
    if (containerRef.current) observer.observe(containerRef.current);

    return () => {
      if (containerRef.current) observer.unobserve(containerRef.current);
    };
  }, [containerRef, options]);

  const setCompanyNameByID = (companyID) => {
    const filteredList = companiesData.filter(
      (company) => company.company_id == companyID
    );
    if (filteredList[0]) {
      setCompanyName(filteredList[0].company_display_name);
    } else {
      setCompanyName(null);
    }
  };

  const getCompanyIDs = (companyName) => {
    const filteredList = companiesData.filter(
      (company) => company.company_display_name == companyName
    );
    const IDs = filteredList.map((company) => company.company_id);
    return IDs;
  };

  const showModal = (render) => {
    render.request_link = render.request_link.replace("_512", "");
    setSelectedLifestyleRender(render);
    if (render.company_id) {
      setCompanyNameByID(render.company_id);
    }
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    setSelectedLifestyleRender(null);
    setCompanyName(null);
  };

  const updateOffset = (offset) => {
    let newOffset = offset + pageSize;
    setOffset(newOffset);
  };

  const getLifestylesFeed = () => {
    const start = moment(moment(startDate, "MM/DD/YYYY")).format("YYYY-MM-DD");
    const end = moment(moment(endDate, "MM/DD/YYYY")).format("YYYY-MM-DD");
    let payload = {
      action: "get_lifestyles_feed",
      start_date: start,
      end_date: end,
      pagination_filters: { limit: pageSize, offset: offset },
    };
    if (selectedCompany && selectedCompany != "all") {
      payload["company_id"] = selectedCompanyIDs;
    }
    if (selectedUser && selectedUser != "all") {
      payload["customer_username"] = selectedUser;
    }
    if (selectedSpaceCategory && selectedSpaceCategory != "all") {
      payload["space_category"] = selectedSpaceCategory;
    }
    if (selectedResolutionCategory && selectedResolutionCategory != "all") {
      payload["res_category"] = selectedResolutionCategory;
    }

    setIsLoadingLifestyles(true);
    setIsLoadingMoreData(true);
    axios
      .post(GET_LIFESTYLES_FEED, payload, {
        signal: controller.signal,
      })
      .then((res) => {
        setIsLoadingMoreData(false);
        if (res.data) {
          if (!res.data.length) {
            setAllDataLoaded(true);
            setIsLoadingLifestyles(false);
            return;
          }
          setAllDataLoaded(false);
          const renders = [...lifestyles, ...res.data];
          let lifestyleRenders = renders.map((render) => {
            return {
              ...render,
              request_link: render.request_link
                .replace(".tiff", ".jpg")
            };
          });

          let rendersList = lifestyleRenders.map(render=>{
            if (render.request_link.includes("_512.jpg") || render.request_link.includes("_512.png")) {
              return {...render};
            } else {
              if (render.request_link.includes(".png")){
                return {
                  ...render,
                  request_link: render.request_link.replace(".png", "_512.png"),
                };
              } else {
                return {
                  ...render,
                  request_link: render.request_link.replace(".jpg", "_512.jpg"),
                };
              }
            }
          })

          setLifestyles(rendersList);
          updateOffset(offset);
          setIsLoadingLifestyles(false);
        }
      });
  };

  useEffect(() => {
    if (!isLoadingMoreData && !allDataLoaded && offset == 0) {
      controller.abort();
      getLifestylesFeed();
    }
  }, [offset]);

  useEffect(() => {
    if (isObservable && !isLoadingMoreData && !allDataLoaded) {
      controller.abort();
      getLifestylesFeed();
    }
  }, [isObservable]);

  useEffect(() => {
    setLifestyles([]);
    setAllDataLoaded(false);
    setOffset(0);
    if (offset == 0) {
      getLifestylesFeed();
    }
  }, [
    startDate,
    endDate,
    selectedCompany,
    selectedUser,
    selectedSpaceCategory,
    selectedResolutionCategory,
  ]);

  return (
    <Content
      className="d-xl-block layout-snow"
      style={{ padding: "20px 20px 42px 20px" }}
    >
      <div>
        <Row className="justify-in-start mb-20">
          <Link className="navbar-brand" to={"/"}>
            <img src="/logo-2.svg" width="105px" height="25px" alt="ALL3D" />
          </Link>
          <Divider type="vertical" className="divider" />
          <div className="font manrope w-500 f-24 black-14">
            Lifestyles Feed
          </div>
        </Row>

        {companies.length > 0 &&
        users.length > 0 &&
        spaceCategories.length > 0 ? (
          <>
            <Row className="justify-in-start mb-20" gutter={12}>
              <Col span={5}>
                <RangePicker
                  style={{ width: "100%" }}
                  size={"medium"}
                  defaultValue={[
                    moment(startDate, DATE_FORMAT),
                    moment(endDate, DATE_FORMAT),
                  ]}
                  format={DATE_FORMAT}
                  className="date-picker"
                  popupStyle={{
                    fontFamily: "Manrope",
                    fontSize: 14,
                    color: "#40a9ff",
                    background: "#e6efff",
                  }}
                  placeholder={["Date (From)", "Date (To)"]}
                  value={[
                    moment(startDate, DATE_FORMAT),
                    moment(endDate, DATE_FORMAT),
                  ]}
                  onCalendarChange={(val) => onCalChange(val)}
                />
              </Col>
              <Col span={4} className="selector">
                <Select
                  showSearch
                  placeholder="Select Company"
                  size={"medium"}
                  style={{ width: "100%", marginRight: 20 }}
                  onChange={(val) => {
                    const IDs = getCompanyIDs(val);
                    setSelectedCompanyIDs(IDs);
                    setSelectedCompany(val);
                  }}
                  value={selectedCompany}
                  filterOption={(input, option) =>
                    (option?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  <Option
                    className="manrope f-14 black-55 select-view"
                    value="all"
                  >
                    All (Default)
                  </Option>
                  {companies.map((company) => (
                    <Option
                      className="manrope f-14 black-55 select-view"
                      value={company}
                    >
                      {company}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={4} className="selector">
                <Select
                  showSearch
                  placeholder="Select User"
                  size={"medium"}
                  style={{ width: "100%", marginRight: 20 }}
                  onChange={(val) => {
                    setSelectedUser(val);
                  }}
                  value={selectedUser}
                  filterOption={(input, option) =>
                    (option?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  <Option
                    className="manrope f-14 black-55 select-view"
                    value="all"
                  >
                    All (Default)
                  </Option>
                  {users.map((username) => (
                    <Option
                      className="manrope f-14 black-55 select-view"
                      value={username}
                    >
                      {username}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={4} className="selector">
                <Select
                  showSearch
                  placeholder="Select Space Category"
                  size={"medium"}
                  style={{ width: "100%", marginRight: 20 }}
                  onChange={(val) => {
                    setSelectedSpaceCategory(val);
                  }}
                  value={selectedSpaceCategory}
                  filterOption={(input, option) =>
                    (option?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  <Option
                    className="manrope f-14 black-55 select-view"
                    value="all"
                  >
                    All (Default)
                  </Option>
                  {spaceCategories.map((category) => (
                    <Option
                      className="manrope f-14 black-55 select-view"
                      value={category}
                    >
                      {category}
                    </Option>
                  ))}
                </Select>
              </Col>
              <Col span={4} className="selector">
                <Select
                  showSearch
                  placeholder="Select Resolution"
                  size={"medium"}
                  style={{ width: "100%", marginRight: 20 }}
                  onChange={(val) => {
                    setSelectedResolutionCategory(val);
                  }}
                  value={selectedResolutionCategory}
                  filterOption={(input, option) =>
                    (option?.children ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                >
                  <Option
                    className="manrope f-14 black-55 select-view"
                    value="all"
                  >
                    All (Default)
                  </Option>
                  {resolution_category_options.map((resolution) => (
                    <Option
                      className="manrope f-14 black-55 select-view"
                      value={resolution}
                    >
                      {resolution}
                    </Option>
                  ))}
                </Select>
              </Col>
            </Row>
          </>
        ) : (
          ""
        )}

        {isLoadingLifestyles && offset == 0 ? (
          <div className="justify-in-center">
            <DottedLoader />
          </div>
        ) : (
          <>
            {lifestyles.length > 0 ? (
              <>
                <Row gutter={[16, 16]} className="justify-in-start">
                  {lifestyles.map((render) => (
                    <Col span={4}>
                      <div className="outer-container">
                        <div className="inner-container">
                          <img className="img-cont" src={render.request_link} />
                          <div className="icon-container">
                            <ExpandAltOutlined
                              className="expand-icon"
                              onClick={() => {
                                showModal(render);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  ))}
                </Row>
                <div
                  className="loader-container justify-in-center"
                  ref={containerRef}
                >
                  {isLoadingMoreData ? (
                    <Spin indicator={antIcon} tip="Loading more renders" />
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              <div className="justify-in-center mt-40">
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
              </div>
            )}
          </>
        )}
      </div>
      <Modal
        maskClosable={false}
        centered
        onCancel={handleCancel}
        visible={isModalOpen}
        width={"fit-content"}
        bodyStyle={{ padding: 0 }}
        style={{
          padding: "20px 0px 20px 0px",
          minWidth: "25%",
          maxWidth: "90%",
        }}
        footer={null}
        className="img-modal"
      >
        {selectedLifestyleRender ? (
          <>
            <Image
              src={selectedLifestyleRender.request_link}
              className="render-img"
              preview={false}
              placeholder={
                <div className="img-loader justify-in-center">
                  <Spin indicator={antIcon} />
                </div>
              }
            />
            <div className="img-details-container font manrope w-400 f-12 black-33">
              {companyName ? (
                <div>Company: {companyName} </div>
              ) : ""}
              {selectedLifestyleRender.requested_by ? (
                selectedLifestyleRender.is_designer && selectedLifestyleRender.is_designer == true ?
                <div>Requested by: {selectedLifestyleRender.requested_by} (Designer)</div> :
                <div>Requested by: {selectedLifestyleRender.requested_by}</div>
              ) : 
              (
                <div>
                  Customer Username: {selectedLifestyleRender.customer_username}
                </div>
              )}
              {selectedLifestyleRender.space_category ? (
                <div>Category: {selectedLifestyleRender.space_category}</div>
              ) : (
                ""
              )}
              {selectedLifestyleRender.resolution ? (
                <div>Resolution: {selectedLifestyleRender.resolution}</div>
              ) : (
                ""
              )}
              <div>
                Date Created: &nbsp;
                {moment(
                  moment(selectedLifestyleRender.updated_date, "YYYY/MM/DD")
                ).format("DD 	MMMM, YYYY")}
              </div>
            </div>
          </>
        ) : (
          ""
        )}
      </Modal>
    </Content>
  );
};

export default LifestylesFeed;
