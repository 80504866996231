import React, { useState, useEffect, useCallback } from "react";
import { Row, Col, Tabs, Card, Layout, Tooltip, Image } from "antd";
import { Link } from "react-router-dom";
import CookieConsentBanner from "../cookieConsentBanner";
import NavBar from "../landingPage/header/navbar";
import Footer from "../landingPage/footer";
import "./index.scss";
import * as ENVIRONMENT from "../../env";
import axios from "axios";
import FileConstants from "../../FileConstants";
import MetaTagContents from "../../MetaTagContents";
import SpaceDetailComponent from "./SpaceDetailComponent";
import { Helmet } from "react-helmet";

const { Content } = Layout;
const { TabPane } = Tabs;

const SpaceDetails = (props) => {
  const [spaceDetails, setSpaceDetails] = useState(null);
  const [loader, setLoader] = useState(true);
  const [schemaMarkup, setSchemaMarkup] = useState({})
  const {
    match: { params },
  } = props;

  const fetchSpace = () => {
    let payload = {
      room_id: params.id,
    };

  /**
   * @param {*} product 
   * Schema Markup for SEO
   */
   const createSchemaMarkup = (space) => {
    const ldJson = {
      "@context": "https://schema.org/", 
      "@type": "Product", 
      "description": space.category,
      "image": space.thumbnail,
      "name": space.room_name,
      "offers": {
        "@type": "Offer",
        "url": `https://all3d.ai/product-assets/${params.id}`,
        "availability": "https://schema.org/OnlineOnly"
      }
    }
    setSchemaMarkup(ldJson)
  }

    axios.post(ENVIRONMENT.FETCH_ROOM, payload).then((res) => {
      console.log(res.data);
      setSpaceDetails(res.data);
      setLoader(false);
      createSchemaMarkup(res.data)
    });
  };

  useEffect(() => {
    fetchSpace();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let elmnt = document.getElementsByClassName("product-row");
      if (elmnt && elmnt[0] && elmnt[0].offsetHeight) {
        let updatedElem =
          window.document.getElementsByClassName("navbar-light");
        if (updatedElem && updatedElem[0]) {
          if (window.scrollY > elmnt[0].offsetHeight) {
            updatedElem[0].classList.add("full-opacity");
          } else {
            updatedElem[0].classList.remove("full-opacity");
          }
        }
      }
    });
  }, []);
  const { name, content } = MetaTagContents.SPACE_DETAILS;
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{MetaTagContents.SPACE_DETAILS.title}</title>
        <link rel="canonical" href="https://all3d.ai/room-assets/:id" />
        <meta name={name} content={content} />
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>
      {loader ? (
        <div className="loader-rol"></div>
      ) : (
        <React.Fragment>
          <NavBar />
          <Layout>
            <Content>
              <CookieConsentBanner />
              <Row
                type="flex"
                justify="space-around"
                style={{ marginTop: "100px" }}
              >
                <Col lg={20} md={22} sm={22} xs={22}>
                  <div className="pdp-visible">
                    {spaceDetails && spaceDetails.model_status == 5 ? (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                        className="product-row"
                      >
                        <h2
                          className="manrope f-14 w-600 upper-case"
                          style={{
                            textTransform: "uppercase",
                            marginBottom: "10px",
                          }}
                        >
                          <Link id="store-bd" to={"/store"}>
                            Store
                          </Link>{" "}
                          /{" "}
                          <span style={{ color: "#D93025" }}>
                            {spaceDetails.category}
                          </span>
                        </h2>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <SpaceDetailComponent spaceDetails={spaceDetails} />
                  <div className="pdp-visible">
                    <Row
                      type="flex"
                      style={{
                        justifyContent: "space-between",
                        marginTop: "16px",
                      }}
                    >
                      <h3 className="manrope f-20 black-33 clamp-text w-60 capitalize">
                        {spaceDetails.room_name}
                      </h3>

                      {spaceDetails.price == 0 ? (
                        <h3 className="manrope f-20 black-33">Free</h3>
                      ) : spaceDetails.company_id && FileConstants.ALL3D_COMPANY_IDS().includes(spaceDetails.company_id) && spaceDetails.price && spaceDetails.price != 0 ? (
                        <h3 className="manrope f-20 black-33">
                          Included in platform fee
                        </h3>
                      ) : spaceDetails.price ? (
                        <h3 className="manrope f-20 black-33">
                          {"Price: $" + spaceDetails.price.toString()}{" "}
                        </h3>
                      ): ''}
                    </Row>

                    <Row
                      type="flex"
                      style={{
                        justifyContent: "space-between",
                        marginTop: "0px",
                        alignItems: "center",
                      }}
                    >
                      <Col span={14}>
                        <div
                          className="justify-in-start"
                          style={{ flexWrap: "wrap", alignItems: "flex-start" }}
                        >
                          {spaceDetails.category ? (
                            <Col span={6} style={{ marginBottom: "10px" }}>
                              <div
                                className="manrope f-12 grey-99"
                                style={{
                                  marginBottom: "4px",
                                  textAlign: "left",
                                }}
                              >
                                Category
                              </div>
                              <div className="manrope f-12 black-33 w-600">
                                {spaceDetails.category}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {spaceDetails &&
                          spaceDetails.licensing_options &&
                          spaceDetails.licensing_options.license ? (
                            <Col span={6} style={{ marginBottom: "10px" }}>
                              <div
                                className="manrope f-12 grey-99"
                                style={{
                                  marginBottom: "4px",
                                  textAlign: "left",
                                }}
                              >
                                License
                              </div>
                              <div className="manrope f-12 black-33 w-600 capitalize">
                                {spaceDetails.licensing_options.license ==
                                "private"
                                  ? spaceDetails.licensing_options.license
                                  : ""}
                                {spaceDetails.licensing_options.license ==
                                "public" ? (
                                  spaceDetails.licensing_options
                                    .public_option == "forCreativeCommon" ? (
                                    <Tooltip
                                      title={
                                        <span className="manrope f-12 white">
                                          {
                                            spaceDetails.licensing_options
                                              .creative_common
                                          }
                                        </span>
                                      }
                                    >
                                      <img
                                        style={{ marginLeft: 5 }}
                                        alt="License icon"
                                        src={FileConstants.getLicensingIcon(
                                          spaceDetails.licensing_options
                                            .creative_common
                                        )}
                                      />
                                    </Tooltip>
                                  ) : (
                                    <span>
                                      <Tooltip
                                        title={
                                          <span className="manrope f-12 white">
                                            Only For ALL3D Usage
                                          </span>
                                        }
                                      >
                                        <img
                                          alt="License icon"
                                          src={FileConstants.getLicensingIcon(
                                            spaceDetails.licensing_options
                                              .public_option
                                          )}
                                        />
                                      </Tooltip>
                                      &nbsp;
                                      <Tooltip
                                        title={
                                          <span className="manrope f-12 white">
                                            Not Downloadable
                                          </span>
                                        }
                                      >
                                        <img
                                          alt="Can't Download"
                                          src="/not_downloadable.png"
                                        />
                                      </Tooltip>
                                    </span>
                                  )
                                ) : (
                                  ""
                                )}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                          {spaceDetails &&
                          spaceDetails.tags &&
                          spaceDetails.tags.length > 0 ? (
                            <Col span={6} style={{ marginBottom: "10px" }}>
                              <div
                                className="manrope f-12 grey-99"
                                style={{ textAlign: "left", marginBottom: 4 }}
                              >
                                Tags
                              </div>
                              <div
                                className="justify-in-start"
                                style={{ flexWrap: "wrap" }}
                              >
                                {spaceDetails.tags.map((tag, index) => (
                                  <span className="manrope f-12 black-33 w-600">
                                    {index > 0 ? `, ${tag}` : `${tag}`}
                                  </span>
                                ))}
                              </div>
                            </Col>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>
                    </Row>

                    <hr />
                    <Row
                      type="flex"
                      style={{
                        justifyContent: "space-between",
                        marginTop: "0px",
                        alignItems: "center",
                      }}
                    >
                      <Col span={16}>
                        <div
                          className="justify-in-start"
                          style={{ alignItems: "flex-start" }}
                        >
                          {spaceDetails.floorplan &&
                          spaceDetails.floorplan[0] ? (
                            <div style={{ marginBottom: "10px" }}>
                              <div
                                className="manrope f-14 grey-99"
                                style={{
                                  marginBottom: "4px",
                                  textAlign: "left",
                                }}
                              >
                                Floorplan
                              </div>
                              <Row>
                                <Col>
                                  <Card className="reference-img-card">
                                    <Image
                                      className="reference-img-photo"
                                      src={
                                        ENVIRONMENT.BASE_URL +
                                        encodeURIComponent(
                                          spaceDetails.floorplan[0].uid +
                                            "/" +
                                            spaceDetails.floorplan[0].name
                                        )
                                      }
                                    />
                                  </Card>
                                </Col>
                              </Row>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Content>
          </Layout>
          <div className="assets-visible">
            <Row type="flex" justify="space-around">
              <Col id="centerAlign" span={24}>
                <CookieConsentBanner />

                <Row
                  type="flex"
                  style={{ justifyContent: "space-between", marginTop: 0 }}
                  className="margin-class"
                >
                  <h3 className="product-name-h3">{spaceDetails.room_name}</h3>
                </Row>
                <Row
                  type="flex"
                  style={{ justifyContent: "space-between", marginTop: 10 }}
                  className="margin-class"
                >
                  {spaceDetails.price ? (
                    <h3 className="manrope f-20 green-75 w-500">
                      {spaceDetails.price}{" "}
                    </h3>
                  ) : (
                    <h3 className="manrope f-20 green-75 w-500">Free</h3>
                  )}
                </Row>
                <hr className="margin-class-hr" />
                <Row
                  type="flex"
                  style={{ justifyContent: "flex-start" }}
                  className="margin-class"
                >
                  <h3 className="manrope f-18 black-55 w-600">Basic Details</h3>
                </Row>
                {spaceDetails && spaceDetails.category ? (
                  <Row
                    type="flex"
                    style={{ justifyContent: "space-between" }}
                    className="margin-class"
                  >
                    <h3
                      className="product-detail-list"
                      style={{ marginRight: "40px" }}
                    >
                      Category
                    </h3>
                    <h3
                      className="product-detail-list"
                      style={{ fontWeight: "bold", color: "#333333" }}
                    >
                      {spaceDetails.category}
                    </h3>
                  </Row>
                ) : (
                  ""
                )}

                {spaceDetails &&
                spaceDetails.licensing_options &&
                spaceDetails.licensing_options.license ? (
                  <Row
                    type="flex"
                    style={{ justifyContent: "space-between" }}
                    className="margin-class"
                  >
                    <h3
                      className="product-detail-list"
                      style={{ marginRight: "40px" }}
                    >
                      License
                    </h3>
                    <h3
                      className="product-detail-list"
                      style={{ fontWeight: "bold", color: "#333333" }}
                    >
                      {spaceDetails.licensing_options.license == "private"
                        ? spaceDetails.licensing_options.license
                        : ""}{" "}
                      {spaceDetails.licensing_options.license == "public" ? (
                        spaceDetails.licensing_options.public_option ==
                        "forCreativeCommon" ? (
                          <Tooltip
                            title={
                              <span className="manrope f-12 white">
                                {spaceDetails.licensing_options.creative_common}
                              </span>
                            }
                          >
                            <img
                              alt="License icon"
                              style={{ marginLeft: 5 }}
                              src={FileConstants.getLicensingIcon(
                                spaceDetails.licensing_options.creative_common
                              )}
                            />
                          </Tooltip>
                        ) : (
                          <span>
                            <Tooltip
                              title={
                                <span className="manrope f-12 white">
                                  Only For ALL3D Usage
                                </span>
                              }
                            >
                              <img
                                alt="License icon"
                                src={FileConstants.getLicensingIcon(
                                  spaceDetails.licensing_options.public_option
                                )}
                              />
                            </Tooltip>
                            &nbsp;
                            <Tooltip
                              title={
                                <span className="manrope f-12 white">
                                  Not Downloadable
                                </span>
                              }
                            >
                              <img
                                alt="Can't Download"
                                src="/not_downloadable.png"
                              />
                            </Tooltip>
                          </span>
                        )
                      ) : (
                        ""
                      )}
                    </h3>
                  </Row>
                ) : (
                  ""
                )}

                {spaceDetails &&
                spaceDetails.tags &&
                spaceDetails.tags.length > 0 ? (
                  <Row
                    type="flex"
                    style={{ justifyContent: "flex-start" }}
                    className="margin-class"
                  >
                    <div>
                      <h3
                        className="product-detail-list"
                        style={{ textAlign: "left" }}
                      >
                        <div style={{ marginBottom: "10px" }}>Tags</div>
                      </h3>
                      <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {spaceDetails.tags.map((tag) => {
                          return (
                            <div className="bg-tag btm-10">
                              <span
                                style={{ color: "#333333", fontSize: "16px" }}
                              >
                                {tag}
                              </span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </Row>
                ) : (
                  ""
                )}

                <hr className="margin-class" />

                {spaceDetails.floorplan && spaceDetails.floorplan[0] && (
                  <Row
                    type="flex"
                    style={{ justifyContent: "flex-start" }}
                    className="margin-class"
                  >
                    <h3 className="manrope f-18 black-55 w-600">
                      Space Model Information
                    </h3>
                  </Row>
                )}

                {spaceDetails.floorplan && spaceDetails.floorplan[0] ? (
                  <Row
                    type="flex"
                    style={{ justifyContent: "flex-start" }}
                    className="margin-class"
                  >
                    <div>
                      <h3
                        className="product-detail-list"
                        style={{ textAlign: "left" }}
                      >
                        <div style={{ marginBottom: "10px" }}>Floorplan</div>
                      </h3>
                      <Row className="justify-in-start">
                        <Col>
                          <Card className="product-photo-card floorplan">
                            <Image
                              className="product-photo-image floorplan"
                              src={
                                ENVIRONMENT.BASE_URL +
                                encodeURIComponent(
                                  spaceDetails.floorplan[0].uid +
                                    "/" +
                                    spaceDetails.floorplan[0].name
                                )
                              }
                            />
                          </Card>
                        </Col>
                      </Row>
                    </div>
                  </Row>
                ) : (
                  ""
                )}

                {/* {spaceDetails.elements && spaceDetails.elements.length > 0 ?
                        <Row type="flex" style={{ justifyContent: "flex-start"}}  className="margin-class">
                            <div>

                                <h3 className="product-detail-list" style={{textAlign:"left"}}>Space Elements</h3>

                                <div className="j-start-a-start " style={{flexWrap: 'wrap'}}>
                                {spaceDetails.elements.map(element => (
                                    element ? 
                                    <Col>
                                        <div className="bg-tag" style={{width:'fit-content'}}>
                                            <span className="manrope f-16 black-33 capitalize">
                                                {element.elementName}
                                            </span>
                                        </div>
                                        {((element.elementImages && element.elementImages.length > 0) || ((element.elementText && !Array.isArray(element.elementText)) || (element.elementText && Array.isArray(element.elementText) && element.elementText.length > 0)  )) && 
                                        <Card className="product-photo-card w-auto" style={{padding: 16, marginTop: 8}}>
                                            {element.elementImages && element.elementImages.length > 0 && <div>
                                                <div className="manrope f-16 grey-77" style={{marginBottom: 8}}>Reference Images</div>
                                                <div className="justify-in-start">
                                                {element.elementImages.map(photo => (
                                                    <Card className="product-photo-card">
                                                        <Image
                                                        className="product-photo-image" src={ENVIRONMENT.BASE_URL + encodeURIComponent(photo.uid + '/' + photo.name)}
                                                        />
                                                    </Card>
                                                ))}
                                                </div>
                                                
                                            </div>}
                                            {element.elementText && !Array.isArray(element.elementText) ?
                                             <div>
                                                <div className="manrope f-16 grey-77" style={{marginBottom: 8}}>Reference URLs</div>
                                                <div className="manrope f-14 blue hover-change clamp-w-width" style={{width:"110px"}} >{element.elementText}</div>
                                            </div>: 
                                            element.elementText && Array.isArray(element.elementText) && element.elementText.length > 0 ?
                                            <div>
                                                <div className="manrope f-16 grey-77" style={{marginBottom: 8}}>Reference URLs</div>
                                                {element.elementText.map((element_text) => (
                                                    <div style={{display:'block',marginBottom: 5,width:"110px"}} className="manrope f-14 blue hover-change clamp-w-width">{element_text}</div>
                                                ))}
                                            </div> :''}
                                        </Card>}
                                    </Col> : ''

                                ))}
                                </div>
                            </div>
                        </Row>: ''} */}

                {/* {spaceDetails.outside_view_custom && spaceDetails.outside_view_custom.length > 0 ?
                            <Row type="flex" style={{ justifyContent: "flex-start"}} className="margin-class">
                                <div>
                                <h3 className="product-detail-list" style={{textAlign:"left"}}><div style={{marginBottom:"10px"}}>Outside View</div></h3>

                                <Row className='justify-in-start'>
                                    {spaceDetails.outside_view_custom.map(photo => (
                                        <Col>
                                            <Card className="product-photo-card">
                                                
                                                <Image
                                                className="product-photo-image" src={ENVIRONMENT.BASE_URL + encodeURIComponent(photo.uid + '/' + photo.name)}
                                                />
                                                        
                                            </Card>
                                        </Col>

                                    ))}
                                </Row>
                            </div>
                            </Row>: ''} */}
                {/* {spaceDetails.outside_view && spaceDetails.outside_view.image  ?
                            <Row type="flex" style={{ justifyContent: "flex-start"}} className="margin-class">
                                <div>
                                    <h3 className="product-detail-list" style={{textAlign:"left"}}><div style={{marginBottom:"10px"}}>Outside View</div></h3>

                                    <Row className="justify-in-start">
                                        <Col>
                                            <Card className="product-photo-card">
                                                
                                                <Image
                                                className="product-photo-image" src={spaceDetails.outside_view.image}
                                                />
                                                        
                                            </Card>
                                        </Col>  
                                    </Row>
                                </div>
                            </Row>: ''} */}
              </Col>
            </Row>
          </div>

          <div className="abs-btm">
            <Footer />
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default SpaceDetails;
