import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Input,
  Pagination,
  Select,
  Button,
  Drawer,
  Tag,
  TreeSelect,
  Checkbox,
  Tooltip,
  Tabs,
  Slider,
  Radio,
  Breadcrumb,
  Empty,
} from "antd";
import { withRouter } from "react-router-dom";
import { ArrowRightOutlined, DownOutlined } from "@ant-design/icons";
import { connect } from "react-redux";
import NavBar from "../landingPage/header/navbar";
import Footer from "../landingPage/footer";
import { LoadingOutlined } from "@ant-design/icons";
import { listStoreProducts, LIST_STORE_PRODUCT } from "../../redux/actions";
import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroll-component";
import CookieConsentBanner from "../cookieConsentBanner";
import axios from "axios";
import $ from "jquery";
import FileConstants from "../../FileConstants";
import TextConstants from "../../TextConstants";
import MetaTagContents from "../../MetaTagContents";
import * as Utilities from "../../Utilities";
import * as ENVIRONMENT from "../../env";
import {
  ADMIN_CATEGORY_GET_BATCH,
  COMPANY_GET_BATCH,
  GET_CATEGORY_AND_PRICE,
  GET_STORE_ITEMS,
  TEMPLATE_ACTIONS,
  BASE_URL,
} from "../../env";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./index.scss";
import DottedLoader from "../DottedLoader/DottedLoader.jsx";
import StoreContent from "./StoreContent";

const { Search } = Input;
const { Option } = Select;
const { TabPane } = Tabs;

const categoryStyle = {
  maxHeight: 400,
  overflow: "auto",
  fontFamily: "Manrope",
  fontSize: 12,
  color: "#555555",
};

const responsive = {
  screen_6: {
    breakpoint: { max: 4000, min: 1920 },
    items: 9,
  },
  screen_7: {
    breakpoint: { max: 1920, min: 1700 },
    items: 9,
  },
  screen_8: {
    breakpoint: { max: 1700, min: 1525 },
    items: 7,
  },
  screen_1: {
    breakpoint: { max: 1525, min: 1475 },
    items: 7,
  },
  screen_5: {
    breakpoint: { max: 1475, min: 1280 },
    items: 6,
  },
  screen_2: {
    breakpoint: { max: 1280, min: 1024 },
    items: 5,
  },
  screen_3: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
  },
  screen_4: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const Store = (props) => {

  let baseitemType = new URL(window.location.href).searchParams.get("item_type");
  let selectedDisplayType = new URL(window.location.href).searchParams.get("item_type");
  if (baseitemType == undefined || !(['products','spaces','scenes'].includes(baseitemType))) {
    baseitemType = 'products'
  }
  const [searchValue, setSearchValue] = useState("");
  const [offset, setOffset] = useState(24);
  const [array, setArray] = useState([]);
  const [length, setLength] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [storeState, setStoreState] = useState(true);
  const [companyData, setCompanyData] = useState(null);
  const [brandLoader, setBrandLoader] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [brandCompanyData, setBrandCompanyData] = useState(null);
  const [storeData, setStoreData] = useState(null);
  const [itemType, setItemType] = useState(baseitemType);
  const [loader, setLoader] = useState(false);
  const [productModels, setProductModels] = useState(null);
  const [spaceModels, setSpaceModels] = useState(null);
  const [filtersDrawer, setFiltersDrawer] = useState(false);
  const [productCategoriesData, setProductCategoriesData] = useState([]);
  const [productCategoryValue, setProductCategoryValue] = useState([]);
  const [categorySearchValue, setCategorySearchValue] = useState([]);
  const [companyFilter, setCompanyFilter] = useState([]);
  const [productModelType, setProductModelType] = useState([]);
  const [filtersApplied, setFiltersApplied] = useState(false);
  const [spaceCategoryValue, setSpaceCategoryValue] = useState([]);
  const [styleCategoryValue, setStyleCategoryValue] = useState([]);
  const [spaceCategories, setSpaceCategories] = useState([]);
  const [pageLoad, setPageLoad] = useState(true);
  const [showLoader, setShowLoader] = useState(false);
  const [, updateState] = React.useState();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const [maxPriceProduct, setMaxPriceProduct] = useState(0);
  const [maxPriceSpace, setMaxPriceSpace] = useState(0);
  const [maxPrice, setMaxPrice] = useState(0);
  const [minPrice, setMinPrice] = useState(0);
  const [featuredBrands, setFeaturedBrands] = useState([]);
  const [selectedDisplay, setSelectedDisplay] = useState(selectedDisplayType == 'brands' ? selectedDisplayType : baseitemType);
  const [brandsSelected, setBrandsSelected] = useState(false);
  const [itemCount, setItemCount] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(33);
  const [initialLoadProducts, setInitialLoadProducts] = useState(false);
  const [initialLoadSpaces, setInitialLoadSpaces] = useState(false);
  const [carousel, setCarousel] = useState(null);
  const [productLoad, setProductLoad] = useState(true);
  const [spacesLoad, setSpacesLoad] = useState(true);
  const [showBrandsSpaces, setShowBrandsSpaces] = useState(false);
  const [showBrandsProducts, setShowBrandsProducts] = useState(false);
  const [logoWidth, setLogoWidth] = useState(0);
  const [comingSoonCompanies, setComingSoonCompanies] = useState([]);
  const [loadingInitialProducts, setLoadInitialProducts] = useState(true);
  const [loadingInitialSpaces, setLoadInitialSpaces] = useState(true);
  const [loadingInitialScenes, setLoadInitialScenes] = useState(true);
  const [entityScenePrice, setEntityScenePrice] = useState(-1);
  const [maxPriceScene, setMaxPriceScene] = useState(0);
  const [templateLoader, setTemplateLoader] = useState(false);
  const [initialLoadScenes, setInitialLoadScenes] = useState(false);
  const [templateScenes, setTemplateScenes] = useState([]);
  const [templateProductCategories, setTemplateProductCategories] = useState([]);
  const [selectedProductCategory, setSelectedProductCategory] = useState([]);
  const [productParentCateogries, setProductParentCateogries] = useState({});
  const [productStyleCategory, setProductStyleCategory] = useState([])
  const [colorValue, setColorValue] = useState([]);
  const [productMaterialsData, setProductMaterialsData] = useState([]);
  const [productMaterials, setProductMaterials] = useState([]);
  const [materialsCategoryValueWithChilds, setMaterialsCategoryValueWithChilds] = useState([]);
  const [productCategoryValueWithChilds, setProductCategoryValueWithChilds] = useState([]);
  const [searchKeywords, setSearchKeywords] = useState([]);
  const {
    match: { params },
  } = props;

  useEffect(() => {
    setModelsData();
  }, [itemType, productModels, spaceModels, templateScenes]);

  useEffect(() => {
    if (initialLoadProducts) {
      populateProducts();
    }
    if (initialLoadSpaces) {
      populateSpaces();
    }
    if (initialLoadScenes) {
      populateScenes();
    }
  }, [initialLoadProducts, initialLoadSpaces, initialLoadScenes]);

  useEffect(() => {
    if (itemType == "products") {
      setMaxPrice(maxPriceProduct);
    } else if (itemType == "spaces") {
      setMaxPrice(maxPriceSpace);
    } else if (itemType == 'scenes') {
      setMaxPrice(maxPriceScene);
    }
  }, [maxPriceProduct, maxPriceSpace, maxPriceScene, itemType]);

  const setModelsData = () => {
    if (itemType == "products") {
      if (storeState) {
        //if models state is selected
        productDataLoad();
      } else {
        //if explore brands is clicked
        brandProductDataLoad();
      }
    } else if (itemType == "spaces") {
      if (storeState) {
        //if models state is selected
        spaceDataLoad();
      } else {
        //if explore brands is clicked
        brandSpaceDataLoad();
      }
    } else if (itemType == "scenes") {
      if (storeState) {
        //if models state is selected and not brand state
        sceneDataLoad();
      } else {
        //if explore brands is clicked
        brandSceneDataLoad();
      }
    }
  };

  const loadFeaturedBrandsData = () => {
    let product_domains = extractCompanies("products");
    let space_domains = extractCompanies("spaces");
    let brands = product_domains.concat(space_domains);
    var brands_union = brands.filter(
      (item, pos) => brands.indexOf(item) === pos
    );

    let count = {};
    let product_brands_count = setForBrand(productModels);
    for (const [key, value] of Object.entries(product_brands_count)) {
      count[key] = value.length;
    }

    let space_brands_count = setForBrand(spaceModels);
    for (const [key, value] of Object.entries(space_brands_count)) {
      count[key] = count[key] + value.length;
    }

    if (templateScenes && templateScenes.length > 0) {
      let scene_brands_count = setForBrand(templateScenes);
      for (const [key, value] of Object.entries(scene_brands_count)) {
        count[key] = count[key] + value.length;
      }
    }

    let coming_soon_companies = [];
    let featured_companies = [];
    if (companyData.length > 0) {
      companyData.map((company, index) => {
        featured_companies.push(company.company_domain);
        if (!count[company.company_domain]) {
          if (
            (!loadingInitialProducts && itemType == "products") ||
            (!loadingInitialSpaces && itemType == "spaces") ||
            (!loadingInitialScenes && itemType == "scenes")
          ) {
            coming_soon_companies.push(company.company_domain);
          }
          count[company.company_domain] = 0;
        }
      });
    }
    setComingSoonCompanies(coming_soon_companies);
    brands_union = featured_companies.filter(
      (item, pos) => featured_companies.indexOf(item) === pos
    );
    setItemCount(count);
    setFeaturedBrands(brands_union);
  };

  useEffect(() => {
    if (
      spaceModels &&
      spaceModels.length > 0 &&
      productModels &&
      productModels.length > 0 &&
      companyData &&
      companyData.length > 0
    ) {
      loadFeaturedBrandsData();
      forceUpdate();
    }
  }, [
    spaceModels,
    productModels,
    companyData,
    loadingInitialProducts,
    loadingInitialSpaces,
    templateScenes,
  ]);

  const productDataLoad = () => {
    //load data for product models by defaut and reset filters
    setStoreData(productModels);
    let product = setForBrand(productModels);
    setBrandCompanyData(product);
    setSpaceCategoryValue([]);
    setStyleCategoryValue([]);
  };

  const sceneDataLoad = () => {
    //load data for scene model by default and reset filters
    setStoreData(templateScenes);
    let scene = setForBrand(templateScenes);
    setBrandCompanyData(scene);
    setProductModelType([]);
  };

  const spaceDataLoad = () => {
    //load data for space model by default and reset filters
    setStoreData(spaceModels);
    let space = setForBrand(spaceModels);
    setBrandCompanyData(space);
    setProductCategoryValue([]);
    setProductModelType([]);
    setStyleCategoryValue([]);
  };

  const brandProductDataLoad = () => {
    //load data for product models
    let product = setForBrand(productModels);
    setBrandCompanyData(product);
    setSpaceCategoryValue([]);
    setStyleCategoryValue([]);
    if (selectedCompany) {
      setSelectedCompany(selectedCompany);
      if (product && product[selectedCompany.company_domain]) {
        setStoreData(product[selectedCompany.company_domain]);
      } else {
        setStoreData([]);
      }
    }
  };

  const brandSpaceDataLoad = () => {
    //load data for space models
    let space = setForBrand(spaceModels);
    setBrandCompanyData(space);
    setProductCategoryValue([]);
    setProductModelType([]);
    setStyleCategoryValue([]);
    if (selectedCompany) {
      setSelectedCompany(selectedCompany);
      if (space && space[selectedCompany.company_domain]) {
        setStoreData(space[selectedCompany.company_domain]);
      } else {
        setStoreData([]);
      }
    }
  };

  const brandSceneDataLoad = () => {
    //load data for scene models
    let scene = setForBrand(templateScenes);
    setBrandCompanyData(scene);
    setProductModelType([]);
    if (selectedCompany) {
      setSelectedCompany(selectedCompany);
      if (scene && scene[selectedCompany.company_domain]) {
        setStoreData(scene[selectedCompany.company_domain]);
      } else {
        setStoreData([]);
      }
    }
  };

  useEffect(() => {
    let brand_name = props.match.params.brand;
    if (brand_name && pageLoad) {
      setBrandsSelected(true);
      let baseitemType = new URL(window.location.href).searchParams.get("item_type");
      let selectedDisplayType = new URL(window.location.href).searchParams.get("item_type");

      if (baseitemType == undefined || !(['products','spaces','scenes'].includes(baseitemType))) {
        baseitemType = 'products'
      }
      setSelectedDisplay(selectedDisplayType == 'brands' ? selectedDisplayType : baseitemType);
      setItemType(baseitemType);
      if ((brandCompanyData && Object.keys(brandCompanyData).length > 0) && (companyData && companyData.length > 0)) {
        let selected_company = companyData && companyData.filter((company) => {
          return company.company_domain.includes(brand_name) == true;
        });
        setStoreState(!storeState);
        setSelectedCompany(selected_company[0]);

        //calculate new width of the logo according to their aspect ratio
        let aspect_ratio = 0;
        const img = new Image();
        img.onload = function() {
            aspect_ratio = 200 / this.height;
            let new_width = aspect_ratio * this.height;
            new_width = new_width/10;
            setLogoWidth(new_width);
        }
        img.src = ENVIRONMENT.BASE_URL + selected_company[0].logo.uid + '/' + selected_company[0].logo.name;

        if (brandCompanyData[selected_company[0].company_domain]) {
          setStoreData(brandCompanyData[selected_company[0].company_domain]);
        } else {
          setStoreData([]);
        }
        setPageLoad(false);
        forceUpdate();
      }
    } else if (pageLoad) {
      let selectedDisplayType = new URL(window.location.href).searchParams.get("item_type");

      if (selectedDisplayType && selectedDisplayType == 'brands' && storeState) {

        resetEverything();
        changeStoreState();
        setPageLoad(false);

      }


    }
  },[brandCompanyData,companyData])

  const changeSearchValue = (value) => {
    setSearchValue(value);
    setSearchKeywords(value.toLowerCase().split(" "));
  };

  const openCompanyItems = (company) => {
    let company_name = company.company_domain.split(".")[0];
    window.location.href = "/store/" + company_name;

    //save this code for later use
    // window.location.href = '/store?brand_name=' + company.company_domain+ '&item_type='+itemType;
    // setSelectedCompany(company);
    // setStoreData(brandCompanyData[company.company_domain]);
  };

  //for switch
  const changeItemType = (e, popStateAction = false) => {
    setShowLoader(false);
    resetEverything();
    setCurrentPage(1);
    if (selectedDisplay == "brands") {
      goBack(e.target.value);
    }
    setSelectedDisplay(e.target.value);
    console.log('db', e.target.value, popStateAction)
    if (e.target.value == "brands") {
      changeStoreState();
    } else {
      //commenting for now
      // if (e.target.value == "products") {
      //   setMaxPrice(maxPriceProduct);
      // } else if (e.target.value == "spaces") {
      //   setMaxPrice(maxPriceSpace);
      // } else {
      //   setMaxPrice(maxPriceScene);
      // }
      //item type is products or spaces or scenes
      setItemType(e.target.value);
    }
    if (popStateAction == false)
    {
      const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?item_type=' + e.target.value;
      window.history.pushState({path:newurl},'',newurl);
    }
  };

  const goBack = (type = 'brands') => {
    //go back to the main page and reset everything
    setBrandsSelected(false);
    setSelectedDisplay("brands");
    if (selectedCompany != null) {
      setSelectedCompany(null);
    } else {
      if (type != 'brands') {
        setStoreState(!storeState);
        setSelectedCompany(null);
        if (itemType == "products") {
          setSelectedDisplay("products");
          productDataLoad();
        } else if (itemType == "spaces") {
          setSelectedDisplay("spaces");
          spaceDataLoad();
        } else if (itemType == "scenes") {
          setSelectedDisplay("scenes");
          sceneDataLoad();
        }
      }
    }
  };

  const loadProductMaterialsData = () => {
    let data = [];
        axios.post(ENVIRONMENT.ADMIN_MATERIAL_BATCH, {
        })
        .then(res => {
            let categoriesData = Utilities.getMaterialsData( res.data );
            for (let i = 1; i <= (res.data).length; i++) {
                if( res.data[i - 1].parent_id == "")
                {
                    data.push({
                        key: res.data[i - 1]['material_id'],
                        title: res.data[i - 1]['name'],
                        value: res.data[i - 1]['name'],
                        children: categoriesData[res.data[i - 1]['material_id']],
                    });
                }

            }
            setProductMaterialsData(data);
        });
  }

  const populateProducts = (initial=false, selected_company = '') => {
    let payload = {
      item_type: 'product',
    }
    if (initial) {
      setProductLoad(true);
      payload.limit = '';
      if (selected_company != '') {
        payload.company_id = selected_company.company_id
      }

      setInitialLoadProducts(true);
    }
    axios.post(GET_STORE_ITEMS, payload)
      .then( (response) => {

        let products = response.data.map((obj) => {
          let keywords = []
          for(let keyword of obj.name.replace(/[^a-zA-Z0-9 ]/g, " ").split(" ")) {
            if (!keywords.includes(keyword)) {
              if (keyword.length > 1) {
                keywords.push(keyword.toLowerCase())
              }
            }
          }

          for(let keyword of obj.category.replace(/[^a-zA-Z0-9 ]/g, " ").split(" ")) {
            if (!keywords.includes(keyword)) {
              if (keyword.length > 1) {
                keywords.push(keyword.toLowerCase())
              }
            }
          }

          if (obj.color && obj.color != "") {
            keywords.push(obj.color.toLowerCase());
          }

          if (obj.materials && obj.materials != "") {
            keywords = keywords.concat(obj.materials.toLowerCase().split(", "));
          }

          if (obj.style_category && obj.style_category != "") {
            keywords.push(obj.style_category.toLowerCase());
          }

          if (obj.gtin && obj.gtin != "") {
            keywords.push(obj.gtin.toLowerCase());
          }

          if (obj.tags && obj.tags != "") {
            keywords = keywords.concat(obj.tags.toLowerCase().split(", "));
          }

          return { ...obj, 'keywords': keywords };
        });
        let max_price = products.reduce((prevValue, currentValue) => prevValue = prevValue > currentValue.price ? prevValue : currentValue.price, 0);
        setMaxPriceProduct(max_price);
        setProductModels(products);
        if (!initial) {
          setProductLoad(false);
          setShowBrandsProducts(true);
          setLoadInitialProducts(false);
        }
      })
      .catch((error) => {
      });
  }

  const populateSpaces = (initial = false, selected_company = '') => {

    let payload = {
      item_type: 'room',
    }
    if (initial) {
      payload.limit = '';
      if (selected_company != '') {
        payload.company_id = selected_company.company_id
      }
      setLoader(true);
      setInitialLoadSpaces(true);
      setSpacesLoad(true);
    }
    axios.post(GET_STORE_ITEMS, payload)
      .then( (response) => {
        // setSpacesLoad(false);
        let rooms = response.data.map((obj) => {
          let keywords = []
          for(let keyword of obj.room_name.replace(/[^a-zA-Z0-9 ]/g, " ").split(" ")) {
            if (!keywords.includes(keyword)) {
              if (keyword.length > 1) {
                keywords.push(keyword.toLowerCase())
              }
            }
          }

          for(let keyword of obj.category.replace(/[^a-zA-Z0-9 ]/g, " ").split(" ")) {
            if (!keywords.includes(keyword)) {
              if (keyword.length > 1) {
                keywords.push(keyword.toLowerCase())
              }
            }
          }

          if (obj.tags && obj.tags != "") {
            keywords = keywords.concat(obj.tags.toLowerCase().split(", "));
          }
          
          return { ...obj, 'keywords': keywords };
        });
        let max_price = rooms.reduce((prevValue, currentValue) => prevValue = prevValue > currentValue.price ? prevValue : currentValue.price, 0);
        setMaxPriceSpace(max_price);
        setSpaceModels(rooms);
        setLoader(false);
        if (!initial) {
          setShowBrandsSpaces(true);
          setLoadInitialSpaces(false);
          setSpacesLoad(false);
        }
      })
      .catch((error) => {
      });
  }

  const populateScenes = (initial=false) => {
    let payload = {
      action: 'get_batch',
      status: '3'
    }
    if (initial) {
      setTemplateLoader(true);
      payload.limit = '100';
      setInitialLoadScenes(true);
    }
    axios.post(ENVIRONMENT.TEMPLATE_ACTIONS, payload)
    .then(res => {
      setTemplateLoader(false)
      let scenes = res.data.map((obj) => {
        let keywords = []
        for(let keyword of obj.name.replace(/[^a-zA-Z0-9 ]/g, " ").split(" ")) {
          if (!keywords.includes(keyword)) {
            if (keyword.length > 1) {
              keywords.push(keyword.toLowerCase())
            }
          }
        }

        for(let keyword of obj.room_name.replace(/[^a-zA-Z0-9 ]/g, " ").split(" ")) {
          if (!keywords.includes(keyword)) {
            if (keyword.length > 1) {
              keywords.push(keyword.toLowerCase())
            }
          }
        }
        return { ...obj, 'keywords': keywords };
      });
      let max_price = scenes.reduce((prevValue, currentValue) => prevValue = prevValue > currentValue.price ? prevValue : currentValue.price, 0);
      setMaxPriceScene(max_price)
      setTemplateScenes(scenes);
      if (!initial) {
        setLoadInitialScenes(false);
      }
    })
    .catch((error) => {
    });
  }

  const populateBrandData = () => {
    let payload = {};
    setBrandLoader(true);
    axios.post(COMPANY_GET_BATCH, payload).then((res) => {
      if (res && res.data) {
        let companies = [];
        res.data.map((company, index) => {
          if (company.featured_brand) {
            companies.push(company);
          }
        });
        setCompanyData(companies);
        setBrandLoader(false);
        let brand_name = props.match.params.brand;
        if (brand_name) {
          let selected_company =
            companies &&
            companies.filter((company) => {
              return company.company_domain.includes(brand_name) == true;
            });
          setSelectedCompany(selected_company[0]);
          populateProducts(true, selected_company[0]);
          populateSpaces(true, selected_company[0]);
          populateScenes(true);
        }
      }
    });
  };

  const onChangePageSize = (page, pageSize) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setCurrentPage(page);
  };

  const extractCompanies = (model_type) => {
    let models = model_type == "products" ? productModels : spaceModels;
    let companies = [];
    models.map((model, index) => {
      if (model.company_domain && !companies.includes(model.company_domain)) {
        companies.push(model.company_domain);
      }
    });
    return companies;
  };
  const setForBrand = (models) => {
    if (models && models.length > 0) {
      let groups = models.reduce((groups, entity) => {
        let company_domain = entity.company_domain;
        if (!groups[company_domain]) {
          groups[company_domain] = [];
        }
        groups[company_domain].push(entity);
        return groups;
      }, {});
      return groups;
    }
  };

  const resetEverything = () => {
    setSearchValue("");
    setFiltersApplied(false);
    setCompanyFilter([]);
    setProductModelType([]);
    setSpaceCategoryValue([]);
    setProductCategoryValue([]);
    setCategorySearchValue([]);
    setStyleCategoryValue([]);
    setSearchKeywords([]);
    setProductCategoryValue([]);
    setProductCategoryValueWithChilds([]);
    setCategorySearchValue([]);
    setProductStyleCategory([]);
    setColorValue([]);
    setProductMaterials([]);
    setMaterialsCategoryValueWithChilds([]);
  };

  const changeStoreState = () => {
    //when explore brands is clicked, load brand cards and reset everything else
    setStoreState(!storeState);
    resetEverything();
    if (storeState) {
      setSelectedCompany(null);
    }
  };

  const openFilters = () => {
    setFiltersDrawer(true);
  };

  const changeProductModelType = (value) => {
    setProductModelType(value);
    if (value.includes("ar") && value.includes("high_res")) {
      let model_types = value;
      model_types.push("high_res_and_ar");
      setProductModelType(model_types);
    }
  };

  const setProductCategoryData = () => {
    let payload = {
      output: 'tree'
    };
    axios.post(ADMIN_CATEGORY_GET_BATCH, payload)
    .then(res => {
      setProductCategoriesData(res.data);
    });

    payload = {
      output: 'serial'
    };
    axios.post(ADMIN_CATEGORY_GET_BATCH, payload)
    .then(res => {
      setProductParentCateogries(res.data);
    });
  };

  const setSpaceCategoriesData = () => {
    let payload = {
      request_type: "room_model",
    };
    axios.post(GET_CATEGORY_AND_PRICE, payload).then((res) => {
      let categories = [];
      if (res.data) {
        res.data.map((request) => {
          if (!categories.includes(request.category)) {
            categories.push(request.category);
          }
        });
        categories.shift();
        setSpaceCategories(categories);
      }
    });
  };

  useEffect(() => {
    if (
      productCategoryValue.length > 0 ||
      companyFilter.length > 0 ||
      productModelType.length > 0 ||
      spaceCategoryValue.length > 0 ||
      styleCategoryValue.length > 0 ||
      minPrice != 0 ||
      (itemType == "products" && maxPrice != maxPriceProduct) ||
      (itemType == "spaces" && maxPrice != maxPriceSpace) ||
      (itemType == "scenes" && maxPrice != maxPriceScene) ||
      (itemType == "products" && productStyleCategory.length > 0) ||
      (itemType == "products" && colorValue.length > 0) ||
      (itemType == "products" && productMaterials.length > 0)
    ) {
      setFiltersApplied(true);
      forceUpdate();
    } else {
      setFiltersApplied(false);
    }
  }, [
    productCategoryValue,
    companyFilter,
    productModelType,
    spaceCategoryValue,
    minPrice,
    maxPrice,
    styleCategoryValue,
    productStyleCategory,
    colorValue,
    productMaterials,
  ]);

  const closeFiltersDrawer = () => {
    setFiltersDrawer(false);
  };

  const onChangePrice = (value) => {
    setMinPrice(value[0]);
    setMaxPrice(value[1]);
  };

  const findItemsByParentId = (data, targetItemName, parentId) => {
    const items = [];
    for (const item of data) {
        if (item.parent_id === parentId) {
            items.push(item.name);
            items.push(...findItemsByParentId(data, targetItemName, item.category_id));
        }
    }
    return items;
  }

  const changeProductCategory = (value) => {
    let values = []

    value.map((name)=>{
      const targetItemName = name;
      const targetItem = productParentCateogries.find(item => item.name === targetItemName);

    if (targetItem) {
        const result = findItemsByParentId(productParentCateogries, targetItemName, targetItem.category_id);
        result.unshift(name);
        values = [...values,...result]
    }});

    if (value.length > 0
      || companyFilter.length > 0 || productModelType.length > 0 || spaceCategoryValue.length > 0
      || styleCategoryValue.length > 0
      || (minPrice != 0) || (itemType == 'products' && maxPrice != maxPriceProduct)
      || (itemType == 'spaces' && maxPrice != maxPriceSpace)
      || (itemType == 'scenes' && maxPrice != maxPriceScene)
      || (itemType == 'products' && productStyleCategory.length > 0)
      || (itemType == 'products' && colorValue.length > 0)
      || (itemType == 'products' && productMaterials.length > 0)) {
      setFiltersApplied(true);
    } else {
      setFiltersApplied(false);
    }

    setProductCategoryValue(value);
    setProductCategoryValueWithChilds(values);
  }

  const changeProductStyleCategory = (value) => {
    if (value.length > 0
      || companyFilter.length > 0 || productModelType.length > 0 || spaceCategoryValue.length > 0
      || styleCategoryValue.length > 0
      || (minPrice != 0) || (itemType == 'products' && maxPrice != maxPriceProduct)
      || (itemType == 'spaces' && maxPrice != maxPriceSpace)
      || (itemType == 'scenes' && maxPrice != maxPriceScene)
      || (itemType == 'products' && colorValue.length > 0)
      || (itemType == 'products' && productMaterials.length > 0)) {
      setFiltersApplied(true);
    } else {
      setFiltersApplied(false);
    }

    setProductStyleCategory(value);
  }

  const changeColorValue = (value) => {
    if (value.length > 0
      || companyFilter.length > 0 || productModelType.length > 0 || spaceCategoryValue.length > 0
      || styleCategoryValue.length > 0
      || (minPrice != 0) || (itemType == 'products' && maxPrice != maxPriceProduct)
      || (itemType == 'spaces' && maxPrice != maxPriceSpace)
      || (itemType == 'scenes' && maxPrice != maxPriceScene)
      || (itemType == 'products' && productStyleCategory.length > 0)
      || (itemType == 'products' && productMaterials.length > 0)) {
      setFiltersApplied(true);
    } else {
      setFiltersApplied(false);
    }

    setColorValue(value);
  }

  const findChildrenByParentTitle = (parentTitle, node) => {
    if (node.title === parentTitle) {
        return node.children.map(child => child.title);
    } else if (node.children) {
        let result = [];
        for (const childNode of node.children) {
            result = result.concat(findChildrenByParentTitle(parentTitle, childNode));
        }
        return result;
    } else {
        return [];
    }
  }

  const changeProductMaterial = (value) => {

    let values = []

    value.map((name)=>{
      const targetItemTitle = name;
      const targetItem = productMaterialsData.find(item => item.title === targetItemTitle);

    if (targetItem) {
        const result = findChildrenByParentTitle(targetItemTitle, targetItem);
        result.unshift(name);
        values = [...values,...result]
    }});

    if (value.length > 0
      || companyFilter.length > 0 || productModelType.length > 0 || spaceCategoryValue.length > 0
      || styleCategoryValue.length > 0
      || (minPrice != 0) || (itemType == 'products' && maxPrice != maxPriceProduct)
      || (itemType == 'spaces' && maxPrice != maxPriceSpace)
      || (itemType == 'scenes' && maxPrice != maxPriceScene)
      || (itemType == 'products' && productStyleCategory.length > 0)
      || (itemType == 'products' && colorValue.length > 0)) {
      setFiltersApplied(true);
    } else {
      setFiltersApplied(false);
    }

    if(values.length <= 0){
      values = value 
    }

    setProductMaterials(value);
    setMaterialsCategoryValueWithChilds(values);
    
  }

  const changeSpaceCategory = (value) => {
    setSpaceCategoryValue(value);
  };

  const changeStyleCategory = (value)=> {
    setStyleCategoryValue(value);
  }

  const changeCompany = (value) => {
    setCompanyFilter(value);
  };

  const changeProductSearchCategory = (value) => {
    setCategorySearchValue(value);
  };

  const returnSearchValue = (entity) => {
    //return value according to the filters applied
    
    let brand_name = entity.company_domain;
    if (storeState || selectedCompany != null) {
      if (itemType == 'products')
      {
       return getProductSearchValue(entity);
      } else if (itemType == 'spaces') {
        return getSpaceSearchValue(entity);
      } else if (itemType == 'scenes') {
        return getSceneSearchValue(entity);
      }
    } else {
      if (companyFilter.length > 0 && companyFilter.includes(brand_name)) {
        return true;
      }
    }
    return false;
  }

  const getProductSearchValue = (entity) => {
    let brand_name = entity.company_domain;
    let category = entity.category;
    let need_to_model = entity.need_to_model;
    let price = entity.price;
    let style_category = entity.style_category;
    let color = entity.color;
    let materials = []
    if (entity.materials && entity.materials != ""){
      materials = entity.materials.split(", "); 
    }
    let product_category = false;
    let company_filter = false;
    let product_model_type = false;
    let style_category_matched = false;
    let color_matched = false;
    let material_matched = false;
    let product_price = false;
    if (category != '') {
      if ((productCategoryValueWithChilds.length > 0 && productCategoryValueWithChilds.includes(category)) || productCategoryValueWithChilds.length == 0){
        product_category = true;
      }
    }

    if ((companyFilter.length > 0 && companyFilter.includes(brand_name)) || companyFilter.length == 0) {
      company_filter = true;
    }
    if ((productModelType.length > 0 && productModelType.includes(need_to_model)) || productModelType.length == 0) {
      product_model_type = true;
    }
    if (minPrice <= price && maxPrice >= price) {
      product_price = true;
    }

    if ((productStyleCategory.length > 0 && productStyleCategory.includes(style_category)) || productStyleCategory.length == 0){
      style_category_matched = true;
    }

    if ((colorValue.length > 0 && colorValue.includes(color)) || colorValue.length == 0){
      color_matched = true;
    }

    if ((materialsCategoryValueWithChilds.length > 0 && materialsCategoryValueWithChilds.some(element => materials.includes(element))) || materialsCategoryValueWithChilds.length == 0){
      material_matched = true;
    }

    if (product_category && company_filter && product_model_type && product_price && style_category_matched && material_matched && color_matched) {
      return true;
    }
  }

  const getSpaceSearchValue = (entity) => {  
    let brand_name = entity.company_domain;
    let category = entity.category;
    let price = entity.price;
    let space_category = false;
    let company_filter = false;
    let space_price = false;
    if ((spaceCategoryValue.length > 0 && spaceCategoryValue.includes(category)) || spaceCategoryValue.length == 0) {
      space_category = true;
    }
    if ((companyFilter.length > 0 && companyFilter.includes(brand_name)) || companyFilter.length == 0) {
      company_filter = true;
    }
    if (minPrice <= price && price <= maxPrice) {
      space_price = true;
    }

    if (space_category && company_filter && space_price) {
      return true;
    }
  }

  const getSceneSearchValue = (entity) => {
    let brand_name = entity.company_domain;
    let price = entity.price;
    let category = entity.room_name;
    let style = entity.style;
    let scene_product_category = entity.id;
    let space_category = false;
    let company_filter = false;
    let scene_price = false;
    let style_category = false;
    let template_category = false;
    if ((spaceCategoryValue.length > 0 && spaceCategoryValue.includes(category)) || spaceCategoryValue.length == 0) {
      space_category = true;
    }
    if ((styleCategoryValue.length > 0 && styleCategoryValue.includes(style)) || styleCategoryValue.length == 0) {
      style_category = true;
    }
    if ((companyFilter.length > 0 && companyFilter.includes(brand_name)) || companyFilter.length == 0) {
      company_filter = true;
    }
    if (minPrice <= price && price <= maxPrice) {
      scene_price = true;
    }
    if (productCategoryValueWithChilds.length > 0 && Object.keys(templateProductCategories).length > 0) {
      productCategoryValueWithChilds.map(product_category => {
        if (templateProductCategories[product_category] && templateProductCategories[product_category].includes(scene_product_category)) {
          template_category = true;
        }
      })
    } else if (productCategoryValueWithChilds.length == 0) {
      template_category = true;
    }

    if (space_category && company_filter && scene_price && style_category && template_category) {
      return true;
    }
  }


  const closeTags = (entity,type) => {
    //remove the tag when this is clicked from showing result section
    if (type == 'product_category') {
      let arr = productCategoryValue.filter(category =>  entity != category);
      setProductCategoryValue([...arr]);
    } else if (type == 'space_category') {
      let arr = spaceCategoryValue.filter(category => {return entity != category});
      setSpaceCategoryValue(arr);
    } else if (type == 'style_category') {
      let arr = styleCategoryValue.filter(category => {return entity != category});
      setStyleCategoryValue(arr);
    } else if (type == 'brand') {
      let arr = companyFilter.filter(brand => {return entity != brand});
      setCompanyFilter(arr);
    } else if (type == 'model_type') {
      let arr = productModelType.filter(model_type => {return entity != model_type});
      setProductModelType(arr);
    } else if (type == 'product_style_category') {
      let arr = productStyleCategory.filter(style_category => {return entity != style_category});
      setProductStyleCategory(arr);
    } else if (type == 'product_color') {
      let arr = colorValue.filter(color => {return entity != color});
      setColorValue(arr);
    } else if (type == 'product_material') {
      let arr = productMaterials.filter(material => {return entity != material});
      setProductMaterials(arr);
    } else if (type == 'price') {
      setMinPrice(0);
      if (itemType == 'products') {
        setMaxPrice(maxPriceProduct);
      } else if (itemType == 'spaces') {
        setMaxPrice(maxPriceSpace);
      } else if (itemType == 'scenes') {
        setMaxPrice(maxPriceScene);
      }
    }
  }

  useEffect(() => {
    let brand_name = props.match.params.brand;
    changeNavOnScroll();
    popStateEvenetListner();

    if (!brand_name) {
      populateProducts(true);
      populateSpaces(true);
      populateScenes(true);
      populateBrandData();
    } else {
      populateBrandData();
    }

    setProductCategoryData();
    setSpaceCategoriesData();
    getTemplateProductsCategories();
    loadProductMaterialsData();
  }, []);

  const popStateEvenetListner = () => {
    // window.addEventListener("popstate", (event) => {
    //   let baseitemType = new URL(window.location.href).searchParams.get("item_type");
    //   let selectedDisplayType = new URL(window.location.href).searchParams.get("item_type");

    //   if (baseitemType == undefined || !(['products','spaces','scenes'].includes(baseitemType))) {
    //     baseitemType = itemType
    //   }
    //   let e = {
    //     target: {
    //       value: selectedDisplayType == 'brands' ? selectedDisplayType : baseitemType
    //     }
    //   };
    //   changeItemType(e, true);
    // }, false);
  }

  const changeNavOnScroll = () => {
    window.addEventListener("scroll", () => {
      let elmnt = document.getElementsByClassName("store-row");
      if (elmnt && elmnt[0] && elmnt[0].offsetHeight) {
        let updatedElem =
          window.document.getElementsByClassName("navbar-light");
        if (updatedElem && updatedElem[0]) {
          if (window.scrollY > elmnt[0].offsetHeight) {
            updatedElem[0].classList.add("full-opacity");
          } else {
            updatedElem[0].classList.remove("full-opacity");
          }
        }
      }
    });
  }

  // Get categories of products that are present in template scenes
  const getTemplateProductsCategories = () => {
    let payload = {"action": "filter_by_products" }
    axios.post(TEMPLATE_ACTIONS, payload)
    .then( res => {
      console.log('Template Product Categories',res);
      setTemplateProductCategories(res.data);
    })
  }

  const returnCompanyDisplayName = (company_domain) => {
    if (companyData && companyData.length > 0) {
      let company = companyData.filter((company) => {
        return company.company_domain == company_domain;
      });
      if (company && company.length > 0) {
        return company[0].company_display_name;
      }
    }
  };

  const onMouseOut = (evt) => {
    let element = document.getElementById("filter");
    if (element) {
      element.setAttribute("src", "/filter_blue.png");
      element.setAttribute("height", "20px");
      element.setAttribute("width", "20px");
    }
  };

  const onMouseOver = (evt) => {
    let element = document.getElementById("filter");
    if (element) {
      element.setAttribute("src", "/filter.png");
      element.setAttribute("height", "20px");
      element.setAttribute("width", "20px");
    }
  };

  const { title, items, products, spaces, scenes, link_text, results } = TextConstants.STORE;

  let searchBar = (
    <Row className="store-row justify-in-start">
      <Col lg={2} xs={6} sm={4} md={3} style={{ padding: "0px 10px 0px 0" }}>
        <h1
          className="font graphik-regular w-600 f-24 black-55"
          style={{ marginBottom: 0, lineHeight: "24px" }}
        >
          {title}
        </h1>
      </Col>
      <Col
        lg={15}
        xs={18}
        sm={20}
        md={20}
        style={{ padding: "0px 10px 0px 0" }}
      >
        <Input.Group compact size="large" className="d-inline">
          <Input
            className4="manrope f-14 grey-99"
            style={{ padding: 9 }}
            placeholder="Search Store"
            onChange={(e) => {
              changeSearchValue(e.target.value);
            }}
            value={searchValue}
          ></Input>
        </Input.Group>
      </Col>
      <Col lg={7} md={8} xs={24} sm={24} className="justify-in-start">
        <Col className="switch-bg">
          <Radio.Group
            className="radio-header"
            onChange={changeItemType}
            defaultValue={selectedDisplay}
            value={selectedDisplay}
            buttonStyle="solid"
          >
            <Radio.Button className="radio-btn-header" value="products">
              {items[0]}
            </Radio.Button>
            <Radio.Button className="radio-btn-header" value="spaces">
              {items[1]}
            </Radio.Button>
            <Radio.Button className="radio-btn-header" value="scenes">
              {items[2]}
            </Radio.Button>

            {!brandsSelected && (
              <Radio.Button className="radio-btn-header" value="brands">
                {items[3]}
              </Radio.Button>
            )}
          </Radio.Group>
        </Col>

        {selectedCompany != null || storeState ? (
          <Col style={{ padding: "10px" }}>
            <Button
              className="modal-okay-blue square font-14"
              onClick={openFilters}
              onMouseOut={onMouseOut}
              onMouseOver={onMouseOver}
            >
              <img
                height="20px"
                width="20px"
                src="/filter_blue.png"
                id="filter"
              />
            </Button>
          </Col>
        ) : (
          ""
        )}
      </Col>
    </Row>
  );

  const returnMaxPrice = (type) => {
    if (type == "products") {
      return maxPriceProduct;
    } else if (type == "spaces") {
      return maxPriceSpace;
    } else {
      return maxPriceScene;
    }
  };

  useEffect(()=>{
    console.log('filters Applied: ',filtersApplied, 'storeData: ',storeData)
  },[productLoad,spacesLoad,searchValue, filtersApplied, storeData])

  const getStoreContent = () => {
    let storeDataComp = [];
    let storeDataByRelevance = {} 
    storeData && storeData.map((entity, index) => {
        if (searchValue == '' && !filtersApplied && (index >= ((currentPage-1)*pageSize) && index < ((currentPage-1)*pageSize+(pageSize - 1)))){
          let storeEntry = (<StoreContent
          index={index}
          item_type={entity.item_type}
          id={itemType == 'spaces' ? entity.room_id : entity.id}
          name={itemType == 'spaces' ? entity.room_name : entity.name}
          price={entity.price}
          customer_username={itemType == 'scenes' ? entity.requested_by : entity.customer_username}
          company_display_name={entity.company_display_name}
          company_id={entity.company_id}
          itemType={itemType}
          thumbnail={entity.thumbnail}
          category={itemType == 'scenes' ? entity.room_name : entity.category}
          />)
        if (searchValue == '' && !filtersApplied) {
          storeDataComp.push(storeEntry)
        }
      }
      else if (searchValue != '' || filtersApplied) {
        let storeEntry = (<StoreContent
          index={index}
          item_type={entity.item_type}
          id={itemType == 'spaces' ? entity.room_id : entity.id}
          name={itemType == 'spaces' ? entity.room_name : entity.name}
          price={entity.price}
          company_display_name={entity.company_display_name}
          company_id={entity.company_id}
          customer_username={itemType == 'scenes' ? entity.requested_by : entity.customer_username}
          itemType={itemType}
          thumbnail={entity.thumbnail}
          category={itemType == 'scenes' ? entity.room_name : entity.category}
          />)
          if (searchValue == '' && filtersApplied) {
            if (returnSearchValue(entity)) {
              storeDataComp.push(storeEntry)
    
            }
          } else if (searchValue != '' && filtersApplied) {

              if (returnSearchValue(entity)) {
                if (itemType == 'products' || itemType == 'spaces' || itemType == 'scenes') {
                  let name = itemType == 'spaces' ? entity.room_name:entity.name;
                  let nameSearchRelevance = Utilities.getStringsRelevance(searchValue.toLowerCase(), name.toLowerCase(), searchKeywords, entity.keywords)
                  let relevance = nameSearchRelevance
                  
                  if (relevance > 0 ) {
                    if (storeDataByRelevance[relevance] == undefined) {
                      storeDataByRelevance[relevance] = [];
                    }
                    storeDataByRelevance[relevance].push(storeEntry); 
                  }      
                }
              }

          } else if (searchValue != '' && !filtersApplied) {
            if (itemType == 'products' || itemType == 'spaces' || itemType == 'scenes') {
              let name = itemType == 'spaces' ? entity.room_name:entity.name;
              let nameSearchRelevance = Utilities.getStringsRelevance(searchValue.toLowerCase(), name.toLowerCase(), searchKeywords, entity.keywords)
              let relevance = nameSearchRelevance
              
              if (relevance > 0 ) {
                if (storeDataByRelevance[relevance] == undefined) {
                  storeDataByRelevance[relevance] = [];
                }
                storeDataByRelevance[relevance].push(storeEntry); 
              }      
            }
          }
      }
      })

      if (searchValue != '') {
        storeDataComp = Object.keys(storeDataByRelevance)
        .sort((a, b) => b - a) // Sort keys in descending order
        .reduce((acc, key) => acc.concat(storeDataByRelevance[key]), []);
      }

      let  MainStoreContent =  <> 
      {((productLoad || spacesLoad) && (searchValue != '' || filtersApplied))?<><Col span={24} style={{ margin: "0 5%" }}>
          <Row>
            <DottedLoader type="flex"
              style={{ justifyContent: "center", margin: "40px auto" }} />
          </Row>
        </Col>
        </>:storeDataComp?.length <=0 ? <Empty style={{marginTop: 40, marginBottom: 40}}/>
         : loader || storeData && storeData.length == 0 ?
        storeData && storeData.length == 0 && (itemType == 'spaces' || itemType == 'scenes') ?
        <Empty style={{marginTop: 40, marginBottom: 40}}/> :
        <><Col span={24} style={{ margin: "0 5%" }}>
          <Row>
            <DottedLoader type="flex"
              style={{ justifyContent: "center", margin: "40px auto" }} />
          </Row>
        </Col>
        </> : 
        <>
        <Row type="flex" style={{justifyContent: "start",margin:"0px 0px 40px 0px"}}>
          {storeDataComp}
        </Row>
        </>}
        </>

      return MainStoreContent;
    }

    let BrandStoreContent = <>
    {brandLoader || (companyData && companyData.length == 0)  ? <DottedLoader/> :
    <Row type="flex" style={{justifyContent: "start",margin:"0px 0px 40px 0px"}}>
    {itemCount && companyData  && companyData.map((company, index) => {
        let brandEntry = (<Col span={6} style={{padding : "6px", cursor: "pointer"}} key={index}>
            <Card bodyStyle={{ padding: "10px" }} className="card-shadow-product" bordered={false}>
              <div onClick={() => openCompanyItems(company)} className="suggested-product" style={{zIndex:"1"}}>
                <div>
                  <div className="box">
                      <img style={{objectFit: "contain",height:"275px",zIndex:1, padding: 20 }} className="product-image" src={company.featured_brand ? ENVIRONMENT.BASE_URL + company.logo.uid + '/' + company.logo.name : '/img/image_placeholder.png'} />
                  </div>
                  <hr style={{marginTop: 0, marginBottom: 0}}/>

                  <Tooltip title={<span className='manrope f-12 white'>{company.company_display_name}</span>}>
                    <div className="manrope f-14 w-500 black-00 j-end clamp-text w-90" style= {{margin:"12px 12px 8px 12px"}}>
                        {company.company_display_name}
                    </div>
                  </Tooltip>
                  <div className="justify-in-start manrope f-12 grey-77 capitalize" style= {{marginLeft:"12px",marginRight:"12px",marginBottom:"12px",textAlign:"left"}}>
                    <div>{itemCount[company.company_domain] || 0} Items</div>
                  </div>
                </div>
              </div>
            </Card>
        </Col>)
          if(searchValue == '' && !filtersApplied){
            return brandEntry;
          } else if (searchValue != '' && !filtersApplied) {
            if (Utilities.isMatching(searchValue.toLowerCase(), company.company_display_name.toLowerCase())){
              return brandEntry;
            }
          }
    })}
    </Row>
    }
    </>;

  let storeHeading =
    filtersApplied || (!storeState && selectedCompany != null) ? (
      <Row
        type="flex"
        style={{ justifyContent: "flex-start", margin: "20px 5% 20px 5%" }}
      >
        {!storeState &&
          selectedCompany != null &&
          !loader &&
          !productLoad &&
          selectedCompany &&
          selectedCompany.featured_brand &&
          ((storeData && storeData.length > 0) ||
            (selectedCompany &&
              itemCount &&
              itemCount[selectedCompany.company_domain] == 0)) && (
            <Col className="d-lg-block d-none" span={24}>
              <div className="banner-container">
                <img className="banner-style" src="/Banner.png" />
                <div className="logo-website-container">
                  <div className="logo-banner">
                    <img
                      className="logo-placement"
                      style={{ width: logoWidth + "%" }}
                      src={
                        BASE_URL +
                        selectedCompany.logo.uid +
                        "/" +
                        selectedCompany.logo.name
                      }
                    />
                  </div>
                  <div className="website-container">
                    <a
                      className="manrope f-16"
                      href={selectedCompany.website}
                      target="_blank"
                    >
                      {link_text} <ArrowRightOutlined />
                    </a>
                  </div>
                </div>
              </div>
            </Col>
          )}
        {filtersApplied && (
          <Col span={24} style={{ marginTop: 20 }}>
            <h4 className="manrope f-16 black-55 italic">{results}</h4>
            <div className="justify-in-start" style={{flexWrap: "wrap", marginTop: "8px"}}>
              {(itemType == "products" || itemType == 'scenes')
                ? productCategoryValue.length > 0 &&
                  productCategoryValue.map((category) => {
                    return (
                      <Tag
                        onClose={() => closeTags(category, "product_category")}
                        closable
                        className="manrope f-14 black-55 tag-filter"
                        key={category}
                        style={{marginBottom: "8px"}}
                      >
                        Product Category:{" "}
                        <span className="manrope f-14 grey-77">{category}</span>
                      </Tag>
                    );
                  })
                : ''}

                {(itemType == 'spaces' || itemType == 'scenes') ?
                  spaceCategoryValue.length > 0 &&
                  spaceCategoryValue.map((category) => {
                    return (
                      <Tag
                        onClose={() => closeTags(category, "space_category")}
                        closable
                        className="manrope f-14 black-55 tag-filter"
                        key={category}
                        style={{marginBottom: "8px"}}
                      >
                        Space Category:{" "}
                        <span className="manrope f-14 grey-77">{category}</span>
                      </Tag>
                    );
                  }): ''}
              {itemType == 'scenes' ?
                styleCategoryValue.length > 0 && styleCategoryValue.map((category) => {
                  return <Tag 
                  onClose={() => closeTags(category,'style_category')} 
                  closable 
                  visible={true} 
                  className='manrope f-12 black-55 tag-filter'
                  key={category}
                  style={{marginBottom: "8px"}}>Style: <span className='manrope f-12 grey-77'>{category}</span>
                  </Tag>
                }) : ''
              }
              {companyFilter.length > 0 &&
                companyFilter.map((company) => {
                  return (
                    <Tag
                      onClose={() => closeTags(company, "brand")}
                      closable
                      className="manrope f-14 black-55 tag-filter"
                      key={company}
                      style={{marginBottom: "8px"}}
                    >
                      Brand:{" "}
                      <span className="manrope f-14 grey-77">
                        {returnCompanyDisplayName(company)}
                      </span>
                    </Tag>
                  );
                })}

              {itemType == 'products' ?
                productStyleCategory.length > 0 && productStyleCategory.map((category) => {
                  return <Tag onClose={() => closeTags(category,'product_style_category')} closable visible={true} className='manrope f-12 black-55 tag-filter' key={category} style={{marginBottom: "8px"}}>Style Category: <span className='manrope f-12 grey-77'>{category}</span></Tag>
                }) : ''}

              {itemType == 'products' ?
                colorValue.length > 0 && colorValue.map((category) => {
                  return <Tag onClose={() => closeTags(category,'product_color')} closable visible={true} className='manrope f-12 black-55 tag-filter' key={category} style={{marginBottom: "8px"}}>Color: <span className='manrope f-12 grey-77'>{category}</span></Tag>
                }) : ''}

              {itemType == 'products' ?
                productMaterials.length > 0 && productMaterials.map((category) => {
                  return <Tag onClose={() => closeTags(category,'product_material')} closable visible={true} className='manrope f-12 black-55 tag-filter' key={category} style={{marginBottom: "8px"}}>Material: <span className='manrope f-12 grey-77'>{category}</span></Tag>
                }) : ''}
                
              {itemType == "products"
                ? productModelType.length > 0 &&
                  productModelType.map((model) => {
                    return (
                      <Tag
                        closable
                        onClose={() => closeTags(model, "model_type")}
                        className="manrope f-14 black-55 tag-filter"
                        key={model}
                        style={{marginBottom: "8px"}}
                      >
                        Model Type:{" "}
                        <span className="manrope f-14 grey-77">
                          {FileConstants.getModelType(model)}
                        </span>
                      </Tag>
                    );
                  })
                : ""}
              {itemType == "products" &&
              (minPrice != 0 || maxPrice != maxPriceProduct) ? (
                <Tag
                  closable
                  onClose={() => closeTags("none", "price")}
                  className="manrope f-12 black-55 tag-filter"
                  key="price-1"
                  style={{marginBottom: "8px"}}
                >
                  Price:{" "}
                  <span className="manrope f-12 grey-77">
                    {minPrice != maxPrice
                      ? "$" + minPrice + " - $" + maxPrice
                      : "$" + minPrice}
                  </span>
                </Tag>
              ) : itemType == "spaces" &&
                (minPrice != 0 || maxPrice != maxPriceSpace) ? (
                <Tag
                  closable
                  onClose={() => closeTags("none", "price")}
                  className="manrope f-12 black-55 tag-filter"
                  key="price-2"
                  style={{marginBottom: "8px"}}
                >
                  Price:{" "}
                  <span className="manrope f-12 grey-77">
                    {minPrice != maxPrice
                      ? "$" + minPrice + " - $" + maxPrice
                      : "$" + minPrice}
                  </span>
                </Tag>
              ) : itemType == "scenes" &&
                (minPrice != 0 || maxPrice != maxPriceScene) ? (
                <Tag
                  closable
                  onClose={() => closeTags("none", "price")}
                  className="manrope f-12 black-55 tag-filter"
                  key="price-3"
                  style={{marginBottom: "8px"}}
                >
                  Price:{" "}
                  <span className="manrope f-12 grey-77">
                    {minPrice != maxPrice
                      ? "$" + minPrice + " - $" + maxPrice
                      : "$" + minPrice}
                  </span>
                </Tag>
              ) : (
                ""
              )}
            </div>
          </Col>
        )}
      </Row>
    ) : (
      ""
    );
  const { name, content, schemaMarkup } = MetaTagContents.STORE;

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{MetaTagContents.STORE.title}</title>
        <link rel="canonical" href="https://all3d.ai/store" />
        <meta name={name} content={content} />
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      </Helmet>
      {loader ? (
        <Row
          type="flex"
          style={{ justifyContent: "center", margin: "40px auto" }}
        >
          <DottedLoader />
        </Row>
      ) : (
        <span>
          <NavBar />
          <div style={{ height: 80 }}></div>
          <CookieConsentBanner />
          {searchBar}
          {!storeState && (
            <Breadcrumb className="breadcrumb-settings">
              <Breadcrumb.Item>
                <a
                  className="font graphik-regular f-16 w-400 grey-6b"
                  href="/store"
                >
                  Store
                </a>
              </Breadcrumb.Item>
              <Breadcrumb.Item>
                <a
                  className="font graphik-regular f-16 w-400 grey-6b"
                  href="#"
                  onClick={() => goBack()}
                >
                  Brands
                </a>
              </Breadcrumb.Item>
              {selectedCompany != null && (
                <Breadcrumb.Item>
                  <a
                    className="font graphik-regular f-16 w-400 grey-6b"
                    href="#"
                  >
                    {selectedCompany.company_display_name}
                  </a>
                </Breadcrumb.Item>
              )}
            </Breadcrumb>
          )}
          {storeHeading}

          {showBrandsProducts &&
            showBrandsSpaces &&
            storeState &&
            !filtersApplied &&
            companyData &&
            companyData.length > 0 &&
            featuredBrands &&
            featuredBrands.length > 0 && (
              <div className="brand-container d-lg-block d-none">
                <h3
                  className="font graphik-regular f-20 black-14 w-500"
                  style={{ marginTop: 28, lineHeight: "20px" }}
                >
                  Brands
                </h3>

                <Carousel
                  ref={(el) => {
                    setCarousel(el);
                  }}
                  swipeable={true}
                  draggable={true}
                  showDots={false}
                  responsive={responsive}
                  ssr={true}
                  infinite={true}
                  autoPlay={false}
                  arrows={true}
                  keyBoardControl={false}
                  containerClass="carousel-container-store"
                >
                  {companyData &&
                    companyData.map((company, index) => (
                      <div
                        className="logo-container justify-in-center"
                        style={{ marginRight: 10 }}
                        onClick={() => openCompanyItems(company)}
                      >
                        <img
                          className="logo-image"
                          src={
                            BASE_URL +
                            company.logo.uid +
                            "/" +
                            company.logo.name
                          }
                        />
                        <p
                          style={{ marginBottom: 0, marginLeft: 10 }}
                          className="font graphik-regular f-14 black-55 w-600"
                        >
                          {company.company_display_name}
                        </p>
                      </div>
                    ))}
                </Carousel>
              </div>
            )}
          <Col span={24} style={{ margin: "0 5%" }}>
            {storeState || selectedCompany != null ? (
              selectedCompany &&
              itemCount &&
              itemCount[selectedCompany.company_domain] == 0 ? (
                comingSoonCompanies.includes(selectedCompany.company_domain) ? (
                  <h3
                    className="manrope f-20 black-14 w-500"
                    style={{
                      lineHeight: "20px",
                      marginTop: 40,
                      marginBottom: 40,
                    }}
                  >
                    Coming Soon
                  </h3>
                ) : (
                  <Row>
                    <DottedLoader
                      type="flex"
                      style={{ justifyContent: "center", margin: "40px auto" }}
                    />
                  </Row>
                )
              ) : (
                getStoreContent()
              )
            ) : (
              BrandStoreContent
            )}
            {(storeState || selectedCompany != null) &&
            searchValue == "" &&
            !filtersApplied &&
            !loader &&
            !productLoad &&
            storeData &&
            storeData.length > 0 ? (
              <Pagination
                style={{ marginBottom: 40 }}
                showSizeChanger={false}
                pageSize={pageSize}
                current={currentPage}
                onChange={onChangePageSize}
                total={storeData && storeData.length}
              />
            ) : (
              ""
            )}
          </Col>

          <div className="abs-btm">
            <Footer />
          </div>
        </span>
      )}
      <Drawer
        width={360}
        visible={filtersDrawer}
        onClose={closeFiltersDrawer}
        placement="right"
      >
        <div style={{ padding: 20 }}>
          <h2 className="manrope f-18 black-14 w-700">Filters</h2>
          <div>
            {(storeState || selectedCompany != null) &&
            <>
            {(itemType == 'products' || itemType == 'scenes') ? 
              <>
              <h5 className='manrope f-16 black-14' style={{marginTop: 20}}> 
                {itemType == 'products' ? 'Product Category' : 'Search Templates by Product Category'}
              </h5>
              <TreeSelect
                value={productCategoryValue}
                showSearch={true}
                className="tree-select-material f-14 filter bg-light"
                style={{ width: "100%" }}
                multiple
                dropdownStyle={categoryStyle}
                placeholder="Search Category"
                treeData={productCategoriesData}
                onChange={changeProductCategory}
                onSearch={changeProductSearchCategory}
                notFoundContent={
                  <span>
                    <h5>No Result Found</h5>
                    <p>Press enter to add your category</p>
                  </span>
                }
              />
            </>: ''}
            {(itemType == 'spaces' || itemType == 'scenes') ? 
              <>
                <h5 className='manrope f-16 black-14' style={{marginTop: 20}}> 
                  {itemType == 'spaces' ? 'Space Category' : 'Search Templates by Space Category'}
                </h5>
              <Select
                className={`tree-select-material f-14 filter bg-light`}
                showSearch={true}
                value={spaceCategoryValue}
                onChange={changeSpaceCategory}
                placeholder="Search Category"
                mode="tags"
              >
                {spaceCategories &&
                  spaceCategories.map((category, index) => {
                    return (
                      <Option
                        className="manrope f-12 select-view"
                        value={category}
                      >
                        {category}
                      </Option>
                    );
                  })}
              </Select>
            </> : ''}

          {itemType == 'scenes' ? <>
            <h5 className='manrope f-16 black-14'  style={{marginTop: 20}}>Style</h5>
            <Select
              className={`tree-select-material f-14 filter bg-light`}
              showSearch={true} 
              value={styleCategoryValue}
              onChange={changeStyleCategory}
              placeholder="Search Style" mode="tags">
                {FileConstants.STYLE_CATEGORY.map((category,index) => {
                  return <Option className="manrope f-12 select-view" value={category}>{category}</Option>
                })}
            </Select>
          </>: ''}
          </>}


            {selectedCompany == null ? (
              <>
                <h5 className="manrope f-16 black-14" style={{ marginTop: 20 }}>
                  Brands
                </h5>
                <Select
                  className={`tree-select-material f-14 filter bg-light`}
                  showSearch={true}
                  value={companyFilter}
                  onChange={changeCompany}
                  placeholder="Search Brands"
                  mode="tags"
                >
                  {companyData &&
                    companyData.map((company, index) => {
                      return (
                        <Option
                          className="manrope f-12 select-view"
                          value={company.company_domain}
                        >
                          {company.company_display_name}
                        </Option>
                      );
                    })}
                </Select>
              </>
            ) : (
              ""
            )}

          {itemType == 'products' ?
            <>
              <h5 className='manrope f-16 black-14'  style={{marginTop: 20}}>Style Category</h5>
              <Select
                className={`tree-select-material f-14 filter bg-light`}
                showSearch={true}
                onChange={changeProductStyleCategory}
                value={productStyleCategory}
                placeholder="Search Style Category" mode="tags"
              >
                <Option className="manrope f-10 select-view" value="Mid Century">Mid Century</Option>
                <Option className="manrope f-10 select-view" value="Traditional">Traditional</Option>
                <Option className="manrope f-10 select-view" value="Farmhouse">Farmhouse</Option>
                <Option className="manrope f-10 select-view" value="Modern">Modern</Option>
                <Option className="manrope f-10 select-view" value="Glam">Glam</Option>
                <Option className="manrope f-10 select-view" value="Rustic">Rustic</Option>
                <Option className="manrope f-10 select-view" value="Global">Global</Option>
                <Option className="manrope f-10 select-view" value="Bohemian">Bohemian</Option>
                <Option className="manrope f-10 select-view" value="Ecclectic">Ecclectic</Option>
                <Option className="manrope f-10 select-view" value="Industrial">Industrial</Option>
                <Option className="manrope f-10 select-view" value="Coastal">Coastal</Option>
                <Option className="manrope f-10 select-view" value="Transitional">Transitional</Option>
                <Option className="manrope f-10 select-view" value="Other">Other</Option>
                
              </Select>
            </>
            :''
          }

          {itemType == 'products' ?
          <>
            <h5 className='manrope f-16 black-14'  style={{marginTop: 20}}>Product Color</h5>
            <Select
              className={`tree-select-material f-14 filter bg-light`}
              onChange={changeColorValue}
              value={colorValue}
              showSearch
              placeholder="Search by Color" mode="tags"
            >
              <Option className="manrope f-10 select-view" value="Beige">Beige</Option>
              <Option className="manrope f-10 select-view" value="Black">Black</Option>
              <Option className="manrope f-10 select-view" value="Blue">Blue</Option>
              <Option className="manrope f-10 select-view" value="Brass">Brass</Option>
              <Option className="manrope f-10 select-view" value="Bronze">Bronze</Option>
              <Option className="manrope f-10 select-view" value="Brown">Brown</Option>
              <Option className="manrope f-10 select-view" value="Chrome">Chrome</Option>
              <Option className="manrope f-10 select-view" value="Copper">Copper</Option>
              <Option className="manrope f-10 select-view" value="Gold">Gold</Option>
              <Option className="manrope f-10 select-view" value="Gray">Gray</Option>
              <Option className="manrope f-10 select-view" value="Green">Green</Option>
              <Option className="manrope f-10 select-view" value="Iron">Iron</Option>
              <Option className="manrope f-10 select-view" value="Ivory/Cream">Ivory/Cream</Option>
              <Option className="manrope f-10 select-view" value="Multi-color">Multi-color</Option>
              <Option className="manrope f-10 select-view" value="Natural  (bone, almond)">Natural  (bone, almond)</Option>
              <Option className="manrope f-10 select-view" value="Navy">Navy</Option>
              <Option className="manrope f-10 select-view" value="Nickel">Nickel</Option>
              <Option className="manrope f-10 select-view" value="Orange">Orange</Option>
              <Option className="manrope f-10 select-view" value="Pattern">Pattern</Option>
              <Option className="manrope f-10 select-view" value="Pewter">Pewter</Option>
              <Option className="manrope f-10 select-view" value="Pink">Pink</Option>
              <Option className="manrope f-10 select-view" value="Purple">Purple</Option>
              <Option className="manrope f-10 select-view" value="Reflective ">Reflective</Option>
              <Option className="manrope f-10 select-view" value="Transparent">Transparent</Option>
              <Option className="manrope f-10 select-view" value="Red">Red</Option>
              <Option className="manrope f-10 select-view" value="Rose Gold">Rose Gold</Option>
              <Option className="manrope f-10 select-view" value="Rust">Rust</Option>
              <Option className="manrope f-10 select-view" value="Silver">Silver</Option>
              <Option className="manrope f-10 select-view" value="Stainless Steel">Stainless Steel</Option>
              <Option className="manrope f-10 select-view" value="Unfinished">Unfinished</Option>
              <Option className="manrope f-10 select-view" value="White">White</Option>
              <Option className="manrope f-10 select-view" value="Yellow">Yellow</Option>
              <Option className="manrope f-10 select-view" value="Other">Other</Option>
          </Select>
          </>:''
          }

          {itemType == 'products' ?
          <>
            <h5 className='manrope f-16 black-14'  style={{marginTop: 20}}>Product Material</h5>
            <TreeSelect
              value={productMaterials}
              showSearch={true}
              className="tree-select-material f-14 filter bg-light"
              style={{ width: '100%' }}
              multiple
              dropdownStyle={categoryStyle}
              placeholder="Search by Material"
              treeData={productMaterialsData}
              onChange={changeProductMaterial}
              onSearch={changeProductSearchCategory}
              notFoundContent={<span><h5>No Result Found</h5><p>Press enter to add your category</p></span>}
          >
          </TreeSelect>
            </>:''
          }
            
            {itemType == "products" &&
            (storeState || selectedCompany != null) ? (
              <>
                <h5 className="manrope f-16 black-14" style={{ marginTop: 20 }}>
                  Search In
                </h5>
                <Checkbox.Group
                  value={productModelType}
                  onChange={changeProductModelType}
                >
                  <Checkbox className="manrope f-14 black-14 w-600" value="ar">
                    AR Model
                  </Checkbox>
                  <Checkbox
                    className="manrope f-14 black-14 w-600"
                    value="high_res"
                  >
                    High Res Model
                  </Checkbox>
                </Checkbox.Group>
              </>
            ) : (
              ""
            )}

            <>
              <h5 className="manrope f-16 black-14" style={{ marginTop: 20 }}>
                Price
              </h5>
              <Slider
                value={[minPrice, maxPrice]}
                className="adjust-pattern"
                onChange={onChangePrice}
                min={0}
                max={returnMaxPrice(itemType)}
                range
                defaultValue={[0, returnMaxPrice(itemType)]}
              />
            </>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

const mapStateToProps = (state) => state;

const mapDispatchToProps = (dispatch) => ({
  listStoreProducts: () => {
    dispatch(listStoreProducts(LIST_STORE_PRODUCT));
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Store));
