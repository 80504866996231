import React from "react";
import "./index.scss";
import FileConstants from "../../../FileConstants";
import TextConstants from "../../../TextConstants";
import LazyLoad from "react-lazyload";

const CreateBrandPage = () => {
  return (
    <>
      <WebVersion />
      <MobileVersion />
    </>
  );
};

const { title, text } = TextConstants.CREATE_BRAND_PAGE;

const WebVersion = () => {
  return (
    <div className="d-none d-lg-block">
      <div
        className="row justify-space-between our-product-offering"
        style={{ marginTop: 120 }}
      >
        <div className="col-lg-6 col-12" style={{ paddingRight: 50 }}>
          <h4 className="font graphik-medium f-18 w-500 black-2b">{title}</h4>
          <p className="font graphik-regular f-16 w-400 grey-light">{text}</p>
        </div>
        <div className="col-lg-6 col-12 bg-img">
          <img
            className="section-img load-lazy"
            src={FileConstants.BRAND_PAGE}
            alt="Brands"
          />
        </div>
      </div>
    </div>
  );
};

const MobileVersion = () => {
  return (
    <div className="d-lg-none">
      <div className="our-product-offering" style={{ marginTop: 48 }}>
        <div
          className="col-12 justify-in-center direction-flex col"
          style={{ textAlign: "center" }}
        >
          <h4 className="font graphik-medium f-16 w-500 black-2b">{title}</h4>
          <p className="font graphik-regular f-14 w-400 grey-light">{text}</p>
        </div>
        <div className="col-12 bg-img justify-in-center">
          <img
            className="section-img load-lazy"
            src={FileConstants.BRAND_PAGE}
            alt="Brands"
          />
        </div>
      </div>
    </div>
  );
};
export default CreateBrandPage;
