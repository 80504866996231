import 'rxjs-compat/add/operator/switchMap';
import 'rxjs-compat/add/operator/map';
import 'rxjs-compat/add/observable/of';
import 'rxjs-compat/add/operator/catch';
import { ajax } from 'rxjs-compat/observable/dom/ajax';
import {
  LIST_STORE_PRODUCT,
  RENDER_PRODUCT,
  productFailureAction,
  productSuccessAction,
  getProductRender,
} from '../actions';

export const listStoreProducts = (action$) => {
  return action$
      .ofType(LIST_STORE_PRODUCT)
      .switchMap(({ payload }) => {
        return ajax.post('https://4z75fv9tm5.execute-api.us-west-2.amazonaws.com/landing-page',
            { approved: '', item_type: 'product' }, { 'Content-Type': 'application/json' })
            .map((data) => productSuccessAction({ products: data.response, isCreated: false, isUpdated: false }))
            .catch((error) => Promise.resolve(productFailureAction({ message: 'Unable to create product. Fill missing fields.' })));
      });
};

export const renderProduct = (action$) => {
  return action$
      .ofType(RENDER_PRODUCT)
      .switchMap(({ payload }) => {
        return ajax.post("https://k1sfniydii.execute-api.us-west-2.amazonaws.com/production",
            { ...payload }, { 'Content-Type': 'application/json' })
            .map((data) => productSuccessAction({ ...data.response, isCreated: false, isUpdated: false }))
            .catch((error) => Promise.resolve(productFailureAction({ message: 'Unable to get product.' })));
      });
};