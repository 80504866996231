import React from "react";
import "./aboutUs.scss";
import { Layout, Row, Card } from "antd";
import { Helmet } from "react-helmet";
import NavBar from "../landingPage/header/navbar";
import FileConstants from "../../FileConstants";
import MetaTagContents from "../../MetaTagContents";
import TextConstants from "../../TextConstants";
import Footer from "../landingPage/footer";

const { Content } = Layout;

function aboutus() {
  const { Meta } = Card;
  const { name, content } = MetaTagContents.ABOUT_US;
  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{MetaTagContents.ABOUT_US.title}</title>
        <link rel="canonical" href="https://all3d.ai/about-us" />
        <meta name={name} content={content} />
      </Helmet>

      <NavBar />

      <Layout>
        <WebVersion />
        <MobileVersion />
      </Layout>
      <Footer />
    </React.Fragment>
  );
}

const {
  heading_1,
  text_1,
  text_2,
  heading_2,
  heading_3,
  headquarters,
  work_across,
} = TextConstants.ABOUT_US;
const WebVersion = () => {
  return (
    <Content className="d-none d-lg-block">
      <Row>
        <img
          className="about-us-bg"
          src="/About-us-bg.png"
          alt="About Us"
          width={"100%"}
        />
        <div className="mission-container">
          <h1 className="font lora f-32 ws-6 w-400 white">{heading_1}</h1>
          <h3 className="font graphik-regular f-16 white w-400">
            {text_1}
            <br />
            <br />
            {text_2}
          </h3>
        </div>
      </Row>
      {/* <Row className="about-us-container">
        <h2
          className="font lora f-32 ws-6 black-2b w-400"
          style={{ marginTop: "110px", marginBottom: 40 }}
        >
          {heading_2}
        </h2>
      </Row> */}

      {/* <Row className="about-us-container">
        <div
          className="justify-in-center"
          style={{ flexWrap: "wrap", width: "100%", justifyContent: "center" }}
        >
          {TextConstants.LEADERS.map((leader) => (
            <div className="col-lg-4 col-xl-3" style={{ padding: 0 }}>
              <Card hoverable className="team-card">
                <div style={{ textAlign: "center" }}>
                  <img className="team-picture" src={leader.icon} />
                  <div className="position-member-text">
                    <h3
                      className="font graphik-medium f-18 black-2b text-overlay"
                      style={{
                        marginTop: 12,
                        marginBottom: 0,
                        textShadow: "0 2px 25px rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      {leader.name},
                      <span
                        className="font graphik-medium f-16 w-400 black-2b text-overlay"
                        style={{
                          marginBottom: 0,
                          marginTop: 0,
                          textShadow: "0 2px 25px rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        {leader.title}
                      </span>
                      <a href={leader.linkedin} target="_blank">
                        <img
                          height="16px"
                          src="/linkedin_app.svg"
                          alt="Linkedin app icon"
                        />
                      </a>
                    </h3>
                  </div>
                </div>
              </Card>
            </div>
          ))}
        </div>
      </Row> */}

      {/* <Row  className='about-us-container'>
            <h2 className='font lora f-32 ws-6 black-2b w-400' style={{marginTop:"70px",marginBottom: 40}}>
                {heading_3}
            </h2>
        </Row>

        <Row className='about-us-container'>
            <div className='justify-in-center' style={{flexWrap: 'wrap',width:"100%",justifyContent:"center"}}>
                {TextConstants.TEAM.map((person) => ((
                    <div className='col-lg-4 col-xl-3' style={{ padding: 0}}>
                        <Card hoverable className='team-card' style={{ marginBottom: 0}}>
                            <div style={{textAlign: 'center'}}>
                                <img className='team-picture' src={person.icon}/>
                                <div  className='position-member-text'>
                                    <h3 className='font graphik-regular f-18 w-600 black-2b text-overlay' style={{marginTop: 12, textShadow: "0 2px 25px rgba(0, 0, 0, 0.5)"}}>{person.name}</h3>
                                </div>
                            </div>
                        </Card>
                    </div>
                )))}
            </div>
        </Row> */}

      <Row
        className="about-us-container"
        style={{ paddingBottom: 105, paddingTop: 35 }}
      >
        <div className="col-lg-12" style={{ padding: 0 }}>
          <Row
            className="flag-container justify-space-between"
            style={{ flexWrap: "wrap" }}
          >
            <div className="col-lg-4 justify-in-start">
              <div>
                <h4
                  className="font graphik-medium f-20 black-2b w-400"
                  style={{ marginBottom: 0, textAlign: "left" }}
                >
                  {headquarters}
                </h4>
                <h4
                  className="font graphik-medium f-18 black-2b w-400"
                  style={{ marginBottom: 0, textAlign: "left" }}
                >
                  {work_across}
                </h4>
              </div>
            </div>
            <div className="justify-in-end">
              {FileConstants.flags.map((flag, index) => (
                <div className="col-lg-2" style={{ padding: "0" }}>
                  <img
                    className="flag-size"
                    src={
                      "https://all3d.s3.us-west-2.amazonaws.com/static_files/diversity_flags/" +
                      flag
                    }
                    alt={flag}
                  />
                </div>
              ))}
            </div>
          </Row>
        </div>
      </Row>
    </Content>
  );
};

const MobileVersion = () => {
  return (
    <Content className="d-lg-none">
      <Row>
        <img
          className="about-us-bg"
          src="/About-us-bg.png"
          alt="About Us"
          width={"100%"}
        />
        <div className="mission-container">
          <h2 className="font lora f-20 white w-400">{heading_1}</h2>
          <p className="font graphik-regular f-14 white w-400">
            {text_1}
            <br />
            <br />
            {text_2}
          </p>
        </div>
      </Row>
      <Row className="about-us-container">
        <h2
          className="font lora f-20 white black-2b w-400"
          style={{ marginTop: 35, marginBottom: 20 }}
        >
          {heading_2}
        </h2>
      </Row>

      <Row className="about-us-container">
        <div
          className="justify-in-center"
          style={{ flexWrap: "wrap", width: "100%", justifyContent: "center" }}
        >
          {TextConstants.LEADERS.map((leader, index) => (
            <div className="col-12 col-md-4 col-sm-6" style={{ padding: 0, marginBottom:15 }}>
              <Card hoverable className="team-card" style={{ marginBottom: 0 }}>
                <div style={{ textAlign: "center" }}>
                  <img className="team-picture" src={leader.icon} />
                  <div className="position-member-text">
                    <h3
                      className="font graphik-medium f-16 black-2b text-overlay"
                      style={{
                        marginTop: 12,
                        marginBottom: 0,
                        textShadow: "0 2px 25px rgba(0, 0, 0, 0.5)",
                      }}
                    >
                      {leader.name},
                      <span
                        className="font graphik-medium f-14 w-400 black-2b text-overlay"
                        style={{
                          marginBottom: 0,
                          marginTop: 0,
                          textShadow: "0 2px 25px rgba(0, 0, 0, 0.5)",
                        }}
                      >
                        {leader.title}
                      </span>
                      <a href={leader.linkedin} target="_blank">
                        <img height="16px" src="/linkedin_app.svg" />
                      </a>
                    </h3>
                  </div>
                </div>
              </Card>
            </div>
          ))}
        </div>
      </Row>

      {/* <Row  className='about-us-container'>
            <h2 className='font lora f-20 white black-2b w-400' style={{marginTop:35,marginBottom: 20}}>
                {heading_3}
            </h2>
        </Row>

        <Row className='about-us-container' style={{paddingBottom:0}}>
            <div className='justify-in-center' style={{flexWrap: 'wrap',width:"100%",justifyContent:"center"}}>
                {TextConstants.TEAM.map((person,index) => ((
                    <div className='col-12  col-md-4  col-sm-6'  style={{padding: 0}}>
                        <Card hoverable className='team-card' style={{marginBottom: 0}}>
                            <div style={{textAlign: 'center'}}>
                                <img className='team-picture' src={person.icon}/>
                                <div className='position-member-text'>
                                    <h3 className='font graphik-medium f-16 w-400 black-2b text-overlay' style={{marginTop: 12, textShadow: "0 2px 25px rgba(0, 0, 0, 0.5)"}}>{person.name}</h3>
                                </div>
                            </div>
                        </Card>
                    </div>
                )))}
            </div>
        </Row> */}

      <Row
        className="about-us-container"
        style={{ paddingBottom: 35, marginTop: 30 }}
      >
        <div className="col-12" style={{ padding: 0 }}>
          <Row
            className="flag-container justify-space-between"
            style={{ flexWrap: "wrap" }}
          >
            <div className="col-md-12 col-sm-12 col-12 justify-in-start">
              <div>
                <h4
                  className="font graphik-medium f-16 black-2b w-400"
                  style={{ marginBottom: 0, textAlign: "left" }}
                >
                  {headquarters}
                </h4>
                <h4
                  className="font graphik-medium f-14 black-2b w-400"
                  style={{ marginBottom: 0, textAlign: "left" }}
                >
                  {work_across}
                </h4>
              </div>
            </div>
            <div
              className="justify-in-start"
              style={{ flexWrap: "wrap", padding: "0px 15px", width: 300 }}
            >
              {FileConstants.flags.map((flag, index) => (
                <div className="col-md-2 col-sm-2 col-2" style={{ padding: 0 }}>
                  <img
                    className="flag-size"
                    src={
                      "https://all3d.s3.us-west-2.amazonaws.com/static_files/diversity_flags/" +
                      flag
                    }
                    alt={flag}
                  />
                </div>
              ))}
            </div>
          </Row>
        </div>
      </Row>
    </Content>
  );
};

export default aboutus;
