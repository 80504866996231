import React from 'react';
import {Animated} from "react-animated-css";
import $ from 'jquery';

// import Img from 'react-image'

class SectionFour extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      assetImages: [
        { imgSrc: '/landingPage/color-swatch1.svg', selected: true, loadImage: 'landingPage/colourable-01.jpg' },
        { imgSrc: '/landingPage/color-swatch2.svg', selected: false, loadImage: 'landingPage/colourable-02.jpg' },
        { imgSrc: '/landingPage/color-swatch3.svg', selected: false, loadImage: 'landingPage/colourable-03.jpg' },
        { imgSrc: '/landingPage/color-swatch4.svg', selected: false, loadImage: 'landingPage/colourable-04.jpg' },
        { imgSrc: '/landingPage/color-swatch5.svg', selected: false, loadImage: 'landingPage/colourable-05.jpg' },
      ],

      assetVisible1: false,
      assetVisible2: false
    }
  }

  componentDidMount(){

    this.state.assetImages.forEach((picture) => {
        const img = new Image();
        img.src = picture.loadImage;
    });

    // window.addEventListener('scroll', () => {
    //   var top = $(window).scrollTop() + $(window).height();
    //   var classTop = $('#assetText').offset().top;
    //   var isVisible = false;
    //   if (classTop) {
    //     isVisible = top > classTop
    //     if (isVisible){
    //         this.setState({
    //           assetVisible1: true,
    //           assetVisible2: true
    //         })
    //     }
    //   }
    //   classTop = $('#assetImage').offset().top;
    //   if (classTop) {
    //       isVisible = top > classTop;
    //       if (isVisible){
    //           this.setState({
    //             assetVisible2: true
    //           })
    //       }

    //   }
    //     });

  }

  setRenderImage = (position) => {
    let imagesList = this.state.assetImages
    imagesList.map((img) => {
      img.selected = false
    })
    imagesList[position].selected = true
    this.setState({assetImage: imagesList})
  }


  findAssetImage = () => {
    let image =  ''
    this.state.assetImages.forEach((img) => {
      if (img.selected){
        image =  img.loadImage
      }
    })
    return image
  }

  render() {
    let assetImagesList = [];
    for(let i = 0; i < this.state.assetImages.length; i++){
      assetImagesList.push(<AssetSelectorButton assetImage={this.state.assetImages[i]} key={i} setRenderImage={() => this.setRenderImage(i)} />)
    }
    return (
      <div className='container-fluid'>
        <div className='section-4'>
          <div className='row'>
            <div className='col col-lg-4 offset-lg-1 col-12' id="assetText">
            <div className='txt padding-3d-assets'>

              {/* <div > */}
                <div className='text-border-brake mb-3' />
                <div className='heading d-none d-lg-block'>
                  <div>Customize Your</div>
                  <div>3D assets</div>
                </div>
                <div className='heading d-lg-none'>
                  <div>Customize Your 3D assets</div>
                </div>
                <p className='subheading '>
                  Each Product can be customized by changing attributes like color, textures & materials and component parts, and provide configurations in real-time.
                </p>
                <div className='component'>
                  <h5>Fabric</h5>
                  <div className='asset-component-buttons'>
                    <span>
                      {assetImagesList}
                    </span>
                  </div>
                </div>

              {/* </div> */}
            </div>
            </div>
            <div className='col col-lg-7 col-12' id="assetImage">
            <div>
              <img className='asset-img' src={this.findAssetImage()} alt="Sofa Chair"/>
            </div>
            </div>
          </div>

        </div>

      </div>
    );
  }
}

function AssetSelectorButton(props) {
  let {assetImage, setRenderImage} = props
  let classVal = 'asset-selectors'
  if(assetImage.selected){
    classVal += ' selected' 
  }
  return (
    <div className={classVal} onClick={setRenderImage} >
      <img  src={assetImage.imgSrc} alt="Sofa Chair Swatch"/>
    </div>
  );
}

export default SectionFour;
