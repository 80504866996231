import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import VisibilitySensor from 'react-visibility-sensor';
import {Animated} from "react-animated-css";
import $ from 'jquery';


class SectionThree extends React.Component {
  constructor(props) {
    super(props)
    this.imageLabels = ["Lifestyle", "Product Perspective 1", "Product Perspective 2", "Dimensional Image", "Product Perspective 3", "Product Perspective 4", "Product Perspective 5"]
    this.state = {
      curImage: 0,
      autoPlay: false,
      isVisible: false,
      productVisible1: false,
      lifestyleVisible1: false,
      productVisible2: false,
      lifestyleVisible2: false
    }
    this.timer = null
  }
  updateLabel = (e, elm) => {
    this.setState({
      curImage: e
    })
  }

  // componentDidMount() {
  //   window.addEventListener('scroll', () => {
  //     var top = $(window).scrollTop() + $(window).height()
  //     var classTop = $('#productText').offset().top;
  //     var isVisible = false;
  //     if (classTop != undefined) {
  //       isVisible = top > classTop;
  //       if (isVisible){
  //           this.setState({
  //             productVisible1: true
  //           })
  //       }
  //     }
  //     classTop = $('#productPicture').offset().top;
  //     if (classTop != undefined) {
  //     isVisible = top > classTop
  //       if (isVisible){
  //           this.setState({
  //             productVisible2: true
  //           })
  //     }
  //     }

  //     classTop = $('#lifestylePicture').offset().top;
  //     if (classTop !=undefined){
  //       isVisible = top > classTop
  //       if (isVisible){
  //           this.setState({
  //               lifestyleVisible1: true
  //           })
  //       }
  //     }

  //     classTop = $('#lifestyleText').offset().top;
  //     if (classTop !=undefined) {
  //     isVisible = top >classTop
  //     if (isVisible){
  //         this.setState({
  //             lifestyleVisible2: true
  //         })
  //     }
  //    }
  //       });
  // }


  componentWillUnmount() {
    this.clearAuto()
  }

  clearAuto = () => {
    clearInterval(this.timer);
  }

  onChangeVisibility = (isVisible) => {
    if (isVisible == false) {
      this.setState({
        autoPlay: false,
        isVisible: false
      })
    } else {
      this.setState({
        autoPlay: true,
        isVisible: true,
        curImage: 0,
      }, () => {
        this.clearAuto()
        this.timer = setInterval(() => {
          let index = this.state.curImage + 1
          if (index > this.imageLabels.length) {
            index = 0
          }
          this.setState({ curImage: index })
        }, 3000)
      })
    }
  }
  // centerMode centerSlidePercentage={80}
  render() {
    return (
      <div className='section-3'>
        <div className=' container-fluid '>

          <div className='row'>
            <div className='col col-lg-5 offset-lg-1 col-12' id="productText">

              <div className='txt product-text-padding'>

                <div className='text-border-brake mb-3' />
                <div className='heading '>
                  <div>Product Photography</div>
                </div>
                <p className='subheading '>
                Use the 3D models created on the All3D platform (or your own) to create Product Renders from any angle with results that surpass actual photography.
                </p>

              </div>
            </div>
            
            <div className='col col-lg-6 col-12' id="productPicture">

              <Carousel infiniteLoop showIndicators={true}  autoPlay showThumbs={false} dynamicHeight showStatus={false} >
                <div>
                  <img src="corousel/731_1.jpg" height={'100%'} alt="Product Photography - Chair 1" />
                </div>
                <div>
                  <img src="corousel/731_2.jpg" height={'100%'} alt="Product Photography - Chair 2" />
                </div>
                <div>
                  <img src="corousel/731_3.jpg" height={'100%'} alt="Product Photography - Chair 3" />
                </div>
                <div>
                  <img src="corousel/731_4.jpg" height={'100%'} alt="Product Photography - Chair 4" />
                </div>
                <div> 
                  <img src="corousel/731_5.jpg" height={'100%'} alt="Product Photography - Chair 5" />
                </div>
                <div>
                  <img src="corousel/731_6.jpg" height={'100%'} alt="Product Photography - Chair Dimensions" />
                </div>
              </Carousel>
            </div>
          </div>

          <div className=' mt-80'>

            <div className='row justify-content-md-center' >
              <div className='col-lg-7 col-12 order-2 lifestyle-image-padding' id="lifestylePicture">

                <Carousel infiniteLoop showIndicators={true} autoPlay showThumbs={false} dynamicHeight showStatus={false} >
                  <div>
                    <img src="corousel/lifestyle_1.jpg" alt="Lifestyle Photography - Dining" />
                  </div>
                  <div>
                    <img src="corousel/lifestyle_2.jpg" alt="Lifestyle Photography - Living 1"  />
                  </div>
                  <div>
                    <img src="corousel/lifestyle_3.jpg" alt="Lifestyle Photography - Kitchen" />
                  </div>
                  <div>
                    <img src="corousel/lifestyle_4.jpg" alt="Lifestyle Photography - Patio" />
                  </div>
                  <div>
                    <img src="corousel/lifestyle_5.jpg" alt="Lifestyle Photography - Living 2" />
                  </div>

                </Carousel>
              </div>
              <div className='col-lg-5 col-12 order-1 order-lg-2' id="lifestyleText">

                <div className='txt lifestyle-margin-right'>
                  <div className='text-border-brake mb-3' />

                  <div className='heading '>
                    <div>Lifestyle Photography</div>
                  </div>
                  <p className='subheading '>
                  Place your products in virtual rooms and create Lifestyle Renders that show how the product will look in life like settings.
                  </p>
                </div>
              </div>
            </div>



          </div>

        </div >


      </div>
    );
  }

}


export default SectionThree;
