import React from "react";
import { CheckOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Button, Card, Tooltip } from "antd";
import * as ENVIRONMENT from './env';
const FileConstants = new Object();

FileConstants.creative_commons_text = [
  {
    name: "Public Domain",
    description:
      "Public domain is the purest form of open/free, since no one owns or controls the material in any way. You give the users the license to anything with your work or 3D model",
    icon: "/license_1.png",
  },
  {
    name: "Attribution License",
    description:
      "This means that you let other users use the model only if they give you attribution - Other uses include others copy, distribute, display, and perform your copyrighted work - and derivative works based upon it - but only if they give you credit. ie it is your model when used anywhere",
    icon: "/license_2.png",
  },
  {
    name: "Attribution - NonDerivs License",
    description:
      "This means the user give your credit and attribution if the model is as if they make  any changes to the model no attribution to you.",
    icon: "/license_3.png",
  },
  {
    name: "Attribution - NonCommercial-NonDerivs License",
    description:
      "You let others copy, distribute, display, and perform only verbatim copies of your work/model, not derivative works based upon it - but for noncommercial purposes only.",
    icon: "/license_4.png",
  },
  {
    name: "Attribution - NonCommercial License",
    description:
      "You let others copy, distribute, display, and perform your copyrighted work - and derivative works based upon it - but only if they give you credit - only for noncommercial purposes only.",
    icon: "/license_5.png",
  },
  {
    name: "Attribution - NonCommercial-ShareAlike License",
    description:
      "You allow others to distribute derivative works only under a license identical to the license that governs your work- but for non commercial purposes only",
    icon: "/license_6.png",
  },
  {
    name: "Attribution - ShareAlike License",
    description:
      "You allow others to distribute derivative works only under a license identical to the license that governs your work.",
    icon: "/license_7.png",
  },
];

FileConstants.getLicensingIcon = (license) => {
  let icons = {
    "Public Domain": "/license_1.png",
    "Attribution License": "/license_2.png",
    "Attribution - NonDerivs License": "/license_3.png",
    "Attribution - NonCommercial-NonDerivs License": "/license_4.png",
    "Attribution - NonCommercial License": "/license_5.png",
    "Attribution - NonCommercial-ShareAlike License": "/license_6.png",
    "Attribution - ShareAlike License": "/license_7.png",
    forSale: "/for_sale.png",
  };
  return icons[license];
};

FileConstants.PlansData = [
  {
    Name: <div style={{ border: "0px", width: "20%" }} />,
    Free: (
      <div
        style={{
          textAlignLast: "center",
          borderTop: "5px solid #E5E5E5",
          borderTopLeftRadius: "6px",
        }}
      >
        <Card>
          <div
            className="font graphik-regular f-16 w-500"
            style={{ margin: "10px 0px", textAlign: "center" }}
          >
            Free Trial
          </div>
          <div
            style={{ textAlign: "center" }}
            className="font graphik-medium f-14 w-500"
          >
            $0.00
          </div>
          <div
            className="font graphik-regular f-12 black-2b w-400"
            style={{
              marginTop: "0px",
              marginBottom: "8px",
              textAlign: "center",
            }}
          >
            for one month
          </div>
          <Button
            style={{
              margin: "0px auto",
              marginBottom: "10px",
              textAlign: "center",
              display: "flex",
            }}
            className="modal-okay-price square justify-in-center"
            onClick={() =>
              (window.location.href = "https://app.all3d.ai/register")
            }
          >
            Sign Up Free
          </Button>
        </Card>
      </div>
    ),
    Pro: (
      <div
        style={{
          textAlignLast: "center",
          borderTop: "5px solid #83B4FF",
          borderTopLeftRadius: "6px",
        }}
      >
        <Card>
          <div
            className="font graphik-regular f-16 w-500"
            style={{ margin: "10px 0px", textAlign: "center" }}
          >
            <span style={{ marginRight: 4 }}>Pro</span>
            <Tooltip
              title={
                <span className="manrope f-12 white">
                  You can mix and match lifestyle rendering and modeling
                  services
                </span>
              }
            >
              <InfoCircleOutlined />
            </Tooltip>
          </div>
          <div
            style={{ textAlign: "center" }}
            className="font graphik-medium f-14 w-500"
          >
            $450
          </div>
          <div
            className="font graphik-regular f-12 black-2b w-400"
            style={{
              marginTop: "0px",
              marginBottom: "8px",
              textAlign: "center",
            }}
          >
            per month
          </div>
          <Button
            style={{
              margin: "0px auto",
              marginBottom: "10px",
              textAlign: "center",
              display: "flex",
            }}
            className="modal-okay-price square justify-in-center"
            onClick={() =>
              (window.location.href = "https://app.all3d.ai/register")
            }
          >
            Sign Up
          </Button>
        </Card>
      </div>
    ),
    Premium: (
      <div
        style={{
          textAlignLast: "center",
          borderTop: "5px solid #4184EB",
          borderTopLeftRadius: "6px",
        }}
      >
        <Card>
          <div
            className="font graphik-regular f-16 w-500"
            style={{ margin: "10px 0px", textAlign: "center" }}
          >
            <span style={{ marginRight: 4 }}>Premium</span>
            <Tooltip
              title={
                <span className="manrope f-12 white">
                  You can mix and match lifestyle rendering and modeling
                  services
                </span>
              }
            >
              <InfoCircleOutlined />
            </Tooltip>
          </div>
          <div
            style={{ textAlign: "center" }}
            className="font graphik-medium f-14 w-500"
          >
            $900
          </div>
          <div
            className="font graphik-regular f-12 black-2b w-400"
            style={{
              marginTop: "0px",
              marginBottom: "8px",
              textAlign: "center",
            }}
          >
            per month
          </div>
          <Button
            style={{
              margin: "0px auto",
              marginBottom: "10px",
              textAlign: "center",
              display: "flex",
            }}
            className="modal-okay-price square justify-in-center"
            onClick={() =>
              (window.location.href = "https://app.all3d.ai/register")
            }
          >
            Sign Up
          </Button>
        </Card>
      </div>
    ),
    Enterprise: (
      <div
        style={{
          textAlignLast: "center",
          borderTop: "5px solid #C2A374",
          borderTopLeftRadius: "6px",
        }}
      >
        <Card className="enterprise-setting">
          <div
            className="font graphik-regular f-16 w-500"
            style={{ margin: "10px 0px", textAlign: "center" }}
          >
            Enterprise
          </div>
          <div
            style={{ textAlign: "center" }}
            className="font graphik-medium f-14 w-500 justify-in-center"
          >
            <span style={{ marginRight: 3 }}>Contact Us</span>{" "}
            <Tooltip
              title={
                <span className="manrope f-12 white">
                  Contact Us for Subscription Pricing
                </span>
              }
            >
              <InfoCircleOutlined />
            </Tooltip>
          </div>
          <div
            className="font graphik-regular f-12 black-2b w-400"
            style={{
              marginTop: "0px",
              marginBottom: "8px",
              textAlign: "center",
            }}
          >
            &nbsp;
          </div>
          <Button
            style={{
              margin: "0px auto",
              marginBottom: "10px",
              textAlign: "center",
              display: "flex",
            }}
            className="modal-okay-brown square justify-in-center"
            onClick={() => (window.location.href = "/contact-us")}
          >
            Let's Talk
          </Button>
        </Card>
      </div>
    ),
  },
];

FileConstants.PhotoServicesColumns = [
  {
    title: (
      <div
        style={{ alignText: "left", textAlign: "left" }}
        className="service-heading font graphik-medium w-400 f-16 black-2b"
      >
        <span style={{ marginRight: 4, marginBotton: 2 }}>
          Subscription Based Services
        </span>
      </div>
    ),
    dataIndex: "Name",
    key: "Name",
    width: "20%",
    colSpan: 5,
    fixed: true,
  },
  {
    dataIndex: "Free",
    key: "Free",
    colSpan: 0,
    width: "20%",
    fixed: true,
  },
  {
    dataIndex: "Pro",
    key: "Pro",
    colSpan: 0,
    width: "20%",
    fixed: true,
  },
  {
    dataIndex: "Premium",
    key: "Premium",
    colSpan: 0,
    width: "20%",
    fixed: true,
  },
  {
    dataIndex: "Enterprise",
    key: "Enterprise",
    colSpan: 1,
    width: "20%",
    fixed: true,
  },
];

FileConstants.AppPurchasesColumns = [
  {
    title: (
      <div
        style={{ alignText: "left", textAlign: "left" }}
        className="service-heading font graphik-medium w-400 f-16 black-2b"
      >
        In-app Purchases{" "}
        <span className="font graphik-medium f-14 grey-6b">
          {" "}
          (limited quantities apply *)
        </span>
      </div>
    ),
    dataIndex: "Name",
    key: "Name",
    width: "20%",
    colSpan: 5,
    fixed: true,
  },
  {
    dataIndex: "Free",
    key: "Free",
    colSpan: 0,
    width: "20%",
    fixed: true,
  },
  {
    dataIndex: "Pro",
    key: "Pro",
    colSpan: 0,
    width: "20%",
    fixed: true,
  },
  {
    dataIndex: "Premium",
    key: "Premium",
    colSpan: 0,
    width: "20%",
    fixed: true,
  },
  {
    dataIndex: "Enterprise",
    key: "Enterprise",
    colSpan: 1,
    width: "20%",
    fixed: true,
  },
];

FileConstants.ConfiguratorColumns = [
  {
    title: (
      <div
        style={{ alignText: "left", textAlign: "left" }}
        className="servie-heading font graphik-medium w-400 f-16 black-2b"
      >
        3D Configurator
      </div>
    ),
    dataIndex: "Name",
    key: "Name",
    width: "20%",
    colSpan: 5,
    fixed: true,
  },
  {
    dataIndex: "Free",
    key: "Free",
    colSpan: 0,
    width: "20%",
    fixed: true,
  },
  {
    dataIndex: "Pro",
    key: "Pro",
    colSpan: 0,
    width: "20%",
    fixed: true,
  },
  {
    dataIndex: "Premium",
    key: "Premium",
    colSpan: 0,
    width: "20%",
    fixed: true,
  },
  {
    dataIndex: "Enterprise",
    key: "Enterprise",
    colSpan: 1,
    width: "20%",
    fixed: true,
  },
];

FileConstants.SupportColumns = [
  {
    title: (
      <div
        style={{ alignText: "left", textAlign: "left" }}
        className="service-heading font graphik-medium w-400 f-16 black-2b"
      >
        Support, SLAs and Seat License
      </div>
    ),
    dataIndex: "Name",
    key: "Name",
    width: "20%",
    colSpan: 5,
    fixed: true,
  },
  {
    dataIndex: "Free",
    key: "Free",
    colSpan: 0,
    width: "20%",
    fixed: true,
  },
  {
    dataIndex: "Pro",
    key: "Pro",
    colSpan: 0,
    width: "20%",
    fixed: true,
  },
  {
    dataIndex: "Premium",
    key: "Premium",
    colSpan: 0,
    width: "20%",
    fixed: true,
  },
  {
    dataIndex: "Enterprise",
    key: "Enterprise",
    colSpan: 1,
    width: "20%",
    fixed: true,
  },
];

FileConstants.PhotoServicesColumnsResponsive = [
  {
    title: (
      <div
        style={{
          alignText: "left",
          background: "#F0F2F6",
          fontSize: "16px",
          textAlign: "left",
        }}
        className="font graphik-regular"
      >
        Covered In Subscription
      </div>
    ),
    dataIndex: "Name",
    key: "Name",
    width: "140px",
    colSpan: 2,
  },
  {
    dataIndex: "Replace",
    key: "Replace",
    colSpan: 0,
    width: "100px",
  },
];

FileConstants.ConfiguratorColumnsResponsive = [
  {
    title: (
      <div
        style={{
          alignText: "left",
          background: "#F0F2F6",
          fontSize: "16px",
          textAlign: "left",
        }}
        className="font graphik-regular"
      >
        3D Configurator
      </div>
    ),
    dataIndex: "Name",
    key: "Name",
    width: "140px",
    colSpan: 2,
  },
  {
    dataIndex: "Replace",
    key: "Replace",
    colSpan: 0,
    width: "100px",
  },
];

FileConstants.AppPurchasesColumnsResponsive = [
  {
    title: (
      <div
        style={{
          alignText: "left",
          background: "#F0F2F6",
          fontSize: "16px",
          textAlign: "left",
        }}
        className="font graphik-regular"
      >
        In-app Purchases{" "}
        <span className="font graphik-regular f-14">
          {" "}
          (limited quantities apply *)
        </span>
      </div>
    ),
    dataIndex: "Name",
    key: "Name",
    width: "140px",
    colSpan: 2,
  },
  {
    dataIndex: "Replace",
    key: "Replace",
    colSpan: 0,
    width: "100px",
  },
];

FileConstants.SupportColumnsResponsive = [
  {
    title: (
      <div
        style={{
          alignText: "left",
          background: "#F0F2F6",
          fontSize: "16px",
          textAlign: "left",
        }}
        className="font graphik-regular"
      >
        Support, SLAs and Seat License
      </div>
    ),
    dataIndex: "Name",
    key: "Name",
    width: "140px",
    colSpan: 2,
  },
  {
    dataIndex: "Replace",
    key: "Replace",
    colSpan: 0,
    width: "100px",
  },
];

FileConstants.SubscriptionPricingData = [
  {
    Name: "Model Creation (1 Change)",
    Unit: <div className="justify-in-center">$110</div>,
  },
  {
    Name: "Model Creation (Unlimited Changes)",
    Unit: <div className="justify-in-center">$220</div>,
  },
  {
    Name: "Model State Change",
    Unit: <div className="justify-in-center">$50</div>,
  },
  {
    Name: "Model Color Change",
    Unit: <div className="justify-in-center">$15</div>,
  },
  {
    Name: "Model Size Change",
    Unit: <div className="justify-in-center">$50</div>,
  },
  {
    Name: "Model Material Change",
    Unit: <div className="justify-in-center">$50</div>,
  },
  {
    Name: "Model Hardware Change",
    Unit: <div className="justify-in-center">$70</div>,
  },
  {
    Name: "Material Creation",
    Unit: <div className="justify-in-center">$50</div>,
  },
  {
    Name: "Bed Variant (By Category Only - Bedding)",
    Unit: <div className="justify-in-center">$160</div>,
  },
  {
    Name: "Material Application on Model (Material Already in Material Library)",
    Unit: <div className="justify-in-center">$40</div>,
  },
  {
    Name: "AR Model (Converting an Existing High Res Model to AR)",
    Unit: <div className="justify-in-center">$60</div>,
  },
  {
    Name: "Upload Existing Model",
    Unit: <div className="justify-in-center">$10</div>,
  },
  {
    Name: "Product Image / Render @ 4K",
    Unit: <div className="justify-in-center">$5</div>,
  },
  {
    Name: "Product Image / Render @ 2K",
    Unit: <div className="justify-in-center">$4</div>,
  },
  {
    Name: "Product 360 @ 4K",
    Unit: <div className="justify-in-center">$50</div>,
  },
  {
    Name: "Lifestyle Render / Image @ 1K",
    Unit: <div className="justify-in-center">$10</div>,
  },
  {
    Name: "Lifestyle Render / Image @ 2K",
    Unit: <div className="justify-in-center">$35</div>,
  },
  {
    Name: "Lifestyle Render / Image @ 4K",
    Unit: <div className="justify-in-center">$50</div>,
  },
  {
    Name: "Lifestyle 360 @ 4K",
    Unit: <div className="justify-in-center">$75</div>,
  },
  {
    Name: "Space Model Creation",
    Unit: <div className="justify-in-center">$150-$750</div>,
  },
  {
    Name: "3D Tour / Virtual Showroom",
    Unit: (
      <div className="justify-in-center">
        Variable&nbsp;
        <Tooltip
          title={
            <span className="manrope f-12 white">
              Based on number of rooms, products, props and camera placements
            </span>
          }
        >
          <InfoCircleOutlined />
        </Tooltip>
      </div>
    ),
  },
  {
    Name: "Design Services per Product",
    Unit: <div className="justify-in-center">$160</div>,
  },
  {
    Name: "SLA for Subscription",
    Unit: (
      <div className="justify-in-center" style={{ textAlign: "center" }}>
        24-48 hours
      </div>
    ),
  },
];

FileConstants.SpaceData = [
  {
    Name: "Living Room",
    Unit: <div className="justify-in-center">$150</div>,
  },
  {
    Name: "Bedroom",
    Unit: <div className="justify-in-center">$150</div>,
  },
  {
    Name: "Bathroom",
    Unit: <div className="justify-in-center">$150</div>,
  },
  {
    Name: "Kitchen",
    Unit: <div className="justify-in-center">$175</div>,
  },
  {
    Name: "Dining Room",
    Unit: <div className="justify-in-center">$150</div>,
  },
  {
    Name: "Entry",
    Unit: <div className="justify-in-center">$150</div>,
  },
  {
    Name: "Hallway",
    Unit: <div className="justify-in-center">$150</div>,
  },
  {
    Name: "Office",
    Unit: <div className="justify-in-center">$150</div>,
  },
  {
    Name: "Conference Room",
    Unit: <div className="justify-in-center">$150</div>,
  },
  {
    Name: "Hotel Room",
    Unit: <div className="justify-in-center">$150</div>,
  },
  {
    Name: "Store (1 Room)",
    Unit: <div className="justify-in-center">$150</div>,
  },
  {
    Name: "Store (More than 1 Room)",
    Unit: <div className="justify-in-center">$250</div>,
  },
  {
    Name: "Restaurant",
    Unit: <div className="justify-in-center">$150</div>,
  },
  {
    Name: "Restaurant (More than 1 Room)",
    Unit: <div className="justify-in-center">$250</div>,
  },
  {
    Name: "Studio",
    Unit: <div className="justify-in-center">$500</div>,
  },
  {
    Name: "Outdoors",
    Unit: <div className="justify-in-center">$250</div>,
  },
  {
    Name: "Apartment (3 Room +)",
    Unit: <div className="justify-in-center">$750</div>,
  },
  {
    Name: "Cabin",
    Unit: <div className="justify-in-center">$750</div>,
  },
  {
    Name: "House (4 Rooms +, including Kitchen)",
    Unit: <div className="justify-in-center">$750</div>,
  },
];

FileConstants.TourData = [
  {
    Name: "Lifestyle 360",
    Unit: <div className="justify-in-center">$75</div>,
  },
  {
    Name: "3D Tour (Camera View Point)",
    Unit: <div className="justify-in-center">$300</div>,
  },
  {
    Name: "3D Room Camera Viewport",
    Unit: <div className="justify-in-center">$87/viewport</div>,
  },
];

FileConstants.SubscriptionPricingColumns = [
  {
    title: (
      <div
        style={{ alignText: "left", background: "#F0F2F6", textAlign: "left" }}
        className="font graphik-medium w-400 f-16 black-2b"
      >
        Subscription Pricing
      </div>
    ),
    dataIndex: "Name",
    key: "Name",
    width: "100%",
    colSpan: 2,
  },
  {
    dataIndex: "Unit",
    key: "Unit",
    colSpan: 0,
    width: "100%",
  },
];

FileConstants.AppColumns = [
  {
    title: (
      <div
        style={{ alignText: "left", background: "#F0F2F6", textAlign: "left" }}
        className="font graphik-medium w-400 f-16 black-2b"
      >
        Space Creation (Subscription and In-app)
      </div>
    ),
    dataIndex: "Name",
    key: "Name",
    width: "100%",
    colSpan: 2,
  },
  {
    dataIndex: "Unit",
    key: "Unit",
    colSpan: 0,
    width: "100%",
  },
];

FileConstants.SupportPriceColumn = [
  {
    title: (
      <div
        style={{ alignText: "left", background: "#F0F2F6", textAlign: "left" }}
        className="font graphik-medium w-400 f-16 black-2b"
      >
        Virtual Tours and 360s
      </div>
    ),
    dataIndex: "Name",
    key: "Name",
    width: "100%",
    colSpan: 2,
  },
  {
    dataIndex: "Unit",
    key: "Unit",
    colSpan: 0,
    width: "100%",
  },
];

FileConstants.PhotoServicesData = [
  {
    Name: <span>Lifestyle Images / Renders</span>,
    Free: <div className="justify-in-center">5 1K</div>,
    Pro: (
      <div className="justify-in-center">
        up to 15 2K &#160;
        <Tooltip
          title={
            <span className="manrope f-12 white">
              30 images at 1K OR
              <br />
              15 images at 2K OR
              <br />3 images at 4K
            </span>
          }
        >
          <InfoCircleOutlined />
        </Tooltip>
      </div>
    ),
    Premium: (
      <div className="justify-in-center">
        up to 15 4K &#160;
        <Tooltip
          title={
            <span className="manrope f-12 white">
              60 images at 1K OR
              <br />
              30 images at 2K OR
              <br />
              15 images at 4K
            </span>
          }
        >
          <InfoCircleOutlined />
        </Tooltip>
      </div>
    ),
    Enterprise: <div className="justify-in-center">Included</div>,
  },
  {
    Name: <span>3D Product Model Creation</span>,
    Free: <div className="justify-in-center">-</div>,
    Pro: (
      <div className="justify-in-center">
        up to 3 &#160;
        <Tooltip
          title={
            <span className="manrope f-12 white">
              In case you mix lifestyle renders and modeling services
            </span>
          }
        >
          <InfoCircleOutlined />
        </Tooltip>
      </div>
    ),
    Premium: (
      <div className="justify-in-center">
        up to 6 &#160;
        <Tooltip
          title={
            <span className="manrope f-12 white">
              In case you mix lifestyle renders and modeling services
            </span>
          }
        >
          <InfoCircleOutlined />
        </Tooltip>
      </div>
    ),
    Enterprise: <div className="justify-in-center">Included</div>,
  },
  {
    Name: "Product Images / Renders",
    Free: <div className="justify-in-center">-</div>,
    Pro: (
      <div className="justify-in-center">
        up to 4 2K &#160;
        <Tooltip
          title={
            <span className="manrope f-12 white">up to 4 2K per model</span>
          }
        >
          <InfoCircleOutlined />
        </Tooltip>
      </div>
    ),
    Premium: (
      <div className="justify-in-center">
        up to 4 2K &#160;
        <Tooltip
          title={
            <span className="manrope f-12 white">up to 4 2K per model</span>
          }
        >
          <InfoCircleOutlined />
        </Tooltip>
      </div>
    ),
    Enterprise: <div className="justify-in-center">Included</div>,
  },
  {
    Name: "ALL3D Store Product Models for Free",
    Free: <div className="justify-in-center">20</div>,
    Pro: <div className="justify-in-center">50</div>,
    Premium: <div className="justify-in-center">200</div>,
    Enterprise: <div className="justify-in-center">Included</div>,
  },
  {
    Name: "ALL3D Store Space Models for Free",
    Free: <div className="justify-in-center">2</div>,
    Pro: <div className="justify-in-center">5</div>,
    Premium: <div className="justify-in-center">10</div>,
    Enterprise: <div className="justify-in-center">Included</div>,
  },
  {
    Name: "Upload 3D Models for Free",
    Free: <div className="justify-in-center">10</div>,
    Pro: <div className="justify-in-center">10</div>,
    Premium: <div className="justify-in-center">20</div>,
    Enterprise: <div className="justify-in-center">Included</div>,
  },
  {
    Name: "Scene Creator Design Tool",
    Free: <div className="justify-in-center">Unlimited</div>,
    Pro: <div className="justify-in-center">Unlimited</div>,
    Premium: <div className="justify-in-center">Unlimited</div>,
    Enterprise: <div className="justify-in-center">Included</div>,
  },
  {
    Name: "Sharing Capability",
    Free: <div className="justify-in-center">Unlimited</div>,
    Pro: <div className="justify-in-center">Unlimited</div>,
    Premium: <div className="justify-in-center">Unlimited</div>,
    Enterprise: <div className="justify-in-center">Included</div>,
  },
  {
    Name: "Cloud Storage",
    Free: <div className="justify-in-center">Unlimited</div>,
    Pro: <div className="justify-in-center">Unlimited</div>,
    Premium: <div className="justify-in-center">Unlimited</div>,
    Enterprise: <div className="justify-in-center">Included</div>,
  },
];

FileConstants.AppPurchasesData = [
  {
    Name: "Lifestyle 360s",
    Free: (
      <div id="check" className="justify-in-center">
        <CheckOutlined />
      </div>
    ),
    Pro: (
      <div id="check" className="justify-in-center">
        <CheckOutlined />
      </div>
    ),
    Premium: (
      <div id="check" className="justify-in-center">
        <CheckOutlined />
      </div>
    ),
    Enterprise: <div className="justify-in-center">Included</div>,
  },
  {
    Name: "3D Tour",
    Free: (
      <div id="check" className="justify-in-center">
        <CheckOutlined />
      </div>
    ),
    Pro: (
      <div id="check" className="justify-in-center">
        <CheckOutlined />
      </div>
    ),
    Premium: (
      <div id="check" className="justify-in-center">
        <CheckOutlined />
      </div>
    ),
    Enterprise: <div className="justify-in-center">Included</div>,
  },
  {
    Name: "3D Product Model Creation",
    Free: (
      <div id="check" className="justify-in-center">
        <CheckOutlined />
      </div>
    ),
    Pro: (
      <div id="check" className="justify-in-center">
        -
      </div>
    ),
    Premium: (
      <div id="check" className="justify-in-center">
        -
      </div>
    ),
    Enterprise: <div className="justify-in-center">Included</div>,
  },
  {
    Name: "3D Product Variants (SKUs)",
    Free: (
      <div id="check" className="justify-in-center">
        <CheckOutlined />
      </div>
    ),
    Pro: (
      <div id="check" className="justify-in-center">
        <CheckOutlined />
      </div>
    ),
    Premium: (
      <div id="check" className="justify-in-center">
        <CheckOutlined />
      </div>
    ),
    Enterprise: <div className="justify-in-center">Included</div>,
  },
  {
    Name: "3D Space / Room Creation",
    Free: (
      <div id="check" className="justify-in-center">
        <CheckOutlined />
      </div>
    ),
    Pro: (
      <div id="check" className="justify-in-center">
        <CheckOutlined />
      </div>
    ),
    Premium: (
      <div id="check" className="justify-in-center">
        <CheckOutlined />
      </div>
    ),
    Enterprise: <div className="justify-in-center">Included</div>,
  },
  {
    Name: "AR Model Creation",
    Free: (
      <div id="check" className="justify-in-center">
        <CheckOutlined />
      </div>
    ),
    Pro: (
      <div id="check" className="justify-in-center">
        <CheckOutlined />
      </div>
    ),
    Premium: (
      <div id="check" className="justify-in-center">
        <CheckOutlined />
      </div>
    ),
    Enterprise: <div className="justify-in-center">Included</div>,
  },
  {
    Name: "Product Images / Renders",
    Free: (
      <div id="check" className="justify-in-center">
        <CheckOutlined />
      </div>
    ),
    Pro: (
      <div id="check" className="justify-in-center">
        <CheckOutlined />
      </div>
    ),
    Premium: (
      <div id="check" className="justify-in-center">
        <CheckOutlined />
      </div>
    ),
    Enterprise: <div className="justify-in-center">Included</div>,
  },
  {
    Name: "Product 360s / MP4 Videos",
    Free: (
      <div id="check" className="justify-in-center">
        <CheckOutlined />
      </div>
    ),
    Pro: (
      <div id="check" className="justify-in-center">
        <CheckOutlined />
      </div>
    ),
    Premium: (
      <div id="check" className="justify-in-center">
        <CheckOutlined />
      </div>
    ),
    Enterprise: <div className="justify-in-center">Included</div>,
  },
];

FileConstants.ConfiguratorData = [
  {
    Name: "3D Configurator",
    Free: <div className="justify-in-center">-</div>,
    Pro: <div className="justify-in-center">-</div>,
    Premium: <div className="justify-in-center">-</div>,
    Enterprise: <div className="justify-in-center">Included</div>,
  },
];

FileConstants.SupportData = [
  {
    Name: "Priority",
    Free: <a className="justify-in-center" href='/contact-us'>Contact us</a>,
    Pro: <a className="justify-in-center" href='/contact-us'>Contact us</a>,
    Premium: <a className="justify-in-center" href='/contact-us'>Contact us</a>,
    Enterprise: (
      <div className="justify-in-center" style={{ textAlign: "center" }}>
        Dedicated Support
      </div>
    ),
  },
  {
    Name: "Account Manager",
    Free: <div className="justify-in-center">-</div>,
    Pro: <div className="justify-in-center">-</div>,
    Premium: <div className="justify-in-center">-</div>,
    Enterprise: (
      <div id="check-brown" className="justify-in-center">
        <CheckOutlined />
      </div>
    ),
  },
  {
    Name: "Designer",
    Free: <div className="justify-in-center">-</div>,
    Pro: <div className="justify-in-center">-</div>,
    Premium: <div className="justify-in-center">-</div>,
    Enterprise: (
      <div id="check-brown" className="justify-in-center">
        <CheckOutlined />
      </div>
    ),
  },
  {
    Name: "Model Creation SLA",
    Free: <div className="justify-in-center">-</div>,
    Pro: <div className="justify-in-center">-</div>,
    Premium: <div className="justify-in-center">-</div>,
    Enterprise: <div className="justify-in-center">48-72 hrs delivery</div>,
  },
  {
    Name: "Seat License",
    Free: <div className="justify-in-center">1</div>,
    Pro: <div className="justify-in-center">1</div>,
    Premium: <div className="justify-in-center">1</div>,
    Enterprise: <div className="justify-in-center">Included</div>,
  },
];

FileConstants.getModelType = (value) => {
  let model_type = {
    ar: "AR Model",
    high_res: "High Res Model",
    high_res_and_ar: "High Res and AR",
  };

  return model_type[value];
};

FileConstants.variationDict = (value) => {
  let request_type_labels = {
    color: "Colors",
    material: "Materials",
    size: "Size/Dimensions",
    state: "State",
    ar_conversion: "AR Conversion",
    high_conversion: "High Res Conversion",
    other: "Others",
  };
  return request_type_labels[value];
};

FileConstants.HIDDEN_CATEGORY = {
  Bed: {
    "Bedding Type": [
      {
        type: "Bed Frame Only",
        heading: "Bed Frame only",
        price_text: "",
        subtext:
          "The bed model will be without bedding and will be based on the image shown below",
        image: "/bedframe.jpg",
        attachment: false,
      },
      {
        type: "White Mattress and Pillows",
        heading: "White Mattress and Pillows",
        price_text: "",
        subtext:
          "The bedding on your model will be based on the mattress and pillows shown below",
        image: "/simple_bedding.png",
        attachment: false,
      },
      {
        type: "Bed with Bedding",
        heading: "Custom Bedding",
        price_text: "($160 Charges Applied)",
        subtext:
          "Upload the reference images that you want the model to be based on",
        image: "/custom_bedding.png",
        attachment: true,
      },
    ],
  },
  Beds: {
    "Bedding Type": [
      {
        type: "Bed Frame Only",
        heading: "Bed Frame Only",
        price_text: "",
        subtext:
          "The bed model will be without bedding and will be based on the image shown below",
        image: "/bedframe.jpg",
        attachment: false,
      },
      {
        type: "White Mattress and Pillows",
        heading: "White Mattress and Pillows",
        price_text: "",
        subtext:
          "The bedding on your model will be based on the mattress and pillows shown below",
        image: "/simple_bedding.png",
        attachment: false,
      },
      {
        type: "Bed with Bedding",
        heading: "Custom Bedding",
        price_text: "($160 Charges Applied)",
        subtext:
          "Upload the reference images that you want the model to be based on",
        image: "/custom_bedding.png",
        attachment: true,
      },
    ],
  },
};

FileConstants.featured_in = [
  {
    name: "Apartment Theory",
    logo: "https://d48f7equ64qjl.cloudfront.net/static_files/press_logos/apartment_theory.png",
    press_link:
      "https://www.apartmenttherapy.com/john-robshaw-for-cloth-and-company-36991626",
  },
  {
    name: "Architectural Digest",
    logo: "https://d48f7equ64qjl.cloudfront.net/static_files/press_logos/ad.png",
    press_link:
      "https://www.architecturaldigest.com/story/whats-the-right-digital-rendering-solution-for-you",
  },
  {
    name: "Business of Home",
    logo: "https://d48f7equ64qjl.cloudfront.net/static_files/press_logos/business_of_home.png",
    press_link:
      "https://businessofhome.com/articles/will-3d-modeling-change-the-design-industry-forever",
  },
  {
    name: "Furniture Today",
    logo: "https://d48f7equ64qjl.cloudfront.net/static_files/press_logos/furniture_today.png",
    press_link:
      "https://www.furnituretoday.com/furniture-manufacturing/skyline-furniture-pivots-to-virtual-showhouse-to-introduce-new-product/",
  },
];

FileConstants.trusted_by_top_brands = [
  "https://d48f7equ64qjl.cloudfront.net/static_files/trusted_brands/bassett_furniture.png",
  "https://d48f7equ64qjl.cloudfront.net/static_files/trusted_brands/butler.png",
  "https://d48f7equ64qjl.cloudfront.net/static_files/trusted_brands/vispring.png",
  "https://d48f7equ64qjl.cloudfront.net/static_files/trusted_brands/the_home_depot.png",
  "https://d48f7equ64qjl.cloudfront.net/static_files/trusted_brands/hillsdale_furniture.png",
  "https://d48f7equ64qjl.cloudfront.net/static_files/trusted_brands/home_meridian.png",
  "https://d48f7equ64qjl.cloudfront.net/static_files/trusted_brands/and_so_to_bed.png",
  "https://d48f7equ64qjl.cloudfront.net/static_files/trusted_brands/skyline.png",
  "https://d48f7equ64qjl.cloudfront.net/static_files/trusted_brands/accentrics_home.png",
  "https://d48f7equ64qjl.cloudfront.net/static_files/trusted_brands/uttermost.png",
  "https://d48f7equ64qjl.cloudfront.net/static_files/trusted_brands/nathan_anthony.png",
  "https://d48f7equ64qjl.cloudfront.net/static_files/trusted_brands/flexsteel.png",
  "https://d48f7equ64qjl.cloudfront.net/static_files/trusted_brands/linon.png",
  "https://d48f7equ64qjl.cloudfront.net/static_files/trusted_brands/bassett_mirror.png",
  "https://d48f7equ64qjl.cloudfront.net/static_files/trusted_brands/my_home_furnishings.png",
];

FileConstants.why_choose_us = [
  {
    icon: "whychooseus/1.png",
    heading: "Easy to use",
    text: "No 3D experience or software required, just use your web browser with complete creative freedom.",
  },
  {
    icon: "whychooseus/2.png",
    heading: "90% faster & cheaper",
    text: "Create virtual products and storefronts without creating a physical sample or a showroom - all virtual from first sample to final sale.",
  },
  {
    icon: "whychooseus/3.png",
    heading: "High quality & photorealistic",
    text: "Fast and high quality delivery of virtual products and experiences, leveraging automatic workflows and cloud based rendering.",
  },
];

FileConstants.who_can_use_us = [
  {
    icon: "/whocanuseus/manufacturers.png",
    user: "Manufacturers",
    text: (
      <span>
        Create prototypes of products, show your products in beautiful spaces
        and generate all your virtual content in one place. Easily share your
        virtual products with your retail partners.
      </span>
    ),
    link: "",
  },
  {
    icon: "/whocanuseus/retailers.png",
    user: "Retailers",
    text: "Get all your manufacturer models in one place to create multi-vendor lifestyle content quickly and easily.",
    link: "",
  },
  {
    icon: "/whocanuseus/interior_designers.png",
    user: "Interior Designers",
    text: "Create photorealistic images, 360s and 3D tours of designs for your clients. Quickly create engaging social media content.",
    link: "",
  },
];

FileConstants.threed_tour = {
  newScheme: true,
  panos: [
    {
      title: "Living1",
      filename: "Living1.jpg",
      centerTarget: -150,
      hotspots: [
        {
          title: "Kitchen",
          position: {
            x: -200.75164778603965,
            y: -0.000011800896895335211,
            z: 400.01302322169953,
          },
        },
        {
          title: "Living2",
          position: {
            x: -200.75164778603965,
            y: -0.000011800896895335211,
            z: -100.01302322169953,
          },
        },
        {
          title: "Dining",
          position: {
            x: 0.75164778603965,
            y: -0.000011800896895335211,
            z: 20.01302322169953,
          },
        },
      ],
    },
    {
      title: "Kitchen",
      filename: "Kitchen.jpg",
      centerTarget: 270,
      hotspots: [
        {
          title: "Living2",
          position: {
            x: -20.8139205454876,
            y: 0.000007364201721103414,
            z: -150.35537553971179,
          },
        },
        {
          title: "Dining",
          position: {
            x: 150.8139205454876,
            y: 0.000007364201721103414,
            z: -100.35537553971179,
          },
        },
        {
          title: "Living1",
          position: {
            x: 70.8139205454876,
            y: 0.000007364201721103414,
            z: -150.35537553971179,
          },
        },
      ],
    },
    {
      title: "Living2",
      filename: "Living2.jpg",
      centerTarget: 60,
      hotspots: [
        {
          title: "Kitchen",
          position: {
            x: 0.8139205454876,
            y: 0.000007364201721103414,
            z: 800.35537553971179,
          },
        },
        {
          title: "Dining",
          position: {
            x: 400.8139205454876,
            y: 0.000007364201721103414,
            z: 800.35537553971179,
          },
        },
        {
          title: "Living1",
          position: {
            x: 200.8139205454876,
            y: 0.000007364201721103414,
            z: 100.35537553971179,
          },
        },
      ],
    },
    {
      title: "Dining",
      filename: "Dining.jpg",
      centerTarget: 200,
      hotspots: [
        {
          title: "Kitchen",
          position: {
            x: -100.8139205454876,
            y: 0.000007364201721103414,
            z: 100.35537553971179,
          },
        },
        {
          title: "Living2",
          position: {
            x: -100.8139205454876,
            y: 0.000007364201721103414,
            z: -150.35537553971179,
          },
        },
        {
          title: "Living1",
          position: {
            x: 0.8139205454876,
            y: 0.000007364201721103414,
            z: -100.35537553971179,
          },
        },
      ],
    },
  ],
  hotspots: {},
  status: "rendered",
  captured_by: "ashredmond",
  is_msprovider: false,
  time_updated: 1651106793865,
};

FileConstants.threed_tour2 = {
  panos: [
    {
      title: "Bedroom",
      filename: "Bedroom.jpg",
      hotspots: [
        {
          title: "Living",
          position: {
            x: -499.7682149412285,
            y: 0.000037295038563895344,
            z: -15.22272427841608,
          },
        },
      ],
    },
    {
      title: "Living",
      filename: "Living.jpg",
      hotspots: [
        {
          title: "Bedroom",
          position: {
            x: -495.75,
            y: 6.74,
            z: 60.11,
          },
        },
      ],
    },
  ],
  status: "rendered",
  hotspots: {
    Living: [
      {
        x: 0.46520061750412256,
        y: 1.2700004028119074,
        z: 6.915053840593291,
      },
      {
        x: 0.46520061750412256,
        y: 1.2700004028119074,
        z: 3.115053840593301,
      },
      {
        x: -0.7083762034119345,
        y: 1.2700004028119074,
        z: 12.853158836064349,
      },
      {
        x: 1.4652006175041226,
        y: 1.2700004028119074,
        z: 3.9150538405932913,
      },
      {
        x: 0.9652006175041226,
        y: 1.2700004028119074,
        z: 8.915053840593291,
      },
    ],
    Bedroom: [
      {
        x: 0.4706865952652439,
        y: 1.2699999107708773,
        z: 16.245870294923108,
      },
      {
        x: -0.7083760901489446,
        y: 1.2699999107708773,
        z: 14.539993726066983,
      },
      {
        x: -1.9471918202326752,
        y: 1.2699999107708773,
        z: 14.555982880496055,
      },
      {
        x: -0.9083760901489446,
        y: 1.2699999107708773,
        z: 15.239993726066983,
      },
    ],
  },
  newScheme: true,
};

FileConstants.flags = [
  "United States.png",
  "Pakistan.png",
  "Ukraine.png",
  "Turkey.png",
  "India.svg",
  "Philippines.svg",
];

FileConstants.getLogoWidth = (value) => {
  let brands = {
    "uttermost.com": "15%",
    "hillsdalefurniture.com": "10%",
    "hookerfurnishings.com": "15%",
    "hookerfurniture.com": "20%",
    "bassettfurniture.com": "10%",
    "bassettmirror.com": "25%",
    "nafurniture.com": "20%",
    "butlerspecialty.net": "20%",
    "skylinefurnituremfg.com": "15%",
    "linon.com": "10%",
    "flexsteel.com": "20%",
    "myhomefurnishings.net": "10%",
    "all3d.ai": "20%",
    "safavieh.com": "20%",
  };

  return brands[value];
};

FileConstants.getTourHeading = (value) => {
  let headings = {
    virtual_product: "Create Virtual Product",
    generate_product_assets: "Generate Product Assets",
    generate_product_variants: "Generate Product Variants",
    use_all3d_store: "Use ALL3D Store",
    create_virtual_space: "Create Virtual Space",
    create_3d_tours: "Create 3D Tours",
    create_lifestyle_images: "Create Lifestyle Images",
  };

  return headings[value];
};

FileConstants.congratsMessage = (value) => {
  let headings = {
    virtual_product: "You just learned how to create your first product model!",
    generate_product_assets:
      "You just learned how to generate product imagery and assets!",
    generate_product_variants:
      "You just learned how to create product variations!",
    use_all3d_store: "You just learned how to use ALL3D Store!",
    create_virtual_space: "You just learned how to create space models!",
    create_3d_tours: "You just learned how to generate 3D Tours!",
    create_lifestyle_images: "You just learned how to create Lifestyle Images!",
  };

  return headings[value];
};

FileConstants.exploreTourOptions = [
  "virtual_product",
  "generate_product_assets",
  "generate_product_variants",
  "use_all3d_store",
  "create_virtual_space",
  "create_3d_tours",
  "create_lifestyle_images",
];

FileConstants.TOUR_FEATURES = [
  {
    text: "Create Virtual Product",
    image: "/7.png",
    value: "virtual_product",
  },
  {
    text: "Generate Product Assets",
    image: "/6.png",
    value: "generate_product_assets",
  },
  {
    text: "Create Product Variants",
    image: "/5.png",
    value: "generate_product_variants",
  },
  {
    text: "Use ALL3D Store",
    image: "/1.png",
    value: "use_all3d_store",
  },
  {
    text: "Create Virtual Space",
    image: "/2.png",
    value: "create_virtual_space",
  },
  {
    text: "Create 3D Tours",
    image: "/3.png",
    value: "create_3d_tours",
  },
  {
    text: "Create Lifestyle Images",
    image: "/4.png",
    value: "create_lifestyle_images",
  },
];

FileConstants.PRODUCT_IMAGERY = [
  "https://d48f7equ64qjl.cloudfront.net/static_files/product_offerings/section2f.jpg",
  "https://d48f7equ64qjl.cloudfront.net/static_files/product_offerings/section2a.png",
  "https://d48f7equ64qjl.cloudfront.net/static_files/product_offerings/section2b.png",
  "https://d48f7equ64qjl.cloudfront.net/static_files/product_offerings/section2c.png",
  "https://d48f7equ64qjl.cloudfront.net/static_files/product_offerings/section2d.png",
  "https://d48f7equ64qjl.cloudfront.net/static_files/product_offerings/section2e.png",
];

FileConstants.PRODUCT_VARIANTS = [
  "https://d48f7equ64qjl.cloudfront.net/static_files/product_offerings/section3a.png",
  "https://d48f7equ64qjl.cloudfront.net/static_files/product_offerings/section3b.png",
  "https://d48f7equ64qjl.cloudfront.net/static_files/product_offerings/section3c.png",
];

FileConstants.SPACES = [
  "https://d48f7equ64qjl.cloudfront.net/static_files/product_offerings/section11e.png",
  "https://d48f7equ64qjl.cloudfront.net/static_files/product_offerings/section11c.png",
  "https://d48f7equ64qjl.cloudfront.net/static_files/product_offerings/section11a.png",
  "https://d48f7equ64qjl.cloudfront.net/static_files/product_offerings/section11b.png",
  "https://d48f7equ64qjl.cloudfront.net/static_files/product_offerings/section11d.png",
];

FileConstants.ALL3D_STORE =
  "https://d48f7equ64qjl.cloudfront.net/static_files/product_offerings/section10b.jpg";

FileConstants.BRAND_PAGE =
  "https://d48f7equ64qjl.cloudfront.net/static_files/product_offerings/section12.png";

FileConstants.LIFESTYLE_IMAGES = [
  "https://d48f7equ64qjl.cloudfront.net/static_files/product_offerings/section5a.png",
  "https://d48f7equ64qjl.cloudfront.net/static_files/product_offerings/section5b.jpg",
  "https://d48f7equ64qjl.cloudfront.net/static_files/product_offerings/section5c.png",
  "https://d48f7equ64qjl.cloudfront.net/static_files/product_offerings/section5d.jpg",
];

FileConstants.SOCIAL_MEDIA =
  "https://d48f7equ64qjl.cloudfront.net/static_files/product_offerings/section8.jpg";

FileConstants.AI_CONTENT =
  "/sofa-ai-content.svg";

FileConstants.HOLISTIC_SECTION_1 =
  "https://d48f7equ64qjl.cloudfront.net/static_files/holistic_solution/slide_1.png";

FileConstants.HOLISTIC_SECTION_2 = [
  "https://d48f7equ64qjl.cloudfront.net/static_files/holistic_solution/slide_2a.png",
  "https://d48f7equ64qjl.cloudfront.net/static_files/holistic_solution/slide_2b.png",
  "https://d48f7equ64qjl.cloudfront.net/static_files/holistic_solution/slide_2c.png",
];

FileConstants.HOLISTIC_SECTION_3 = [
  "https://d48f7equ64qjl.cloudfront.net/static_files/holistic_solution/slide_3a.png",
  "https://d48f7equ64qjl.cloudfront.net/static_files/holistic_solution/slide_3b.png",
];

FileConstants.HOLISTIC_SECTION_4 =
  "https://d48f7equ64qjl.cloudfront.net/static_files/holistic_solution/slide_4.png";
FileConstants.HOLISTIC_SECTION_5 =
  "https://d48f7equ64qjl.cloudfront.net/static_files/holistic_solution/slide_5.png";

FileConstants.CONFIGURATOR =
  "https://d48f7equ64qjl.cloudfront.net/static_files/product_offerings/section4.jpg";
FileConstants.tour_images = (value) => {
  let headings = {
    virtual_product: [
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/virtual_product/1.png",
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/virtual_product/2.png",
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/virtual_product/3.png",
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/virtual_product/4.png",
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/virtual_product/5.png",
    ],
    generate_product_assets: [
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/generate_product_assets/1.png",
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/generate_product_assets/2.png",
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/generate_product_assets/3.png",
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/generate_product_assets/4.png",
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/generate_product_assets/5.png",
    ],
    generate_product_variants: [
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/generate_product_variants/1.png",
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/generate_product_variants/2.png",
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/generate_product_variants/3.png",
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/generate_product_variants/4.png",
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/generate_product_variants/5.png",
    ],
    use_all3d_store: [
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/use_all3d_store/1.png",
    ],
    create_virtual_space: [
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/create_virtual_space/1.png",
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/create_virtual_space/2.png",
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/create_virtual_space/3.png",
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/create_virtual_space/4.png",
    ],
    create_3d_tours: [
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/create_3d_tours/1.png",
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/create_3d_tours/2.png",
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/create_3d_tours/3.png",
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/create_3d_tours/4.png",
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/create_3d_tours/5.png",
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/create_3d_tours/6.png",
    ],
    create_lifestyle_images: [
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/create_lifestyle_images/1.png",
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/create_lifestyle_images/2.png",
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/create_lifestyle_images/3.png",
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/create_lifestyle_images/4.png",
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/create_lifestyle_images/5.png",
      "https://d48f7equ64qjl.cloudfront.net/static_files/tour/create_lifestyle_images/6.png",
    ],
  };

  return headings[value];
};

FileConstants.UNIT_PRICING_CATEGORIES = [
  "virtual_products",
  "virtual_spaces",
  "imaging",
  "virtual_tours",
  "additional_services",
];

FileConstants.UNIT_PRICING_CATEGORY_NAMES = {
  virtual_products: "Virtual Products",
  virtual_spaces: "Virtual Spaces & Showrooms",
  imaging: "Imaging",
  virtual_tours: "Virtual Tours & 360",
  additional_services: "Additional Services",
};

FileConstants.UNIT_PRICE_LIST = {
  virtual_products: [
    "product_model_high_res",
    "product_model_unlimited_qa_passes",
    "variation_models",
    "existing_product_model",
    "product_model_material_application",
  ],
  virtual_spaces: ["space_models"],
  imaging: ["product_silo", "scene_lifestyle_image"],
  virtual_tours: [
    "scene_lifestyle_360_4k",
    "3d_tour_2_4k",
    "3d_tour_camera_viewpoint",
  ],
  additional_services: [
    "managed_service_lifestyle_image_4k",
    "product_360_spin_4k",
    "product_video_1k",
    "sla",
  ],
};

FileConstants.UNIT_PRICING_SUB_CATEGORIES = {
  space_models: [
    "room_model_apartment",
    "room_model_bathroom",
    "room_model_bedroom",
    "room_model_cabin",
    "room_model_conference_room",
    "room_model_dining_room",
    "room_model_entry",
    "room_model_garden",
    "room_model_hallway",
    "room_model_hotel_room",
    "room_model_kitchen",
    "room_model_house_(4_rooms_+,_including_kitchen)",
    "room_model_living_room",
    "room_model_office",
    "room_model_other",
    "room_model_outdoors",
    "room_model_patio",
    "room_model_pool",
    "room_model_restaurant_(1_room)",
    "room_model_restaurant_(more_than_1_room)",
    "room_model_store_(1_room)",
    "room_model_store_(more_than_1_room)",
    "room_model_studio",
  ],
  product_silo: ["product_silo_2k", "product_silo_4k"],
  variation_models: [
    "variation_product_model_color",
    "variation_product_model_hardware",
    "variation_product_model_material",
    "variation_product_model_size",
    "variation_product_model_bedding",
    "variation_product_model_state",
    "variation_product_model_ar_conversion",
  ],
  scene_lifestyle_image: [
    "scene_lifestyle_image_1k",
    "scene_lifestyle_image_2k",
    "scene_lifestyle_image_4k",
  ],
};

FileConstants.setRequestTypeText = (value, summaryState = false) => {
  let request_type_labels = {
    space_models: "Space Model Creation (between $175 - $750)",
    variation_models: "Model Changes",
    product_silo: summaryState
      ? "Total Silo Images"
      : "Product Images/ Renders",
    product_silo_4k: "Product Image/ Render @ 4K",
    product_silo_3k: "Silo Image (3K)",
    product_silo_2k: "Product Image/ Render @ 2K",
    product_silo_1k: "Product Image/ Render @ 1K",
    product_silo_0k: "Silo Image (Custom)",
    material_swatch_silo: "Material Swatch for Silos",
    sla: "SLA",

    product_video: summaryState
      ? "Total Product MP4 Videos"
      : "Product MP4 Videos",
    product_video_1k: "Product MP4 Video",
    product_dimensional: summaryState
      ? "Total Product Dimensional Images"
      : "Product Dimensional Images",
    product_360_spin: summaryState
      ? "Total Product 360 Spins"
      : "Product 360 Spins",

    product_dimensional_1k: "Product Dimensional (1K)",
    product_dimensional_2k: "Product Dimensional (2K)",
    product_dimensional_3k: "Product Dimensional (3K)",
    product_dimensional_4k: "Product Dimensional (4K)",

    product_360_spin_2k: "Product 360 Spin (2K)",
    product_360_spin_4k: "Product 360 @ 4K",
    product_360_spin_1k: "Product 360 Spin (1K)",

    product_model_ar: "Product Model Creation (AR)",
    product_model_high_res: "Model Creation (1 Change)",
    product_model_high_res_and_ar: "Model Creation (1 Change)",
    existing_product_model: "User Uploaded Model",
    existing_product_model_fix: "User Uploaded Model Fix",
    app_product_model: "Scanned Model Creation",
    product_model_high_res_and_ar_beds:
      "Product Model AR & High Res (Custom Bedding)",
    product_model_ar_beds: "Product Model AR (Custom Bedding)",
    product_model_high_res_beds: "Product Model High Res (Custom Bedding)",
    existing_product_model_beds: "User Uploaded Model (Custom Bedding)",
    product_model_bed: "Product Model (Custom Bedding)",
    product_model_ar_bed: "Product Model AR (Custom Bedding)",
    product_model_high_res_and_ar_bed:
      "Product Model AR & High Res (Custom Bedding)",
    app_product_model_bed: "Scanned Product Model (Custom Bedding)",

    variation_product_model_: "Product Model Variation",
    variation_product_model_color: "Model Color Change",
    variation_product_model_hardware: "Model Hardware Change",
    variation_product_model_material: "Model Material Change",
    variation_product_model_size: "Model Size Change",
    variation_product_model_bedding: "Bed Variant (By Category Only - Bedding)",
    variation_product_model_ar_conversion:
      "AR Model (Converting an Existing High Res Model to AR)",
    variation_product_model_high_conversion:
      "Product Model Variation (High Res Conversion)",
    variation_product_model_other: "Product Model Variation (Other)",
    variation_product_model_state: "Model State Change",

    room_model: "Space Model Creation",
    variation_room_model: "Space Model Variation",
    room_model_apartment: "Apartment",
    variation_room_model_apartment: "Space Model Variation (Apartment)",
    variation_room_model_bathroom: "Space Model Variation (Bathroom)",
    room_model_bathroom: "Bathroom",
    room_model_bedroom: "Bedroom",
    variation_room_model_bedroom: "Space Model Variation (Bedroom)",
    room_model_cabin: "Cabin",
    variation_room_model_cabin: "Space Model Variation (Cabin)",
    room_model_conference_room: "Conference Room",
    variation_room_model_conference_room:
      "Space Model Variation (Conference Room)",
    room_model_dining_room: "Dining Room",
    variation_room_model_dining_room: "Space Model Variation (Dining Room)",
    variation_room_model_entry: "Space Model Variation (Entry)",
    room_model_entry: "Entry",
    room_model_garden: "Garden",
    variation_room_model_garden: "Space Model Variation (Garden)",
    room_model_hallway: "Hallway",
    variation_room_model_hallway: "Space Model Variation (Hallway)",
    room_model_hotel_room: "Hotel Room",
    variation_room_model_hotel_room: "Space Model Variation (Hotel Room)",
    "room_model_house_(4_rooms_+,_including_kitchen)":
      "House - 4 Rooms including Kitchen",
    "variation_room_model_house_(4_rooms_+,_including_kitchen)":
      "Space Model Variation (House - 4 Rooms including Kitchen)",
    variation_room_model_kitchen: "Space Model Variation (Kitchen)",
    room_model_kitchen: "Kitchen",
    room_model_living_room: "Living Room",
    variation_room_model_living_room: "Space Model Variation (Living Room)",
    variation_room_model_office: "Space Model Variation (Office)",
    room_model_office: "Office",
    variation_room_model_other: "Space Model Variation (Other)",
    room_model_other: "Other",
    room_model_outdoors: "Outdoors",
    variation_room_model_outdoors: "Space Model Variation (Outdoors)",
    variation_room_model_patio: "Space Model Variation (Patio)",
    room_model_patio: "Patio",
    variation_room_model_pool: "Space Model Variation (Pool)",
    room_model_pool: "Pool",
    "variation_room_model_restaurant_(1_room)": "Restaurant - 1 Room",
    "variation_room_model_restaurant_(more_than_1_room)":
      "Space Model Variation (Restaurant - More Than 1 Room)",
    "variation_room_model_store_(1_room)":
      "Space Model Variation (Store - 1 Room)",
    "variation_room_model_store_(more_than_1_room)":
      "Space Model Variation (Store - More Than 1 Room)",
    variation_room_model_studio: "Space Model Variation (Studio)",
    "room_model_restaurant_(1_room)": "Restaurant - 1 Room",
    "room_model_restaurant_(more_than_1_room)": "Restaurant - More Than 1 Room",
    "room_model_store_(1_room)": "Store - 1 Room",
    "room_model_store_(more_than_1_room)": "Store - More Than 1 Room",
    room_model_studio: "Studio",

    new_material: "New Material Creation",
    scanned_material: "Scanned Material Creation",

    product_model_material_application:
      "Material Application on Model (Material Already in Material Library)",
    product_model_unlimited_qa_passes:
      "Model Creation (Unlimited Changes)",

    scene_lifestyle_image: summaryState
      ? "Total Scene Lifestyle Photos"
      : "Lifestyle Images/ Renders",
    scene_lifestyle_image_4k: "Lifestyle Image/ Render @ 4K",
    scene_lifestyle_image_2k: "Lifestyle Image/ Render @ 2K",
    scene_lifestyle_image_1k: "Lifestyle Image/ Render @ 1K",
    scene_lifestyle_image_0k: "Lifestyle Image/ Render (Custom)",

    scene_lifestyle_360: summaryState
      ? "Total Scene Lifestyle 360s"
      : "Scene Lifestyle 360",
    scene_lifestyle_360_4k: "Lifestyle 360 @ 4K",
    scene_lifestyle_360_2k: "Scene Lifestyle 360 (2K)",
    scene_lifestyle_360_1k: "Scene Lifestyle 360 (1K)",
    scene_lifestyle_360_0k: "Scene Lifestyle 360 (Custom)",

    scene_lifestyle_3d_tour: summaryState ? "Total 3D Tours" : "3D Tour",
    scene_lifestyle_3d_tour_4k: "3D Tour (4K)",
    "3d_tour_2_4k": "3D Tour (Camera View Point)",
    "3d_tour_camera_viewpoint": "3D Room Camera Viewport",
    scene_lifestyle_3d_tour_2k: "3D Tour (2K)",
    scene_lifestyle_3d_tour_1k: "3D Tour (1K)",
    scene_lifestyle_3d_tour_0k: "3D Tour (Custom)",

    shared_material: "Shared Material",
    shared_product: "Shared Product",
    shared_space: "Shared Space",

    flagged_lifestyle_image: "Flagged Scene Lifestyle Photos",
    flagged_lifestyle_image_0k: "Flagged Scene Lifestyle Photo (Custom)",
    flagged_lifestyle_image_1k: "Flagged Scene Lifestyle Photo (1K)",
    flagged_lifestyle_image_2k: "Flagged Scene Lifestyle Photo (2K)",
    flagged_lifestyle_image_4k: "Flagged Scene Lifestyle Photo (4K)",

    flag_resolved_lifestyle_image: "Complaint Resolved Lifestyle Photos",
    flag_resolved_lifestyle_image_0k:
      "Complaint Resolved Lifestyle Photo (Custom)",
    flag_resolved_lifestyle_image_1k: "Complaint Resolved Lifestyle Photo (1K)",
    flag_resolved_lifestyle_image_2k: "Complaint Resolved Lifestyle Photo (2K)",
    flag_resolved_lifestyle_image_4k: "Complaint Resolved Lifestyle Photo (4K)",

    flagged_lifestyle_360: "Flagged Lifestyle 360s",
    flagged_lifestyle_360_0k: "Flagged Lifestyle 360 (Custom)",
    flagged_lifestyle_360_1k: "Flagged Lifestyle 360 (1K)",
    flagged_lifestyle_360_2k: "Flagged Lifestyle 360 (2K)",
    flagged_lifestyle_360_4k: "Flagged Lifestyle 360 (4k)",

    flag_resolved_lifestyle_360: "Complaint Resolved Lifestyle 360s",
    flag_resolved_lifestyle_360_0k: "Complaint Resolved Lifestyle 360 (Custom)",
    flag_resolved_lifestyle_360_1k: "Complaint Resolved Lifestyle 360 (1K)",
    flag_resolved_lifestyle_360_2k: "Complaint Resolved Lifestyle 360 (2K)",
    flag_resolved_lifestyle_360_4k: "Complaint Resolved Lifestyle 360 (4K)",

    flag_resolved_lifestyle_360_shared: "(Shared) Complaint Resolved 360s",
    flag_resolved_lifestyle_360_shared_0k:
      "(Shared) Complaint Resolved 360 (Custom)",
    flag_resolved_lifestyle_360_shared_1k:
      "(Shared) Complaint Resolved 360 (1K)",
    flag_resolved_lifestyle_360_shared_2k:
      "(Shared) Complaint Resolved 360 (2K)",
    flag_resolved_lifestyle_360_shared_4k:
      "(Shared) Complaint Resolved 360 (4K)",

    flagged_lifestyle_360_shared: "(Shared) Flagged Lifestyle 360s",
    flagged_lifestyle_360_shared_0k: "(Shared) Flagged Lifestyle 360 (Custom)",
    flagged_lifestyle_360_shared_1k: "(Shared) Flagged Lifestyle 360 (1K)",
    flagged_lifestyle_360_shared_2k: "(Shared) Flagged Lifestyle 360 (2K)",
    flagged_lifestyle_360_shared_4k: "(Shared) Flagged Lifestyle 360 (4k)",

    flag_resolved_lifestyle_image_shared:
      "(Shared) Complaint Resolved Lifestyle Photos",
    flag_resolved_lifestyle_image_shared_0k:
      "(Shared) Complaint Resolved Lifestyle Photos (Custom)",
    flag_resolved_lifestyle_image_shared_1k:
      "(Shared) Complaint Resolved Lifestyle Photos (1K)",
    flag_resolved_lifestyle_image_shared_2k:
      "(Shared) Complaint Resolved Lifestyle Photos (2K)",
    flag_resolved_lifestyle_image_shared_4k:
      "(Shared) Complaint Resolved Lifestyle Photos (4K)",

    flagged_lifestyle_image_shared: "(Shared) Flagged Lifestyle Photos",
    flagged_lifestyle_image_shared_0k:
      "(Shared) Flagged Lifestyle Photos (Custom)",
    flagged_lifestyle_image_shared_1k: "(Shared) Flagged Lifestyle Photos (1K)",
    flagged_lifestyle_image_shared_2k: "(Shared) Flagged Lifestyle Photos (2K)",
    flagged_lifestyle_image_shared_4k: "(Shared) Flagged Lifestyle Photos (4K)",

    scene_lifestyle_3d_tour_shared: summaryState
      ? "(Shared) Total 3D Tours"
      : "(Shared) 3D Tour",
    scene_lifestyle_3d_tour_shared_0k: "(Shared) 3D Tour (Custom)",
    scene_lifestyle_3d_tour_shared_1k: "(Shared) 3D Tour (1K)",
    scene_lifestyle_3d_tour_shared_2k: "(Shared) 3D Tour (2K)",
    scene_lifestyle_3d_tour_shared_4k: "(Shared) 3D Tour (4K)",

    scene_lifestyle_image_shared: summaryState
      ? "(Shared) Total Lifestyle Photos"
      : "(Shared) Lifestyle Photo",
    scene_lifestyle_image_shared_0k: "(Shared) Lifestyle Photo (Custom)",
    scene_lifestyle_image_shared_1k: "(Shared) Lifestyle Photo (1K)",
    scene_lifestyle_image_shared_2k: "(Shared) Lifestyle Photo (2K)",
    scene_lifestyle_image_shared_4k: "(Shared) Lifestyle Photo (4K)",

    scene_lifestyle_360_shared: summaryState
      ? "(Shared) Total Scene 360s"
      : "(Shared) Scene 360",
    scene_lifestyle_360_shared_0k: "(Shared) Scene 360 (Custom)",
    scene_lifestyle_360_shared_1k: "(Shared) Scene 360 (1K)",
    scene_lifestyle_360_shared_2k: "(Shared) Scene 360 (2K)",
    scene_lifestyle_360_shared_4k: "(Shared) Scene 360 (4K)",

    managed_service_lifestyle_image: summaryState
      ? "Total Managed Service Lifestyle Photos"
      : "Managed Service Lifestyle Photo",
    managed_service_lifestyle_image_0k:
      "Managed Service Lifestyle Photo (Custom)",
    managed_service_lifestyle_image_1k: "Managed Service Lifestyle Photo (1K)",
    managed_service_lifestyle_image_2k: "Managed Service Lifestyle Photo (2K)",
    managed_service_lifestyle_image_4k: "Design Services per Product",

    managed_service_flagged_lifestyle_image:
      "Managed Service Flagged Lifestyle Photos",
    managed_service_flagged_lifestyle_image_0k:
      "Managed Service Flagged Lifestyle Photo (Custom)",
    managed_service_flagged_lifestyle_image_1k:
      "Managed Service Flagged Lifestyle Photo (1K)",
    managed_service_flagged_lifestyle_image_2k:
      "Managed Service Flagged Lifestyle Photo (2K)",
    managed_service_flagged_lifestyle_image_4k:
      "Managed Service Flagged Lifestyle Photo (4K)",

    managed_service_flag_resolved_lifestyle_image:
      "Managed Service Complaint Resolved Lifestyle Photos",
    managed_service_flag_resolved_lifestyle_image_0k:
      "Managed Service Complaint Resolved Lifestyle Photo (Custom)",
    managed_service_flag_resolved_lifestyle_image_1k:
      "Managed Service Complaint Resolved Lifestyle Photo (1K)",
    managed_service_flag_resolved_lifestyle_image_2k:
      "Managed Service Complaint Resolved Lifestyle Photo (2K)",
    managed_service_flag_resolved_lifestyle_image_4k:
      "Managed Service Complaint Resolved Lifestyle Photo (4K)",

    managed_service_lifestyle_360: summaryState
      ? "Total Managed Service Lifestyle 360s"
      : "Managed Service Lifestyle 360",
    managed_service_lifestyle_360_0k: "Managed Service Lifestyle 360 (Custom)",
    managed_service_lifestyle_360_1k: "Managed Service Lifestyle 360 (1K)",
    managed_service_lifestyle_360_2k: "Managed Service Lifestyle 360 (2K)",
    managed_service_lifestyle_360_4k: "Managed Service Lifestyle 360 (4K)",

    managed_service_flagged_lifestyle_360:
      "Managed Service Flagged Lifestyle 360s",
    managed_service_flagged_lifestyle_360_0k:
      "Managed Service Flagged Lifestyle 360 (Custom)",
    managed_service_flagged_lifestyle_360_1k:
      "Managed Service Flagged Lifestyle 360 (1K)",
    managed_service_flagged_lifestyle_360_2k:
      "Managed Service Flagged Lifestyle 360 (2K)",
    managed_service_flagged_lifestyle_360_4k:
      "Managed Service Flagged Lifestyle 360 (4K)",

    managed_service_flag_resolved_lifestyle_360:
      "Managed Service Complaint Resolved Lifestyle 360s",
    managed_service_flag_resolved_lifestyle_360_0k:
      "Managed Service Complaint Resolved Lifestyle 360 (Custom)",
    managed_service_flag_resolved_lifestyle_360_1k:
      "Managed Service Complaint Resolved Lifestyle 360 (1K)",
    managed_service_flag_resolved_lifestyle_360_2k:
      "Managed Service Complaint Resolved Lifestyle 360 (2K)",
    managed_service_flag_resolved_lifestyle_360_4k:
      "Managed Service Complaint Resolved Lifestyle 360 (4K)",

    managed_service_lifestyle_image_shared: summaryState
      ? "(Shared) Total Managed Lifestyle Photos"
      : "(Shared) Managed Lifestyle Photo",
    managed_service_lifestyle_image_shared_0k:
      "(Shared) Managed Lifestyle Photo (Custom)",
    managed_service_lifestyle_image_shared_1k:
      "(Shared) Managed Lifestyle Photo (1K)",
    managed_service_lifestyle_image_shared_2k:
      "(Shared) Managed Lifestyle Photo (2K)",
    managed_service_lifestyle_image_shared_4k:
      "(Shared) Managed Lifestyle Photo (4K)",

    managed_service_flagged_lifestyle_image_shared:
      "(Shared) Managed Service Flagged Lifestyle Photos",
    managed_service_flagged_lifestyle_image_shared_0k:
      "(Shared) Managed Service Flagged Lifestyle Photo (Custom)",
    managed_service_flagged_lifestyle_image_shared_1k:
      "(Shared) Managed Service Flagged Lifestyle Photo (1K)",
    managed_service_flagged_lifestyle_image_shared_2k:
      "(Shared) Managed Service Flagged Lifestyle Photo (2K)",
    managed_service_flagged_lifestyle_image_shared_4k:
      "(Shared) Managed Service Flagged Lifestyle Photo (4K)",

    managed_service_flag_resolved_lifestyle_image_shared:
      "(Shared) Managed Service Complaint Resolved Lifestyle Photos",
    managed_service_flag_resolved_lifestyle_image_shared_0k:
      "(Shared) Managed Service Complaint Resolved Lifestyle Photo (Custom)",
    managed_service_flag_resolved_lifestyle_image_shared_1k:
      "(Shared) Managed Service Complaint Resolved Lifestyle Photo (1K)",
    managed_service_flag_resolved_lifestyle_image_shared_2k:
      "(Shared) Managed Service Complaint Resolved Lifestyle Photo (2K)",
    managed_service_flag_resolved_lifestyle_image_shared_4k:
      "(Shared) Managed Service Complaint Resolved Lifestyle Photo (4K)",

    managed_service_lifestyle_360_shared: "(Shared) Managed Lifestyle 360s",
    managed_service_lifestyle_360_shared_0k:
      "(Shared) Managed Lifestyle 360 (Custom)",
    managed_service_lifestyle_360_shared_1k:
      "(Shared) Managed Lifestyle 360 (1K)",
    managed_service_lifestyle_360_shared_2k:
      "(Shared) Managed Lifestyle 360 (2K)",
    managed_service_lifestyle_360_shared_4k:
      "(Shared) Managed Lifestyle 360 (4K)",

    managed_service_flagged_lifestyle_360_shared:
      "(Shared) Managed Service Flagged Lifestyle 360s",
    managed_service_flagged_lifestyle_360_shared_0k:
      "(Shared) Managed Service Flagged Lifestyle 360 (Custom)",
    managed_service_flagged_lifestyle_360_shared_1k:
      "(Shared) Managed Service Flagged Lifestyle 360 (1K)",
    managed_service_flagged_lifestyle_360_shared_2k:
      "(Shared) Managed Service Flagged Lifestyle 360 (2K)",
    managed_service_flagged_lifestyle_360_shared_4k:
      "(Shared) Managed Service Flagged Lifestyle 360 (4K)",

    managed_service_flag_resolved_lifestyle_360_shared:
      "(Shared) Managed Service Complaint Resolved Lifestyle 360s",
    managed_service_flag_resolved_lifestyle_360_shared_0k:
      "(Shared) Managed Service Complaint Resolved Lifestyle 360 (Custom)",
    managed_service_flag_resolved_lifestyle_360_shared_1k:
      "(Shared) Managed Service Complaint Resolved Lifestyle 360 (1K)",
    managed_service_flag_resolved_lifestyle_360_shared_2k:
      "(Shared) Managed Service Complaint Resolved Lifestyle 360 (2K)",
    managed_service_flag_resolved_lifestyle_360_shared_4k:
      "(Shared) Managed Service Complaint Resolved Lifestyle 360 (4K)",

    managed_service_3d_tour_shared: summaryState
      ? "(Shared) Total Managed 3D Tours"
      : "(Shared) Managed 3D Tour",
    managed_service_3d_tour_shared_0k: "(Shared) Managed 3D Tour (Custom)",
    managed_service_3d_tour_shared_1k: "(Shared) Managed 3D Tour (1K)",
    managed_service_3d_tour_shared_2k: "(Shared) Managed 3D Tour (2K)",
    managed_service_3d_tour_shared_4k: "(Shared) Managed 3D Tour (4K)",

    managed_service_3d_tour: summaryState
      ? "Total Managed Service 3D Tours"
      : "Managed Service 3D Tour",
    managed_service_3d_tour_0k: "Managed Service 3D Tour (Custom)",
    managed_service_3d_tour_1k: "Managed Service 3D Tour (1K)",
    managed_service_3d_tour_2k: "Managed Service 3D Tour (2K)",
    managed_service_3d_tour_4k: "Managed Service 3D Tour (4K)",
  };

  return request_type_labels[value];
};

FileConstants.HIGH_POINT_LANDING_PAGE = {
  store: "../highPointLandingPage/store.jpeg",
  chair: "../highPointLandingPage/chair.png",
  lifestyle: "../highPointLandingPage/lifestyle.jpeg",
  space: "../highPointLandingPage/3d-space.jpeg",
  virtual_tour: "../highPointLandingPage/virtual-tour.png",
  icon: "../highPointLandingPage/icon.svg",
};

FileConstants.STYLE_CATEGORY = [
  'Mid Century',
  'Traditional',
  'Farmhouse',
  'Modern',
  'Glam',
  'Rustic',
  'Global',
  'Bohemian',
  'Ecclectic',
  'Industrial',
  'Coastal',
  'Transitional',
  'Other'
]

FileConstants.ALL3D_COMPANY_IDS = () => ENVIRONMENT.ENV == 'production'?['274','2378']:['1', '67']

export default FileConstants;
