import NavBar from "../landingPage/header/navbar";
import React, { useState } from "react";
import Footer from "../landingPage/footer";
import "./index.scss";
import FileConstants from "../../FileConstants";
import TextConstants from "../../TextConstants";
import MetaTagContents from "../../MetaTagContents";
import { Tabs, Row, Col, Card, Modal, Button, Carousel, Select, Collapse } from "antd";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import {
  CloseOutlined,
  LeftCircleOutlined,
  LeftOutlined,
  LoadingOutlined,
  RightCircleOutlined,
  RightOutlined,
} from "@ant-design/icons";
import * as Utilities from "../../Utilities";
import $ from "jquery";
import { Helmet } from "react-helmet";
import VisibilitySensor from "react-visibility-sensor";

const { TabPane } = Tabs;
const { Option } = Select;
const { Panel } = Collapse;

const HowALL3DWorks = () => {
  let carousel = React.createRef();
  const [selectedFeature, setSelectedFeature] = useState("");
  // const [carousel, setCarousel] = useState(React.createRef());
  const [congratsModal, setCongratsModal] = useState(false);
  const [carouselData, setCarouselData] = useState([]);
  const [totalSlides, setTotalSlides] = useState(0);
  const [triggerNext, setTriggerNext] = useState(false);
  const [carouselStart, setCarouselStart] = useState(1);

  const search = useLocation().search;
  const featureInUrl = new URLSearchParams(search).get('feature');

  useEffect(() => {
    let data_arr = [];
    FileConstants.exploreTourOptions.map((option) => {
      data_arr[option] = FileConstants.tour_images(option);
    });
    setCarouselData(data_arr);
    if (featureInUrl) {
      setSelectedFeature(featureInUrl);
      openFeatureSteps(featureInUrl);
    }
  }, []);

  const openFeatureSteps = (feature) => {
    if (carousel && carousel.current) {
      carousel.goTo(0, true);
    }

    setSelectedFeature(feature);
    const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + `?feature=${feature}`;
    window.history.pushState({ path: newUrl }, '', newUrl);

  };

  const learnMore = () => {
    setCongratsModal(false);
    setSelectedFeature("");
  };

  const onCancelCongrats = () => {
    setCongratsModal(false);
    setSelectedFeature("");
  };

  const next = () => {
    if (carousel) {
      carousel.next();
      //this detects that the user moved the carousel through next/prev button
      setTriggerNext(true);
    }
  };

  const prev = () => {
    //if user moves back from step from carousel then close the carousel
    if (carousel) {
      if (carouselStart == 1) {
        carousel.goTo(0, true);
        setSelectedFeature("");
        setCongratsModal(false);
        setTriggerNext(false);
      } else {
        carousel.prev();
        //this detects that the user moved the carousel through next/prev button
        setTriggerNext(true);
      }
    }
  };

  const closeCarouselSlides = () => {
    setSelectedFeature("");
    setCarouselStart(1);
    const newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname;
    window.history.pushState({ path: newUrl }, '', newUrl);
    carousel.goTo(0, true);
  };

  const onChangeCarousel = (before, to) => {
    setCarouselStart(to + 1);
    if (selectedFeature != "") {
      //if user completes the tour i.e if the next step is equal to the number of images on s3, then show congrats modal
      if (
        before == carouselData[selectedFeature].length - 1 &&
        to == 0 &&
        triggerNext
      ) {
        if (carousel) {
          carousel.goTo(0, true);
        }
        
        if (Utilities.deviceType() == "desktop") {
          setCongratsModal(true);
        } else {
          setSelectedFeature("");
        }
        setTriggerNext(false);
      } else if (
        before == 0 &&
        to == carouselData[selectedFeature].length - 1 &&
        triggerNext
      ) {
        //if the user goes back from the first step, then close carousel and move to previous step
        setSelectedFeature("");
        setCongratsModal(false);
        setTriggerNext(false);
      }
    }
  };

  const onCancelCarousel = () => {
    if (carousel) {
      carousel.goTo(0, true);
    }
    setSelectedFeature("");
  };
  const { name, content, schemaMarkup } = MetaTagContents.HOW_ALL3D_WORKS;

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{MetaTagContents.HOW_ALL3D_WORKS.title}</title>
        <link rel="canonical" href="https://all3d.ai/how-all3d-works" />
        <meta name={name} content={content} />
        {Object.keys(schemaMarkup).map((featureSchema) => {
          return (
            <script type="application/ld+json">
              {JSON.stringify(schemaMarkup[featureSchema])}
            </script>
          )
        })}
      </Helmet>
      <React.Fragment>
        <NavBar />
        <div className="container-fluid how-all3d-works-container">
          <div className="how-all3d-works">
            <WebVersion openFeatureSteps={openFeatureSteps} />
            <MobileVersion openFeatureSteps={openFeatureSteps} />
          </div>
        </div>
        <Modal
          onCancel={onCancelCarousel}
          title={null}
          closable={false}
          maskClosable={false}
          footer={null}
          visible={selectedFeature != "" && !congratsModal}
          bodyStyle={{ padding: 0 }}
          className="tour-modal"
          centered={true}
        >
          <div
            style={{ padding: "20px 28px" }}
            id={`modal-container`}
            className="container-portrait"
          >
            {carouselData[selectedFeature] &&
              carouselData[selectedFeature].length > 0 ? (
              <>
                {Utilities.deviceType() == "desktop" ? (
                  <div
                    className="manrope f-24 black-14 w-600 justify-in-center"
                    style={{ textAlign: "center", margin: "auto" }}
                  >
                    {FileConstants.getTourHeading(selectedFeature)}
                  </div>
                ) : (
                  <div className="justify-space-between">
                    <div className="manrope f-16 black-14 w-600">
                      {FileConstants.getTourHeading(selectedFeature)}
                    </div>
                    <div className="justify-in-end">
                      <p
                        style={{ marginBottom: 0, marginRight: 10 }}
                        className="manrope f-14 black-14"
                      >
                        {carouselStart} / {carouselData[selectedFeature].length}
                      </p>
                      <CloseOutlined onClick={closeCarouselSlides} />
                    </div>
                  </div>
                )}

                <div
                  className="justify-in-center"
                  style={{ position: "relative" }}
                >
                  {Utilities.deviceType() == "desktop" ? (
                    ""
                  ) : (
                    <div className="car-btn-bg left" onClick={() => prev()}>
                      <div className="justify-in-center pos-icon">
                        <LeftCircleOutlined style={{ color: "white" }} />
                      </div>
                    </div>
                  )}
                  <div style={{ width: "100%", textAlign: "center" }}>
                    <Carousel
                      dots={false}
                      autoplay={false}
                      ref={(node) => carousel = node}
                      style={{ width: "100%" }}
                      beforeChange={onChangeCarousel}
                    >
                      {carouselData[selectedFeature].map((img, index) => (
                        <div>
                          <img
                            style={{ marginTop: 21 }}
                            className="carousel-help"
                            src={img}
                            alt="carousel help"
                          />
                        </div>
                      ))}
                    </Carousel>
                  </div>
                  {Utilities.deviceType() == "desktop" ? (
                    ""
                  ) : (
                    <div className="car-btn-bg right" onClick={() => next()}>
                      <div className="justify-in-center pos-icon">
                        <RightCircleOutlined style={{ color: "white" }} />
                      </div>
                    </div>
                  )}
                </div>

                {Utilities.deviceType() == "desktop" && (
                  <div
                    className="justify-space-between"
                    style={{ marginTop: 28 }}
                  >
                    <Button
                      className="modal-okay-light-gray square font-14"
                      onClick={closeCarouselSlides}
                    >
                      Skip Tour
                    </Button>
                    <div className="justify-in-end">
                      <Button
                        className="modal-okay-light-gray square font-14"
                        style={{ marginRight: 30 }}
                        onClick={() => prev()}
                      >
                        <LeftOutlined />
                      </Button>
                      <p
                        style={{ marginBottom: 0, marginRight: 30 }}
                        className="manrope f-14 black-14"
                      >
                        {carouselStart} / {carouselData[selectedFeature].length}
                      </p>
                      <Button
                        className="modal-okay square font-14"
                        onClick={() => next()}
                      >
                        {" "}
                        <RightOutlined />
                      </Button>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <div style={{ height: "500px" }} className="justify-in-center">
                <LoadingOutlined
                  style={{ fontSize: 40, textAlign: "center" }}
                />
              </div>
            )}
          </div>
        </Modal>

        <Modal
          className="congrats-modal"
          centered={true}
          closable={false}
          maskClosable={false}
          width={600}
          onCancel={onCancelCongrats}
          visible={congratsModal}
          bodyStyle={{ padding: 0 }}
          footer={[
            Utilities.deviceType() == "desktop" && (
              <div className="justify-in-center">
                <Button
                  className="modal-okay-light-gray square font-16"
                  onClick={onCancelCongrats}
                >
                  Okay
                </Button>
              </div>
            ),
          ]}
        >
          <div style={{ padding: "40px" }} id={`modal-container`}>
            <div style={{ textAlign: "center" }}>
              <img
                style={{ width: 85, height: 85 }}
                src={"/smiley.png"}
                alt="smiley"
              ></img>
            </div>
            <div style={{ textAlign: "center", margin: "20px auto" }}>
              <h2 className=" manrope f-20 green-75 w-600 justify-in-center">
                Congratulations!
              </h2>
              <p className="manrope f-16 black-55">
                {FileConstants.congratsMessage(selectedFeature)}
              </p>
            </div>
          </div>
        </Modal>
        <Footer />
      </React.Fragment>
    </div>
  );
}

const { title, tabs, heading_1, text_1, video_tutorials } =
  TextConstants.HOW_ALL3D_WORKS;

const WebVersion = (props) => {

  let videoTutorialParam = new URLSearchParams(window.location.search).get("video_tutorial");

  const [videoElement, setVideoElement] = useState(React.createRef());
  const [currentVideoTutorial, setCurrentVideoTutorial] = useState([videoTutorialParam ?? video_tutorials[0]['id']]);
  const [currentTutotialTab, setCurrentTutotialTab] = useState(videoTutorialParam == undefined ? 'step_by_step' : 'video_tutorials');

  const changeCurrentTutorialTab = (value) => {
    setCurrentTutotialTab(value);
    if (value == 'step_by_step') {
      const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
      window.history.pushState({ path: newurl }, '', newurl);
    }
    if (value == 'video_tutorials') {
      const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?video_tutorial=' + video_tutorials[0]['id'];
      window.history.pushState({ path: newurl }, '', newurl);
    }
  }

  const changeCurrentVideoTutorial = (value) => {
    setCurrentVideoTutorial(value);
    window.scrollTo({ top: 140, behavior: 'smooth' })
    const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?video_tutorial=' + value;
    window.history.pushState({ path: newurl }, '', newurl);
  }

  const onChangeVisibility = (isVisible) => {
    const node = videoElement.current;
    if (node) {
      if (isVisible == true) {
        node.play();
      } else {
        node.pause();
      }
    }
  };
  return (
    <div className="d-none d-lg-block">
      <div
        className="col-lg-12 col-12"
        id="textSection"
        style={{ margin: "auto", padding: 0 }}
      >
        <h1
          className="font lora f-32 w-400 ws-6 black-2b justify-in-center"
          style={{ marginBottom: 40 }}
        >
          {title}
        </h1>
        <Tabs
          defaultActiveKey={currentTutotialTab}
          value={currentTutotialTab}
          onChange={(value) => { changeCurrentTutorialTab(value) }}
          className="how-it-works-tab justify-in-center"
          type="card"
        >
          <TabPane
            className="how-it-works-tabpane"
            tab={
              <span className="font graphik-regular f-16 black-2b">
                {tabs[0]}
              </span>
            }
            key="step_by_step"
          >
            <Row>
              <Col span={24} style={{ textAlign: "center" }}>
                <h3 className="font graphik-medium f-20 black-2b">
                  {heading_1}
                </h3>
                <p className="font graphik-regular grey-light f-16">{text_1}</p>
                <div className="justify-in-center" style={{ flexWrap: "wrap" }}>
                  {FileConstants.TOUR_FEATURES.map((ele) => (
                    <Card
                      className="explore-card"
                      style={{
                        padding: 12,
                        marginBottom: 12,
                        marginTop: 12,
                        marginRight: 12,
                      }}
                      onClick={() => props.openFeatureSteps(ele.value)}
                    >
                      <img
                        className="explore-img"
                        src={ele.image}
                        alt="explore image"
                      />
                      <h2
                        className="manrope f-14 black-14 w-500"
                        style={{ textAlign: "center", marginTop: 12 }}
                      >
                        {ele.text}
                      </h2>
                    </Card>
                  ))}
                </div>
              </Col>
            </Row>
          </TabPane>
          <TabPane
            className="how-it-works-tabpane"
            tab={
              <span className="font graphik-regular f-16 black-2b">
                {tabs[1]}
              </span>
            }
            key="video_tutorials"
          >
            <Row>
              <Col id="video-tutorials" span={24} style={{ textAlign: "center" }}>
                <Collapse
                  value={currentVideoTutorial}
                  defaultActiveKey={currentVideoTutorial}
                  onChange={(key) => { changeCurrentVideoTutorial([key]) }} accordion>
                  {
                    Object.keys(video_tutorials).map((key) => {
                      return <Panel header={<h3 className="font graphik-medium f-16 black-2b" style={{ marginBottom: 0, textAlign: 'justify' }}>
                        {video_tutorials[key]['title']}
                      </h3>}
                        key={video_tutorials[key]['id']}>
                        <div className="justify-in-center">
                          <VisibilitySensor onChange={onChangeVisibility}>
                            <div className="scene-video">
                              <div className="container-fluid">
                                <div className="row no-gutters justify-content-center">
                                  <div className="col-12 text-center parent-video">
                                    <iframe
                                      src={video_tutorials[key]['link']}
                                      title="Creating Lifestyle Imagery using ALL3D Scene Creator"
                                      frameborder="0"
                                      width="100%"
                                      height="100%"
                                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                      allowfullscreen
                                    ></iframe>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </VisibilitySensor>
                        </div>
                      </Panel>
                    })
                  }
                </Collapse>
              </Col>
            </Row>
          </TabPane>
        </Tabs>
      </div>
    </div>
  );
};

const MobileVersion = (props) => {
  let videoTutorialParam = new URLSearchParams(window.location.search).get("video_tutorial");

  const [selectedStep, setSelectedStep] = useState(videoTutorialParam == undefined ? 'step_by_step' : 'video_tutorials');
  const handleChange = (value) => {
    setSelectedStep(value);
    if (value == 'step_by_step') {
      const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname;
      window.history.pushState({ path: newurl }, '', newurl);
    }
    if (value == 'video_tutorials') {
      const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?video_tutorial=' + video_tutorials[0]['id'];
      window.history.pushState({ path: newurl }, '', newurl);
    }
  };

  const [videoElement, setVideoElement] = useState(React.createRef());
  const [currentVideoTutorial, setCurrentVideoTutorial] = useState([videoTutorialParam ?? video_tutorials[0]['id']]);

  const changeCurrentVideoTutorial = (value) => {
    setCurrentVideoTutorial(value);
    window.scrollTo({ top: 140, behavior: 'smooth' })
    const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?video_tutorial=' + value;
    window.history.pushState({ path: newurl }, '', newurl);
  }

  const onChangeVisibility = (isVisible) => {
    const node = videoElement.current;
    if (node) {
      if (isVisible == true) {
        node.play();
      } else {
        node.pause();
      }
    }
  };

  return (
    <div className="d-lg-none">
      <div
        className="col-12"
        id="textSection"
        style={{ margin: "auto", padding: 0 }}
      >
        <h2
          className="font lora f-20 w-500 ws-2 black-2b justify-in-center"
          style={{ paddingBottom: 20 }}
        >
          {title}
        </h2>
        <Select
          className="select-input-font"
          value={selectedStep}
          defaultValue={selectedStep}
          style={{ width: "100%", paddingBottom: 20 }}
          onChange={handleChange}
        >
          <Option value="step_by_step" className="manrope f-14 black-2b">
            {tabs[0]}
          </Option>
          <Option value="video_tutorials" className="manrope f-14 black-2b">
            {tabs[1]}
          </Option>
        </Select>
        {selectedStep == "step_by_step" ? (
          <div className="tab-bg">
            {FileConstants.TOUR_FEATURES.map((ele, index) => (
              <div
                className="explore-tabs"
                key={index}
                style={{ marginBottom: 16 }}
                onClick={() => props.openFeatureSteps(ele.value)}
              >
                <div className="justify-in-start">
                  <img src="/file-icon.png" alt="file icon" />
                  <div
                    className="manrope f-14 black-14 w-500"
                    style={{ marginLeft: 12 }}
                  >
                    {ele.text}
                  </div>
                </div>
                <div className="justify-in-end">
                  <RightOutlined />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <Row>
            <Col id="video-tutorials" span={24} style={{ textAlign: "center" }}>
              <Collapse
                value={currentVideoTutorial}
                defaultActiveKey={currentVideoTutorial}
                onChange={(key) => { changeCurrentVideoTutorial([key]) }} accordion>
                {
                  Object.keys(video_tutorials).map((key) => {
                    return <Panel header={<h3 className="font graphik-medium f-16 black-2b" style={{ marginBottom: 0, textAlign: 'justify' }}>
                      {video_tutorials[key]['title']}
                    </h3>}
                      key={video_tutorials[key]['id']}>
                      <div className="justify-in-center">
                        <VisibilitySensor onChange={onChangeVisibility}>
                          <div className="scene-video">
                            <div className="container-fluid">
                              <div className="row no-gutters justify-content-center">
                                <div className="col-12 text-center parent-video">
                                  <iframe
                                    src={video_tutorials[key]['link']}
                                    title="Creating Lifestyle Imagery using ALL3D Scene Creator"
                                    frameborder="0"
                                    width="100%"
                                    height="100%"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowfullscreen
                                  ></iframe>
                                </div>
                              </div>
                            </div>
                          </div>
                        </VisibilitySensor>
                      </div>
                    </Panel>
                  })
                }
              </Collapse>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default HowALL3DWorks;
