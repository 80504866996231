import React from "react";
import { Col,
  Row,
} from 'antd';
import TextConstants from "../../../TextConstants";

function Header() {
  return <div>
    <div style={{textAlign: "center"}}>
      <img style={{width: 195, margin: 16}} src="../logo-2.svg"></img>
    </div>
    <div style={{textAlign: "center"}}>
      <h5>{TextConstants.HIGH_POINT_TITLE}</h5>
    </div>
    <div>
      <Row>
        <Col span={24}>
          <img style={{width: "100%"}} src="../high-point-main.jpg"></img>
        </Col>
      </Row>
    </div>

  </div>;
}

export default Header;
