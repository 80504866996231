import React, { useState, useEffect } from "react";
import { Card, Col, Image, Row } from "antd";
import "./index.scss";
import { TEMPLATE_BASE_URL } from "../../env";
import * as Utilities from "../../Utilities";

const SceneDetailComponent = (props) => {
  const [imageVisible, setImageVisible] = useState([]);
  const [expand_image, setExpandImage] = useState(false);

  const expandImage = (name) => {
    let temp_arr = imageVisible;
    props.templateDetails.lifestyle_renders.map((x) => {
      if (x.filename == name) {
        temp_arr[x.filename.split(".")[0]] = true;
      } else {
        temp_arr[x.filename.split(".")[0]] = false;
      }
    });

    setImageVisible(temp_arr);
    setExpandImage(true);
  };

  const changeVisibility = (visible, prevVisible) => {
    let temp_arr = [];
    if (expand_image) {
      props.templateDetails.lifestyle_renders.map((x) => {
        if (imageVisible[x.filename.split(".")[0]] && !visible) {
          temp_arr[x.filename.split(".")[0]] = false;
        } else if (imageVisible[x.filename.split(".")[0]] && visible) {
          temp_arr[x.filename.split(".")[0]] = true;
        } else {
          temp_arr[x.filename.split(".")[0]] = false;
        }
      });
      setImageVisible(temp_arr);
      setExpandImage(false);
    }
  };

  return (
    <Col span={24} style={{ marginTop: 20 }}>
      <h1 className="manrope f-24 black-33">{props.templateDetails.name}</h1>
      <Row
        className="justify-space-between"
        style={{ marginBottom: 20 }}
        gutter={[16, 16]}
      >
        {props.templateDetails.lifestyle_renders &&
          props.templateDetails.lifestyle_renders
            .slice(0)
            .reverse()
            .map((x) =>
              x.status == "rendered" && x.filename != "360Camera.jpg" ? (
                <Col lg={12} md={24} sm={24}>
                  <Card className="lifestyle-image-card">
                    <div className="position-flag-dim">
                      <div className="resolution-btn">
                        <span className="category-artist white">
                          {Math.round(x.image_width) +
                            " x " +
                            Math.round(x.image_height)}
                        </span>
                      </div>
                    </div>
                    <div className="img-format-tag">
                      <div className="resolution-btn">
                        <span className="category-artist white">
                          {Utilities.imageFormat(x.filename)}
                        </span>
                      </div>
                    </div>

                    <Image
                      preview={{
                        visible: imageVisible[x.filename.split(".")[0]],
                        maskClassName: "customize-mask",
                        mask: (
                          <span>
                            <img
                              className="image-thumbnail"
                              src="/expand_thumbnail.png"
                              onClick={() => expandImage(x.filename)}
                            />
                          </span>
                        ),
                        onVisibleChange: changeVisibility,
                      }}
                      className="lifestyle-image-settings"
                      src={
                        TEMPLATE_BASE_URL +
                        props.templateDetails.id +
                        "/" +
                        x.filename.replace("tiff", "jpg")
                      }
                    />
                  </Card>
                </Col>
              ) : (
                ""
              )
            )}
      </Row>
    </Col>
  );
};

export default SceneDetailComponent;
