import React, { Component } from "react";
// import "@google/model-viewer";

class ProductGoogleViewer extends React.Component{

    render() {
        return <div><model-viewer
                    style={this.props.custom_style}
                    src={this.props.gltf}
                    alt="3D View"
                    auto-rotate camera-controls
                    data-js-focus-visible
                >
            </model-viewer>
        </div>
    }
}

export default ProductGoogleViewer;
