import React from "react";
import Header from "./header";
import SectionTwo from "./sectionTwo";
import SectionThree from "./sectionThree";
import SectionFour from "./sectionFour";
import SceneCreator from "./sceneCreator";
import LazyLoad from "react-lazyload";
import SectionSix from "./sectionSix";
import NutShell from "./nutshell";
import WhoCanUseUs from "./WhoCanUseUs";
import { useState } from "react";
import Footer from "./footer";
import { Helmet } from "react-helmet";
import CookieConsentBanner from "../cookieConsentBanner";
import FeaturedIn from "./FeaturedIn";
import HowItWorks from "./HowItWorks";
import TrustedByBrands from "./TrustedByBrands";
import ClientTestimonials from "./ClientTestimonials";
import WhyChooseUs from "./WhyChooseUs";
import HolisticSolution from "./HolisticSolution";
import OurProductOffering from "./OurProductOffering/OurProductOffering";
import Modal from "antd/lib/modal/Modal";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import * as Utilities from "../../Utilities";
import MetaTagContents from "../../MetaTagContents";
import TextConstants from "../../TextConstants";
import AIContentSection from "./AIContentSection/AIContentSection";

function LandingPage() {
  const [loaded, setLoader] = useState(true);
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    openPopupWindow();
  }, [document.querySelectorAll(".load-lazy")]);

  const openPopupWindow = () => {
    if (Utilities.getCookie("republic-popup")) {
      setPopup(false);
    } else {
      setTimeout(() => {
        setPopup(false);
      }, 2000);
    }
  };

  const cancelPopup = () => {
    Utilities.setCookie("republic-popup", true, 365);
    setPopup(false);
  };

  const { title, text, btn_text } = TextConstants.LANDING_PAGE_MODAL;
  const { name, content, schemaMarkup  } = MetaTagContents.LANDING_PAGE;

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{MetaTagContents.LANDING_PAGE.title}</title>
        <link rel="canonical" href="https://all3d.ai/" />
        <meta name={name} content={content} />
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup.video)}
        </script>
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup.organization)}
        </script>
      </Helmet>
      <Header />

      <>
        <CookieConsentBanner />
        <FeaturedIn />
        {/* This is commented temporarily */}
        {/* <HolisticSolution/> */}
        <AIContentSection/>
        <OurProductOffering />
        <HowItWorks />
        <LazyLoad scroll={true}>
          <WhoCanUseUs />
        </LazyLoad>
        <WhyChooseUs />
        <ClientTestimonials />
        {/* This is commented temporarily */}
        {/* <TrustedByBrands /> */} 
        <Footer />
      </>

      <Modal
        className="selling-popup"
        visible={popup}
        closable={true}
        onCancel={cancelPopup}
      >
        <>
          <div className="d-none d-lg-block">
            <div className="justify-space-between">
              <div className="popup-bg">
                <h1 className="font lora f-32 black-2b ws-6 w-500">{title}</h1>
                <p className="font graphik-regular f-16 grey-6b">{text}</p>
                <Link
                  to={{ pathname: "https://republic.com/all3d" }}
                  target="_blank"
                >
                  <button
                    type="button"
                    className="btn popup-btn font graphik-regular f-16 w-500 white"
                  >
                    {btn_text}
                  </button>
                </Link>
              </div>
              <img src="/republic.jpg" className="popup-img" alt="republic" />
            </div>
          </div>
          <div div className="d-lg-none">
            <img src="/republic.jpg" className="popup-img" alt="republic" />
            <div className="popup-bg">
              <h1
                className="font lora f-28 white ws-2 w-500 text-shadow"
                style={{ textAlign: "center", justifyContent: "center" }}
              >
                {title}
              </h1>
              <p
                className="font graphik-regular f-16 white text-shadow"
                style={{ textAlign: "center" }}
              >
                {text}
              </p>
              <Link
                to={{ pathname: "https://republic.com/all3d" }}
                target="_blank"
              >
                <button
                  type="button"
                  className="btn popup-btn font graphik-regular f-14 w-500 white"
                  style={{ textAlign: "center", margin: "auto" }}
                >
                  {btn_text}
                </button>
              </Link>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
}

export default LandingPage;
