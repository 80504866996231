import React, { useState, useEffect, useCallback } from "react";
import { Card, message, Tooltip } from "antd";
import ReactPlayer from "react-player";
import ProductGoogleViewer from "../ProductGoogleViewer";
import ImageZoom from "../ImageZoom";
import "./index.scss";

const { Meta } = Card;

const google_viewer_style = { height: "calc(100vh - 277px)", width: "100%" };
const google_viewer_style_fullscreen = { height: "100vh", width: "100%" };

const ProductDetailComponent = (props) => {
  const [viewType, setViewType] = useState("2D");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [image, setImage] = useState(null);

  const handleViewChange = (e) => {
    setTimeout(() => {
      window.CI360.destroy();
      window.CI360.init();
    }, 1);
    setViewType(e);
  };
  const setFullScreen = () => {
    if (!isFullScreen) {
      message.info("Press ESC to Exit Full Screen");
    }
    setIsFullScreen(!isFullScreen);
  };

  const view2DImage = (image) => {
    setViewType("2D");
    setImage(image);
  };

  const view360Player = () => {
    handleViewChange("360D");
  };
  const {
    match: { params },
  } = props;

  useEffect(() => {
    props.getProduct({ product_id: params.id });
  }, [params]);

  const getGLBUrl = () => {
    let link = (props.productReducer.product.download_links || []).find(
      (ele) => ele.display_name == "GLB"
    );
    if (link) {
      return link.model_url;
    } else {
      return "";
    }
  };

  const getUSDZUrl = () => {
    let link = (props.productReducer.product.download_links || []).find(
      (ele) => ele.display_name == "USDZ"
    );
    if (link) {
      return link.model_url;
    } else {
      return "";
    }
  };

  const getVideoUrl = () => {
    let link = (props.productReducer.product.download_links || []).find(
      (ele) => ele.display_name == "VIDEO"
    );
    if (link) {
      return link.model_url;
    } else {
      return "";
    }
  };

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setIsFullScreen(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  useEffect(() => {
    window.CI360.destroy();
    window.CI360.init();
  }, [isFullScreen]);

  return (
    <Card
      className={isFullScreen ? "product-full-screen" : "product-viewer-normal"}
      bodyStyle={{ padding: isFullScreen ? 0 : 0 }}
    >
      <Tooltip
        title={!isFullScreen ? "Full Screen" : "Exit Full Screen"}
        placement="left"
      >
        <img
          src={isFullScreen ? "/fullScreenIcon-2.svg" : "/fullScreenIcon.jpg"}
          alt={!isFullScreen ? "FullScreen" : "Exit FullScreen"}
          style={{
            position: "absolute",
            top: "20px",
            zIndex: "999",
            cursor: "pointer",
            opacity: "0.8",
            borderRadius: "4px",
            right: "20px",
          }}
          className="product-full-screen-icon"
          onClick={() => setFullScreen()}
        />
      </Tooltip>
      {viewType == "3D" && (
        <div>
          <ProductGoogleViewer
            custom_style={
              isFullScreen
                ? google_viewer_style_fullscreen
                : google_viewer_style
            }
            gltf={getGLBUrl()}
          />
        </div>
      )}

      {viewType == "360D" && (
        <div
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            marginTop: "50px",
            height: isFullScreen ? "100vh" : "calc(100vh - 361px)",
            width: isFullScreen ? "100vh" : "calc(100vh - 284px)",
          }}
        >
          <div
            className="cloudimage-360"
            data-folder={
              props.productReducer.product["360"] &&
              props.productReducer.product["360"]["data-folder"]
            }
            data-filename={
              props.productReducer.product["360"] &&
              props.productReducer.product["360"]["data-filename"]
            }
            data-amount={
              props.productReducer.product["360"] &&
              props.productReducer.product["360"]["data-amount"]
            }
          ></div>
        </div>
      )}

      {/*  2D Flow here   */}
      {viewType == "2D" && (
        <div>
          {isFullScreen ? (
            <img
              className={isFullScreen ? "full-img" : "card-img"}
              style={{ objectFit: "contain", width: "100%" }}
              src={
                image ||
                (props.productReducer.product["2d"] &&
                  props.productReducer.product["2d"][0])
              }
              alt="Product Image"
            />
          ) : (
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              {/* <img src={image || props.productReducer.product['2d'] && props.productReducer.product['2d'][0]} className="image-zoom"/> */}
              <ImageZoom
                image={
                  isFullScreen
                    ? image ||
                      (props.productReducer.product["2d"] &&
                        props.productReducer.product["2d"][0])
                    : image ||
                      (props.productReducer.product["2d"] &&
                        props.productReducer.product["2d"][0])
                }
                className="image-zoom"
              />
            </div>
          )}
        </div>
      )}

      {viewType == "video" && (
        <div>
          <ReactPlayer
            width="100%"
            height={isFullScreen ? "calc(100vh - 84px)" : "calc(100vh - 361px)"}
            url={getVideoUrl()}
            controls={true}
            loop={true}
          />
        </div>
      )}

      <div
        style={{
          position: "absolute",
          display: "flex",
          bottom: "20px",
          left: "20px",
        }}
      >
        <Tooltip title={"3D"} placement="top">
          <Card bodyStyle={{ padding: "0px" }} style={{ marginRight: "10px" }}>
            <div
              className="product-view-icon"
              onClick={() => setViewType("3D")}
            >
              <span
                style={{
                  background: "url(/3d.jpg)",
                  objectFit: "contain",
                  height: "40px",
                  width: "40px",
                }}
              >
                <b style={{ color: "white" }}></b>
              </span>
            </div>
          </Card>
        </Tooltip>
        {props.productReducer.product["360"] && (
          <Tooltip title={"360"} placement="top">
            <Card
              bodyStyle={{ padding: "0px" }}
              style={{ marginRight: "10px" }}
            >
              <div
                className="product-view-icon"
                onClick={() => view360Player()}
              >
                <span
                  style={{
                    background: "url(/360-tn.jpg)",
                    objectFit: "contain",
                    height: "40px",
                    width: "40px",
                  }}
                >
                  <b style={{ color: "white" }}></b>
                </span>
              </div>
            </Card>
          </Tooltip>
        )}
        {getUSDZUrl() != "" && (
          <Tooltip title={"AR"} placement="top">
            <Card
              bodyStyle={{ padding: "0px" }}
              style={{ marginRight: "10px" }}
            >
              <div
                className="product-view-icon"
                onClick={() => setViewType("view_in_room")}
              >
                <span
                  style={{
                    background: "url(/ar.jpg)",
                    objectFit: "contain",
                    height: "40px",
                    width: "40px",
                  }}
                >
                  <b style={{ color: "white" }}></b>
                </span>
              </div>
            </Card>
          </Tooltip>
        )}
        {getVideoUrl() != "" && (
          <Card bodyStyle={{ padding: "0px" }} style={{ marginRight: "10px" }}>
            <div
              className="product-view-icon"
              onClick={() => setViewType("video")}
            >
              <span style={{ background: "radial-gradient(red, transparent)" }}>
                <b style={{ padding: 4, color: "white" }}>MP4</b>
              </span>
            </div>
          </Card>
        )}
        {(props.productReducer.product["2d"] || []).map((u, index) => {
          return (
            <Card
              key={index}
              bodyStyle={{
                padding: "2px",
                minWidth: 40,
                textAlign: "-webkit-center",
              }}
              style={{
                marginRight: "10px",
                width: "64px",
                height: "64px",
                padding: "10px",
              }}
            >
              <img
                onClick={() => view2DImage(u)}
                style={{
                  cursor: "pointer",
                  height: "40px",
                  objectFit: "contain",
                  display: "block",
                  width: "40px",
                }}
                alt="Clickable Product Image"
                src={u}
              />
            </Card>
          );
        })}
      </div>

      {viewType == "view_in_room" && (
        <div className="position-ar-link">
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "50px",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <p className="product-h2">
              Open the following link in your Apple Device
            </p>
          </div>
          <div
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-around",
            }}
          >
            <h3 style={{ fontFamily: "Graphik-Regular" }}>
              <a href={getUSDZUrl()}>{getUSDZUrl()}</a>
            </h3>
          </div>
        </div>
      )}
    </Card>
  );
};

export default ProductDetailComponent;
