import React from "react";
import { useEffect, useState } from "react";
import $ from "jquery";
import "./index.scss";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Animated } from "react-animated-css";
import LazyLoad from "react-lazyload";
import FileConstants from "../../../FileConstants";
import TextConstants from "../../../TextConstants";

const RealtimeConfigurator = () => {
  return (
    <>
      <WebVersion />
      <MobileVersion />
    </>
  );
};
const { title, text } = TextConstants.REALTIME_CONFIGURATOR;
const WebVersion = () => {
  const [visible, setVisible] = useState(false);

  useEffect((_) => {
    window.addEventListener("scroll", () => {
      if ($("#realtime") && $("#realtime").offset()) {
        var top = $(window).scrollTop() + $(window).height();
        var classTop = $("#realtime").offset().top;
        var isVisible = false;
        if (classTop != undefined) {
          isVisible = top > classTop;
          if (isVisible) {
            setVisible(true);
          }
        }
      }
    });
  }, []);

  return (
    <div className="d-none d-lg-block">
      <div className="our-product-offering" style={{ marginTop: 120 }}>
        <div className="row justify-content-md-center">
          <div
            className="col-lg-12 col-12 justify-in-center direction-flex col"
            style={{ textAlign: "center" }}
          >
            <h4 className="font graphik-medium f-18 w-500 black-2b">{title}</h4>
            <p
              className="font graphik-regular f-16 w-400 grey-light "
              style={{ margin: "auto", width: "100%", marginBottom: 30 }}
            >
              {text}
            </p>
          </div>
          <div id="realtime" className="col-lg-12 col-12">
            <div className="configurator-text">
              <Animated
                animationIn="fadeInUp"
                animationOut="fadeOut"
                animationInDuration={1000}
                animationOutDuration={1000}
                isVisible={visible}
              >
                <a
                  className="font lora f-32 ws-6 w-400 white h-underline text-shadow"
                  href="https://www.vispring.com/design-your-bed/"
                >
                  Design A Bed <ArrowRightOutlined />
                </a>
              </Animated>
            </div>

            <img
              className="section-img configurator load-lazy"
              src={FileConstants.CONFIGURATOR}
              alt="Bed"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const MobileVersion = () => {
  return (
    <div className="d-lg-none">
      <div className="our-product-offering" style={{ marginTop: 48 }}>
        <div
          className="col-12 justify-in-center direction-flex col"
          style={{ textAlign: "center" }}
        >
          <h4 className="font graphik-medium f-16 w-500 black-2b">{title}</h4>
          <p className="font graphik-regular f-14 w-400 grey-light">{text}</p>
        </div>
        <div className="col-12 bg-img justify-in-center">
          <img
            className="section-img load-lazy"
            src={FileConstants.CONFIGURATOR}
            alt="Bed"
          />
        </div>
      </div>
    </div>
  );
};
export default RealtimeConfigurator;
