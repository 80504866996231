import React, { useEffect } from "react";
import "./privacyTerms.scss";
import { Table } from "antd";
import { Helmet } from "react-helmet";
import MetaTagContents from "../../MetaTagContents";

function Privacy() {
  useEffect(() => {
    window.addEventListener("scroll", () => {
      let elmnt = document.getElementsByClassName("heading-alignment");
      if (elmnt && elmnt[0] && elmnt[0].offsetHeight) {
        let updatedElem =
          window.document.getElementsByClassName("navbar-light");
        if (updatedElem && updatedElem[0]) {
          if (window.scrollY > elmnt[0].offsetHeight) {
            updatedElem[0].classList.add("full-opacity");
          } else {
            updatedElem[0].classList.remove("full-opacity");
          }
        }
      }
    });
  }, []);
  const tableData = [
    {
      cookies: "Required Cookies",
      desc: [
        "Required cookies enable you to navigate our Services and use their features, such as accessing secure areas of the Services.",
        <br />,
        <br />,
        "If you have chosen to identify yourself to us, we may place on your browser a cookie that allows us to uniquely identify you when you are logged into the Services and to process your online transactions and requests.",
      ],
      settings:
        "Because required cookies are essential to operate the Services, there is no option to opt out of these cookies.",
    },
    {
      cookies: "Functional Cookies",
      desc: [
        "Functional cookies allow us to remember information you have entered or choices you make (such as your username, language and region) and provide enhanced, personalized features.",
        <br />,
        <br />,
        "Functional cookies may also be used to improve how our Services function and to help us provide you with more relevant communications, including marketing communications. These cookies collect information about how our Services are used, including which pages are viewed most often.",
        <br />,
        <br />,
        "We may use our own technology or third-party technology to track and analyze usage information to provide enhanced interactions and more relevant communications, and to track the performance of our advertisements.",
        <br />,
        <br />,
        'For example, we use Google Analytics ("Google Analytics"), a web analytics service provided by Google, Inc., 1600 Amphitheatre Parkway, Mountain View, CA 94043, USA. Google Analytics uses cookies to help us analyze how our websites are used, including the number of visitors, the websites from which visitors have navigated to our websites, and the pages on our websites to which visitors navigate. This information is used by us to improve our Services.',
        <br />,
        <br />,
        "ALL3D may also utilize HTML5 local storage for the above-mentioned purposes. These technologies differ from browser cookies in the amount and type of data they store, and how they store it.",
      ],
      settings: [
        "You can manage the placement of functional cookies on your browser via your individual browser settings. Note that opting out of functional cookies may impact the functionality of our Services and degrade your user experience.",
        <br />,
        <br />,
        "To opt out from data collection by Google Analytics, you can download and install a browser add-on, which is available ",
        <span class="c9">
          <a
            class="c4"
            href="https://tools.google.com/dlpage/gaoptout?hl=en"
            target="_blank"
          >
            here
          </a>
        </span>,
        ".",
        <br />,
        <br />,
        "To learn how to control functional cookies via your individual browser settings, ",
        <span class="c9">
          <a class="c4" href="https://www.aboutcookies.org" target="_blank">
            click here
          </a>
        </span>,
        ".",
      ],
    },
    {
      cookies: "Targeting or Advertising Cookies",
      desc: [
        "We sometimes use cookies delivered by us or by third parties to show you ads for our products that we think may interest you on devices you use and to track the performance of our advertisements. For example, these cookies collect and remember information such as which browsers have visited our Services. The information provided to third parties does not include Personal Data, but this information may be re-associated with Personal Data after the third party receives it.",
        <br />,
        <br />,
        "ALL3D also contracts with third-party advertising networks that collect IP addresses and other information from web beacons on our Services, from emails and on third-party websites. Advertising networks follow your online activities over time and across different websites or other online services by collecting device and usage data through automated means, including through the use of cookies. These technologies may recognize you across the different devices you use, such as a desktop or laptop computer, smartphone or tablet. Third parties use this information to provide advertisements about products and services tailored to your interests. You may see their advertisements on other websites or mobile applications on any of your devices. This process also helps us manage and track the effectiveness of our marketing efforts.",
      ],
      settings:
        "See Section 4.3, below, to learn more about these and other advertising networks and your ability to opt out of collection by certain third parties.",
    },
  ];

  const columns = [
    {
      title: "Type of Cookies",
      width: "20%",
      dataIndex: "cookies",
      render: (text, row, index) => {
        if (index < 4) {
          return (
            <p class="c0">
              <span class="c3">{text}</span>
            </p>
          );
        }
        return {
          children: (
            <p class="c0">
              <span class="c3">{text}</span>
            </p>
          ),
        };
      },
    },
    {
      title: "Description",
      width: "60%",
      dataIndex: "desc",
      render: (text, row, index) => {
        if (index < 4) {
          return (
            <p class="c0">
              <span class="c3">{text}</span>
            </p>
          );
        }
        return {
          children: (
            <p class="c0">
              <span class="c3">{text}</span>
            </p>
          ),
        };
      },
    },
    {
      title: "Managing Settings",
      width: "20%",
      dataIndex: "settings",
      render: (text, row, index) => {
        if (index < 4) {
          return (
            <p class="c0">
              <span class="c3">{text}</span>
            </p>
          );
        }
        return {
          children: (
            <p class="c0">
              <span class="c3">{text}</span>
            </p>
          ),
        };
      },
    },
  ];
  const { name, content } = MetaTagContents.PRIVACY;
  return (
    <body class="c15" style={{ marginTop: "60px" }}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{MetaTagContents.PRIVACY.title}</title>
        <link rel="canonical" href="https://all3d.ai/privacy" />
        <meta name={name} content={content} />
      </Helmet>
      <div class="heading-alignment">
        <h1 class="welcome-h1">Privacy Policy</h1>
        <p class="c0 date-text">Last updated on July 29, 2020</p>
      </div>
      <p class="c0 content-text">
        <span class="c3">
          This ALL3D Privacy Policy ("Privacy Policy") describes our privacy
          practices. Please read this Privacy Policy carefully to learn how we
          collect, use, share and otherwise process information relating to
          individuals ("Personal Data"), and to learn about your rights and
          choices regarding our processing of your Personal Data.
          <br />
          <br />
          By using or accessing the Services in any manner, you acknowledge that
          you accept the practices and policies outlined in this Privacy Policy,
          and you hereby consent that we will collect, use, and share your
          information in the following ways.
          <br />
          <br />
          Your use of ALL3D’s Services is at all times subject to the{" "}
          <span class="c9">
            <a class="c4" href="/terms" target="_blank">
              Terms of Use
            </a>
          </span>
          , which incorporates this Privacy Policy. Any terms we use in this
          Policy without defining them have the definitions given to them in the{" "}
          <span class="c9">
            <a class="c4" href="/terms" target="_blank">
              Terms of Use
            </a>
          </span>
          .
        </span>
      </p>
      <div class="h2-height"></div>
      <h2 class="c0">
        <span class="c8 h2-text">1. Processing Activities Covered</span>
      </h2>
      <p class="c0">
        <span class="c3">
          The Privacy Policy apples to the processing of Personal Data collected
          by us when you:{" "}
        </span>
      </p>
      <ul class="list-indent">
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Visit our websites that display or link to this Privacy Policy;{" "}
          </span>
        </li>
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Visit our branded social media pages;{" "}
          </span>
        </li>
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Visiting our office;{" "}
          </span>
        </li>
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Receive communications from us, including emails, phone calls, texts
            or fax;
          </span>
        </li>
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Register for our events, webinars or contests; or
          </span>
        </li>
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Participate in community and open source development
          </span>
        </li>
      </ul>
      <p class="c0">
        <span class="c3">
          When applications are provided by us and the application links to this
          Privacy Policy, this Privacy Policy applies. When applications are
          provided by third parties, the Privacy Policy of the third party
          applies and this Privacy Policy does not apply.{" "}
        </span>
      </p>
      <p class="c0">
        <span class="c3">
          Our Services may contain links to other websites, applications and
          services maintained by third parties. The information practices of
          such other services, or of social media networks that host our branded
          social media pages, are governed by third parties’ Privacy Policies,
          which we encourage you to review to better understand those third
          parties’ privacy practices.{" "}
        </span>
      </p>

      <div class="h2-height"></div>
      <h2 class="c0">
        <span class="c8 h2-text">2. Responsible ALL3D Entity</span>
      </h2>
      <p class="c0">
        <span class="c3">
          {" "}
          ALL3D is the controller of your Personal Data as described in this
          Privacy Policy, unless expressly specified otherwise.{" "}
        </span>
      </p>
      <p class="c0">
        <span class="c3">
          This Privacy Policy does not apply to the extent we offer to our
          customers various cloud products and services through which our
          customers create their own websites and applications running on our
          platforms, sell or offer their own products and services, send
          electronic communications to other individuals or collect and analyze
          Personal Data from individuals.
        </span>
      </p>

      <div class="h2-height"></div>
      <h2 class="c0">
        <span class="c8 h2-text">3. What Personal Data Do We Collect?</span>
      </h2>
      <p class="c0">
        <span class="c8 h3-text">
          3.1 Personal Data We Collect Directly From You
        </span>
        <span class="c3">
          <br />
          The Personal Data that we collect directly from you includes the
          following:
        </span>
      </p>
      <ul class="list-indent">
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            If you express an interest in obtaining additional information about
            our services, request customer support, use our "Contact Us" or
            similar features, register to use our Services, sign up for an
            event, webinar or contest, or download certain content, we may
            require that you provide to us your contact information, such as
            your name, job title, company name, address, phone number, email
            address or username and password;
          </span>
        </li>
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            If you make purchases via our Services or register for an event or
            webinar, we may require that you provide to us your financial and
            billing information, such as billing name and address, credit card
            number or bank account information;
          </span>
        </li>
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            If you attend an event, we may upon your consent scan your attendee
            badge, which will provide to us your name, title, company name,
            address, country, phone number and email address;
          </span>
        </li>
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            If you register for an online community that we host, we may ask you
            to provide a username, photo and/or biographical information, such
            as your occupation, social media profiles, company name and areas of
            expertise;
          </span>
        </li>
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            If you use and interact with our Services, we automatically collect
            log files and other information about your device and your usage of
            our Services through cookies, web beacons or similar technologies,
            such as Internet Protocol (IP) addresses, unique device identifiers,
            or other identifiers, which may qualify as Personal Data (please see
            the "What device and usage data we process" section, below);
          </span>
        </li>
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            If you use our mobile applications, we automatically collect
            movement activity, Camera permission, Sensors permission, Storage
            permission and Motion sensors permission;
          </span>
        </li>
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            If you use the scanning function in our mobile applications, we use
            ARKit and may collect information about how your device is used
            through ARKit and use the TrueDepth Camera and the Camera API to
            capture 3D depth maps, per-vertex color information, and texture
            maps.
          </span>
        </li>
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            If you visit our offices, you may be required to register as a
            visitor and to provide your name, email address, phone number,
            company name and time and date of arrival; and{" "}
          </span>
        </li>
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            If you provide to us or to our service providers any Personal Data
            relating to other individuals, you represent that you have the
            authority to do so and permit us to use the Personal Data in
            accordance with this Privacy Policy. If you believe that your
            Personal Data has been provided to us improperly, or to otherwise
            exercise your rights relating to your Personal Data, please contact
            us by using the information in the “Contacting us” section, below.
          </span>
        </li>
      </ul>

      <p class="c0">
        <span class="c8 h3-text">
          3.2 Personal Data We Collect From Other Sources
        </span>
        <span class="c3">
          <br />
          We also collect information about you from other sources, including
          third parties from whom we have purchased Personal Data, and combine
          this information with Personal Data provided by you. This helps us to
          update, expand and analyze our records, identify new customers and
          create more tailored advertising to provide services that may be of
          interest to you. In particular, we collect Personal Data from the
          following sources:
        </span>
      </p>
      <ul
        class="list-indent"
        style={{ paddingBottom: "0px", marginBottom: "0px" }}
      >
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Third party providers of business contact information, including
            mailing addresses, job titles, email addresses, phone numbers,
            intent data (or user behavior data), IP addresses, social media
            profiles, LinkedIn URLs and custom profiles, for purposes of
            targeted advertising, delivering relevant email content, event
            promotion and profiling; and;
          </span>
        </li>
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Platforms such as GitHub, to manage code check-ins and pull
            requests. If you participate in an open source or community
            development project, we may associate your code repository username
            with your community account so we can inform you of program changes
            that are important to your participation or relating to additional
            security requirements.
          </span>
        </li>
      </ul>

      <div class="h2-height"></div>
      <h2 class="c0">
        <span class="c8 h2-text">4. What Device and Usage Data We Process</span>
      </h2>
      <p class="c0">
        <span class="c3">
          We use common information-gathering tools, such as log files, cookies,
          web beacons and similar technologies to automatically collect
          information that may contain Personal Data from your computer or
          mobile device as you navigate our Services or interact with emails we
          have sent to you.
        </span>
      </p>

      <p class="c0">
        <span class="c8 h3-text">4.1 Log Files</span>
        <span class="c3">
          <br />
          As is true of most websites, we gather certain information
          automatically via log files. This information may include your IP
          address (or proxy server), device and application identification
          numbers, your location, your browser type, your Internet service
          provider and/or mobile carrier, the pages and files you viewed, your
          searches, your operating system and system configuration information
          and date/time stamps associated with your usage. This information is
          used to analyze overall trends, to help us provide and improve our
          Services and to guarantee their security and continued proper
          functioning. We also collect IP addresses from users when they log
          into our services as part of our security features.
        </span>
      </p>

      <p class="c0">
        <span class="c8 h3-text">
          4.2 Cookies, Web Beacons and Other Tracking Technologies
        </span>
        <span class="c3">
          <br />
          We use cookies and similar technologies such as web beacons, tags and
          JavaScript alone or in conjunction with cookies to compile information
          about the usage of our Services and interaction with emails from us.
        </span>
      </p>
      <p class="c0">
        <span class="c3">
          When you visit our Services, we or an authorized third party may place
          a cookie on your browser, which cookie collects information, including
          Personal Data, about your online activities over time and across
          different sites. Cookies allow us to track overall usage, determine
          your browsing preferences and improve and customize your browsing
          experience.
        </span>
      </p>
      <p class="c0">
        <span class="c3">
          We use both session-based and persistent cookies. Session-based
          cookies exist only during one session and disappear from your computer
          when you close your browser or turn off your computer. Persistent
          cookies remain on your computer or device after you close your browser
          or turn off your computer. You can control the use of cookies at the
          individual browser level, but choosing to disable cookies may limit
          your use of certain features or functions on our Services.
        </span>
      </p>
      <p class="c0">
        <span class="c3">
          We also use web beacons on our Services and in email communications.
          For example, we may place web beacons in marketing emails that notify
          us when you click on a link in the email that directs you to one of
          our Services. Such technologies are used to operate and improve our
          Services and email communications.
        </span>
      </p>
      <p class="c0">
        <span class="c3">
          The following describes how we use different categories of cookies and
          similar technologies and your options for managing the data collection
          settings of these technologies:
        </span>
      </p>
      <div id="cookieTable" class="display-table">
        <Table
          columns={columns}
          dataSource={tableData}
          pagination={false}
          bordered
          className="custom-table"
        />
      </div>
      <div class="display-text">
        <p class="c0">
          <span class="c8 h4-text">4.2.1 Required Cookies</span>
          <span class="c3">
            <br />
            <b>Description: </b>Required cookies enable you to navigate our
            Services and use their features, such as accessing secure areas of
            the Services.
          </span>
        </p>
        <p class="c0">
          <span class="c3">
            If you have chosen to identify yourself to us, we may place on your
            browser a cookie that allows us to uniquely identify you when you
            are logged into the Services and to process your online transactions
            and requests.
          </span>
        </p>
        <p class="c0">
          <span class="c3">
            <b>Managing Settings: </b> Because required cookies are essential to
            operate the Services, there is no option to opt out of these
            cookies.
          </span>
        </p>
        <p class="c0">
          <span class="c8 h4-text">4.2.2 Functional Cookies</span>
          <br />
          <span class="c3">
            <b>Description: </b>Functional cookies allow us to remember
            information you have entered or choices you make (such as your
            username, language and region) and provide enhanced, personalized
            features.
          </span>
        </p>
        <p class="c0">
          <span class="c3">
            Functional cookies may also be used to improve how our Services
            function and to help us provide you with more relevant
            communications, including marketing communications. These cookies
            collect information about how our Services are used, including which
            pages are viewed most often.
          </span>
        </p>
        <p class="c0">
          <span class="c3">
            We may use our own technology or third-party technology to track and
            analyze usage information to provide enhanced interactions and more
            relevant communications, and to track the performance of our
            advertisements.
          </span>
        </p>
        <p class="c0">
          <span class="c3">
            For example, we use Google Analytics ("Google Analytics"), a web
            analytics service provided by Google, Inc., 1600 Amphitheatre
            Parkway, Mountain View, CA 94043, USA. Google Analytics uses cookies
            to help us analyze how our websites are used, including the number
            of visitors, the websites from which visitors have navigated to our
            websites, and the pages on our websites to which visitors navigate.
            This information is used by us to improve our Services.
          </span>
        </p>
        <p class="c0">
          <span class="c3">
            ALL3D may also utilize HTML5 local storage for the above-mentioned
            purposes. These technologies differ from browser cookies in the
            amount and type of data they store, and how they store it.
          </span>
        </p>
        <p class="c0">
          <span class="c3">
            <b>Managing Settings: </b>You can manage the placement of functional
            cookies on your browser via your individual browser settings. Note
            that opting out of functional cookies may impact the functionality
            of our Services and degrade your user experience.
          </span>
        </p>
        <p class="c0">
          <span class="c3">
            To opt out from data collection by Google Analytics, you can
            download and install a browser add-on, which is available{" "}
            <span class="c9">
              <a
                class="c4"
                href="https://tools.google.com/dlpage/gaoptout?hl=en"
                target="_blank"
              >
                here
              </a>
            </span>
            .
          </span>
        </p>
        <p class="c0">
          <span class="c3">
            To learn how to control functional cookies via your individual
            browser settings,{" "}
            <span class="c9">
              <a class="c4" href="https://www.aboutcookies.org" target="_blank">
                click here
              </a>
            </span>
            .
          </span>
        </p>
        <p class="c0">
          <span class="c8 h4-text">4.2.3 Targeting or Advertising Cookies</span>
          <br />
          <span class="c3">
            <b>Description: </b>We sometimes use cookies delivered by us or by
            third parties to show you ads for our products that we think may
            interest you on devices you use and to track the performance of our
            advertisements. For example, these cookies collect and remember
            information such as which browsers have visited our Services. The
            information provided to third parties does not include Personal
            Data, but this information may be re-associated with Personal Data
            after the third party receives it.
          </span>
        </p>
        <p class="c0">
          <span class="c3">
            ALL3D also contracts with third-party advertising networks that
            collect IP addresses and other information from web beacons on our
            Services, from emails and on third-party websites. Advertising
            networks follow your online activities over time and across
            different websites or other online services by collecting device and
            usage data through automated means, including through the use of
            cookies. These technologies may recognize you across the different
            devices you use, such as a desktop or laptop computer, smartphone or
            tablet. Third parties use this information to provide advertisements
            about products and services tailored to your interests. You may see
            their advertisements on other websites or mobile applications on any
            of your devices. This process also helps us manage and track the
            effectiveness of our marketing efforts.
          </span>
        </p>
        <p class="c0">
          <span class="c3">
            <b>Managing Settings: </b> See Section 4.3, below, to learn more
            about these and other advertising networks and your ability to opt
            out of collection by certain third parties.
          </span>
        </p>
      </div>
      <p class="c0"></p>
      <p class="c0">
        <span class="c8 h3-text">
          4.3 Notices on Behavioral Advertising and Opt-out
        </span>
        <span class="c3">
          <br />
          As described above, we or third parties may place or recognize a
          unique cookie on your browser when you visit our Services for the
          purpose of serving you targeted advertising (also referred to as
          “online behavioral advertising” or “interest-based advertising”). To
          learn more about targeted advertising and advertising networks and
          about your ability to opt out of collection by certain third parties,
          please visit the opt-out pages of the Network Advertising Initiative,{" "}
          <span class="c9">
            <a
              class="c4"
              href="https://optout.networkadvertising.org/?c=1"
              target="_blank"
            >
              here
            </a>
          </span>
          , and the Digital Advertising Alliance,{" "}
          <span class="c9">
            <a
              class="c4"
              href="https://optout.aboutads.info/?c=2&lang=EN"
              target="_blank"
            >
              here
            </a>
          </span>
          .
        </span>
      </p>
      <p class="c0">
        <span class="c3">
          To manage the use of targeting or advertising cookies on this website,
          consult your individual browser settings for cookies. Various browsers
          may also offer their own management tools for removing HTML5 local
          storage.
        </span>
      </p>

      <p class="c0">
        <span class="c8 h3-text">
          4.4 Opt-Out From the Collection of Device and Usage Data
        </span>
        <span class="c3">
          <br />
          You may opt-out from the collection of device and usage data (see the
          "What device and usage data we process" section, above) by managing
          your cookies at the individual browser level. In addition, if you wish
          to opt-out of interest-based advertising{" "}
          <span class="c9">
            <a
              class="c4"
              href="http://preferences-mgr.truste.com/"
              target="_blank"
            >
              click here
            </a>
          </span>{" "}
          (or, if located in the European Union,{" "}
          <span class="c9">
            <a
              class="c4"
              target="_blank"
              href="https://www.youronlinechoices.com/"
            >
              click here
            </a>
          </span>
          ). Please note, however, that by blocking or deleting cookies and
          similar technologies used on our Services, you may not be able to take
          full advantage of the Services.
        </span>
      </p>
      <p class="c0">
        <span class="c3">
          While some internet browsers offer a “do not track” or “DNT” option
          that lets you tell websites that you do not want to have your online
          activities tracked, these features are not yet uniform and there is no
          common standard that has been adopted by industry groups, technology
          companies or regulators. Therefore, we do not currently commit to
          responding to browsers' DNT signals with respect to our Services.
          ALL3D takes privacy and meaningful choice seriously and will make
          efforts to continue to monitor developments around DNT browser
          technology and the implementation of a standard.
        </span>
      </p>

      <p class="c0">
        <span class="c8 h3-text">4.5 Social Media Features</span>
        <span class="c3">
          <br />
          Our Services may use social media features, such as the Facebook
          “like” button, the “Tweet” button and other sharing widgets (“Social
          Media Features”). You may be given the option by such Social Media
          Features to post information about your activities on a website to a
          profile page of yours that is provided by a third party social media
          network in order to share with others within your network. Social
          Media Features are either hosted by the respective social media
          network or hosted directly on our website. To the extent the Social
          Media Features are hosted by the respective social media networks, the
          latter may receive information that you have visited our website from
          your IP address. If you are logged into your social media account, it
          is possible that the respective social media network can link your
          visit of our Services with your social media profile.
        </span>
      </p>
      <p class="c0">
        <span class="c3">
          ALL3D also allows you to log in to certain of our Services using
          sign-in services such as Facebook Connect. These services will
          authenticate your identity and provide you the option to share certain
          Personal Data with us such as your name and email address to
          pre-populate our sign-up form.
        </span>
      </p>
      <p class="c0">
        <span class="c3">
          Your interactions with Social Media Features are governed by the
          privacy policies of the companies providing the relevant Social Media
          Features.
        </span>
      </p>

      <p class="c0">
        <span class="c8 h3-text">4.6 Telephony Log Information</span>
        <span class="c3">
          <br />
          If you use certain features of our services on a mobile device, we may
          also collect telephony log information (like phone numbers, time and
          date of calls, duration of calls, SMS routing information and types of
          calls), device event information (such as crashes, system activity,
          hardware settings, browser language), and location information
          (through IP address, GPS, and other sensors that may, for example,
          provide us with information on nearby devices, Wi-Fi access points and
          cell towers).
        </span>
      </p>

      <div class="h2-height"></div>
      <h2 class="c0">
        <span class="c8 h2-text" style={{ textTransform: "capitalize" }}>
          5. Purposes For Which we process Personal Data and the legal bases on
          which we rely
        </span>
      </h2>
      <p class="c0">
        <span class="c3">
          We collect and process your Personal Data for the purposes and on the
          legal bases identified in the following:
        </span>
      </p>
      <ul class="list-indent">
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Providing our Services: We process your Personal Data to perform our
            contract with you for the use of our Services and to fulfill our
            obligations under applicable terms of use/service; where we have not
            entered into a contract with you, we base the processing of your
            Personal Data on our legitimate interest to operate and administer
            our Services and to provide you with content you access and request
            (e.g., to download content from our Services);
          </span>
        </li>
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Promoting security of our Services: We process your Personal Data by
            tracking use of our Services, creating aggregated, non-personal
            data, verifying accounts and activity, investigating suspicious
            activity and enforcing our terms and policies, to the extent this is
            necessary for our legitimate interest in promoting the safety and
            security of the systems and applications used for our Services and
            in protecting our rights and the rights of others;
          </span>
        </li>
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Managing user registrations: If you have registered for an account
            with us, we process your Personal Data by managing your user account
            for the purpose of performing our contract with you according to
            applicable terms of service;
          </span>
        </li>
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Handling contact and user support requests: If you fill out a
            “Contact Me” web form or request user support, or if you contact us
            by other means, we process your Personal Data to perform our
            contract with you and to the extent it is necessary for our
            legitimate interest in fulfilling your requests and communicating
            with you;
          </span>
        </li>
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Managing event registrations and attendance: We process your
            Personal Data to plan and host events or webinars for which you have
            registered or that you attend, including sending related
            communications to you, to perform of our contract with you;
          </span>
        </li>
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Managing contests or promotions: If you register for a contest or
            promotion, we process your Personal Data to perform our contract
            with you. Some contests or promotions have additional rules
            containing information about how we will process your Personal Data;
          </span>
        </li>

        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Managing payments: If you have provided financial information to us,
            we process your Personal Data to verify that information and to
            collect payments to the extent that doing so is necessary to
            complete a transaction and perform our contract with you;
          </span>
        </li>

        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Developing and improving our Services: We process your Personal Data
            to analyze trends and to track your usage of our Services and
            interactions with our emails to the extent it is necessary for our
            legitimate interest in developing and improving our Services and
            providing our users with more relevant and interesting content;
          </span>
        </li>

        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Registering office visitors: We process your Personal Data for
            security reasons, to register visitors to our offices and to manage
            non-disclosure agreements that visitors may be required to sign, to
            the extent such processing is necessary for our legitimate interest
            in protecting our offices and our confidential information against
            unauthorized access.
          </span>
        </li>

        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Displaying personalized advertisements and content: We process your
            Personal Data to conduct marketing research, advertise to you,
            provide personalized information about us on and off our Services
            and to provide other personalized content based upon your activities
            and interests to the extent it is necessary for our legitimate
            interest in advertising our Services or, where necessary, to the
            extent you have provided your prior consent (please see the "Your
            rights relating to your Personal Data" section, below, to learn how
            you can control how the processing of your Personal Data by ALL3D
            for personalized advertising purposes);
          </span>
        </li>

        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Sending marketing communications: We will process your Personal Data
            to send you marketing information, product recommendations and other
            non-transactional communications (e.g., marketing newsletters,
            telemarketing calls, SMS, or push notifications) about us and our
            affiliates and partners, including information about our products,
            promotions or events as necessary for our legitimate interest in
            conducting direct marketing or to the extent you have provided your
            prior consent (please see the "Your rights relating to your Personal
            Data" section, below, to learn how you can control the processing of
            your Personal Data by ALL3D for marketing purposes);
          </span>
        </li>

        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Complying with legal obligations: We process your Personal Data when
            cooperating with public and government authorities, courts or
            regulators in accordance with our legal obligations under applicable
            laws to the extent this requires the processing or disclosure of
            Personal Data to protect our rights or is necessary for our
            legitimate interest in protecting against misuse or abuse of our
            websites, protecting personal property or safety, pursuing remedies
            available to us and limiting our damages, complying with judicial
            proceedings, court orders or legal processes or to respond to lawful
            requests.
          </span>
        </li>
      </ul>

      <p class="c0">
        <span class="c3">
          Where we need to collect and process Personal Data by law, or under a
          contract we have entered into with you, and you fail to provide the
          required Personal Data when requested, we may not be able to perform
          our contract with you.
        </span>
      </p>

      <div class="h2-height"></div>
      <h2 class="c0">
        <span class="c8 h2-text">6. Who do we Share Personal Data With?</span>
      </h2>
      <p class="c0">
        <span class="c3">We may share your Personal Data as follows:</span>
      </p>

      <ul class="list-indent">
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            With our contracted service providers, who provide services such as
            IT and system administration and hosting, 3D model creation, credit
            card processing, research and analytics, marketing, customer support
            and data enrichment for the purposes and pursuant to the legal bases
            described above; such service providers comprise companies located
            in the countries in which we operate;
          </span>
        </li>

        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            If you use our Services to register for an event or webinar
            organized by one of our affiliates, with the affiliate to the extent
            this is required on the basis of the affiliate’s contract with you
            to process your registration and ensure your participation in the
            event; in such instances, our affiliate will process the relevant
            Personal Data as a separate controller and will provide you with
            further information on the processing of your Personal Data, where
            required;
          </span>
        </li>

        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            If you attend an event or webinar organized by us, or download or
            access an asset on our website, with sponsors of the event. If
            required by applicable law, you may consent to such sharing via the
            registration form or by allowing your attendee badge to be scanned
            at a sponsor booth. In these circumstances, your information will be
            subject to the sponsors’ Privacy Policies. If you do not wish for
            your information to be shared, you may choose to not opt-in via
            event/webinar registration or elect to not have your badge scanned,
            or you can opt-out in accordance with Section 10 below;
          </span>
        </li>

        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            With sponsors of contests or promotions for which you register;
          </span>
        </li>

        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            With third-party social media networks, advertising networks and
            websites, which usually act as separate controllers, so that ALL3D
            can market and advertise on third party platforms and websites;
          </span>
        </li>

        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            In individual instances, with professional advisers acting as
            processors or joint controllers including lawyers, bankers, auditors
            and insurers based in countries in which we operate who provide
            consultancy, banking, legal, insurance and accounting services, and
            to the extent we are legally obliged to share or have a legitimate
            interest in sharing your Personal Data;
          </span>
        </li>

        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            With affiliates within the ALL3D corporate group and companies that
            we acquire in the future when they are made part of the ALL3D
            corporate group, to the extent such sharing of data is necessary to
            fulfill a request you have submitted via our Services or for
            customer support, marketing, technical operations and account
            management purposes; and,
          </span>
        </li>

        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            If we are involved in a merger, reorganization, dissolution or other
            fundamental corporate change, or sell a website or business unit, or
            if all or a portion of our business, assets or stock are acquired by
            a third party, with such third party. In accordance with applicable
            laws, we will use reasonable efforts to notify you of any transfer
            of Personal Data to an unaffiliated third party.
          </span>
        </li>
      </ul>

      <p class="c0">
        <span class="c3">
          Any Personal Data or other information you choose to submit in
          communities, forums, blogs or chat rooms on our Services may be read,
          collected and used by others who visit these forums, depending on your
          account settings.
        </span>
      </p>

      <p class="c0">
        <span class="c3">
          For further information on the recipients of your Personal Data,
          please contact us by using the information in the “Contacting us”
          section, below.
        </span>
      </p>

      <div class="h2-height"></div>
      <h2 class="c0">
        <span class="c8 h2-text">
          7. International Transfer of Personal Data
        </span>
      </h2>
      <p class="c0">
        <span class="c3">
          Your Personal Data may be collected, transferred to and stored by us
          in the United States and by our affiliates in other countries where we
          operate.
        </span>
      </p>

      <p class="c0">
        <span class="c3">
          Therefore, your Personal Data may be processed outside the European
          Economic Area (EEA), and in countries which are not subject to an
          adequacy decision by the European Commission and which may not provide
          for the same level of data protection as the EEA. In this event, we
          will ensure that the recipient of your Personal Data offers an
          adequate level of protection, for instance by entering into standard
          contractual clauses for the transfer of data as approved by the
          European Commission (Art. 46 GDPR), or we will ask you for your prior
          consent to such international data transfers.
        </span>
      </p>

      <div class="h2-height"></div>
      <h2 class="c0">
        <span class="c8 h2-text">8. Children</span>
      </h2>
      <p class="c0">
        <span class="c3">
          Our Services are not directed at children. We do not knowingly collect
          Personal Data from children under the age of 13. If you are a parent
          or guardian and believe your child has provided us with Personal Data
          without your consent, please contact us by using the information in
          the “Contacting us” section, below, and we will take steps to delete
          such Personal Data from our systems.
        </span>
      </p>

      <div class="h2-height"></div>
      <h2 class="c0">
        <span class="c8 h2-text">
          9. How Long Do We Keep Your Personal Data?
        </span>
      </h2>
      <p class="c0">
        <span class="c3">
          We may retain your Personal Data for a period of time consistent with
          the original purpose of collection (see the "Purposes for which we
          process Personal Data and the legal bases on which we rely" section,
          above). We determine the appropriate retention period for Personal
          Data on the basis of the amount, nature and sensitivity of your
          Personal Data processed, the potential risk of harm from unauthorized
          use or disclosure of your Personal Data and whether we can achieve the
          purposes of the processing through other means, as well as on the
          basis of applicable legal requirements (such as applicable statutes of
          limitation).
        </span>
      </p>

      <p class="c0">
        <span class="c3">
          After expiry of the applicable retention periods, your Personal Data
          will be deleted. If there is any data that we are unable, for
          technical reasons, to delete entirely from our systems, we will put in
          place appropriate measures to prevent any further use of such data.
        </span>
      </p>

      <p class="c0">
        <span class="c3">
          For further information on applicable data retention periods, please
          contact us by using the information in the “Contacting us” section,
          below.
        </span>
      </p>

      <div class="h2-height"></div>
      <h2 class="c0">
        <span class="c8 h2-text">
          10. Your Rights Relating to Your Personal Data
        </span>
      </h2>
      <p class="c0">
        <span class="c8 h3-text">10.1 Your Rights</span>
        <span class="c3">
          <br />
          You have certain rights relating to your Personal Data, subject to
          local data protection laws. Depending on the applicable laws and, in
          particular, if you are located in the EEA, these rights may include:
        </span>
      </p>

      <ul class="list-indent">
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            To access your Personal Data held by us (right to access);
          </span>
        </li>
        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            To rectify inaccurate Personal Data and, taking into account the
            purpose of processing the Personal Data, ensure it is complete
            (right to rectification);
          </span>
        </li>

        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            To erase/delete your Personal Data, to the extent permitted by
            applicable data protection laws (right to erasure; right to be
            forgotten);
          </span>
        </li>

        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            To restrict our processing of your Personal Data, to the extent
            permitted by law (right to restriction of processing);
          </span>
        </li>

        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            To transfer your Personal Data to another controller, to the extent
            possible (right to data portability);
          </span>
        </li>

        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            To object to any processing of your Personal Data carried out on the
            basis of our legitimate interests (right to object). Where we
            process your Personal Data for direct marketing purposes or share it
            with third parties for their own direct marketing purposes, you can
            exercise your right to object at any time to such processing without
            having to provide any specific reason for such objection;
          </span>
        </li>

        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            Not to be subject to a decision based solely on automated
            processing, including profiling, which produces legal effects
            ("Automated Decision-Making"); and,
          </span>
        </li>

        <li class="c0 c6" style={{ color: "#333333" }}>
          <span class="c1" style={{ color: "#333333" }}>
            To the extent we base the collection, processing and sharing of your
            Personal Data on your consent, to withdraw your consent at any time,
            without affecting the lawfulness of the processing based on such
            consent before its withdrawal.
          </span>
        </li>
      </ul>

      <p class="c0">
        <span class="c3">
          If you are a resident of California, under the age of 18 and have
          registered for an account with us, you may ask us to remove content or
          information that you have posted to our Services. Please note that
          your request does not ensure complete or comprehensive removal of the
          content or information, because, for example, some of your content may
          have been reposted by another visitor to our Services.
        </span>
      </p>

      <p class="c0">
        <span class="c8 h3-text">10.2 How to Exercise Your Rights</span>
        <span class="c3">
          <br />
          To exercise your rights, please contact us by using the information in
          the “Contacting us” section, below. We try to respond to all
          legitimate requests within one month and will contact you if we need
          additional information from you in order to honor your request.
          Occasionally it may take us longer than a month, taking into account
          the complexity and number of requests we receive. If you are an
          employee of an ALL3D customer, we recommend you contact your company’s
          system administrator for assistance in correcting or updating your
          information.
        </span>
      </p>
      <p class="c0">
        <span class="c3">
          Some registered users may update their user settings, profiles,
          organization settings and event registrations by logging into their
          accounts and editing their settings or profiles.
        </span>
      </p>

      <p class="c0">
        <span class="c3">
          To update your billing information, discontinue your account and/or
          request return or deletion of your Personal Data and other information
          associated with your account, please contact us by using the
          information in the “Contacting us” section, below.
        </span>
      </p>

      <p class="c0">
        <span class="c8 h3-text">
          10.3 Your Rights Relating to Customer Data
        </span>
        <span class="c3">
          <br />
          As described above, we may also process Personal Data in the role of a
          processor (see the "Responsible ALL3D entity" section above). If your
          data has been submitted to us by an ALL3D customer and you wish to
          exercise any rights you may have under applicable data protection
          laws, please inquire with the applicable customer directly. Because we
          may only access a customer’s data upon instruction from that customer,
          if you wish to make your request directly to us, please provide to us
          the name of the ALL3D customer who submitted your data to us. We will
          refer your request to that customer, and will support them as needed
          in responding to your request within a reasonable timeframe.
        </span>
      </p>

      <p class="c0">
        <span class="c8 h3-text" style={{ textTransform: "capitalize" }}>
          10.4 Your preferences for email and SMS marketing communications
        </span>
        <span class="c3">
          <br />
          If we process your Personal Data for the purpose of sending you
          marketing communications, you may manage your receipt of marketing and
          non-transactional communications from us by clicking on the
          “unsubscribe” link located on the bottom of our marketing emails, by
          replying or texting ‘STOP’ if you receive SMS communications or by
          turning off push notifications on our apps on your device.
          Additionally, you may unsubscribe here or by contacting us using the
          information in the “Contacting us" section, below. Please note that
          opting out of marketing communications does not opt you out of
          receiving important business communications related to your current
          relationship with us, such as communications about your subscriptions
          or event registrations, service announcements or security information.
        </span>
      </p>

      <p class="c0">
        <span class="c8 h3-text">
          10.5 Your Preferences For Telemarketing Communications
        </span>
        <span class="c3">
          <br />
          If you want your phone number to be added to our internal Do-Not-Call
          telemarketing register, please contact us by using the information in
          the “Contacting us” section, below. Please include your first name,
          last name, company and the phone number you wish to add to our
          Do-Not-Call register.
        </span>
      </p>

      <p class="c0">
        <span class="c3">
          Alternatively, you can always let us know during a telemarketing call
          that you do not want to be called again for marketing purposes.
        </span>
      </p>

      <div class="h2-height"></div>
      <p class="c0">
        <span class="c8 h2-text">11. How We Secure Your Personal Data</span>
      </p>
      <p class="c0">
        <span class="c3">
          We take precautions including organizational, technical and physical
          measures to help safeguard against the accidental or unlawful
          destruction, loss, alteration and unauthorized disclosure of, or
          access to, the Personal Data we process or use.
        </span>
      </p>

      <p class="c0">
        <span class="c3">
          While we follow generally accepted standards to protect Personal Data,
          no method of storage or transmission is 100% secure. You are solely
          responsible for protecting your password, limiting access to your
          devices and signing out of Services after your sessions. If you have
          any questions about the security of our Services, please contact us by
          using the information in the “Contacting us” section, below.
        </span>
      </p>

      <div class="h2-height"></div>
      <h2 class="c0">
        <span class="c8 h2-text">12. Changes to this Privacy Policy</span>
      </h2>
      <p class="c0">
        <span class="c3">
          We will update this Privacy Policy from time to time to reflect
          changes in our practices, technologies, legal requirements and other
          factors. If we do, we will update the “effective date” at the top of
          this Privacy Policy. If we make an update, we may provide you with
          notice prior to the update taking effect, such as by posting a
          conspicuous notice on our website or by contacting you using the email
          address you provided.
        </span>
      </p>

      <p class="c0">
        <span class="c3">
          We encourage you to periodically review this Privacy Policy to stay
          informed about our collection, processing and sharing of your Personal
          Data.
        </span>
      </p>

      <div class="h2-height"></div>
      <h2 class="c0">
        <span class="c8 h2-text">13. Contacting Us</span>
      </h2>
      <p class="c0">
        <span class="c3">
          To exercise your rights regarding your Personal Data, or if you have
          questions regarding this Privacy Policy or our privacy practices
          please{" "}
          <span class="c9">
            <a class="c4" href="/contact-us" target="_blank">
              contact us
            </a>
          </span>
          . We are committed to working with you to obtain a fair resolution of
          any complaint or concern about privacy. If, however, you believe that
          we have not been able to assist with your complaint or concern, and
          you are located in the EEA, you have the right to lodge a complaint
          with the competent supervisory authority.
        </span>
      </p>
      <p class="c0">
        <span class="c3" style={{ color: "#333333" }}>
          The effective date of this Privacy Policy is July 29th, 2020.
        </span>
      </p>
      {/* <p class="c0 c14 c21"><span class="c1"></span></p> */}
      {/* <div>
            <p class="c14 c23"><span class="c11 c24"></span></p>
        </div> */}
    </body>
  );
}

export default Privacy;
