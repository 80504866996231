import React from "react";
import { Link } from "react-router-dom";
import TextConstants from "../../../TextConstants";

function Footer() {
  return (
    <>
      <WebVersion />
      <MobileVersion />
    </>
  );
}

function SocialIcons() {
  return (
    <span className="socialIcons col " style={{ padding: 0 }}>
      <div className="iconHolder justify-space-between">
        <a href="https://www.instagram.com/all3d.ai/" target="_blank">
          <img
            className="social-icons"
            src="/landingPage/insta.svg"
            alt="Instagram"
          />
        </a>
        <a href="https://twitter.com/all3d_ai" target="_blank">
          <img
            className="social-icons"
            src="/landingPage/twitter.svg"
            alt="Twitter"
          />
        </a>
        <a
          href="https://www.linkedin.com/company/all3d-ai/about/"
          target="_blank"
        >
          <img
            className="social-icons"
            src="/landingPage/linkedin.svg"
            alt="LinkedIn"
          />
        </a>
        <a href="https://www.facebook.com/all3dai/" target="_blank">
          <img
            className="social-icons"
            src="/landingPage/fb.svg"
            alt="Facebook"
          />
        </a>
      </div>
    </span>
  );
}

const { phone, address } = TextConstants.HIGH_POINT_FOOTER;

function MobileVersion() {
  return (
    <div className="d-lg-none">
      <div className="d-flex ">
        <div
          className="col-12 footer-sec-3-pd left black-2b"
          style={{ background: "#23222D" }}
        >
          <div className="col-lg-3 col-12 justify-in-center">
            <Link className="navbar-brand" to={"/"}>
              <img
                src="/All3DLogo.svg"
                width="54px"
                height="15px"
                alt="ALL3D"
              />
            </Link>
          </div>

          <div
            style={{ marginTop: 12, marginBottom: 14 }}
            className="col-lg-3 col-12 font graphik-regular f-14 w-300 white justify-in-center"
          >
            {phone}
          </div>

          <a
            className="col-lg-3 col-12 font graphik-regular f-14 w-300 white justify-in-center"
            style={{ marginTop: 12, marginBottom: 14 }}
            href="https://www.google.com/maps/place/541+19th+Ave,+San+Francisco,+CA+94121,+USA/@37.7776905,-122.4784448,17z/data=!3m1!4b1!4m5!3m4!1s0x80858712283982e9:0xb58805bd65df56bd!8m2!3d37.7776905!4d-122.4784448"
          >
            {address}
          </a>
          <div className="col-lg-3 col-12 justify-in-center">
            <SocialIcons />
          </div>
        </div>
      </div>
    </div>
  );
}
function WebVersion() {
  return (
    <div className="d-none d-lg-block ">
      <div className=" row justify-space-between ">
        <div className="col-12 footer-sec-3-pd black-2b ">
          <div className="col-lg-12 col-12 justify-in-center ">
            <Link className="navbar-brand" to={"/"} style={{ marginRight: 25 }}>
              <img
                src="/All3DLogo.svg"
                width="54px"
                height="15px"
                alt="ALL3D"
              />
            </Link>
            <div
              className="font graphik-regular f-14 w-300 white justify-in-center "
              style={{ marginRight: 25 }}
            >
              {phone}
            </div>
            <a
              style={{ marginRight: 25 }}
              className="font graphik-regular f-14 w-300 white justify-in-center"
              href="https://www.google.com/maps/place/541+19th+Ave,+San+Francisco,+CA+94121,+USA/@37.7776905,-122.4784448,17z/data=!3m1!4b1!4m5!3m4!1s0x80858712283982e9:0xb58805bd65df56bd!8m2!3d37.7776905!4d-122.4784448"
            >
              {address}
            </a>
            <div style={{ marginRight: 25 }}>
              <SocialIcons />
            </div>
          </div>
        </div>{" "}
      </div>{" "}
    </div>
  );
}

export default Footer;
