export const isMatching = (a, b) => {

    let substringMatch = false
    if (a.length >= 3) {
        if (b.includes(a)) {
            substringMatch = true
        }
    }
  
    let c = b.split(" ");
    b = b.replace(/[^a-zA-Z0-9 ]/g, " ");
    a = a.split(" ");
    b = b.split(" ");
    let match = [];
    let match2 = [];
    a.map((word) => {
        b.filter((string) => {
            if (word != "" && string != "" && (string == word)) {
                match.push(word)
            }
            
        } )
    });
  
    a.map((word) => {
        c.filter((string) => {
            if (word != "" && string != "" && (string == word)) {
                match2.push(word)
            }
            
        } )
    });
  
    return (match.length > 0 || match2.length > 0 || substringMatch);
}

export const getCategoriesData = ( catDataAray ) => {
    var catDataDict = {};
  
    for (let i = 1; i <= (catDataAray).length; i++) {
        if (catDataAray[ i - 1 ].parent_id == "") {
            catDataDict[ catDataAray[ i - 1 ].category_id ] = []
        }  
    }
    for (let i = 1; i <= (catDataAray).length; i++) {
        if (catDataAray[ i - 1 ].parent_id != "" && !catDataAray[ i - 1 ].hidden_category) {
  
            catDataDict[ catDataAray[ i - 1 ].parent_id ].push( {
                    title: catDataAray[ i - 1 ].name,
                    value: catDataAray[ i - 1 ].name,
                    key: catDataAray[ i - 1 ].category_id,
                    hidden_category: catDataAray[ i - 1 ].hidden_category
                }
            );
  
        }  
    }
  
    return catDataDict;
  }
  

export const getCookie = (cname) => {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
}


export const setCookie =(cname, cvalue, exdays) => {
    var d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    var expires = "expires="+d.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const deviceType = () => {
    if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
        return 'mobile'
    }
    return 'desktop';
}

export const imageFormat = (img_filename) => {

    let extension = '';
    let format_text = '';
    extension = img_filename.split('.').pop()
    if(extension == 'jpg')
        format_text = 'JPG'
    else if(extension == 'jpeg')
        format_text = 'JPEG'
    else if(extension == 'tiff')
        format_text = 'TIFF'
    else if(extension == 'png')
        format_text = 'PNG'
    
    return format_text;
}

export const getMaterialsData = ( catDataAray ) => {
    var catDataDict = {};
  
    for (let i = 1; i <= (catDataAray).length; i++) {
        if (catDataAray[ i - 1 ].parent_id == "") {
            catDataDict[ catDataAray[ i - 1 ].material_id ] = []
        }
    }
  
  
    for (let i = 1; i <= (catDataAray).length; i++) {
        if (catDataAray[ i - 1 ].parent_id != "") {
  
            catDataDict[ catDataAray[ i - 1 ].parent_id ].push( {
                    title: catDataAray[ i - 1 ].name,
                    value: catDataAray[ i - 1 ].name,
                    key: catDataAray[ i - 1 ].material_id,
                }
            );
  
        }
    }
  
    return catDataDict;
  }

  export const getStringsRelevance = (a, b, searchKeywords, targetKeywords) => {

    let substringMatch = false
    if (a.length >= 3) {
        if (b.includes(a)) {
            substringMatch = true
        }
    }

    let match = [];
    searchKeywords.map((word) => {
        targetKeywords.filter((string) => {
            if (word != "" && string != "" && (string == word)) {
                match.push(word)
            }
        } )
    });

    let relevance = 0;
    if (substringMatch) {relevance++;}
    relevance = relevance + (match.length*2);
    return relevance
}