import React, { useState } from "react";
import TextConstants from "../../../TextConstants";
import "./index.scss";
import { Card, Modal } from "antd";
import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 1280 },
    items: 3,
  },

  tablet: {
    breakpoint: { max: 1280, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const ClientTestimonials = () => {
  const [clientCarousel, setClientCarousel] = useState();
  const [selectedTestimonial, setSelectedTestimonial] = useState(null);

  const openModalPopup = (client) => {
    setSelectedTestimonial(client);
  };
  const closePopup = () => {
    setSelectedTestimonial(null);
  };

  const { title, btn_text } = TextConstants.CLIENT_TESTIMONIALS;

  return (
    <>
      <div className="container-fluid client-testimony-container">
        <div className="client-testimony">
          <div className="d-none d-lg-block">
            <div
              className="col-lg-12 col-12"
              id="textSection"
              style={{ margin: "auto" }}
            >
              <h2
                className="font lora f-32 w-400 ws-6 black-2b justify-in-center"
                style={{ marginBottom: 40 }}
              >
                {title}
              </h2>
            </div>

            <div className="justify-space-between">
              <button
                type="button"
                className={"btn carousel-btns"}
                onClick={() => clientCarousel.previous()}
              >
                <ArrowLeftOutlined
                  style={{ fontSize: 20, lineHeight: "16px" }}
                />
              </button>
              <Carousel
                ref={(el) => setClientCarousel(el)}
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                autoPlay={false}
                arrows={false}
                autoPlaySpeed={8000}
                keyBoardControl={false}
                containerClass="carousel-container"
              >
                {TextConstants.TESTIMONIALS.map((client) => (
                  <div className="card-inner-pd">
                    <div
                      style={{ backgroundImage: "url(" + client.bg + ")" }}
                      className="img-fluid testimonial-bg-img"
                    >
                      <div className="inner-text-pd">
                        <Card className="testimonial-card position-card">
                          <div
                            className="justify-in-start"
                            style={{ marginBottom: 18 }}
                          >
                            <img
                              className="headshot-img"
                              alt="client headshot"
                              src={client.headshot}
                            />
                            <div style={{ marginLeft: 12 }}>
                              <h5
                                className="font graphik-medium f-16 w-500 black-2b"
                                style={{ marginBottom: 0 }}
                              >
                                {client.name}
                              </h5>
                              <p
                                className="font graphik-regular f-12 w-400 black-2b"
                                style={{ marginBottom: 0 }}
                              >
                                {client.position}
                              </p>
                            </div>
                          </div>
                          <p
                            className="font graphik-regular f-14 w-400 grey-light"
                            style={{ marginBottom: 16, width: "fit-content" }}
                          >
                            {client.text}
                            <a
                              className="font graphik-regular blue hover-change"
                              onClick={() => openModalPopup(client)}
                            >
                              {btn_text}
                            </a>
                          </p>
                        </Card>
                      </div>
                    </div>
                  </div>
                ))}
              </Carousel>

              <button
                type="button"
                className="btn carousel-btns brown"
                onClick={() => clientCarousel.next()}
              >
                <ArrowRightOutlined
                  style={{ fontSize: 20, lineHeight: "16px" }}
                />
              </button>
            </div>
          </div>

          <div className="d-lg-none">
            <div className="col-lg-10 col-12" id="textSection">
              <h2
                className="font lora f-20 ws-2 w-400 black-2b justify-in-center"
                style={{ marginBottom: 20 }}
              >
                {title}
              </h2>
            </div>
            <div className="row mx-auto my-auto">
              <div
                id="testimonialCarouselMobile"
                className="carousel slide w-100"
                data-ride="carousel"
              >
                <div className="carousel-inner w-100" role="listbox">
                  {TextConstants.TESTIMONIALS.map((client, index) => (
                    <div
                      className={`carousel-item ${index == 0 ? `active` : ``}`}
                    >
                      <div className="col-12">
                        <div className="card card-body" style={{ padding: 0 }}>
                          <img
                            className="img-fluid testimonial-bg-img"
                            alt="client bg"
                            src={client.bg}
                          />
                          <Card className="testimonial-card position-card">
                            <div
                              className="justify-in-start"
                              style={{ marginBottom: 18 }}
                            >
                              <img
                                className="headshot-img"
                                alt="client headshot"
                                src={client.headshot}
                              />
                              <div style={{ marginLeft: 12 }}>
                                <h5
                                  className="font graphik-medium f-14 w-500 black-2b"
                                  style={{ marginBottom: 0 }}
                                >
                                  {client.name}
                                </h5>
                                <p
                                  className="font graphik-regular f-12 w-300 black-2b"
                                  style={{ marginBottom: 0 }}
                                >
                                  {client.position}
                                </p>
                              </div>
                            </div>
                            <p
                              className="font graphik-regular f-12 w-300 grey-light"
                              style={{ marginBottom: 16 }}
                            >
                              {client.text}
                              <a
                                className="font graphik-regular blue hover-change"
                                onClick={() => openModalPopup(client)}
                              >
                                {btn_text}
                              </a>
                            </p>
                            {/* Use for later */}
                            {/* <Rate allowHalf style={{ fontSize: 14, color: "#F6973F" }} disabled defaultValue={client.stars}/> */}
                          </Card>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                <div
                  className="justify-space-between"
                  style={{ marginTop: 20 }}
                >
                  <a
                    className="carousel-control-prev w-auto"
                    href="#testimonialCarouselMobile"
                    role="button"
                    data-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon carousel-btns"
                      aria-hidden="true"
                    >
                      <ArrowLeftOutlined
                        style={{ fontSize: 16, lineHeight: "16px" }}
                      />
                    </span>
                    <span className="sr-only">Previous</span>
                  </a>
                  <ul className="carousel-indicators">
                    <li
                      data-target="#testimonialCarouselMobile"
                      data-slide-to="0"
                      className="active"
                    ></li>
                    <li
                      data-target="#testimonialCarouselMobile"
                      data-slide-to="1"
                    ></li>
                    <li
                      data-target="#testimonialCarouselMobile"
                      data-slide-to="2"
                    ></li>
                    <li
                      data-target="#testimonialCarouselMobile"
                      data-slide-to="3"
                    ></li>
                  </ul>

                  <a
                    className="carousel-control-next w-auto"
                    href="#testimonialCarouselMobile"
                    role="button"
                    data-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon carousel-btns brown"
                      aria-hidden="true"
                    >
                      <ArrowRightOutlined
                        style={{ fontSize: 16, lineHeight: "16px" }}
                      />
                    </span>
                    <span className="sr-only">Next</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="client-popup"
        visible={selectedTestimonial != null}
        onCancel={closePopup}
        destroyOnClose={true}
        maskClosable={false}
        closable={true}
      >
        {selectedTestimonial != null && (
          <div className="popup-setting">
            <div className="justify-in-start" style={{ marginBottom: 18 }}>
              <img
                className="headshot-img"
                alt="client headshot"
                src={selectedTestimonial.headshot}
              />
              <div style={{ marginLeft: 12 }}>
                <h5
                  className="font graphik-medium f-16 w-500 black-2b"
                  style={{ marginBottom: 0 }}
                >
                  {selectedTestimonial.name}
                </h5>
                <p
                  className="font graphik-regular f-14 w-400 black-2b"
                  style={{ marginBottom: 0 }}
                >
                  {selectedTestimonial.position}
                </p>
              </div>
            </div>
            <p
              className="font graphik-regular f-14 w-400 grey-light"
              style={{ marginBottom: 16, width: "fit-content" }}
            >
              {selectedTestimonial.full_text}
            </p>
          </div>
        )}
      </Modal>
    </>
  );
};

export default ClientTestimonials;
