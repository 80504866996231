import { Card } from "antd";
import React from "react";
import FileConstants from "../../../FileConstants";
import TextConstants from "../../../TextConstants";

const WhyChooseUs = () => {
  return (
    <div className="container-fluid why-choose-us-container">
      <div className="why-choose-us">
        <WebVersion />
        <MobileVersion />
      </div>
    </div>
  );
};

const { title, title_part_1, title_part_2 } = TextConstants.WHY_CHOOSE_US;
const WebVersion = () => {
  return (
    <div className="d-none d-lg-block">
      <div className="row" style={{ alignItems: "flex-start" }}>
        <div
          className="col-3"
          id="textSection"
          style={{ padding: "0 5% 0px 0px" }}
        >
          <h2
            style={{ margin: 0 }}
            className="font lora f-32 ws-6 w-400 black-2b"
          >
            {title_part_1} <br /> {title_part_2}
          </h2>
        </div>
        {FileConstants.why_choose_us.map((why) => (
          <div className="col-3" id="textSection">
            <h5 className="font graphik-medium f-16 w-500 brown">
              {why.heading}
            </h5>
            <p
              style={{ margin: 0 }}
              className="font graphik-regular f-16 w-400 grey-light hyphen-justify"
            >
              {why.text}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

const MobileVersion = () => {
  return (
    <div className="d-lg-none">
      <div className="col-lg-10 col-12" id="textSection">
        <h2
          style={{ marginBottom: 20 }}
          className="font lora f-20 ws-2 w-400 black-2b justify-in-center"
        >
          {title}
        </h2>
      </div>
      {FileConstants.why_choose_us.map((why) => (
        <div
          className="col-12 justify-in-center"
          style={{ flexDirection: "column", marginBottom: 40 }}
          id="textSection"
        >
          <h5
            className="font graphik-medium f-16 w-500 brown"
            style={{ textAlign: "center", marginBottom: 4 }}
          >
            {why.heading}
          </h5>
          <p
            style={{ margin: 0, textAlign: "center" }}
            className="font graphik-regular f-14 w-400 black-2b"
          >
            {why.text}
          </p>
        </div>
      ))}
    </div>
  );
};

export default WhyChooseUs;
