import React, { useState, useEffect, useCallback } from 'react';
import {
    Card,
    message,
    Tooltip
} from 'antd';
import './index.scss'
import * as ENVIRONMENT from '../../env';
import ThreeSixtyViewer from '../../ThreeSixtyViewer';
import { LoadingOutlined } from '@ant-design/icons';

const isTouchDevice = require('is-touch-device');


const SpaceDetailComponent = (props) => {

    const [viewType, setViewType] = useState('360');
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [loaded, setLoader] = useState(false);
    const [threeSixtyViews, setThreeSixtyViews] = useState({});
    const [selected360, setSelected360] = useState('');

   
    const setFullScreen = (value) => {
        setIsFullScreen(value);
        if (value) {
            if (threeSixtyViews[selected360]) {
                threeSixtyViews[selected360].toggleFull(true);
            }
        }
        else {
            if (threeSixtyViews[selected360]) {
                threeSixtyViews[selected360].toggleFull(false);
            }
        }
    }

    const changeSelected360 = (name) => {
        props.spaceDetails.three_sixties.map((x, index) => {
            if ((threeSixtyViews[x.split('.')[0]] != null) && (x != name)) {
                threeSixtyViews[x.split('.')[0]].changeDisplay("none");
            }
        });
        setSelected360(name.split('.')[0]);
           
        if (threeSixtyViews[name.split('.')[0]] == null) {

            setTimeout(() => {
                let temp_arr = threeSixtyViews;
                temp_arr[name.split('.')[0]] = new ThreeSixtyViewer(name.split('.')[0],`loading-container-${name.split('.')[0]}`,ENVIRONMENT.ROOM_PANORAMA_ASSET + props.spaceDetails.room_id + '/' + name,false);
                setThreeSixtyViews(temp_arr);
            }, 1000);
        }
        else if (threeSixtyViews[name.split('.')[0]] != null) {
            threeSixtyViews[name.split('.')[0]].changeDisplay("block");
        }
        
    }

    const escFunction = useCallback((event) => {
        if (event.keyCode === 27) {
            setIsFullScreen(false);
        }
    }, []);

    useEffect(() => {
        document.addEventListener("keydown", escFunction, false);
        return () => {
            document.removeEventListener("keydown", escFunction, false);
        };
    }, []);

    useEffect(() => {
        console.log(props.spaceDetails)
        if (props.spaceDetails && (props.spaceDetails.model_status == "4" || props.spaceDetails.model_status == "5" )){
            if (props.spaceDetails.three_sixties && props.spaceDetails.three_sixties.length == 0) {
                if (threeSixtyViews['360Camera'] == null) {
                    let temp_arr = threeSixtyViews;
                    temp_arr['360Camera'] = new ThreeSixtyViewer('360Camera',`loading-container-360Camera`,ENVIRONMENT.ROOM_PANORAMA_ASSET + props.spaceDetails.room_id + '/' + '360Camera.jpg',false);
                    setThreeSixtyViews(temp_arr);    
                    
                    console.log(temp_arr)
                    setSelected360('360Camera');
                }   
            } else if (props.spaceDetails.three_sixties) {
                if (props.spaceDetails.three_sixties[0]) {
                    let url = props.spaceDetails.three_sixties[0].split('.')[0];
                    setSelected360(url);

                    if (threeSixtyViews[url] == null) {
                        setTimeout(() => {
                            let temp_arr = threeSixtyViews;
                            temp_arr[url] = new ThreeSixtyViewer(url,`loading-container-${url}`,ENVIRONMENT.ROOM_PANORAMA_ASSET + props.spaceDetails.room_id  + '/' + props.spaceDetails.three_sixties[0],false);
                            setThreeSixtyViews(temp_arr);
                            setSelected360(url);

                        }, 1000);
                    }
                }     
            }
        }
    },[props.spaceDetails]);

    useEffect(() => {
       
            let isTouch = false;
            let classVar = 'threeDImage'
            if (isTouchDevice()) {
              let isIos = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
                classVar += ' disable-drag'
                isTouch = isIos
            }
            
            let iOSPermission =  isTouch;
            
        
            if (iOSPermission) {
              if (window.DeviceOrientationEvent) {
                if (typeof (window.DeviceOrientationEvent.requestPermission) == 'function') {
                  window.DeviceOrientationEvent.requestPermission()
                    .then(response => {
                      if (response !== 'granted') {
                        showConfirm()
                      }
                    }).catch(() => {
                      showConfirm()
                    })
                }
              }
            }
          
    },[])

    const showConfirm = () => {
        if (window.confirm("Allow App to Access Motion Sensors") == true) {
            if (window.DeviceOrientationEvent) {
                if (typeof (window.DeviceOrientationEvent.requestPermission) == 'function') {
                  window.DeviceOrientationEvent.requestPermission()
                    .then(response => {
      
                    })
                    .catch((e) => {
      
                    })
                } else {
      
                }
              }
        } else {

        }
      
      }


    return(
       props.spaceDetails ? <>
        { ( (props.spaceDetails.model_status == 5 || props.spaceDetails.model_status == 4) && props.spaceDetails.three_sixties && props.spaceDetails.three_sixties.length == 0) ?
            <Card className={isFullScreen ? 'room-full-screen': 'room-viewer-normal'} bodyStyle={{padding: 0,overflow:'hidden'}}>
                <Tooltip title={(isFullScreen ? "Exit Full Screen" : "Full Screen")} placement="left">
                    <img 
                    src={isFullScreen ? "/fullScreenIcon-2.svg" : "/fullScreenIcon.jpg"}  
                    alt="Fullscreen Icon" 
                    style={{position:"absolute", top:"20px",zIndex:"5",cursor:"pointer",opacity:"0.8",borderRadius:"4px",right:"20px"}} 
                    className="product-full-screen-icon" 
                    onClick={() => setFullScreen(!isFullScreen)} type={(isFullScreen) ? "fullscreen-exit":"fullscreen"} />
                </Tooltip>
                    <div id={'360Camera'} style={{cursor:"grabbing",background:"white"}}>                           
                    </div>
                    <div id={`loading-container-360Camera`}>
                        <LoadingOutlined  className='center-loader  space-360-loader' id={`loadingImages-360Camera`}/>
                    </div>
            </Card>
            :
            (props.spaceDetails.model_status == 5 || props.spaceDetails.model_status == 4) && (props.spaceDetails.three_sixties) ?
            <>
                <Card className={isFullScreen ? 'room-full-screen': 'room-viewer-normal'} bodyStyle={{padding: isFullScreen ?0:0,overflow:'hidden'}}>
                    <Tooltip title={(isFullScreen ? "Exit Full Screen" : "Full Screen")} placement="left">
                        <img 
                        src={isFullScreen ? "/fullScreenIcon-2.svg" : "/fullScreenIcon.jpg"}  
                        alt="Fullscreen Icon" 
                        style={{position:"absolute", top:"20px",zIndex:"5",cursor:"pointer",opacity:"0.8",borderRadius:"4px",right:"20px"}} 
                        className="product-full-screen-icon" 
                        onClick={() => setFullScreen(!isFullScreen)} type={(isFullScreen) ? "fullscreen-exit":"fullscreen"} />
                    </Tooltip>
                    
                    <div id={selected360} style={{cursor:"grabbing",background:"white"}}>
                    </div>
                    <div id={`loading-container-${selected360}`}>
                        <LoadingOutlined className='center-loader space-360-loader' id={`loadingImage-${selected360}`}/>
                    </div>
                    <div className='pdp-visible '>
                        <div className="scene-card-bg scene-thumbnail" style={{background: "unset"}}> 
                            {props.spaceDetails.three_sixties.map((name,index) => (
                            <Card onClick={() => changeSelected360(name)}  key={index} bodyStyle={{ padding: "2px", minWidth:40, textAlign: "-webkit-center" }} className="scene-card-bg no-settings" style={{border: name.split(".")[0] == selected360 ? "1px solid #ffffff" : "1px solid #333333"}}> 
                                <p className="flag-card-settings"> {name.split(".")[0]}</p>
                            </Card>
                            ))}
                        </div>
                    </div>
                </Card>
                <div className='assets-visible '>
                    <div className='justify-in-start' style={{flexWrap: 'wrap',marginTop: 10}}>
                        {props.spaceDetails.three_sixties.map((name,index) => (
                            <div className='space-card-bg' onClick={() => changeSelected360(name)} style={{marginRight: 12, background: name.split(".")[0] == selected360 ? "#555555" : "rgba(0, 0, 0, 0.08)"}}>
                                <p className='manrope f-14 card-text' style={{marginBottom: 0, color: name.split(".")[0] == selected360 ? "white" : "#141414"}}>{name.split(".")[0]}</p>
                            </div>
                        ))}
                    </div>
                    <hr/>
                </div>
            </>
                :
                ""
            }
            

    </> : <LoadingOutlined/>
    );
    

}


export default SpaceDetailComponent;