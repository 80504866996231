import React from "react";
import "./index.scss";
import { Carousel } from "react-responsive-carousel";
import FileConstants from "../../../FileConstants";
import LazyLoad from "react-lazyload";
import { useEffect, useState } from "react";
import TextConstants from "../../../TextConstants";

const CreateSpaces = () => {
  const [autoPlaySetting, setAutoPlaySetting] = useState(false);
  useEffect(() => {
    FileConstants.SPACES.map((img_src, index) => {
      const img = new Image();
      img.onload = () => {
        if (index == FileConstants.SPACES.length - 1) {
          console.log("auto play on");
          setAutoPlaySetting(true);
        }
      };
      img.src = img_src;
    });
  }, []);

  return (
    <>
      <WebVersion autoPlaySetting={autoPlaySetting} />
      <MobileVersion autoPlaySetting={autoPlaySetting} />
    </>
  );
};

const { title, text } = TextConstants.CREATE_SPACES;
const WebVersion = (props) => {
  return (
    <div className="d-none d-lg-block">
      <div
        className="row justify-space-between our-product-offering"
        style={{ marginTop: 120 }}
      >
        <div className="col-lg-6 col-12" style={{ paddingRight: 50 }}>
          <h4 className="font graphik-medium f-18 w-500 black-2b">{title}</h4>
          <p className="font graphik-regular f-16 w-400 grey-light">{text}</p>
        </div>
        <div className="col-lg-6 col-12 bg-img">
          <Carousel
            className="custom-carousel-2"
            infiniteLoop
            showIndicators={true}
            autoPlay={props.autoPlaySetting}
            showThumbs={false}
            dynamicHeight
            showStatus={false}
          >
            {FileConstants.SPACES.map((img_src, index) => (
              <div>
                {index == 0 ? (
                  <img
                    className="section-img space load-lazy"
                    src={img_src}
                    height={"100%"}
                    alt={index}
                  />
                ) : (
                  <LazyLoad>
                    <img
                      className="section-img space load-lazy"
                      src={img_src}
                      height={"100%"}
                      alt={index}
                    />
                  </LazyLoad>
                )}
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

const MobileVersion = (props) => {
  return (
    <div className="d-lg-none">
      <div className="our-product-offering" style={{ marginTop: 48 }}>
        <div
          className="col-12 justify-in-center direction-flex col"
          style={{ textAlign: "center" }}
        >
          <h4 className="font graphik-medium f-16 w-500 black-2b">{title}</h4>
          <p className="font graphik-regular f-14 w-400 grey-light">{text}</p>
        </div>
        <div className="col-12 bg-img">
          <Carousel
            className="custom-carousel"
            infiniteLoop
            showIndicators={true}
            autoPlay={props.autoPlaySetting}
            showThumbs={false}
            dynamicHeight
            showStatus={false}
          >
            {FileConstants.SPACES.map((img_src, index) => (
              <div>
                {index == 0 ? (
                  <img
                    className="section-img space load-lazy"
                    src={img_src}
                    height={"100%"}
                    alt={index}
                  />
                ) : (
                  <LazyLoad>
                    <img
                      className="section-img space load-lazy"
                      src={img_src}
                      height={"100%"}
                      alt={index}
                    />
                  </LazyLoad>
                )}
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};
export default CreateSpaces;
